// src/components/MyTeam.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyService } from '../services/companyService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';
import { authService } from '../services/authService';
import { mapService } from '../services/mapService';
import Header from './Header';
import Sidebar from './Sidebar';
import { 
  FaUsers, FaUserPlus, FaWarehouse, FaBuilding, 
  FaPencilAlt, FaTrashAlt, FaKey, FaTimes, 
  FaCheck, FaExclamationTriangle, FaSearch,
  FaFilter, FaSave, FaBan
} from 'react-icons/fa';
import { debounce } from 'lodash';

function MyTeam() {
  // Core State
  const [currentUser, setCurrentUser] = useState(null);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [activeSection, setActiveSection] = useState('team');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);

  // Data State
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('all');
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredDistributionCenters, setFilteredDistributionCenters] = useState([]);

  // UI State
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', message: '' });

  // Modal State
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  // Form State
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    phone: '',
    distributionCenters: [],
    role: 'User',
    company: ''
  });

  const [newDC, setNewDC] = useState({
    name: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    company: ''
  });

  const [newCompany, setNewCompany] = useState({
    name: '',
    contactName: '',
    contactEmail: '',
    contactPhone: ''
  });

  const navigate = useNavigate();

  // Toast Helper
  const showToastMessage = (type, message) => {
    setToastMessage({ type, message });
    setShowToast(true);
    setTimeout(() => setShowToast(false), 5000);
  };

  // Data Filtering
  const filterData = useCallback((companyId, usersList, dcList, search = '') => {
    const normalizedSearch = search.toLowerCase();

    if (companyId === 'all') {
      setFilteredUsers(
        usersList.filter(user => 
          user.name.toLowerCase().includes(normalizedSearch) ||
          user.email.toLowerCase().includes(normalizedSearch)
        )
      );
      setFilteredDistributionCenters(
        dcList.filter(dc => 
          dc.name.toLowerCase().includes(normalizedSearch) ||
          dc.address?.city.toLowerCase().includes(normalizedSearch)
        )
      );
    } else {
      const filteredUsersList = usersList.filter(user => {
        const matchesSearch = 
          user.name.toLowerCase().includes(normalizedSearch) ||
          user.email.toLowerCase().includes(normalizedSearch);
        
        if (user.role === 'SystemAdmin') {
          return matchesSearch && user.companies?.includes(companyId);
        }
        return matchesSearch && user.company === companyId;
      });

      const filteredDCList = dcList.filter(dc => 
        dc.company === companyId &&
        (dc.name.toLowerCase().includes(normalizedSearch) ||
         dc.address?.city.toLowerCase().includes(normalizedSearch))
      );

      setFilteredUsers(filteredUsersList);
      setFilteredDistributionCenters(filteredDCList);
    }
    setSelectedCompany(companyId);
  }, []);

  // Debounced Search
  const debouncedSearch = useCallback(
    debounce((term) => {
      filterData(selectedCompany, users, distributionCenters, term);
    }, 300),
    [filterData, selectedCompany, users, distributionCenters]
  );

  // Search Handler
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  // Data Fetching
  const fetchCompanyData = useCallback(async (companyId = 'all') => {
    try {
      setIsLoading(true);
      setError(null);

      if (isSystemAdmin) {
        const [allCompanies, allDCs, allUsers] = await Promise.all([
          companyService.getAll(),
          distributionCenterService.getAll(),
          userService.getAllUsers()
        ]);

        setCompanies(allCompanies || []);
        setDistributionCenters(allDCs || []);
        setUsers(allUsers || []);
        filterData(companyId, allUsers, allDCs, searchTerm);
      } else {
        const [companyData, dcs, usersData] = await Promise.all([
          companyService.getById(companyId),
          distributionCenterService.getByCompany(companyId),
          userService.getByCompany(companyId)
        ]);

        setCompanies(companyData ? [companyData] : []);
        setDistributionCenters(dcs || []);
        setUsers(usersData || []);
        setFilteredDistributionCenters(dcs || []);
        setFilteredUsers(usersData || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showToastMessage('error', 'Failed to load data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [isSystemAdmin, filterData, searchTerm]);

  // Initial Load
  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = authService.getUserSession();
        if (!user) {
          navigate('/login');
          return;
        }
        
        // Get the full user document from Firestore
        const fullUserData = await userService.getById(user.id);
        if (!fullUserData) {
          throw new Error('User data not found');
        }
        
        setCurrentUser(fullUserData); // Now contains all user data including company
        const userRole = await userService.getUserRole(user.id);
        setIsSystemAdmin(userRole === 'SystemAdmin');
        const companyIdToFetch = userRole === 'SystemAdmin' ? 'all' : fullUserData.company;
        await fetchCompanyData(companyIdToFetch);
      } catch (error) {
        console.error('Error checking user:', error);
        showToastMessage('error', 'Session error. Please log in again.');
        navigate('/login');
      }
    };
    checkUser();
  }, [navigate, fetchCompanyData]);

  // Keyboard Navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') {
        setShowEditModal(false);
        setShowDeleteConfirm(null);
        setShowResetModal(false);
      }
      if (e.ctrlKey && e.key === 'f' && searchInputRef.current) {
        e.preventDefault();
        searchInputRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Form Handlers
  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      const tempPassword = Math.random().toString(36).slice(-8);
      let userData = {
        name: newUser.name,
        email: newUser.email,
        phone: newUser.phone,
        role: newUser.role,
        password: tempPassword,
      };
      
      if (newUser.role === 'SystemAdmin') {
        const allCompanies = await companyService.getAll();
        userData.companies = allCompanies.map(company => company.id);
        await userService.createSystemAdmin(userData);
      } else {
        userData.company = isSystemAdmin ? 
          (selectedCompany === 'all' ? newUser.company : selectedCompany) : 
          currentUser.company;
        userData.distributionCenters = newUser.distributionCenters;
        await userService.create(userData);
      }

      setNewUser({
        name: '',
        email: '',
        phone: '',
        distributionCenters: [],
        role: 'User',
        company: ''
      });

      await fetchCompanyData(selectedCompany);
      showToastMessage('success', `User created successfully. Temporary password: ${tempPassword}`);
    } catch (error) {
      showToastMessage('error', error.message);
    }
  };

  const handleEditUser = async () => {
    try {
      setError(null);
      const { email, ...updateData } = editingUser;
      
      // Optimistic update
      const updatedUsers = users.map(user => 
        user.id === editingUser.id ? { ...user, ...updateData } : user
      );
      setUsers(updatedUsers);
      filterData(selectedCompany, updatedUsers, distributionCenters, searchTerm);

      await userService.update(editingUser.id, updateData);
      setShowEditModal(false);
      showToastMessage('success', 'User updated successfully');
    } catch (error) {
      // Revert optimistic update
      await fetchCompanyData(selectedCompany);
      showToastMessage('error', 'Failed to update user. Please try again.');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      setError(null);
      
      // Optimistic update
      const updatedUsers = users.filter(user => user.id !== userId);
      setUsers(updatedUsers);
      filterData(selectedCompany, updatedUsers, distributionCenters, searchTerm);

      await userService.delete(userId);
      setShowDeleteConfirm(null);
      showToastMessage('success', 'User deleted successfully');
    } catch (error) {
      // Revert optimistic update
      await fetchCompanyData(selectedCompany);
      showToastMessage('error', 'Failed to delete user. Please try again.');
    }
  };

  const handleAddDC = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      const address = `${newDC.street}, ${newDC.city}, ${newDC.state} ${newDC.zip}`;
      const geocodedLocation = await mapService.geocodeAddress(address).catch(() => null);

      const dcData = {
        name: newDC.name,
        address: {
          street: newDC.street,
          city: newDC.city,
          state: newDC.state,
          zip: newDC.zip,
          latitude: geocodedLocation?.latitude,
          longitude: geocodedLocation?.longitude,
        },
        company: isSystemAdmin ? 
          (selectedCompany === 'all' ? newDC.company : selectedCompany) : 
          currentUser.company
      };

      // Optimistic update
      const newDCWithId = { ...dcData, id: Date.now() }; // Temporary ID
      setDistributionCenters([...distributionCenters, newDCWithId]);
      filterData(selectedCompany, users, [...distributionCenters, newDCWithId], searchTerm);

      await distributionCenterService.create(dcData);
      setNewDC({ name: '', street: '', city: '', state: '', zip: '', company: '' });
      showToastMessage('success', 'Distribution center added successfully');
    } catch (error) {
      // Revert optimistic update
      await fetchCompanyData(selectedCompany);
      showToastMessage('error', 'Failed to add distribution center. Please try again.');
    }
  };

  const handleDeleteDC = async (dcId) => {
    try {
      setError(null);
      
      // Optimistic update
      const updatedDCs = distributionCenters.filter(dc => dc.id !== dcId);
      setDistributionCenters(updatedDCs);
      filterData(selectedCompany, users, updatedDCs, searchTerm);

      await distributionCenterService.delete(dcId);
      setShowDeleteConfirm(null);
      showToastMessage('success', 'Distribution center deleted successfully');
    } catch (error) {
      // Revert optimistic update
      await fetchCompanyData(selectedCompany);
      showToastMessage('error', 'Failed to delete distribution center. Please try again.');
    }
  };

  const handleResetPassword = async () => {
    try {
      setError(null);
      await authService.resetPassword(resetEmail);
      setShowResetModal(false);
      showToastMessage('success', 'Password reset email sent successfully');
    } catch (error) {
      showToastMessage('error', 'Failed to reset password. Please try again.');
    }
  };

  // UI Components
  const TabButton = ({ active, onClick, children, icon: Icon }) => (
    <button
      onClick={onClick}
      className={`flex items-center px-6 py-3 font-medium rounded-lg transition-all
        ${active ? 
          'bg-blue-600 text-white shadow-lg transform scale-105' : 
          'text-gray-600 hover:bg-gray-100 hover:transform hover:scale-102'
        }`}
    >
      {Icon && <Icon className={`w-5 h-5 mr-2 ${active ? 'text-white' : 'text-gray-500'}`} />}
      {children}
    </button>
  );

  const FormInput = ({ label, type = 'text', icon: Icon, error, ...props }) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative">
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 w-5 text-gray-400" />
          </div>
        )}
        <input
          type={type}
          className={`w-full px-3 py-2 border rounded-lg shadow-sm transition-colors
            ${Icon ? 'pl-10' : ''}
            ${error 
              ? 'border-red-300 focus:ring-red-500 focus:border-red-500' 
              : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
            }
            focus:outline-none focus:ring-2`}
          {...props}
        />
        {error && (
          <p className="mt-1 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );

  const ActionButton = ({ 
    icon: Icon, 
    onClick, 
    variant = 'primary', 
    size = 'md',
    children,
    disabled = false
  }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        inline-flex items-center justify-center rounded-lg transition-all duration-200
        ${size === 'sm' ? 'px-2 py-1 text-sm' : 'px-4 py-2'}
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'transform hover:scale-105'}
        ${variant === 'primary' 
          ? 'bg-blue-600 text-white hover:bg-blue-700 shadow-md hover:shadow-lg' 
          : variant === 'danger'
          ? 'bg-red-600 text-white hover:bg-red-700 shadow-md hover:shadow-lg'
          : variant === 'success'
          ? 'bg-green-600 text-white hover:bg-green-700 shadow-md hover:shadow-lg'
          : 'bg-gray-100 text-gray-700 hover:bg-gray-200 shadow hover:shadow-md'
        }
      `}
    >
      {Icon && <Icon className={`${size === 'sm' ? 'w-4 h-4' : 'w-5 h-5'} ${children ? 'mr-2' : ''}`} />}
      {children}
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Header user={currentUser} />
      <div className="flex">
        <Sidebar />
        <main className="flex-1 p-8 pl-[260px]">
          <div className="max-w-7xl mx-auto">
            {/* Header */}
            <div className="mb-8">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-4">
                  <FaUsers className="w-8 h-8 text-blue-600" />
                  <h1 className="text-3xl font-bold text-gray-900">My Team</h1>
                </div>
                <div className="relative">
                  <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="w-64 px-4 py-2 pl-10 border border-gray-300 rounded-lg
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              {currentUser && (
                <h2 className="text-xl text-gray-600">
                  Welcome, {currentUser.name}
                </h2>
              )}
            </div>

            {/* Toast Notification */}
            {showToast && (
              <div className={`
                fixed top-4 right-4 p-4 rounded-lg shadow-lg z-50 transition-all transform
                ${toastMessage.type === 'error' 
                  ? 'bg-red-100 border-l-4 border-red-500' 
                  : 'bg-green-100 border-l-4 border-green-500'
                }
              `}>
                <div className="flex items-center">
                  {toastMessage.type === 'error' 
                    ? <FaExclamationTriangle className="w-5 h-5 text-red-500 mr-3" />
                    : <FaCheck className="w-5 h-5 text-green-500 mr-3" />
                  }
                  <p className={`text-sm ${
                    toastMessage.type === 'error' ? 'text-red-600' : 'text-green-600'
                  }`}>
                    {toastMessage.message}
                  </p>
                </div>
              </div>
            )}

            {/* Company Selection */}
            {isSystemAdmin && (
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <select
                    value={selectedCompany}
                    onChange={(e) => filterData(e.target.value, users, distributionCenters, searchTerm)}
                    className="w-64 px-4 py-2 border border-gray-300 rounded-lg shadow-sm
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="all">All Companies</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                  <div className="flex items-center text-sm text-gray-500">
                    <FaFilter className="w-4 h-4 mr-2" />
                    {`${filteredUsers.length} users`} • {`${filteredDistributionCenters.length} locations`}
                  </div>
                </div>
              </div>
            )}

            {/* Tabs */}
            <div className="flex gap-4 mb-8">
              <TabButton
                active={activeSection === 'team'}
                onClick={() => setActiveSection('team')}
                icon={FaUsers}
              >
                Team Members
              </TabButton>
              <TabButton
                active={activeSection === 'dc'}
                onClick={() => setActiveSection('dc')}
                icon={FaWarehouse}
              >
                Distribution Centers
              </TabButton>
              {isSystemAdmin && (
                <TabButton
                  active={activeSection === 'companies'}
                  onClick={() => setActiveSection('companies')}
                  icon={FaBuilding}
                >
                  Companies
                </TabButton>
              )}
            </div>

            {/* Content Sections */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              {isLoading ? (
                <div className="flex justify-center items-center p-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                </div>
              ) : (
                <>
                  {activeSection === 'team' && (
                    <div className="space-y-8">
                      {/* Team Members Table */}
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Role
                              </th>
                              {isSystemAdmin && (
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Company
                                </th>
                              )}
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredUsers.map(user => (
                              <tr key={user.id} className="hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                                      <span className="text-blue-600 font-medium">
                                        {user.name.charAt(0).toUpperCase()}
                                      </span>
                                    </div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {user.name}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {user.email}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                                    ${user.role === 'SystemAdmin' 
                                      ? 'bg-purple-100 text-purple-800' 
                                      : user.role === 'Admin'
                                      ? 'bg-blue-100 text-blue-800'
                                      : 'bg-green-100 text-green-800'
                                    }`}>
                                    {user.role}
                                  </span>
                                </td>
                                {isSystemAdmin && (
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {user.role === 'SystemAdmin' 
                                      ? 'All Companies' 
                                      : companies.find(c => c.id === user.company)?.name || 'N/A'}
                                  </td>
                                )}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex gap-2">
                                    <ActionButton
                                      icon={FaPencilAlt}
                                      onClick={() => {
                                        setEditingUser(user);
                                        setShowEditModal(true);
                                      }}
                                      variant="secondary"
                                      size="sm"
                                    >
                                      Edit
                                    </ActionButton>
                                    <ActionButton
                                      icon={FaKey}
                                      onClick={() => {
                                        setResetEmail(user.email);
                                        setShowResetModal(true);
                                      }}
                                      variant="secondary"
                                      size="sm"
                                    >
                                      Reset
                                    </ActionButton>
                                    <ActionButton
                                      icon={FaTrashAlt}
                                      onClick={() => setShowDeleteConfirm({
                                        type: 'user',
                                        id: user.id,
                                        name: user.name
                                      })}
                                      variant="danger"
                                      size="sm"
                                    >
                                      Delete
                                    </ActionButton>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Add New User Form */}
                      <div className="bg-gray-50 rounded-lg p-6 shadow-sm">
                        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                          <FaUserPlus className="w-5 h-5 mr-2 text-blue-600" />
                          Add New Team Member
                        </h3>
                        <form onSubmit={handleAddUser} className="space-y-4">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <FormInput
                              label="Name"
                              name="name"
                              value={newUser.name}
                              onChange={(e) => setNewUser(prev => ({
                                ...prev,
                                name: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="Email"
                              type="email"
                              name="email"
                              value={newUser.email}
                              onChange={(e) => setNewUser(prev => ({
                                ...prev,
                                email: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="Phone"
                              type="tel"
                              name="phone"
                              value={newUser.phone}
                              onChange={(e) => setNewUser(prev => ({
                                ...prev,
                                phone: e.target.value
                              }))}
                              required
                            />
                            <div className="mb-4">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Role
                              </label>
                              <select
                                name="role"
                                value={newUser.role}
                                onChange={(e) => setNewUser(prev => ({
                                  ...prev,
                                  role: e.target.value
                                }))}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                required
                              >
                                <option value="User">User</option>
                                <option value="Admin">Admin</option>
                                {isSystemAdmin && <option value="SystemAdmin">System Admin</option>}
                              </select>
                            </div>
                          </div>

                          {newUser.role !== 'SystemAdmin' && (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              {isSystemAdmin && selectedCompany === 'all' && (
                                <div className="mb-4">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Company
                                  </label>
                                  <select
                                    name="company"
                                    value={newUser.company}
                                    onChange={(e) => {
                                      const companyId = e.target.value;
                                      setNewUser(prev => ({
                                        ...prev,
                                        company: companyId,
                                        distributionCenters: []
                                      }));
                                      const filteredDCs = distributionCenters.filter(
                                        dc => dc.company === companyId
                                      );
                                      setFilteredDistributionCenters(filteredDCs);
                                    }}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    required
                                  >
                                    <option value="">Select Company</option>
                                    {companies.map(company => (
                                      <option key={company.id} value={company.id}>
                                        {company.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}

                              <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Distribution Centers
                                </label>
                                <select
                                  multiple
                                  name="distributionCenters"
                                  value={newUser.distributionCenters}
                                  onChange={(e) => {
                                    const selectedDCs = Array.from(e.target.selectedOptions).map(
                                      option => option.value
                                    );
                                    setNewUser(prev => ({
                                      ...prev,
                                      distributionCenters: selectedDCs
                                    }));
                                  }}
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                                    min-h-[120px]"
                                  required
                                >
                                  {filteredDistributionCenters.map(dc => (
                                    <option key={dc.id} value={dc.id}>
                                      {dc.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          <div className="flex justify-end">
                            <ActionButton
                              type="submit"
                              icon={FaUserPlus}
                              variant="primary"
                            >
                              Add User
                            </ActionButton>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {/* Edit User Modal */}
                  {showEditModal && editingUser && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 shadow-xl">
                        <div className="flex justify-between items-center mb-4">
                          <h2 className="text-xl font-semibold flex items-center">
                            <FaPencilAlt className="w-5 h-5 mr-2 text-blue-600" />
                            Edit User
                          </h2>
                          <button
                            onClick={() => setShowEditModal(false)}
                            className="text-gray-400 hover:text-gray-500 transition-colors"
                          >
                            <FaTimes className="w-5 h-5" />
                          </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <FormInput
                            label="Name"
                            name="name"
                            value={editingUser.name}
                            onChange={(e) => setEditingUser(prev => ({
                              ...prev,
                              name: e.target.value
                            }))}
                            required
                          />
                          <FormInput
                            label="Phone"
                            type="tel"
                            name="phone"
                            value={editingUser.phone}
                            onChange={(e) => setEditingUser(prev => ({
                              ...prev,
                              phone: e.target.value
                            }))}
                            required
                          />
                          <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Role
                            </label>
                            <select
                              name="role"
                              value={editingUser.role}
                              onChange={(e) => setEditingUser(prev => ({
                                ...prev,
                                role: e.target.value
                              }))}
                              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              required
                            >
                              <option value="User">User</option>
                              <option value="Admin">Admin</option>
                              {isSystemAdmin && <option value="SystemAdmin">System Admin</option>}
                            </select>
                          </div>

                          {editingUser.role !== 'SystemAdmin' && (
                            <>
                              {isSystemAdmin && (
                                <div className="mb-4">
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Company
                                  </label>
                                  <select
                                    name="company"
                                    value={editingUser.company}
                                    onChange={(e) => setEditingUser(prev => ({
                                      ...prev,
                                      company: e.target.value,
                                      distributionCenters: []
                                    }))}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    required
                                  >
                                    {companies.map(company => (
                                      <option key={company.id} value={company.id}>
                                        {company.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}

                              <div className="mb-4 col-span-2">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Distribution Centers
                                </label>
                                <select
                                  multiple
                                  name="distributionCenters"
                                  value={editingUser.distributionCenters || []}
                                  onChange={(e) => {
                                    const selectedDCs = Array.from(e.target.selectedOptions).map(
                                      option => option.value
                                    );
                                    setEditingUser(prev => ({
                                      ...prev,
                                      distributionCenters: selectedDCs
                                    }));
                                  }}
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                                    min-h-[120px]"
                                  required
                                >
                                  {(editingUser.role === 'Admin' 
                                    ? distributionCenters.filter(dc => dc.company === editingUser.company)
                                    : distributionCenters
                                  ).map(dc => (
                                    <option key={dc.id} value={dc.id}>
                                      {dc.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="flex justify-end gap-3 mt-6">
                          <ActionButton
                            onClick={() => setShowEditModal(false)}
                            variant="secondary"
                          >
                            Cancel
                          </ActionButton>
                          <ActionButton
                            onClick={handleEditUser}
                            variant="primary"
                            icon={FaSave}
                          >
                            Save Changes
                          </ActionButton>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Distribution Centers Section */}
                  {activeSection === 'dc' && (
                    <div className="space-y-8">
                      {/* Distribution Centers Table */}
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Address
                              </th>
                              {isSystemAdmin && (
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Company
                                </th>
                              )}
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredDistributionCenters.map(dc => (
                              <tr key={dc.id} className="hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                                      <FaWarehouse className="w-4 h-4 text-blue-600" />
                                    </div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {dc.name}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4">
                                  <div className="text-sm text-gray-900">
                                    {dc.address?.street}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {`${dc.address?.city}, ${dc.address?.state} ${dc.address?.zip}`}
                                  </div>
                                </td>
                                {isSystemAdmin && (
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {companies.find(c => c.id === dc.company)?.name || 'N/A'}
                                  </td>
                                )}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <ActionButton
                                    icon={FaTrashAlt}
                                    onClick={() => setShowDeleteConfirm({
                                      type: 'dc',
                                      id: dc.id,
                                      name: dc.name
                                    })}
                                    variant="danger"
                                    size="sm"
                                  >
                                    Delete
                                  </ActionButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Add New DC Form */}
                      <div className="bg-gray-50 rounded-lg p-6 shadow-sm">
                        <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                          <FaWarehouse className="w-5 h-5 mr-2 text-blue-600" />
                          Add New Distribution Center
                        </h3>
                        <form onSubmit={handleAddDC} className="space-y-4">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <FormInput
                              label="Name"
                              name="name"
                              value={newDC.name}
                              onChange={(e) => setNewDC(prev => ({
                                ...prev,
                                name: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="Street Address"
                              name="street"
                              value={newDC.street}
                              onChange={(e) => setNewDC(prev => ({
                                ...prev,
                                street: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="City"
                              name="city"
                              value={newDC.city}
                              onChange={(e) => setNewDC(prev => ({
                                ...prev,
                                city: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="State"
                              name="state"
                              value={newDC.state}
                              onChange={(e) => setNewDC(prev => ({
                                ...prev,
                                state: e.target.value
                              }))}
                              required
                            />
                            <FormInput
                              label="ZIP Code"
                              name="zip"
                              value={newDC.zip}
                              onChange={(e) => setNewDC(prev => ({
                                ...prev,
                                zip: e.target.value
                              }))}
                              required
                            />
                            {isSystemAdmin && selectedCompany === 'all' && (
                              <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                  Company
                                </label>
                                <select
                                  name="company"
                                  value={newDC.company}
                                  onChange={(e) => setNewDC(prev => ({
                                    ...prev,
                                    company: e.target.value
                                  }))}
                                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm
                                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                  required
                                >
                                  <option value="">Select Company</option>
                                  {companies.map(company => (
                                    <option key={company.id} value={company.id}>
                                      {company.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </div>

                          <div className="flex justify-end">
                            <ActionButton
                              type="submit"
                              icon={FaWarehouse}
                              variant="primary"
                            >
                              Add Distribution Center
                            </ActionButton>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {/* Companies Section */}
                  {isSystemAdmin && activeSection === 'companies' && (
                    <div className="space-y-8">
                      {/* Companies Table */}
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Contact
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email
                              </th>
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Phone
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {companies.map(company => (
                              <tr key={company.id} className="hover:bg-gray-50 transition-colors">
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                                      <FaBuilding className="w-4 h-4 text-blue-600" />
                                    </div>
                                    <div className="text-sm font-medium text-gray-900">
                                      {company.name}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {company.contact?.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {company.contact?.email}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {company.contact?.phone}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>

      {/* Reset Password Modal */}
      {showResetModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl transform transition-all">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold flex items-center">
                <FaKey className="w-5 h-5 mr-2 text-blue-600" />
                Reset Password
              </h2>
              <button
                onClick={() => setShowResetModal(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <FaTimes className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to reset the password for {resetEmail}?
            </p>
            <div className="flex justify-end gap-3">
              <ActionButton
                onClick={() => setShowResetModal(false)}
                variant="secondary"
              >
                Cancel
              </ActionButton>
              <ActionButton
                onClick={handleResetPassword}
                variant="primary"
                icon={FaKey}
              >
                Reset Password
              </ActionButton>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl transform transition-all">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold flex items-center">
                <FaExclamationTriangle className="w-5 h-5 mr-2 text-red-600" />
                Confirm Delete
              </h2>
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <FaTimes className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete {showDeleteConfirm.name}? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-3">
              <ActionButton
                onClick={() => setShowDeleteConfirm(null)}
                variant="secondary"
              >
                Cancel
              </ActionButton>
              <ActionButton
                onClick={() => {
                  if (showDeleteConfirm.type === 'user') {
                    handleDeleteUser(showDeleteConfirm.id);
                  } else {
                    handleDeleteDC(showDeleteConfirm.id);
                  }
                }}
                variant="danger"
                icon={FaTrashAlt}
              >
                Delete
              </ActionButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyTeam;