// src/services/reportsService.js


import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';


Chart.register(...registerables, ChartDataLabels);


// Constants
const COLORS = {
  PRIMARY: '#3498db',
  SECONDARY: '#2c3e50',
  BACKGROUND: '#ecf0f1',
  TEXT: '#34495e',
  CHART_PALETTE: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']
};


const FONTS = {
  REGULAR: 'Roboto',
  BOLD: 'Roboto-Bold'
};


const CHART_SCALE = 4;


// Utility Functions
const formatCurrency = (value, compact = false) => {
  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(compact && { notation: 'compact', compactDisplay: 'short' })
  };
  return new Intl.NumberFormat('en-US', options).format(value);
};


const formatLargeNumber = (value) => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(value);
};


const formatDate = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }).format(new Date(date));
};


const calculateEstTaxSaving = (costBasis, fairMarketValue) => {
  const saving = costBasis + (1/2) * (fairMarketValue - costBasis);
  return Math.min(saving, 2 * costBasis);
};


// PDF Generation Functions
const generatePDF = async (donations, charities, summaryStats, chartData) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4'
  });


  await loadFonts(doc);
  doc.setFont(FONTS.REGULAR);

  addCoverPage(doc, summaryStats);
  doc.addPage();
  addSummaryStatistics(doc, summaryStats);
  doc.addPage();
  await addCharts(doc, chartData);
  doc.addPage();
  addDonationDetailsTable(doc, donations, charities);
  doc.addPage();
  addCharityImpactSection(doc, donations, charities);


  addPageNumbers(doc);


  return doc;
};


const loadFonts = async (doc) => {
  await doc.addFont('https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf', FONTS.REGULAR, 'normal');
  await doc.addFont('https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf', FONTS.BOLD, 'bold');
};


const addCoverPage = (doc, summaryStats) => {
  doc.setFillColor(COLORS.PRIMARY);
  doc.rect(0, 0, 210, 297, 'F');


  doc.setTextColor(255, 255, 255);
  doc.setFontSize(40);
  doc.setFont(FONTS.BOLD);
  doc.text('Completed Donations', 105, 100, { align: 'center' });
  doc.setFontSize(24);
  doc.text('Report', 105, 120, { align: 'center' });


  doc.setFontSize(16);
  doc.setFont(FONTS.REGULAR);
  doc.text(`Total Donations: ${formatLargeNumber(summaryStats.totalDonations)}`, 105, 160, { align: 'center' });
  doc.text(`Total Value: ${formatCurrency(summaryStats.totalFairMarketValue)}`, 105, 175, { align: 'center' });


  doc.setFontSize(12);
  doc.text(`Generated on ${formatDate(new Date())}`, 105, 280, { align: 'center' });
};


const addSummaryStatistics = (doc, summaryStats) => {
  const margin = 15;
  doc.setTextColor(COLORS.SECONDARY);
  doc.setFontSize(24);
  doc.setFont(FONTS.BOLD);
  doc.text('Summary Statistics', margin, 20);


  const stats = [
    { label: 'Total Donations', value: formatLargeNumber(summaryStats.totalDonations) },
    { label: 'Total Fair Market Value', value: formatCurrency(summaryStats.totalFairMarketValue) },
    { label: 'Total Est. Tax Savings', value: formatCurrency(summaryStats.totalEstTaxSavings) },
    { label: 'Total Pallets', value: formatLargeNumber(summaryStats.totalPallets) },
    { label: 'Total Quantity', value: formatLargeNumber(summaryStats.totalQuantity) }
  ];


  doc.setFontSize(14);
  stats.forEach((stat, index) => {
    const y = 40 + index * 25;
    doc.setFillColor(COLORS.BACKGROUND);
    doc.roundedRect(margin, y, 180, 20, 3, 3, 'F');
    doc.setFont(FONTS.BOLD);
    doc.text(stat.label, margin + 5, y + 13);
    doc.setFont(FONTS.REGULAR);
    doc.text(stat.value, 185, y + 13, { align: 'right' });
  });
};


const addCharts = async (doc, chartData) => {
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
 
  // Main heading
  doc.setFontSize(28);
  doc.setFont(FONTS.BOLD);
  doc.setTextColor(COLORS.SECONDARY);
  doc.text('Donation Trends', margin, 25);


  // Sub-heading font size (50% of main heading)
  const subHeadingSize = 28;


  // Line chart
  const lineChartWidth = pageWidth - 2 * margin;
  const lineChartHeight = 90;
  doc.setFontSize(subHeadingSize);
  doc.setFont(FONTS.BOLD);
  doc.setTextColor(COLORS.PRIMARY);
  doc.text('Cumulative Donations Over Time', margin, 40);
  await addChartToPDF(doc, chartData.cumulativeData, '',
                      margin, 45,
                      lineChartWidth, lineChartHeight, 'line');


  // Pie chart
const pieChartWidth = (pageWidth - 3 * margin) * 0.5;
const pieChartHeight = 90;
const pieChartX = margin;
const pieChartY = lineChartHeight + 70;
doc.setFontSize(subHeadingSize);
doc.text('Donations by Charity', pieChartX, pieChartY - 5);
await addChartToPDF(doc, chartData.charityData, '',
                    pieChartX, pieChartY,
                    pieChartWidth, pieChartHeight, 'pie');


// Legend
const legendWidth = pieChartWidth * 1.25; // Match the pie chart width
const legendHeight = pieChartHeight * 0.4; // Keep the height reduced


// Calculate the center point of the pie chart
const pieCenterX = pieChartX + pieChartWidth / 2;
const pieCenterY = pieChartY + pieChartHeight / 2;


// Position the legend to the right of the pie chart, centered vertically
const legendX = pieCenterX + pieChartWidth / 2 + margin / 2;
const legendY = pieCenterY - legendHeight / 2;


const legendCanvas = await createLegendCanvas(chartData.charityData, legendWidth, legendHeight);
doc.addImage(legendCanvas, 'PNG', legendX, legendY, legendWidth, legendHeight);
};


const addChartToPDF = async (doc, chartData, title, x, y, width, height, chartType) => {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = width * CHART_SCALE;
    canvas.height = height * CHART_SCALE;
    const ctx = canvas.getContext('2d');
    ctx.scale(CHART_SCALE, CHART_SCALE);


    const commonOptions = {
      responsive: false,
      maintainAspectRatio: false,
      animation: false,
      devicePixelRatio: CHART_SCALE,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            color: COLORS.PRIMARY,
            boxWidth: 8,
            padding: 4,
            font: {
              size: 8,
              family: FONTS.REGULAR
            }
          }
        },
        title: {
          display: false,
          text: title,
          font: {
            size: 16,
            family: FONTS.BOLD,
            weight: 'bold'
          },
          color: COLORS.PRIMARY,
          padding: {
            top: 10,
            bottom: 10
          },
          align: 'start'
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = context.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== undefined) {
                label += formatCurrency(context.parsed.y);
              } else if (context.parsed !== undefined) {
                label += formatCurrency(context.parsed);
              }
              return label;
            }
          }
        }
      }
    };


    const chartOptions = chartType === 'line' ? {
      ...commonOptions,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM d'
            }
          },
          grid: {
            color: 'rgba(0, 0, 0, 0.1)'
          },
          title: {
            display: true,
            text: 'Date',
            font: {
              size: 14,
              family: FONTS.REGULAR
            },
            color: COLORS.PRIMARY
          },
          ticks: {
            font: {
              size: 10,
              family: FONTS.REGULAR
            },
            color: COLORS.PRIMARY,
            maxRotation: 45,
            autoSkip: true,
            maxTicksLimit: 8
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: 'rgba(0, 0, 0, 0.1)'
          },
          title: {
            display: true,
            text: 'Amount ($)',
            font: {
              size: 14,
              family: FONTS.REGULAR
            },
            color: COLORS.PRIMARY
          },
          ticks: {
            font: {
              size: 10,
              family: FONTS.REGULAR
            },
            color: COLORS.PRIMARY,
            callback: (value) => formatCurrency(value, true)
          }
        }
      },
      plugins: {
        ...commonOptions.plugins,
        datalabels: {
          display: false
        }
      }
    } : {
      ...commonOptions,
      cutout: '50%',
      plugins: {
        ...commonOptions.plugins,
        legend: {
          display: false
        },
        datalabels: {
          formatter: (value, ctx) => {
            let sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = (value / sum * 100).toFixed(1) + "%";
            return percentage;
          },
          color: '#fff',
          font: {
            weight: 'bold',
            size: 12,
          }
        }
      }
    };


    const chart = new Chart(ctx, {
      type: chartType,
      data: {
        ...chartData,
        datasets: chartData.datasets.map((dataset, index) => ({
          ...dataset,
          backgroundColor: chartType === 'line' ? COLORS.CHART_PALETTE[0] : COLORS.CHART_PALETTE,
          borderColor: chartType === 'line' ? COLORS.CHART_PALETTE[0] : 'white',
          borderWidth: 2,
          tension: 0.1,
          pointRadius: 0,
        }))
      },
      options: chartOptions
    });


    await new Promise(resolve => setTimeout(resolve, 500));


    doc.addImage(canvas.toDataURL(), 'PNG', x, y, width, height);
  } catch (error) {
    console.error('Error generating chart:', error);
    doc.setFontSize(10);
    doc.setTextColor(255, 0, 0);
    doc.text(`Error generating chart: ${error.message}`, x, y + 10);
  }
};


const createLegendCanvas = async (chartData, width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width * CHART_SCALE;
  canvas.height = height * CHART_SCALE;
  const ctx = canvas.getContext('2d');
  ctx.scale(CHART_SCALE, CHART_SCALE);


  const itemCount = chartData.labels.length;
  const itemHeight = Math.min(10, height / itemCount); // Reduced from 12 to 10
  const fontSize = Math.min(6, itemHeight * 0.6); // Reduced from 7 to 6
  const boxSize = Math.min(7, itemHeight * 0.8); // Reduced from 8 to 7


  ctx.textBaseline = 'middle';
  ctx.font = `${fontSize}px ${FONTS.REGULAR}`;


  const maxLabelWidth = width - boxSize - 5;


  chartData.labels.forEach((label, index) => {
    const y = (index + 0.5) * itemHeight;


    // Color box
    ctx.fillStyle = COLORS.CHART_PALETTE[index % COLORS.CHART_PALETTE.length];
    ctx.fillRect(0, y - boxSize / 2, boxSize, boxSize);


    // Label text
    ctx.fillStyle = COLORS.TEXT;
    let truncatedLabel = label;
    if (ctx.measureText(label).width > maxLabelWidth) {
      while (ctx.measureText(truncatedLabel + '...').width > maxLabelWidth && truncatedLabel.length > 0) {
        truncatedLabel = truncatedLabel.slice(0, -1);
      }
      truncatedLabel += '...';
    }


    ctx.fillText(truncatedLabel, boxSize + 3, y);
  });


  return canvas;
};


const addDonationDetailsTable = (doc, donations, charities) => {
  const margin = 15;
  doc.setFontSize(24);
  doc.setFont(FONTS.BOLD);
  doc.setTextColor(COLORS.SECONDARY);
  doc.text('Donation Details', margin, 20);


  const tableColumns = [
    { header: 'ID', dataKey: 'id' },
    { header: 'Date', dataKey: 'date' },
    { header: 'Description', dataKey: 'description' },
    { header: 'Pallets', dataKey: 'pallets' },
    { header: 'Quantity', dataKey: 'quantity' },
    { header: 'FMV ($)', dataKey: 'fmv' },
    { header: 'Est. Tax Saving ($)', dataKey: 'taxSaving' },
    { header: 'Charities', dataKey: 'charities' }
  ];


  const tableRows = donations.map(donation => ({
    id: donation.id,
    date: formatDate(donation.completionDate),
    description: donation.itemDescription,
    pallets: donation.palletCount,
    quantity: formatLargeNumber(donation.quantity),
    fmv: formatCurrency(donation.fairMarketValue),
    taxSaving: formatCurrency(calculateEstTaxSaving(donation.costBasis, donation.fairMarketValue)),
    charities: donation.palletGroups.map(group => charities[group.charityId]?.name || 'Unknown').join(", ")
  }));


  doc.autoTable({
    columns: tableColumns,
    body: tableRows,
    startY: 30,
    styles: { font: FONTS.REGULAR, fontSize: 8 },
    headStyles: { fillColor: COLORS.PRIMARY, textColor: 255 },
    alternateRowStyles: { fillColor: COLORS.BACKGROUND },
    margin: { top: 30, left: margin, right: margin },
    didDrawPage: (data) => {
      doc.setFontSize(10);
      doc.text(`Page ${data.pageNumber}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
    }
  });
};


const addCharityImpactSection = (doc, donations, charities) => {
  const margin = 15;
  doc.setFontSize(24);
  doc.setFont(FONTS.BOLD);
  doc.setTextColor(COLORS.SECONDARY);
  doc.text('Charity Impact', margin, 20);


  const charityImpact = calculateCharityImpact(donations, charities);


  const tableColumns = [
    { header: 'Charity', dataKey: 'name' },
    { header: 'Donations Received', dataKey: 'donations' },
    { header: 'Pallets Received', dataKey: 'pallets' },
    { header: 'Total Value ($)', dataKey: 'value' }
  ];


  doc.autoTable({
    columns: tableColumns,
    body: charityImpact,
    startY: 30,
    styles: { font: FONTS.REGULAR, fontSize: 8 },
    headStyles: { fillColor: COLORS.PRIMARY, textColor: 255 },
    alternateRowStyles: { fillColor: COLORS.BACKGROUND },
    margin: { top: 30, left: margin, right: margin },
    didDrawPage: (data) => {
      doc.setFontSize(10);
      doc.text(`Page ${data.pageNumber}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
    }
  });
};


const calculateCharityImpact = (donations, charities) => {
  const impact = {};
  donations.forEach(donation => {
    donation.palletGroups.forEach(group => {
      if (!impact[group.charityId]) {
        impact[group.charityId] = { donations: 0, pallets: 0, value: 0 };
      }
      impact[group.charityId].donations++;
      impact[group.charityId].pallets += group.palletQuantity;
      impact[group.charityId].value += (donation.fairMarketValue / donation.palletCount) * group.palletQuantity;
    });
  });


  return Object.entries(impact)
    .map(([charityId, data]) => ({
      name: charities[charityId]?.name || 'Unknown',
      donations: data.donations,
      pallets: data.pallets,
      value: formatCurrency(data.value)
    }))
    .sort((a, b) => parseFloat(b.value.replace(/[^0-9.-]+/g, "")) - parseFloat(a.value.replace(/[^0-9.-]+/g, "")));
};


const addPageNumbers = (doc) => {
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.setTextColor(COLORS.TEXT);
    doc.text(`Page ${i} of ${totalPages}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
  }
};

// CSV Generation Function
const generateCSV = (donations, charities, summaryStats) => {
  const rows = [
    ['Summary Statistics'],
    ['Total Donations', summaryStats.totalDonations],
    ['Total Fair Market Value', formatCurrency(summaryStats.totalFairMarketValue)],
    ['Total Est. Tax Savings', formatCurrency(summaryStats.totalEstTaxSavings)],
    ['Total Pallets', summaryStats.totalPallets],
    ['Total Quantity', summaryStats.totalQuantity],
    [],
    [
      'Donation Details',
      'ID',
      'Completion Date',
      'Item Description',
      'Pallet Count',
      'Quantity',
      'Fair Market Value',
      'Est. Tax Saving',
      'Receiving Charities'
    ]
  ];


  donations.forEach(donation => {
    rows.push([
      '',
      donation.id,
      formatDate(donation.completionDate),
      donation.itemDescription,
      donation.palletCount,
      donation.quantity,
      formatCurrency(donation.fairMarketValue),
      formatCurrency(calculateEstTaxSaving(donation.costBasis, donation.fairMarketValue)),
      donation.palletGroups.map(group => charities[group.charityId]?.name || 'Unknown').join(", ")
    ]);
  });


  rows.push([]);


  const charityImpact = calculateCharityImpact(donations, charities);
  rows.push(['Charity Impact', 'Charity Name', 'Donations Received', 'Pallets Received', 'Total Value Received']);
  charityImpact.forEach(impact => {
    rows.push([
      '',
      impact.name,
      impact.donations,
      impact.pallets,
      impact.value
    ]);
  });


  return rows.map(row => row.map(cell => `"${cell}"`).join(',')).join('\n');
};


// Exported Service
export const reportsService = {
  generateCSV,
  generatePDF
};


// Exported Helpers (for testing purposes)
export const helpers = {
  formatCurrency,
  formatLargeNumber,
  formatDate,
  calculateEstTaxSaving,
  calculateCharityImpact
};

