// src/components/ClientPreviewRoute.js

import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { authService } from '../services/authService';
import { proposalService } from '../services/proposalService';
import DynamicPresentation from './DynamicPresentation';
import { FaSpinner, FaExclamationTriangle } from 'react-icons/fa';

// Error state component
const ErrorState = ({ message }) => (
  <div className="min-h-screen bg-gray-900 flex items-center justify-center">
    <div className="flex flex-col items-center gap-4 text-white text-center px-4">
      <FaExclamationTriangle className="w-12 h-12 text-yellow-500" />
      <div className="text-xl">{message}</div>
    </div>
  </div>
);

// Loading state component
const LoadingState = ({ message = "Loading..." }) => (
  <div className="min-h-screen bg-gray-900 flex items-center justify-center">
    <div className="flex items-center gap-3 text-white">
      <FaSpinner className="w-6 h-6 animate-spin" />
      <span className="text-xl">{message}</span>
    </div>
  </div>
);

const ClientPreviewRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { companyId } = useParams();

  useEffect(() => {
    const initializeRoute = async () => {
      try {
        console.log('Initializing route...');
        const currentUser = authService.getUserSession();
        console.log('Current user session:', currentUser);
        setUser(currentUser);

        if (!currentUser) {
          console.log('No user found in session');
          return;
        }

        // Only verify that user has Preview role
        if (currentUser.role !== 'Preview') {
          console.log('Authorization failed - not a preview user:', {
            role: currentUser.role
          });
          setError('unauthorized');
          return;
        }

        // Verify the company has at least one proposal
        const latestProposal = await proposalService.getLatestProposalForCompany(companyId);
        if (!latestProposal) {
          console.log('No proposals found for company:', companyId);
          setError('no_proposals');
          return;
        }

      } catch (error) {
        console.error('Error initializing route:', error);
        setError('unknown');
      } finally {
        setLoading(false);
      }
    };

    initializeRoute();
  }, [companyId]);

  if (loading) {
    return <LoadingState />;
  }

  if (!user) {
    // Store the current URL for redirect after login
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/login" />;
  }

  if (error === 'unauthorized') {
    return <ErrorState message="You are not authorized to access previews." />;
  }

  if (error === 'no_proposals') {
    return <ErrorState message="No presentations available for this company." />;
  }

  if (error === 'unknown') {
    return <ErrorState message="Something went wrong. Please try again later." />;
  }

  return <DynamicPresentation />;
};

export default ClientPreviewRoute;