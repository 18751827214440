// CharityProfile.js
import React, { useState } from 'react';
import { 
  FaTimes, 
  FaBuilding,
  FaGlobe, 
  FaClipboard,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaWarehouse,
  FaTruck,
  FaBoxes,
  FaClock,
  FaCalendarCheck,
  FaFileAlt,
  FaCheckCircle,
  FaUserCircle,
  FaExclamationCircle,
  FaDirections
} from 'react-icons/fa';
import '../../styles/Charities/CharityProfile.css';

const CharityProfile = ({ charity, onClose }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    charity.locations.find(loc => loc.isMainLocation) || charity.locations[0]
  );
  
  const dayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatAddress = (address) => {
    return `${address.line1}${address.line2 ? `, ${address.line2}` : ''}, ${address.city}, ${address.stateProvince} ${address.zipCode}`;
  };

  const formatPhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="charity-profile-overlay">
      <div className="charity-profile-modal">
        {/* Close Button */}
        <button onClick={onClose} className="close-button">
          <FaTimes />
        </button>

        {/* Header */}
        <header className="profile-header">
          <div className="header-content">
            {charity.logoUrl ? (
              <img 
                src={charity.logoUrl}
                alt={`${charity.orgName} logo`}
                className="charity-logo"
              />
            ) : (
              <div className="charity-logo-placeholder">
                <FaBuilding />
              </div>
            )}
            
            <div className="charity-info">
              <h1 className="charity-name">{charity.orgName}</h1>
              <div className="charity-causes">
                {charity.organizationCauses.map((cause, index) => (
                  <span key={index} className="cause-tag">
                    {cause}
                  </span>
                ))}
              </div>
              <div className="charity-actions">
                {charity.orgWebsite && (
                  <a
                    href={charity.orgWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="action-button"
                  >
                    <FaGlobe />
                    <span>Website</span>
                  </a>
                )}
                <button
                  onClick={() => copyToClipboard(charity.ein)}
                  className="action-button"
                >
                  <FaClipboard />
                  <span>Copy EIN</span>
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Content */}
        <div className="profile-content">
          <div className="content-grid">
            {/* Main Content Column */}
            <div className="main-content">
              {/* About Section */}
              <section className="content-section">
                <h2 className="section-title">
                  <FaFileAlt className="section-icon" />
                  About
                </h2>
                <div className="section-content">
                  <p className="description">{charity.description}</p>
                  
                  {charity.organizationNotes && (
                    <div className="notes-container">
                      <FaExclamationCircle className="notes-icon" />
                      <div className="notes-content">
                        <h3>Additional Notes</h3>
                        <p>{charity.organizationNotes}</p>
                      </div>
                    </div>
                  )}
                </div>
              </section>

              {/* Acceptable Goods Section */}
              <section className="content-section">
                <h2 className="section-title">
                  <FaBoxes className="section-icon" />
                  Acceptable Goods
                </h2>
                <div className="goods-grid">
                  {charity.acceptableGoods.map((good, index) => (
                    <span key={index} className="good-tag">
                      {good}
                    </span>
                  ))}
                </div>
              </section>
            </div>

            {/* Sidebar */}
            <div className="location-sidebar">
              <div className="location-selector">
                {charity.locations.map((location, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedLocation(location)}
                    className={`location-tab ${selectedLocation.id === location.id ? 'active' : ''}`}
                  >
                    <FaMapMarkerAlt />
                    <span>{location.isMainLocation ? 'Main Location' : `Location ${index + 1}`}</span>
                  </button>
                ))}
              </div>

              <div className="location-details">
                {/* Address Section */}
                <div className="detail-group">
                  <div className="detail-header">
                    <FaMapMarkerAlt className="detail-icon" />
                    <h3>Address</h3>
                  </div>
                  <p className="address-text">{formatAddress(selectedLocation.address)}</p>
                  <div className="address-actions">
                    <button onClick={() => copyToClipboard(formatAddress(selectedLocation.address))}>
                      <FaClipboard /> Copy
                    </button>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formatAddress(selectedLocation.address))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaDirections /> Get Directions
                    </a>
                  </div>
                </div>

                {/* Contacts Section */}
                {selectedLocation.contacts?.map((contact, index) => (
                  <div key={index} className="detail-group">
                    <div className="contact-header">
                      {contact.isMainContact ? (
                        <FaCheckCircle className="contact-icon main" />
                      ) : (
                        <FaUserCircle className="contact-icon" />
                      )}
                      <div>
                        <h3>{`${contact.firstName} ${contact.lastName}`}</h3>
                        <p className="contact-role">{contact.role}</p>
                        {contact.isMainContact && (
                          <span className="main-contact-badge">Main Contact</span>
                        )}
                      </div>
                    </div>
                    <div className="contact-details">
                      {contact.phoneNumber && (
                        <a href={`tel:${contact.phoneNumber}`} className="contact-link">
                          <FaPhone />
                          {formatPhoneNumber(contact.phoneNumber)}
                        </a>
                      )}
                      {contact.email && (
                        <a href={`mailto:${contact.email}`} className="contact-link">
                          <FaEnvelope />
                          {contact.email}
                        </a>
                      )}
                    </div>
                  </div>
                ))}

                {/* Facility Section */}
                <div className="detail-group">
                  <div className="detail-header">
                    <FaWarehouse className="detail-icon" />
                    <h3>Facility Details</h3>
                  </div>
                  <p className="facility-capacity">
                    Capacity: {selectedLocation.palletCapacity.minimum} - {selectedLocation.palletCapacity.maximum} pallets
                  </p>
                  <div className="facility-features">
                    {selectedLocation.facilities.hasLoadingDock && (
                      <div className="facility-feature">
                        <FaTruck />
                        <span>Loading Dock Available</span>
                      </div>
                    )}
                    {selectedLocation.facilities.needsLiftGate && (
                      <div className="facility-feature">
                        <FaTruck />
                        <span>Lift Gate Required</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Hours Section */}
                <div className="detail-group">
                  <div className="detail-header">
                    <FaClock className="detail-icon" />
                    <h3>Hours</h3>
                  </div>
                  <p className="timezone">Timezone: {selectedLocation.timeZone}</p>
                  <div className="hours-grid">
                  {dayOrder.map(day => (
                    <div key={day} className="hours-row">
                      <span className="day">{capitalizeFirstLetter(day)}</span>
                      <span className="hours">{selectedLocation.hours[day] || 'Closed'}</span>
                    </div>
                  ))}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharityProfile;