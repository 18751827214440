// Reports.js
import React, { useReducer, useCallback, useMemo, useEffect, useState, } from 'react';
import {
 FaCalendarAlt, FaDollarSign,
  FaFileAlt, FaFileContract, FaFileCsv, FaFileInvoiceDollar,
  FaFilePdf, FaFilter, FaHandHoldingHeart,
  FaMoneyBillWave, FaPallet, FaReceipt, FaSearch, FaSyncAlt, FaUndo,
  FaWarehouse, FaBuilding, FaUsers, FaBoxes
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DonationCharts, { getChartData } from './DonationCharts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'chartjs-adapter-date-fns';

import Header from './Header';
import Sidebar from './Sidebar';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import { quoteService } from '../services/quoteService';
import { distributionCenterService } from '../services/distributionCenterService';
import { charityService } from '../services/charityService';
import { companyService } from '../services/companyService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { reportsService } from '../services/reportsService';


// Initial state and reducer
const initialState = {
  completedQuotes: [],
  user: null,
  loading: true,
  error: null,
  filters: {
    distributionCenter: 'all',
    company: 'all',
    charity: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  },
  distributionCenters: [],
  companies: [],
  charities: {},
  sortConfig: { key: 'completedAt', direction: 'descending' },
  currentPage: 1,
  itemsPerPage: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_FILTER':
      return { 
        ...state, 
        filters: { ...state.filters, [action.payload.name]: action.payload.value },
        currentPage: 1
      };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters, currentPage: 1 };
    case 'SET_SORT':
      return { ...state, sortConfig: action.payload };
    case 'SET_PAGE':
      return { ...state, currentPage: action.payload };
    case 'SET_ITEMS_PER_PAGE':
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    default:
      return state;
  }
}

// Utility functions
const formatters = {
  number: (number) => {
    if (!number || isNaN(number)) return '0';
    return new Intl.NumberFormat('en-US').format(number);
  },

  currency: (amount) => {
    if (!amount || isNaN(amount)) return '$0.00';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  },

  abbreviatedCurrency: (value) => {
    if (!value || isNaN(value)) return '$0';
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    if (value >= 1000) return `$${(value / 1000).toFixed(1)}K`;
    return formatters.currency(value);
  },

  date: (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString();
  },

  time: (date) => {
    if (!date) return '';
    return new Date(date).toLocaleTimeString();
  }
};

const calculateQuoteStats = (quotes) => {
  return quotes.reduce((acc, quote) => {
    acc.totalQuotes++;
    acc.totalFairMarketValue += quote.fairMarketValue || 0;
    acc.totalEstTaxSavings += calculateEstTaxSaving(quote.costBasis, quote.fairMarketValue);
    acc.totalPallets += quote.palletCount || 0;
    acc.totalQuantity += quote.quantity || 0;
    
    // Calculate total shipping groups
    acc.totalShippingGroups += quote.shippingGroups?.length || 0;
    
    // Track unique charities
    quote.assignedCharities?.forEach(charity => {
      acc.uniqueCharities.add(charity.charityId);
    });
    
    return acc;
  }, {
    totalQuotes: 0,
    totalFairMarketValue: 0,
    totalEstTaxSavings: 0,
    totalPallets: 0,
    totalQuantity: 0,
    totalShippingGroups: 0,
    uniqueCharities: new Set()
  });
};

const calculateCharityStats = (quotes, charityId) => {
  const charityQuotes = quotes.filter(quote =>
    quote.assignedCharities?.some(ac => ac.charityId === charityId)
  );
  
  return charityQuotes.reduce((acc, quote) => {
    const assignment = quote.assignedCharities.find(ac => ac.charityId === charityId);
    if (!assignment) return acc;
    
    const palletRatio = assignment.palletCount / quote.palletCount;
    
    acc.totalQuotes++;
    acc.totalValue += (quote.fairMarketValue * palletRatio) || 0;
    acc.palletsReceived += assignment.palletCount || 0;
    acc.totalQuantity += (quote.quantity * palletRatio) || 0;
    
    // Track shipping groups assigned to this charity
    const charityShippingGroups = quote.shippingGroups?.filter(sg => 
      sg.charityId === charityId
    ) || [];
    acc.shippingGroups += charityShippingGroups.length;
    
    return acc;
  }, {
    totalQuotes: 0,
    totalValue: 0,
    palletsReceived: 0,
    totalQuantity: 0,
    shippingGroups: 0
  });
};

const filterAndSortQuotes = (quotes, filters, sortConfig) => {
  let result = quotes.filter(quote => {
    const searchLower = filters.searchTerm.toLowerCase();
    
    const matchesSearch = [
      quote.id,
      quote.itemId,
      quote.description,
      quote.companyId
    ].some(field => field?.toLowerCase().includes(searchLower));

    const matchesDistributionCenter = 
      filters.distributionCenter === 'all' ||
      quote.distributionCenterId === filters.distributionCenter;

    const matchesCompany = 
      filters.company === 'all' ||
      quote.companyId === filters.company;

    const matchesCharity = 
      filters.charity === 'all' ||
      quote.assignedCharities?.some(ac => ac.charityId === filters.charity);

    const matchesDateRange =
      (!filters.dateRange.start || !filters.dateRange.end) ||
      (new Date(quote.completedAt) >= new Date(filters.dateRange.start) &&
       new Date(quote.completedAt) <= new Date(filters.dateRange.end));

    return matchesSearch && matchesDistributionCenter && 
           matchesCompany && matchesCharity && matchesDateRange;
  });

  if (sortConfig.key) {
    result.sort((a, b) => {
      let aVal = a[sortConfig.key];
      let bVal = b[sortConfig.key];
      
      // Handle dates
      if (sortConfig.key === 'completedAt') {
        aVal = new Date(aVal);
        bVal = new Date(bVal);
      }
      
      // Handle numbers
      if (typeof aVal === 'number' && typeof bVal === 'number') {
        return sortConfig.direction === 'ascending' ? 
          aVal - bVal : bVal - aVal;
      }
      
      // Handle strings
      if (aVal < bVal) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aVal > bVal) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }

  return result;
};

// Calculate estimated tax savings
const calculateEstTaxSaving = (costBasis, fairMarketValue) => {
  if (!costBasis || !fairMarketValue) return 0;
  const saving = costBasis + (fairMarketValue - costBasis) / 2;
  return Math.min(saving, 2 * costBasis);
};

// Main Reports Component
const Reports = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [charityImpactFilter, setCharityImpactFilter] = useState('all');
  const [userRole, setUserRole] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [setUserDistributionCenters] = useState([]);

  // Data fetching
  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');
  
      const userData = await userService.getById(currentUser.id);
      const userRole = await userService.getUserRole(currentUser.id);
      setUserRole(userRole);
  
      let userDCs, companies, rawCharities, allQuotes;
  
      if (userRole === 'SystemAdmin') {
        [userDCs, companies, rawCharities, allQuotes] = await Promise.all([
          distributionCenterService.getAll(),
          companyService.getAll(),
          charityService.getAll(),
          quoteService.getCompletedQuotes()
        ]);
      } else {
        const userCompany = userData.company;
        if (!userCompany) {
          throw new Error('No company assigned to user');
        }
        setUserCompany(userCompany);
        
        const userDCIds = Array.isArray(userData.distributionCenters)
          ? userData.distributionCenters
          : userData.distributionCenter
            ? [userData.distributionCenter]
            : [];
  
        if (!userDCIds.length) {
          throw new Error('No distribution centers assigned to user');
        }
  
        [userDCs, companies, rawCharities, ...allQuotes] = await Promise.all([
          distributionCenterService.getByIds(userDCIds),
          companyService.getById(userCompany).then(company => [company]),
          charityService.getAll(),
          ...userDCIds.map(dcId => quoteService.getCompletedQuotesByDC(dcId))
        ]);
  
        allQuotes = allQuotes.flat();
  
        // Filter charities to only include those that have received donations from this company
        const companyCharityIds = new Set();
        allQuotes.forEach(quote => {
          if (quote.companyId === userCompany) {
            quote.assignedCharities?.forEach(charity => {
              companyCharityIds.add(charity.charityId);
            });
          }
        });
  
        // Filter rawCharities to only include those in companyCharityIds
        rawCharities = rawCharities.filter(charity => 
          companyCharityIds.has(charity.id)
        );
      }
  
      // Ensure all required data is present
      if (!userDCs?.length || !companies?.length) {
        throw new Error('Failed to fetch required data');
      }
  
      // Don't throw error if no charities are found, as a company might not have donated yet
      const charitiesMap = Object.fromEntries(
        rawCharities.map(charity => [
          charity.id,
          {
            ...charity,
            stats: calculateCharityStats(allQuotes, charity.id)
          }
        ])
      );
  
      dispatch({
        type: 'SET_DATA',
        payload: {
          user: userData,
          distributionCenters: userDCs,
          companies,
          completedQuotes: allQuotes,
          charities: charitiesMap
        }
      });
  
      // Set company filter for non-admin users
      if (userRole !== 'SystemAdmin' && userData.company) {
        dispatch({ 
          type: 'SET_FILTER', 
          payload: { name: 'company', value: userData.company }
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ 
        type: 'SET_ERROR', 
        payload: 'Failed to load reports data. Please try again.' 
      });
      toast.error('Failed to load reports data. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Data filtering and sorting
  const filteredAndSortedQuotes = useMemo(() => {
    return filterAndSortQuotes(state.completedQuotes, state.filters, state.sortConfig);
  }, [state.completedQuotes, state.filters, state.sortConfig]);

  // Pagination
  const paginatedQuotes = useMemo(() => {
    const startIndex = (state.currentPage - 1) * state.itemsPerPage;
    return filteredAndSortedQuotes.slice(
      startIndex, 
      startIndex + state.itemsPerPage
    );
  }, [filteredAndSortedQuotes, state.currentPage, state.itemsPerPage]);

  // Calculate summary statistics
  const summaryStats = useMemo(() => {
    return calculateQuoteStats(filteredAndSortedQuotes);
  }, [filteredAndSortedQuotes]);

  // Event handlers
  const handleSort = (key) => {
    let direction = 'ascending';
    if (state.sortConfig.key === key && state.sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    dispatch({ type: 'SET_SORT', payload: { key, direction } });
  };

  const handleCSVExport = async () => {
    try {
      toast.info('Generating CSV report...');
      const csvData = await reportsService.generateCSV(
        filteredAndSortedQuotes,
        state.charities,
        summaryStats
      );
      
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `quotes-report-${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      
      toast.success('CSV report downloaded successfully');
    } catch (error) {
      console.error('Error generating CSV:', error);
      toast.error('Failed to generate CSV report');
    }
  };

  const handlePDFExport = async () => {
    try {
      toast.info('Generating PDF report...');
      
      const chartData = {
        cumulativeData: getChartData.cumulative(filteredAndSortedQuotes),
        charityData: getChartData.charity(filteredAndSortedQuotes, state.charities)
      };
      
      const formattedStats = {
        ...summaryStats,
        uniqueCharities: summaryStats.uniqueCharities.size,
        totalFairMarketValue: formatters.currency(summaryStats.totalFairMarketValue),
        totalEstTaxSavings: formatters.currency(summaryStats.totalEstTaxSavings)
      };
      
      const metadata = {
        userRole,
        companyName: userRole !== 'SystemAdmin' ? 
          state.companies.find(c => c.id === userCompany)?.name : 'All Companies',
        generatedDate: new Date().toLocaleString()
      };

      const doc = await reportsService.generatePDF(
        filteredAndSortedQuotes,
        state.charities,
        formattedStats,
        chartData,
        metadata
      );
      
      doc.save(`quotes-report-${new Date().toISOString().split('T')[0]}.pdf`);
      toast.success('PDF report downloaded successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF report');
    }
  };

  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header user={state.user} />
      <div className="flex">
        <Sidebar />
        {/* Added pl-64 for sidebar width, and other spacing improvements */}
        <main className="flex-1 p-6 pl-64">
          <div className="max-w-8xl mx-auto">
            <PageHeader />
            
            <StatCards stats={summaryStats} />
            
            <FiltersSection
              filters={state.filters}
              distributionCenters={state.distributionCenters}
              companies={state.companies}
              charities={state.charities}
              dispatch={dispatch}
              userRole={userRole}
            />
            
            <div className="mb-8">
            <DonationCharts
              quotes={filteredAndSortedQuotes}
              charities={state.charities}
            />
          </div>
                      
            <ActionButtons
              onCSVExport={handleCSVExport}
              onPDFExport={handlePDFExport}
              onRefresh={fetchData}
            />
            
            <QuotesTable
              quotes={paginatedQuotes}
              charities={state.charities}
              sortConfig={state.sortConfig}
              onSort={handleSort}
            />
            
            <Pagination
              currentPage={state.currentPage}
              totalItems={filteredAndSortedQuotes.length}
              itemsPerPage={state.itemsPerPage}
              onPageChange={(page) => dispatch({ type: 'SET_PAGE', payload: page })}
              onItemsPerPageChange={(items) => dispatch({ 
                type: 'SET_ITEMS_PER_PAGE', 
                payload: items 
              })}
            />
            
            <CharityImpactSection
              quotes={filteredAndSortedQuotes}
              charities={state.charities}
              filter={charityImpactFilter}
              onFilterChange={setCharityImpactFilter}
            />
          </div>
        </main>
      </div>
      
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};

// Subcomponents
const PageHeader = () => (
  <div className="mb-8">
    <h1 className="text-3xl font-bold text-gray-900 flex items-center gap-3">
      <FaFileAlt className="text-blue-600" />
      <span>My Reports</span>
    </h1>
  </div>
);

const StatCards = ({ stats }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 mb-8">
    <StatCard
      icon={FaFileAlt}
      label="Completed Quotes"
      value={formatters.number(stats.totalQuotes)}
      className="bg-blue-50"
      iconClassName="text-blue-600"
    />
    <StatCard
      icon={FaDollarSign}
      label="Total Value"
      value={formatters.abbreviatedCurrency(stats.totalFairMarketValue)}
      className="bg-green-50"
      iconClassName="text-green-600"
    />
    <StatCard
      icon={FaMoneyBillWave}
      label="Est. Tax Benefits"
      value={formatters.abbreviatedCurrency(stats.totalEstTaxSavings)}
      className="bg-purple-50"
      iconClassName="text-purple-600"
    />
    <StatCard
      icon={FaPallet}
      label="Total Pallets"
      value={formatters.number(stats.totalPallets)}
      className="bg-orange-50"
      iconClassName="text-orange-600"
    />
    <StatCard
      icon={FaUsers}
      label="Est. People Helped"
      value={formatters.number(stats.totalQuantity)}
      className="bg-teal-50"
      iconClassName="text-teal-600"
    />
  </div>
);

const StatCard = ({ icon: Icon, label, value, className, iconClassName }) => (
  <div className={`rounded-lg shadow p-6 ${className}`}>
    <div className="flex items-center gap-4">
      <div className={`p-3 rounded-full ${iconClassName}`}>
        <Icon className="w-6 h-6" />
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">{label}</p>
        <p className="text-2xl font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  </div>
);

const FiltersSection = ({
  filters,
  distributionCenters,
  companies,
  charities,
  dispatch,
  userRole,
}) => (
  <div className="bg-white rounded-lg shadow p-6 mb-8">
    <div className="flex items-center justify-between mb-4">
      <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
        <FaFilter className="text-gray-500" />
        <span>Filters</span>
      </h2>
      <button
        onClick={() => dispatch({ type: 'RESET_FILTERS' })}
        className="flex items-center gap-2 px-3 py-2 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
      >
        <FaUndo />
        <span>Reset Filters</span>
      </button>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <FilterField
        icon={FaSearch}
        type="text"
        placeholder="Search quotes..."
        value={filters.searchTerm}
        onChange={(e) => dispatch({
          type: 'SET_FILTER',
          payload: { name: 'searchTerm', value: e.target.value }
        })}
      />
      
      <FilterField
        icon={FaWarehouse}
        type="select"
        value={filters.distributionCenter}
        onChange={(e) => dispatch({
          type: 'SET_FILTER',
          payload: { name: 'distributionCenter', value: e.target.value }
        })}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>
            {dc.name}
          </option>
        ))}
      </FilterField>

      {userRole === 'SystemAdmin' && (
        <FilterField
          icon={FaBuilding}
          type="select"
          value={filters.company}
          onChange={(e) => dispatch({
            type: 'SET_FILTER',
            payload: { name: 'company', value: e.target.value }
          })}
        >
          <option value="all">All Companies</option>
          {companies.map(company => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </FilterField>
      )}

      <FilterField
        icon={FaHandHoldingHeart}
        type="select"
        value={filters.charity}
        onChange={(e) => dispatch({
          type: 'SET_FILTER',
          payload: { name: 'charity', value: e.target.value }
        })}
      >
        <option value="all">All Charities</option>
        {Object.values(charities).map(charity => (
          <option key={charity.id} value={charity.id}>
            {charity?.orgName}
          </option>
        ))}
      </FilterField>

      <div className="relative">
        <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <DatePicker
          selectsRange
          startDate={filters.dateRange.start}
          endDate={filters.dateRange.end}
          onChange={(update) => dispatch({
            type: 'SET_FILTER',
            payload: { name: 'dateRange', value: { start: update[0], end: update[1] } }
          })}
          isClearable
          placeholderText="Select date range"
          className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
    </div>
  </div>
);

const FilterField = ({ icon: Icon, type, children, ...props }) => {
  const baseClassName = "w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500";
  
  return (
    <div className="relative">
      <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      {type === 'select' ? (
        <select className={baseClassName} {...props}>
          {children}
        </select>
      ) : (
        <input className={baseClassName} type={type} {...props} />
      )}
    </div>
  );
};

const ActionButtons = ({ onCSVExport, onPDFExport, onRefresh }) => (
  <div className="flex gap-4 mb-8">
    <button
      onClick={onCSVExport}
      className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
    >
      <FaFileCsv />
      <span>Export CSV</span>
    </button>
    
    <button
      onClick={onPDFExport}
      className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
    >
      <FaFilePdf />
      <span>Export PDF</span>
    </button>
    
    <button
      onClick={onRefresh}
      className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
    >
      <FaSyncAlt />
      <span>Refresh Data</span>
    </button>
  </div>
);

const CharityImpactSection = ({ quotes, charities, filter, onFilterChange }) => {
  // Calculate charity impact data
  const charitableImpact = useMemo(() => {
    const impact = {};
    quotes.forEach(quote => {
      quote.assignedCharities?.forEach(assignment => {
        const charityId = assignment.charityId;
        const charity = charities[charityId];
        if (!impact[charityId]) {
          impact[charityId] = {
            charityId,
            name: charity?.orgName || 'Unknown Charity', // Changed from name to orgName
            palletCount: 0,
            quoteCount: 0,
            totalValue: 0,
            totalQuantity: 0
          };
        }
        
        const palletRatio = assignment.palletCount / quote.palletCount;
        impact[charityId].palletCount += assignment.palletCount || 0;
        impact[charityId].quoteCount += 1;
        impact[charityId].totalValue += (quote.fairMarketValue * palletRatio) || 0;
        impact[charityId].totalQuantity += (quote.quantity * palletRatio) || 0;
      });
    });

    return Object.values(impact).sort((a, b) => b.totalValue - a.totalValue);
  }, [quotes, charities]);

  // Filter charities based on selected filter
  const filteredImpact = useMemo(() => {
    switch (filter) {
      case 'top5':
        return charitableImpact.slice(0, 5);
      case 'top10':
        return charitableImpact.slice(0, 10);
      default:
        return charitableImpact;
    }
  }, [charitableImpact, filter]);

  return (
    <div className="space-y-8">
      {/* Section Header */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 bg-white rounded-xl p-6 shadow-sm">
        <div>
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-3">
            <FaHandHoldingHeart className="text-blue-600" />
            <span>Charitable Impact</span>
          </h2>
          <p className="mt-1 text-gray-500">
            Tracking the impact of donations across our charity partners
          </p>
        </div>
        <div className="flex items-center gap-3">
          <label className="text-sm font-medium text-gray-700">View</label>
          <select
            value={filter}
            onChange={(e) => onFilterChange(e.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            <option value="all">All Charities</option>
            <option value="top5">Top 5 Charities</option>
            <option value="top10">Top 10 Charities</option>
          </select>
        </div>
      </div>

      {/* Impact Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredImpact.map((impact, index) => (
          <CharityImpactCard
            key={impact.charityId}
            impact={impact}
            charity={charities[impact.charityId]}
            rank={index + 1}
          />
        ))}
      </div>
    </div>
  );
};

const CharityImpactCard = ({ impact, charity, rank }) => {
  const [imgError, setImgError] = useState(false);
  
  const stats = [
    {
      label: 'Deliveries Received',
      value: formatters.number(impact.quoteCount),
      icon: FaFileAlt,
      color: 'text-blue-600',
      bgColor: 'bg-blue-50'
    },
    {
      label: 'Pallets Received',
      value: formatters.number(impact.palletCount),
      icon: FaPallet,
      color: 'text-orange-600',
      bgColor: 'bg-orange-50'
    },
    {
      label: 'Total Value',
      value: formatters.abbreviatedCurrency(impact.totalValue),
      icon: FaDollarSign,
      color: 'text-green-600',
      bgColor: 'bg-green-50'
    },
    {
      label: 'Items Received',
      value: formatters.number(impact.totalQuantity),
      icon: FaBoxes,
      color: 'text-purple-600',
      bgColor: 'bg-purple-50'
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200">
      {/* Card Header */}
      <div className="p-6 border-b border-gray-100">
        <div className="flex items-start gap-4">
          <div className="relative">
            <div className="absolute -top-2 -left-2 w-6 h-6 rounded-full bg-blue-600 text-white text-xs flex items-center justify-center font-bold">
              {rank}
            </div>
            <img
              src={!imgError && charity?.logoUrl ? charity.logoUrl : '/api/placeholder/64/64'}
              alt={`${impact.name} logo`}
              className="w-16 h-16 rounded-lg object-cover bg-gray-50 border border-gray-200"
              onError={() => setImgError(true)}
            />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-semibold text-gray-900 truncate">
              {impact.name}
            </h3>
            <p className="mt-1 text-sm text-gray-500 line-clamp-2">
              {charity?.description || 'No description available'}
            </p>
          </div>
        </div>
      </div>

      {/* Impact Stats */}
      <div className="p-6">
        <div className="grid grid-cols-2 gap-4">
          {stats.map((stat, index) => (
            <div key={index} className={`p-4 rounded-lg ${stat.bgColor}`}>
              <div className={`text-2xl font-bold ${stat.color} mb-1`}>
                {stat.value}
              </div>
              <div className="flex items-center gap-1.5 text-sm text-gray-600">
                <stat.icon className={stat.color} />
                <span>{stat.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Footer */}
      {charity?.contactInfo && (
        <div className="px-6 py-4 bg-gray-50 rounded-b-xl border-t border-gray-100">
          <div className="flex flex-wrap items-center gap-4">
            {charity.contactInfo.phone && (
              <a 
                href={`tel:${charity.contactInfo.phone}`}
                className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600 transition-colors"
              >
                <FaPhoneAlt className="text-gray-400" />
                <span>{charity.contactInfo.phone}</span>
              </a>
            )}
            {charity.contactInfo.email && (
              <a 
                href={`mailto:${charity.contactInfo.email}`}
                className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600 transition-colors"
              >
                <FaEnvelope className="text-gray-400" />
                <span>{charity.contactInfo.email}</span>
              </a>
            )}
            {charity.contactInfo.website && (
              <a
                href={charity.contactInfo.website}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600 transition-colors ml-auto"
              >
                <FaGlobe className="text-gray-400" />
                <span>Visit Website</span>
                <FaExternalLinkAlt className="text-xs" />
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const QuotesTable = ({ quotes, charities, sortConfig, onSort }) => (
  <div className="bg-white rounded-lg shadow mb-8">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableColumns.map(column => (
              <th
                key={column.key}
                onClick={() => onSort(column.key)}
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
              >
                <div className="flex items-center gap-1">
                  <span>{column.label}</span>
                  {sortConfig.key === column.key && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {quotes.map(quote => (
            <QuoteRow
              key={quote.id}
              quote={quote}
              charities={charities}
            />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const QuoteRow = ({ quote }) => (
  <tr className="hover:bg-gray-50">
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm font-medium text-gray-900">{quote.id}</div>
      <div className="text-sm text-gray-500">{quote.itemId}</div>
    </td>
    
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">
        {new Date(quote.completedAt).toLocaleDateString()}
      </div>
      <div className="text-xs text-gray-500">
        {new Date(quote.completedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </div>
    </td>

    <td className="px-6 py-4">
      <div className="text-sm text-gray-900">{quote.description}</div>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{formatters.number(quote.palletCount)}</div>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{formatters.number(quote.quantity)}</div>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">
        {formatters.currency(quote.fairMarketValue)}
      </div>
      <div className="text-xs text-gray-500">
        Cost Basis: {formatters.currency(quote.costBasis)}
      </div>
    </td>

    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">
        {formatters.currency(calculateEstTaxSaving(quote.costBasis, quote.fairMarketValue))}
      </div>
    </td>

    <td className="px-6 py-4">
      <div className="space-y-2">
        {quote.assignedCharities?.map(charity => (
          <div key={charity.charityId} className="flex items-center gap-2">
            <div className="flex-1">
              <div className="text-sm font-medium text-gray-900">
                {charity.charityName}
              </div>
              <div className="text-xs text-gray-500">
                {charity.palletCount} pallets
              </div>
            </div>
          </div>
        )) || (
          <div className="text-sm text-gray-500">No charities assigned</div>
        )}
      </div>
    </td>

    <td className="px-6 py-4">
      <div className="flex flex-wrap gap-2">
        {quote.pdfUrl && (
          <a href={quote.pdfUrl} target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-md hover:bg-blue-200" data-tooltip-id="tooltip" data-tooltip-content="View Quote PDF">
            <FaFileContract />
            <span>Quote PDF</span>
          </a>
        )}
        
        {quote.assignedCharities?.map(charity => (
          charity.taxReceiptUrl && (
            <a key={charity.charityId} href={charity.taxReceiptUrl} target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-purple-700 bg-purple-100 rounded-md hover:bg-purple-200" data-tooltip-id="tooltip" data-tooltip-content={`Tax Receipt - ${charity.charityName}`}>
              <FaReceipt />
              <span>{charity.charityName}</span>
            </a>
          )
        ))}

        {quote.fmvAssessmentUrl && (
          <a href={quote.fmvAssessmentUrl} target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-md hover:bg-green-200" data-tooltip-id="tooltip" data-tooltip-content="View FMV Assessment">
            <FaFileInvoiceDollar />
            <span>FMV Assessment</span>
          </a>
        )}
      </div>
    </td>
  </tr>
);

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange, onItemsPerPageChange }) => (
  <div className="flex items-center justify-between px-6 py-3 bg-gray-50 border-t border-gray-200">
    <div className="flex items-center gap-2">
      <select
        value={itemsPerPage}
        onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
        className="border border-gray-300 rounded-md px-2 py-1"
      >
        <option value={10}>10 per page</option>
        <option value={25}>25 per page</option>
        <option value={50}>50 per page</option>
      </select>
      <span className="text-sm text-gray-600">
        Showing {((currentPage - 1) * itemsPerPage) + 1} to{' '}
        {Math.min(currentPage * itemsPerPage, totalItems)}{' '}
        of {totalItems} quotes
      </span>
    </div>

    <div className="flex items-center gap-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-3 py-1 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-100"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage * itemsPerPage >= totalItems}
        className="px-3 py-1 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-100"
      >
        Next
      </button>
    </div>
  </div>
);

const tableColumns = [
  { key: 'id', label: 'Quote ID' },
  { key: 'completedAt', label: 'Completion Date' },
  { key: 'description', label: 'Description' },
  { key: 'palletCount', label: 'Pallets' },
  { key: 'quantity', label: 'Quantity' },
  { key: 'fairMarketValue', label: 'Fair Market Value' },
  { key: 'taxSavings', label: 'Est. Tax Savings' },
  { key: 'charities', label: 'Assigned Charities' },
  { key: 'documents', label: 'Documents & Shipping' }
];

export default Reports;