// src/services/mapService.js
import React from 'react';
import ReactDOMServer from 'react-dom/server';

const API_KEY = 'AIzaSyDdb9oVXHH_iAZi03_MNsqmCQz78MsTLJc';

let googleMapsPromise = null;

const loadGoogleMapsAPI = () => {
  if (googleMapsPromise) {
    return googleMapsPromise;
  }

  googleMapsPromise = new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(window.google.maps);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=geometry,places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      if (window.google && window.google.maps) {
        resolve(window.google.maps);
      } else {
        reject(new Error('Google Maps API not available after script load'));
      }
    };

    script.onerror = () => {
      reject(new Error('Failed to load Google Maps API script'));
    };

    document.head.appendChild(script);
  });

  return googleMapsPromise;
};

export const mapService = {
  async init() {
    try {
      const maps = await loadGoogleMapsAPI();
      console.log('Google Maps API initialized successfully');
      return maps;
    } catch (error) {
      console.error('Error initializing Google Maps API:', error);
      throw error;
    }
  },

  async geocodeAddress(address) {
    try {
      const maps = await this.init();
      const geocoder = new maps.Geocoder();

      return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === maps.GeocoderStatus.OK && results[0]) {
            const { lat, lng } = results[0].geometry.location;
            resolve({ latitude: lat(), longitude: lng() });
          } else {
            reject(new Error(`Geocoding failed due to: ${status}`));
          }
        });
      });
    } catch (error) {
      console.error('Error in geocodeAddress:', error);
      throw error;
    }
  },

  async geocodeAddresses(addresses) {
    try {
      const geocodedAddresses = await Promise.all(
        addresses.map(async (address) => {
          try {
            const geocoded = await this.geocodeAddress(
              `${address.street}, ${address.city}, ${address.state} ${address.zip}`
            );
            return { ...address, geocoded };
          } catch (error) {
            console.error('Error geocoding address:', error);
            return { ...address, geocoded: null };
          }
        })
      );
      return geocodedAddresses;
    } catch (error) {
      console.error('Error in geocodeAddresses:', error);
      throw error;
    }
  },

  async calculateDistances(origin, destinations) {
    try {
      const maps = await this.init();
      const service = new maps.DistanceMatrixService();

      return new Promise((resolve, reject) => {
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: destinations,
            travelMode: maps.TravelMode.DRIVING,
            unitSystem: maps.UnitSystem.IMPERIAL,
          },
          (response, status) => {
            if (status === maps.DistanceMatrixStatus.OK) {
              const distances = response.rows[0].elements.map((element, index) => ({
                destinationIndex: index,
                distance: element.distance?.text || 'N/A',
                duration: element.duration?.text || 'N/A',
              }));
              resolve(distances);
            } else {
              reject(new Error(`Distance Matrix failed due to: ${status}`));
            }
          }
        );
      });
    } catch (error) {
      console.error('Error in calculateDistances:', error);
      throw error;
    }
  },

  createStaticMapUrl(latitude, longitude, zoom = 13, width = 400, height = 300) {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoom}&size=${width}x${height}&key=${API_KEY}`;
  },

  async createMap(containerId, icons) {
    try {
      const maps = await this.init();
      const mapElement = document.getElementById(containerId);
      
      if (!mapElement) {
        throw new Error(`Element with id "${containerId}" not found`);
      }
  
      const mapStyles = [
        {
          featureType: "all",
          elementType: "geometry",
          stylers: [{ color: "#f8f9fa" }]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#e9ecef" }]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#495057" }]
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#f1f3f5" }]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#495057" }]
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [{ color: "#e9ecef" }]
        },
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#495057" }]
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ color: "#f8f9fa" }]
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#e9ecef" }]
        }
      ];
  
      const map = new maps.Map(mapElement, {
        center: { lat: 37.0902, lng: -95.7129 },
        zoom: 4,
        styles: mapStyles,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true
      });
  
      let markers = [];
      let infoWindows = [];
  
      const createMarker = (location, isDistributionCenter) => {
        const iconSvg = isDistributionCenter ? icons.DistributionCenterIcon : icons.CharityIcon;
        const iconColor = isDistributionCenter ? '#3498db' : '#8e44ad'; // Blue for distribution centers, purple for charities
        const iconSvgString = ReactDOMServer.renderToStaticMarkup(
          React.cloneElement(iconSvg, { color: iconColor, size: 24 })
        );
        const iconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvgString)}`;
  
        const marker = new maps.Marker({
          position: { lat: location.address.latitude, lng: location.address.longitude },
          map: map,
          title: location.name,
          icon: {
            url: iconUrl,
            scaledSize: new maps.Size(30, 30),
            anchor: new maps.Point(15, 15),
          },
          animation: maps.Animation.DROP
        });
  
        const infoWindowContent = `
          <div class="map-popup">
            <h3>${location.name}</h3>
            <p>${location.address.street}, ${location.address.city}, ${location.address.state} ${location.address.zip}</p>
            ${isDistributionCenter ? `
              <div class="map-popup-stats">
                <div class="stat-item">
                  <span class="stat-label">Total Donations:</span>
                  <span class="stat-value">${location.stats.totalDonations}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-label">Total Fair Market Value:</span>
                  <span class="stat-value">$${location.stats.totalFairMarketValue.toFixed(2)}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-label">Charities Served:</span>
                  <span class="stat-value">${location.stats.charitiesServed}</span>
                </div>
              </div>
            ` : `
              <div class="map-popup-stats">
                <div class="stat-item">
                  <span class="stat-label">Donations Received:</span>
                  <span class="stat-value">${location.stats.totalDonationsReceived}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-label">Total Fair Market Value:</span>
                  <span class="stat-value">$${location.stats.totalFairMarketValue.toFixed(2)}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-label">Pallets Received:</span>
                  <span class="stat-value">${location.stats.palletsReceived}</span>
                </div>
              </div>
            `}
          </div>
        `;
  
        const infoWindow = new maps.InfoWindow({
          content: infoWindowContent,
          maxWidth: 300
        });
  
        marker.addListener('click', () => {
          infoWindows.forEach(iw => iw.close());
          infoWindow.open(map, marker);
        });
  
        markers.push(marker);
        infoWindows.push(infoWindow);
      };
  
      return {
        map,
        updateMarkers: (distributionCenters, charities) => {
          markers.forEach(marker => marker.setMap(null));
          infoWindows.forEach(infoWindow => infoWindow.close());
          markers = [];
          infoWindows = [];
  
          distributionCenters.forEach(dc => {
            if (dc.address && dc.address.latitude && dc.address.longitude) {
              createMarker(dc, true);
            }
          });
  
          charities.forEach(charity => {
            if (charity.address && charity.address.latitude && charity.address.longitude) {
              createMarker(charity, false);
            }
          });
  
          if (markers.length > 0) {
            const bounds = new maps.LatLngBounds();
            markers.forEach(marker => bounds.extend(marker.getPosition()));
            map.fitBounds(bounds);
          }
        }
      };
    } catch (error) {
      console.error('Error creating map:', error);
      throw error;
    }
  }
};