// src/services/logisticsService.js


import { db, storage } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where, writeBatch, runTransaction } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import { charityService } from './charityService';
import { quoteService } from './quoteService';
import { donationService } from './donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { completedDonationsService } from './completedDonationsService';
import { messageService } from './messageService';
import { userService } from './userService';
import { completedDeliveriesService } from './completedDeliveriesService';


const COLLECTION_NAME = 'logisticsTasks';
const STORAGE_PATH = 'logisticsTasks';
const BOL_FILE_PREFIX = 'BOL_';


// Standard BOL styling configuration
const BOL_STYLES = {
  colors: {
    primary: [41, 128, 185],      // Header blue
    secondary: [52, 73, 94],      // Body text
    accent: [44, 62, 80],         // Section headers
    light: [236, 240, 241],       // Light background
    border: [189, 195, 199],      // Border color
    highlight: [52, 152, 219]     // Highlight color
  },
  fonts: {
    header: {
      size: 22,
      style: 'bold'
    },
    subheader: {
      size: 14,
      style: 'bold'
    },
    body: {
      size: 10,
      style: 'normal'
    },
    small: {
      size: 8,
      style: 'normal'
    }
  },
  spacing: {
    margin: 10,
    padding: 5,
    lineHeight: 6
  }
};


class LogisticsError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'LogisticsError';
    this.code = code;
  }
}


export const logisticsService = {
  async createTask(taskData) {
    try {
      // Validate required fields
      const requiredFields = [
        'quoteId', 'donationId', 'itemId', 'distributionCenterId',
        'charityId', 'charityLocationId', 'palletQuantity'
      ];
     
      for (const field of requiredFields) {
        if (!taskData[field]) {
          throw new LogisticsError(`Missing required field: ${field}`, 'INVALID_DATA');
        }
      }


      const newTaskRef = doc(collection(db, COLLECTION_NAME));
      const taskWithMetadata = {
        ...taskData,
        id: newTaskRef.id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };


      // Get quote data to verify charity location assignment
      const quote = await quoteService.getById(taskData.quoteId);
      if (!quote) {
        throw new LogisticsError('Quote not found', 'QUOTE_NOT_FOUND');
      }


      // Verify charity assignment exists in quote
      const charityAssignment = quote.assignedCharities.find(ac =>
        ac.charityId === taskData.charityId &&
        ac.locationId === taskData.charityLocationId
      );


      if (!charityAssignment) {
        throw new LogisticsError(
          'Charity location not assigned to this quote',
          'INVALID_CHARITY_ASSIGNMENT'
        );
      }


      // Generate and upload BOL
      const bolPdfBlob = await this.generateBOL(taskWithMetadata, quote);
      const bolPdfUrl = await this.uploadPDF(bolPdfBlob, newTaskRef.id);
      taskWithMetadata.bolPdfUrl = bolPdfUrl;


      await setDoc(newTaskRef, taskWithMetadata);
      console.log(`Logistics task created successfully: ${newTaskRef.id}`);
      return taskWithMetadata;
    } catch (error) {
      console.error('Error creating logistics task:', error);
      throw new LogisticsError(
        `Failed to create logistics task: ${error.message}`,
        error.code || 'CREATE_TASK_ERROR'
      );
    }
  },


  async getTasks(filters = {}) {
    try {
      let q = collection(db, COLLECTION_NAME);


      // Apply filters
      if (filters.distributionCenter) {
        q = query(q, where("distributionCenterId", "==", filters.distributionCenter));
      }
      if (filters.status) {
        q = query(q, where("status", "==", filters.status));
      }
      if (filters.startDate) {
        q = query(q, where("pickupDateTime", ">=", filters.startDate));
      }
      if (filters.endDate) {
        q = query(q, where("deliveryDate", "<=", filters.endDate));
      }
      if (filters.quoteId) {
        q = query(q, where("quoteId", "==", filters.quoteId));
      }
      if (filters.charityId) {
        q = query(q, where("charityId", "==", filters.charityId));
      }
      if (filters.locationId) {
        q = query(q, where("charityLocationId", "==", filters.locationId));
      }


      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting logistics tasks:', error);
      throw new LogisticsError(
        `Failed to get logistics tasks: ${error.message}`,
        'GET_TASKS_ERROR'
      );
    }
  },


  async getTaskById(taskId) {
    try {
      const taskDoc = await getDoc(doc(db, COLLECTION_NAME, taskId));
      if (!taskDoc.exists()) {
        console.log(`No logistics task found with ID: ${taskId}`);
        return null;
      }
      return { id: taskDoc.id, ...taskDoc.data() };
    } catch (error) {
      console.error('Error getting logistics task by ID:', error);
      throw new LogisticsError(
        `Failed to get logistics task by ID: ${error.message}`,
        'GET_TASK_ERROR'
      );
    }
  },


  async updateTask(taskId, updateData) {
    try {
      const taskRef = doc(db, COLLECTION_NAME, taskId);
      const taskDoc = await getDoc(taskRef);
     
      if (!taskDoc.exists()) {
        throw new LogisticsError(
          `No logistics task found with ID: ${taskId}`,
          'TASK_NOT_FOUND'
        );
      }


      const updatedTask = {
        ...taskDoc.data(),
        ...updateData,
        updatedAt: new Date().toISOString()
      };


      // Re-validate charity assignment if relevant fields are being updated
      if (updateData.charityId || updateData.charityLocationId || updateData.quoteId) {
        const quote = await quoteService.getById(updatedTask.quoteId);
        if (!quote) {
          throw new LogisticsError('Quote not found', 'QUOTE_NOT_FOUND');
        }


        const charityAssignment = quote.assignedCharities.find(ac =>
          ac.charityId === updatedTask.charityId &&
          ac.locationId === updatedTask.charityLocationId
        );


        if (!charityAssignment) {
          throw new LogisticsError(
            'Charity location not assigned to this quote',
            'INVALID_CHARITY_ASSIGNMENT'
          );
        }
      }


      await updateDoc(taskRef, updatedTask);
      console.log(`Logistics task updated successfully: ${taskId}`);


      // Regenerate BOL if relevant fields changed
      if (updateData.pickupDateTime || updateData.deliveryDate ||
          updateData.status || updateData.charityLocationId) {
        const quote = await quoteService.getById(updatedTask.quoteId);
        const newBolPdfBlob = await this.generateBOL(updatedTask, quote);
        const newBolPdfUrl = await this.uploadPDF(newBolPdfBlob, taskId);
        await updateDoc(taskRef, { bolPdfUrl: newBolPdfUrl });
        updatedTask.bolPdfUrl = newBolPdfUrl;
      }


      return updatedTask;
    } catch (error) {
      console.error('Error updating logistics task:', error);
      throw new LogisticsError(
        `Failed to update logistics task: ${error.message}`,
        error.code || 'UPDATE_TASK_ERROR'
      );
    }
  },


async generateBOL(task, quote) {
  console.log('Generating BOL for task:', JSON.stringify(task, null, 2));


  // Create PDF
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'letter'
  });


  try {
    // Get donation data and distribution center data
    const [donation, distributionCenter] = await Promise.all([
      donationService.getById(quote.donationId),
      distributionCenterService.getById(task.distributionCenterId)
    ]);


    if (!donation) {
      throw new Error(`Donation with ID ${quote.donationId} not found`);
    }


    if (!distributionCenter) {
      throw new Error(`Distribution center with ID ${task.distributionCenterId} not found`);
    }


    // Header
    pdf.setFillColor(...BOL_STYLES.colors.primary);
    pdf.rect(30, 30, pdf.internal.pageSize.width - 60, 50, 'F');
    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(22);
    pdf.setFont(undefined, 'bold');
    pdf.text('BILL OF LADING', pdf.internal.pageSize.width / 2, 60, { align: 'center' });


    // Carrier Information Section
    const startY = 100;
    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'bold');
    pdf.text('CARRIER INFORMATION', 40, startY);
   
    pdf.setFontSize(10);
    pdf.setFont(undefined, 'normal');
    const carrierFields = [
      ['Carrier Name:', '_____________________', 'Phone:', '_____________________'],
      ['USDOT:', '_____________________', 'SCAC:', '_____________________'],
      ['Truck #:', '_____________________']
    ];
   
    let yOffset = startY + 20;
    carrierFields.forEach(row => {
      let xPos = 40;
      row.forEach(field => {
        pdf.text(field, xPos, yOffset);
        xPos += 250;
      });
      yOffset += 20;
    });


    // Reference Numbers
    pdf.setFont(undefined, 'bold');
    pdf.text('REFERENCE NUMBERS', pdf.internal.pageSize.width - 200, startY);
    pdf.setFont(undefined, 'normal');
    pdf.text(`PO #: ${task.id}`, pdf.internal.pageSize.width - 200, startY + 20);


    // Ship From Section
    const shipFromY = startY + 80;
    pdf.setFont(undefined, 'bold');
    pdf.text('SHIP FROM', 40, shipFromY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      `Shipper: ${task.distributionCenterName}`,
      `Address: ${task.distributionCenterAddress.street}${task.distributionCenterAddress.street2 ? ` ${task.distributionCenterAddress.street2}` : ''}`,
      `City/State/Zip: ${task.distributionCenterAddress.city}, ${task.distributionCenterAddress.state} ${task.distributionCenterAddress.zip}`,
      `Contact: ${quote.contactName || ''}`,
      `Phone: ${quote.contactPhone || ''}`,
      `Pickup #: ${task.pickupNumber || ''}`
    ], 40, shipFromY + 20);


    // Ship To Section - Now using task's detailed charity location data
    pdf.setFont(undefined, 'bold');
    pdf.text('SHIP TO', pdf.internal.pageSize.width - 300, shipFromY);
    pdf.setFont(undefined, 'normal');
   
    // Use location-specific contact information from task
    const contactInfo = task.primaryContact || {};
    const charityAddress = task.charityAddress;
   
    pdf.text([
      `Receiver: ${task.charityName}`,
      `Address: ${charityAddress.street}${charityAddress.street2 ? ` ${charityAddress.street2}` : ''}`,
      `City/State/Zip: ${charityAddress.city}, ${charityAddress.state} ${charityAddress.zip}`,
      `Contact: ${contactInfo.name || ''}${contactInfo.title ? ` (${contactInfo.title})` : ''}`,
      `Phone: ${contactInfo.phone || ''}`,
      `Email: ${contactInfo.email || ''}`
    ], pdf.internal.pageSize.width - 300, shipFromY + 20);


    // Bill To Section
    const billToY = shipFromY + 140;
    pdf.setFont(undefined, 'bold');
    pdf.text('BILL TO', 40, billToY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      'Name: Donating Simplified',
      'Address: 750 North St. Paul Street Ste 204',
      'City/State/Zip: Dallas, TX 75201'
    ], 40, billToY + 20);


    // Contact Section
    pdf.setFont(undefined, 'bold');
    pdf.text('CONTACT', pdf.internal.pageSize.width - 300, billToY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      'Name: shipping@simpli.supply',
      'Phone: 877.573.4483',
      'Email: shipping@simpli.supply'
    ], pdf.internal.pageSize.width - 300, billToY + 20);


    // Equipment Section
    const equipmentY = billToY + 100;
    pdf.setFont(undefined, 'bold');
    pdf.text('EQUIPMENT', 40, equipmentY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      'Trailer Type: _____________________',
      'Trailer Length: _____________________',
      'Team Drivers: _____________________'
    ], 40, equipmentY + 20);


    // Cargo Section
    pdf.setFont(undefined, 'bold');
    pdf.text('CARGO', pdf.internal.pageSize.width - 300, equipmentY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      `Cargo Value: $${quote.fairMarketValue || '0'}`,
      'Over-Dimensional: _____________________',
      'HazMat: No'
    ], pdf.internal.pageSize.width - 300, equipmentY + 20);


    // Signature Section
    const signatureY = equipmentY + 90;
   
    // Shipper Signature
    pdf.setFont(undefined, 'bold');
    pdf.text('SHIPPER SIGNATURE', 40, signatureY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      'This is to certify that the above named materials are properly classified,',
      'described, packaged, marked and labeled, and are in proper condition for',
      'transportation according to the applicable regulations of the Department of',
      'Transportation.'
    ], 40, signatureY + 20);
    pdf.text('Signature: _____________________  Date: _________________', 40, signatureY + 80);


    // Carrier Signature
    const carrierSignY = signatureY + 100;
    pdf.setFont(undefined, 'bold');
    pdf.text('CARRIER SIGNATURE', 40, carrierSignY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      'Carrier acknowledges receipt of packages and required placards. Carrier certifies',
      'emergency response information was made available and/or carrier has the DOT',
      'emergency response guidebook or equivalent documentation in the vehicle.',
      'Property described above is received in good order, except as noted.'
    ], 40, carrierSignY + 20);
    pdf.text('Signature: _____________________  Date: _________________', 40, carrierSignY + 80);


    // Consignee Receipt
    const consigneeY = carrierSignY + 100;
    pdf.setFont(undefined, 'bold');
    pdf.text('CONSIGNEE RECEIPT', 40, consigneeY);
    pdf.setFont(undefined, 'normal');
    pdf.text([
      `Expected Receiver: ${contactInfo.name || '_____________________'}`,
      'Actual Receiver (Print Name): _____________________',
      'Signature: _____________________  Date: _________________',
      'Time of Delivery: _____________________',
      'Number of Units Received: _____________________',
      'Condition: ☐ Good Order  ☐ Damaged  ☐ Short',
      'Notes/Exceptions: _________________________________________________'
    ], 40, consigneeY + 20);


    console.log('BOL generated successfully');
    return pdf.output('blob');
  } catch (error) {
    console.error('Error generating BOL:', error);
    throw new LogisticsError(
      `Failed to generate BOL: ${error.message}`,
      'GENERATE_BOL_ERROR'
    );
  }
},


  async uploadPDF(pdfBlob, taskId) {
    try {
      const storageReference = ref(storage, `${STORAGE_PATH}/${BOL_FILE_PREFIX}${taskId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading BOL PDF:', error);
      throw new LogisticsError(
        `Failed to upload BOL PDF: ${error.message}`,
        'UPLOAD_PDF_ERROR'
      );
    }
  },


  async uploadTaxReceipt(file, taskId) {
    try {
      if (!file) {
        throw new LogisticsError('No file provided', 'INVALID_FILE');
      }


      if (file.type !== 'application/pdf') {
        throw new LogisticsError('File must be a PDF', 'INVALID_FILE_TYPE');
      }


      const maxSize = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSize) {
        throw new LogisticsError('File size exceeds 10MB limit', 'FILE_TOO_LARGE');
      }


      const storageReference = ref(
        storage,
        `${STORAGE_PATH}/TaxReceipt_${taskId}_${new Date().toISOString()}.pdf`
      );
      await uploadBytes(storageReference, file);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading tax receipt:', error);
      throw new LogisticsError(
        `Failed to upload tax receipt: ${error.message}`,
        error.code || 'UPLOAD_TAX_RECEIPT_ERROR'
      );
    }
  },


  async deletePDF(taskId) {
    try {
      const storageReference = ref(storage, `${STORAGE_PATH}/${BOL_FILE_PREFIX}${taskId}.pdf`);
      await deleteObject(storageReference);
      console.log(`BOL PDF deleted successfully for task: ${taskId}`);
    } catch (error) {
      console.error('Error deleting BOL PDF:', error);
      throw new LogisticsError(
        `Failed to delete BOL PDF: ${error.message}`,
        'DELETE_PDF_ERROR'
      );
    }
  },


  async validateTaskCompletion(taskId, acceptedDate, taxReceiptUrl) {
    const task = await this.getTaskById(taskId);
    if (!task) {
      throw new LogisticsError('Task not found', 'TASK_NOT_FOUND');
    }
 
    // Status validation
    if (task.status === 'completed') {
      throw new LogisticsError('Task is already completed', 'TASK_ALREADY_COMPLETED');
    }
    if (!['inProgress', 'scheduled'].includes(task.status)) {
      throw new LogisticsError(
        'Task must be in progress or scheduled to complete',
        'INVALID_TASK_STATUS'
      );
    }
 
    // Validate dates
    if (!acceptedDate) {
      throw new LogisticsError('Accepted date is required', 'INVALID_DATE');
    }
    if (new Date(acceptedDate) > new Date()) {
      throw new LogisticsError('Accepted date cannot be in the future', 'INVALID_DATE');
    }
    if (!taxReceiptUrl) {
      throw new LogisticsError('Tax receipt URL is required', 'MISSING_RECEIPT');
    }
 
    // Validate pallet count and assignment
    const quote = await quoteService.getById(task.quoteId);
    if (!quote) {
      throw new LogisticsError('Quote not found', 'QUOTE_NOT_FOUND');
    }
 
    const charityAssignment = quote.assignedCharities.find(ac =>
      ac.charityId === task.charityId &&
      ac.locationId === task.charityLocationId
    );
 
    if (!charityAssignment) {
      throw new LogisticsError(
        'Charity location assignment not found in quote',
        'INVALID_CHARITY_ASSIGNMENT'
      );
    }
 
    if (task.palletQuantity !== charityAssignment.palletCount) {
      throw new LogisticsError(
        'Task pallet quantity does not match quote assignment',
        'PALLET_COUNT_MISMATCH'
      );
    }
 
    return { task, quote, charityAssignment };
  },


  async getTasksByQuote(quoteId) {
    try {
      const tasks = await this.getTasks({ quoteId });
      return tasks.reduce((acc, task) => {
        const key = `${task.charityId}_${task.charityLocationId}`;
        acc[key] = task;
        return acc;
      }, {});
    } catch (error) {
      console.error('Error getting tasks by quote:', error);
      throw new LogisticsError(
        `Failed to get tasks by quote: ${error.message}`,
        'GET_TASKS_BY_QUOTE_ERROR'
      );
    }
  },


  /**
 * Completes a logistics task and handles all related records and notifications
 * @param {string} taskId - The ID of the task to complete
 * @param {string} acceptedDate - The date the delivery was accepted by the charity
 * @param {string} taxReceiptUrl - The URL of the uploaded tax receipt
 * @returns {Promise<Object>} The completion result with updated records
 * @throws {LogisticsError} If any part of the completion process fails
 */
  async completeTask(taskId, acceptedDate, taxReceiptUrl) {
    let completedDelivery;
    let task, quote, donation;
 
    try {
      // STEP 1: Complete the task first
      const taskResult = await runTransaction(db, async (transaction) => {
        // Get and validate task
        const taskRef = doc(db, COLLECTION_NAME, taskId);
        const taskDoc = await transaction.get(taskRef);
        if (!taskDoc.exists()) {
          throw new LogisticsError('Task not found', 'TASK_NOT_FOUND');
        }
        task = { id: taskDoc.id, ...taskDoc.data() };
 
        // Get and validate quote
        const quoteRef = doc(db, 'quotes', task.quoteId);
        const quoteDoc = await transaction.get(quoteRef);
        if (!quoteDoc.exists()) {
          throw new LogisticsError('Quote not found', 'QUOTE_NOT_FOUND');
        }
        quote = { id: quoteDoc.id, ...quoteDoc.data() };
 
        // Validate task status
        if (task.status === 'completed') {
          throw new LogisticsError('Task already completed', 'TASK_ALREADY_COMPLETED');
        }
        if (!['inProgress', 'scheduled'].includes(task.status)) {
          throw new LogisticsError('Task must be in progress or scheduled to complete', 'INVALID_TASK_STATUS');
        }
 
        // Create completed delivery record
        completedDelivery = await completedDeliveriesService.createCompletedDelivery(
          task,
          taxReceiptUrl,
          acceptedDate
        );
 
        // Update task
        const updatedTask = {
          ...task,
          status: 'completed',
          acceptedByCharityDate: acceptedDate,
          taxReceiptUrl,
          completedDeliveryId: completedDelivery.id,
          lastUpdatedAt: new Date().toISOString(),
          completionDate: new Date().toISOString()
        };
       
        transaction.update(taskRef, updatedTask);
        return updatedTask;
      });
 
      // Update our task reference
      task = taskResult;
 
      // STEP 2: Verify all tasks are complete and update quote if needed
      const allTasksSnapshot = await getDocs(
        query(collection(db, COLLECTION_NAME), where('quoteId', '==', task.quoteId))
      );
      const allTasks = allTasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
     
      const allTasksComplete = allTasks.every(t => t.status === 'completed');
     
      if (allTasksComplete) {
        // Complete the quote using quoteService
        await quoteService.completeQuote(task.quoteId);
        quote = await quoteService.getById(task.quoteId);
      
        // Get donation for subsequent donation completion check
        const donationRef = doc(db, 'donations', quote.donationId);
        const donationDoc = await getDoc(donationRef);
        if (!donationDoc.exists()) {
          throw new LogisticsError('Donation not found', 'DONATION_NOT_FOUND');
        }
        donation = { id: donationDoc.id, ...donationDoc.data() };
 
        // STEP 3: Check if all quotes in donation are complete
        const allQuotesSnapshot = await getDocs(
          query(collection(db, 'quotes'), where('donationId', '==', quote.donationId))
        );
        const allQuotes = allQuotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
       
        const allQuotesComplete = allQuotes.every(q => q.status === 'completed');
       
        if (allQuotesComplete && donation && donation.status !== 'completed') {
          // Complete the donation in a final transaction
          await runTransaction(db, async (transaction) => {
            // Double check donation status
            const donationRef = doc(db, 'donations', quote.donationId);
            const donationDoc = await transaction.get(donationRef);
            if (!donationDoc.exists()) {
              throw new LogisticsError('Donation not found', 'DONATION_NOT_FOUND');
            }
            const currentDonation = { id: donationDoc.id, ...donationDoc.data() };
           
            if (currentDonation.status === 'completed') {
              return; // Donation already completed
            }
 
            // Get all tasks for all quotes
            const allQuoteTasks = await Promise.all(
              allQuotes.map(async q => {
                const quoteTasksSnapshot = await getDocs(
                  query(collection(db, COLLECTION_NAME), where('quoteId', '==', q.id))
                );
                return quoteTasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              })
            );
 
            const flatTasks = allQuoteTasks.flat();
           
            // Verify all tasks are actually complete
            const anyIncomplete = flatTasks.some(t => t.status !== 'completed');
            if (anyIncomplete) {
              throw new LogisticsError(
                'Cannot complete donation - found incomplete tasks',
                'INCOMPLETE_TASKS'
              );
            }
 
            // Update donation status
            const donationUpdate = {
              status: 'completed',
              completionDate: new Date().toISOString(),
              lastUpdatedAt: new Date().toISOString()
            };
            transaction.update(donationRef, donationUpdate);
            donation = { ...currentDonation, ...donationUpdate };
          });
        }
      }
 
      // STEP 4: Send notifications
      const notifications = [];
     
      // Task completion notification
      notifications.push({
        type: 'TASK_COMPLETED',
        data: { task, quote, completedDelivery }
      });
 
      // Quote completion notification if applicable
      if (allTasksComplete) {
        notifications.push({
          type: 'QUOTE_COMPLETED',
          data: { quote, task, completedDelivery }
        });
      }
 
      // Donation completion notification if applicable
      if (donation?.status === 'completed') {
        notifications.push({
          type: 'DONATION_COMPLETED',
          data: { donation, quote }
        });
      }
 
      // Send all notifications
      await Promise.allSettled(notifications.map(async notification => {
        try {
          const creator = await userService.getById(quote.requestorId);
          if (!creator?.phone) {
            console.warn('No phone number found for notification recipient');
            return;
          }
 
          switch (notification.type) {
            case 'TASK_COMPLETED': {
              await messageService.sendCharityDeliveryCompletionNotification(
                creator.phone,
                {
                  taskId: notification.data.task.id,
                  itemDescription: notification.data.task.itemDescription,
                  charityName: notification.data.task.charityName,
                  charityLocationName: notification.data.task.charityLocationName,
                  palletQuantity: notification.data.task.palletQuantity,
                  acceptedDate: notification.data.task.acceptedByCharityDate,
                  distributionCenterName: notification.data.task.distributionCenterName
                }
              );
              break;
            }
 
            case 'QUOTE_COMPLETED': {
              await messageService.sendQuoteCompletionNotification(
                creator.phone,
                {
                  id: notification.data.quote.id,
                  description: notification.data.quote.description,
                  fairMarketValue: notification.data.quote.fairMarketValue,
                  distributionCenterName: notification.data.quote.distributionCenterName,
                  assignedCharities: notification.data.quote.assignedCharities,
                  completedAt: notification.data.quote.completionDate
                }
              );
              break;
            }
 
            case 'DONATION_COMPLETED': {
              const allQuotes = await getDocs(
                query(collection(db, 'quotes'),
                where('donationId', '==', notification.data.donation.id))
              );
             
              const quotesData = allQuotes.docs.map(doc => ({
                id: doc.id,
                description: doc.data().description,
                fairMarketValue: doc.data().fairMarketValue,
                costBasis: doc.data().costBasis,
                totalWeight: doc.data().totalWeight,
                distributionCenterName: doc.data().distributionCenterName,
                assignedCharities: doc.data().assignedCharities,
              }));
           
              await messageService.sendDonationCompletionNotification(
                creator.phone,
                {
                  id: notification.data.donation.id,
                  completionDate: notification.data.donation.completionDate,
                  quotes: quotesData
                }
              );
              break;            
            }
          }
        } catch (error) {
          console.error(`Error sending ${notification.type} notification:`, error);
          console.error('Notification data:', notification.data);
          console.error('Error details:', error.message);
          // Don't throw - we don't want notification failures to affect the completion flow
        }
      }));
 
      // Return completion results
      return {
        task,
        completedDelivery,
        quoteCompleted: allTasksComplete,
        donationCompleted: donation?.status === 'completed'
      };
 
    } catch (error) {
      console.error('Error in completion process:', error);
      throw error instanceof LogisticsError ? error :
        new LogisticsError(
          `Failed to complete task: ${error.message}`,
          'COMPLETE_TASK_ERROR'
        );
    }
  },
 
  async validateQuoteCompletion(transaction, quoteId) {
    const tasksQuery = query(collection(db, 'logisticsTasks'), where('quoteId', '==', quoteId));
    const tasksDocs = await transaction.get(tasksQuery);
    const tasks = tasksDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return tasks.length > 0 && tasks.every(task => task.status === 'completed');
  },
 
  async completeQuote(transaction, quoteId, quote) {
    // Step 1: Update quote status
    const quoteRef = doc(db, 'quotes', quoteId);
    const updatedQuote = {
      ...quote,
      status: 'completed',
      completionDate: new Date().toISOString(),
      lastUpdatedAt: new Date().toISOString()
    };
    transaction.update(quoteRef, updatedQuote);
 
    // Step 2: Check and possibly complete donation
    const donationRef = doc(db, 'donations', quote.donationId);
    const donationDoc = await transaction.get(donationRef);
    if (!donationDoc.exists()) {
      throw new Error('Associated donation not found');
    }
    const donation = { id: donationDoc.id, ...donationDoc.data() };
 
    // Verify donation isn't already completed
    if (donation.status === 'completed') {
      throw new Error('Cannot complete quote - donation is already marked as completed');
    }
 
    const allQuotesCompleted = await this.validateDonationCompletion(transaction, quote.donationId);
   
    if (allQuotesCompleted) {
      console.log('All quotes completed for donation, proceeding with donation completion');
      await this.completeDonation(transaction, quote.donationId, donation);
    }
 
    return {
      quote: updatedQuote,
      donationCompleted: allQuotesCompleted
    };
  },
 
  async validateDonationCompletion(transaction, donationId) {
    const quotesQuery = query(collection(db, 'quotes'), where('donationId', '==', donationId));
    const quotesDocs = await transaction.get(quotesQuery);
    const quotes = quotesDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return quotes.length > 0 && quotes.every(quote => quote.status === 'completed');
  },
 
  async completeDonation(transaction, donationId, donation) {
    // Step 1: Get all completed quotes and tasks within transaction
    const quotesQuery = query(collection(db, 'quotes'), where('donationId', '==', donationId));
    const quotesDocs = await transaction.get(quotesQuery);
    const quotes = quotesDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
 
    // Step 2: Collect all tasks within transaction
    const tasksPromises = quotes.map(quote =>
      transaction.get(
        query(collection(db, 'logisticsTasks'), where('quoteId', '==', quote.id))
      )
    );
    const tasksDocs = await Promise.all(tasksPromises);
    const tasks = tasksDocs.flatMap(taskDoc =>
      taskDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    );
 
    // Step 3: Prepare data collections
    const taxReceiptUrls = {};
    const fmvAssessmentUrls = {};
    const palletGroups = [];
 
    tasks.forEach((task, index) => {
      if (task.taxReceiptUrl) {
        taxReceiptUrls[`task_${index + 1}`] = task.taxReceiptUrl;
      }
      palletGroups.push({
        itemId: task.itemId,
        charityId: task.charityId,
        charityName: task.charityName,
        palletGroup: task.palletGroup,
        palletQuantity: task.palletQuantity
      });
    });
 
    quotes.forEach((quote, index) => {
      if (quote.fmvAssessmentUrl) {
        fmvAssessmentUrls[`quote_${index + 1}`] = quote.fmvAssessmentUrl;
      }
    });
 
    // Step 4: Create completed donation
    const completedDonation = await completedDonationsService.createCompletedDonation(
      quotes[0].id,
      taxReceiptUrls,
      quotes[0].quotePdfUrl,
      palletGroups,
      quotes[0].distributionCenterId,
      fmvAssessmentUrls,
      donation.description,
      quotes.reduce((sum, quote) => sum + (quote.costBasis || 0), 0),
      quotes.reduce((sum, quote) => sum + (quote.totalWeight || 0), 0),
      quotes.reduce((sum, quote) => sum + (quote.quantity || 0), 0),
      donation.unitOfMeasure
    );
 
    // Step 5: Update original donation status
    const donationRef = doc(db, 'donations', donationId);
    transaction.update(donationRef, {
      status: 'completed',
      completionDate: new Date().toISOString(),
      lastUpdatedAt: new Date().toISOString(),
      completedDonationId: completedDonation.id
    });
 
    return completedDonation;
  },
 
  async collectFMVAssessmentUrls(quotes) {
    const fmvAssessmentUrls = {};
    quotes.forEach((quote, index) => {
      if (quote.fmvAssessmentUrl) {
        fmvAssessmentUrls[`quote_${index + 1}`] = quote.fmvAssessmentUrl;
      }
    });
    return fmvAssessmentUrls;
  },
 
  async collectPalletGroups(quotes) {
    const tasks = await Promise.all(
      quotes.map(quote => this.getTasks({ quoteId: quote.id }))
    );
    return tasks.flat().map(task => ({
      itemId: task.itemId,
      charityId: task.charityId,
      charityName: task.charityName,
      palletGroup: task.palletGroup,
      palletQuantity: task.palletQuantity
    }));
  },


  async getTaskProgress(quoteId) {
    try {
      const quote = await quoteService.getById(quoteId);
      if (!quote) {
        throw new LogisticsError('Quote not found', 'QUOTE_NOT_FOUND');
      }


      const tasks = await this.getTasks({ quoteId });
     
      const progress = {
        totalAssignments: quote.assignedCharities.length,
        completed: 0,
        inProgress: 0,
        notStarted: 0,
        pendingApproval: 0,
        rejected: 0,
        statuses: {}
      };


      // Create a map of existing tasks
      const taskMap = tasks.reduce((acc, task) => {
        const key = `${task.charityId}_${task.charityLocationId}`;
        acc[key] = task;
        return acc;
      }, {});


      // Check status for each charity assignment
      quote.assignedCharities.forEach(charity => {
        const key = `${charity.charityId}_${charity.locationId}`;
        const task = taskMap[key];


        if (!task) {
          progress.notStarted++;
        } else {
          switch (task.status) {
            case 'completed':
              progress.completed++;
              break;
            case 'inProgress':
              progress.inProgress++;
              break;
            case 'pendingApproval':
              progress.pendingApproval++;
              break;
            case 'rejected':
              progress.rejected++;
              break;
            default:
              progress.notStarted++;
          }
        }


        // Track individual status
        if (task) {
          progress.statuses[key] = {
            status: task.status,
            updatedAt: task.updatedAt,
            pickupDateTime: task.pickupDateTime,
            deliveryDate: task.deliveryDate
          };
        } else {
          progress.statuses[key] = {
            status: 'notStarted',
            updatedAt: null,
            pickupDateTime: null,
            deliveryDate: null
          };
        }
      });


      return progress;
    } catch (error) {
      console.error('Error getting task progress:', error);
      throw new LogisticsError(
        `Failed to get task progress: ${error.message}`,
        'GET_TASK_PROGRESS_ERROR'
      );
    }
  }
};

