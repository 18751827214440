// src/services/charityService.js

import { db } from '../firebase/config';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  query, 
  where,
  writeBatch,
  collectionGroup
} from 'firebase/firestore';

const CHARITIES_COLLECTION = 'charities';
const LOCATIONS_SUBCOLLECTION = 'locations';

export const charityService = {
  // Create a new charity with its locations
  async create(charityData) {
    const batch = writeBatch(db);
    try {
      // Create main charity document
      const charityRef = doc(collection(db, CHARITIES_COLLECTION));
      const { locations, ...mainCharityData } = charityData;
      
      batch.set(charityRef, {
        ...mainCharityData,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      // Create location documents in subcollection
      if (locations && locations.length > 0) {
        locations.forEach(location => {
          const locationRef = doc(collection(db, CHARITIES_COLLECTION, charityRef.id, LOCATIONS_SUBCOLLECTION));
          batch.set(locationRef, {
            ...location,
            createdAt: new Date(),
            updatedAt: new Date()
          });
        });
      }

      await batch.commit();
      console.log(`Charity created successfully: ${charityRef.id}`);
      return charityRef.id;
    } catch (error) {
      console.error('Error creating charity:', error);
      throw error;
    }
  },

  // Get charity by ID including its locations
  async getById(charityId) {
    try {
      // Get main charity document
      const charityDoc = await getDoc(doc(db, CHARITIES_COLLECTION, charityId));
      if (!charityDoc.exists()) return null;

      // Get all locations for this charity
      const locationsSnapshot = await getDocs(
        collection(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION)
      );
      
      const locations = locationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        id: charityDoc.id,
        ...charityDoc.data(),
        locations
      };
    } catch (error) {
      console.error('Error getting charity:', error);
      throw error;
    }
  },

  // Get all charities with their locations
  async getAll() {
    try {
      const charitiesSnapshot = await getDocs(collection(db, CHARITIES_COLLECTION));
      const charities = await Promise.all(
        charitiesSnapshot.docs.map(async (doc) => {
          const locationsSnapshot = await getDocs(
            collection(db, CHARITIES_COLLECTION, doc.id, LOCATIONS_SUBCOLLECTION)
          );
          
          return {
            id: doc.id,
            ...doc.data(),
            locations: locationsSnapshot.docs.map(locationDoc => ({
              id: locationDoc.id,
              ...locationDoc.data()
            }))
          };
        })
      );
      return charities;
    } catch (error) {
      console.error('Error getting all charities:', error);
      throw error;
    }
  },

  // Update charity and/or its locations
  async update(charityId, updateData) {
    const batch = writeBatch(db);
    try {
      const { locations, ...mainCharityData } = updateData;
      
      // Update main charity document if there are changes
      if (Object.keys(mainCharityData).length > 0) {
        const charityRef = doc(db, CHARITIES_COLLECTION, charityId);
        batch.update(charityRef, {
          ...mainCharityData,
          updatedAt: new Date()
        });
      }

      // Update locations if provided
      if (locations) {
        for (const location of locations) {
          const { id: locationId, ...locationData } = location;
          if (locationId) {
            // Update existing location
            const locationRef = doc(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION, locationId);
            batch.update(locationRef, {
              ...locationData,
              updatedAt: new Date()
            });
          } else {
            // Create new location
            const locationRef = doc(collection(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION));
            batch.set(locationRef, {
              ...locationData,
              createdAt: new Date(),
              updatedAt: new Date()
            });
          }
        }
      }

      await batch.commit();
    } catch (error) {
      console.error('Error updating charity:', error);
      throw error;
    }
  },

  // Delete charity and all its locations
  async delete(charityId) {
    const batch = writeBatch(db);
    try {
      // Delete all locations
      const locationsSnapshot = await getDocs(
        collection(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION)
      );
      
      locationsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Delete main charity document
      batch.delete(doc(db, CHARITIES_COLLECTION, charityId));

      await batch.commit();
    } catch (error) {
      console.error('Error deleting charity:', error);
      throw error;
    }
  },

  // Location-specific operations
  async createLocation(charityId, locationData) {
    try {
      const locationRef = doc(collection(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION));
      await setDoc(locationRef, {
        ...locationData,
        createdAt: new Date(),
        updatedAt: new Date()
      });
      return locationRef.id;
    } catch (error) {
      console.error('Error creating location:', error);
      throw error;
    }
  },

  async updateLocation(charityId, locationId, updateData) {
    try {
      const locationRef = doc(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION, locationId);
      await updateDoc(locationRef, {
        ...updateData,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error updating location:', error);
      throw error;
    }
  },

  async deleteLocation(charityId, locationId) {
    try {
      await deleteDoc(doc(db, CHARITIES_COLLECTION, charityId, LOCATIONS_SUBCOLLECTION, locationId));
    } catch (error) {
      console.error('Error deleting location:', error);
      throw error;
    }
  },

  // Search operations
  async getByAcceptableGoods(itemTypes) {
    try {
      const q = query(
        collection(db, CHARITIES_COLLECTION),
        where("acceptableGoods", "array-contains-any", itemTypes)
      );
      const querySnapshot = await getDocs(q);
      
      const charities = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const locationsSnapshot = await getDocs(
            collection(db, CHARITIES_COLLECTION, doc.id, LOCATIONS_SUBCOLLECTION)
          );
          
          return {
            id: doc.id,
            ...doc.data(),
            locations: locationsSnapshot.docs.map(locationDoc => ({
              id: locationDoc.id,
              ...locationDoc.data()
            }))
          };
        })
      );
      return charities;
    } catch (error) {
      console.error('Error searching by acceptable goods:', error);
      throw error;
    }
  },

  async searchByName(name) {
    try {
      const q = query(
        collection(db, CHARITIES_COLLECTION),
        where("orgName", ">=", name),
        where("orgName", "<=", name + '\uf8ff')
      );
      const querySnapshot = await getDocs(q);
      
      const charities = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const locationsSnapshot = await getDocs(
            collection(db, CHARITIES_COLLECTION, doc.id, LOCATIONS_SUBCOLLECTION)
          );
          
          return {
            id: doc.id,
            ...doc.data(),
            locations: locationsSnapshot.docs.map(locationDoc => ({
              id: locationDoc.id,
              ...locationDoc.data()
            }))
          };
        })
      );
      return charities;
    } catch (error) {
      console.error('Error searching by name:', error);
      throw error;
    }
  }
};