// src/firebase/config.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDQh_JLlwJOUiZyDQSlqUkV2xDBYiqNS1w",
  authDomain: "donatingsimplified.firebaseapp.com",
  projectId: "donatingsimplified",
  storageBucket: "donatingsimplified.appspot.com",
  messagingSenderId: "95857886763",
  appId: "1:95857886763:web:34188e21c85ceee1e5cc6e",
  measurementId: "G-H0FHNE9GQJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, storage, auth };