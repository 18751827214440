// src/services/proposalService.js

import { 
  collection, 
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  orderBy,
  limit
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import { db, storage } from '../firebase/config';

export const proposalService = {
  /**
   * Generate a new proposal ID without creating the document
   * @returns {string} The generated proposal ID
   */
  generateProposalId() {
    const newProposalRef = doc(collection(db, 'proposals'));
    return newProposalRef.id;
  },

  /**
   * Delete all logos for a specific proposal
   * @param {string} proposalId - The proposal ID
   * @param {string} type - The type of logo (client/partner)
   * @returns {Promise<void>}
   */
  async deleteExistingLogos(proposalId, type) {
    try {
      if (!proposalId || !type) {
        throw new Error('Proposal ID and type are required for logo deletion');
      }

      // List all files in the logos directory for this proposal
      const logosRef = ref(storage, `proposals/${proposalId}/logos`);
      const filesList = await listAll(logosRef);

      // Find and delete any existing logos of the specified type
      const deletionPromises = filesList.items
        .filter(itemRef => itemRef.name.includes(`-${type}-logo`))
        .map(itemRef => deleteObject(itemRef));

      if (deletionPromises.length > 0) {
        await Promise.all(deletionPromises);
      }
    } catch (error) {
      // Handle case where directory doesn't exist yet
      if (!error.message.includes('Object not found')) {
        console.error('Error deleting existing logos:', error);
        throw error;
      }
    }
  },

  /**
   * Upload a logo file to Firebase Storage with cleanup
   * @param {File} file - The file to upload
   * @param {string} proposalId - The proposal ID
   * @param {string} type - The type of logo (client/partner)
   * @returns {Promise<string>} The download URL of the uploaded file
   */
  async uploadLogo(file, proposalId, type) {
    try {
      // Validate parameters
      if (!file || !proposalId || !type) {
        throw new Error('Missing required parameters for logo upload');
      }

      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!validTypes.includes(file.type)) {
        throw new Error('Invalid file type. Please upload an image file.');
      }

      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        throw new Error('File size exceeds 5MB limit');
      }

      // Delete any existing logos of this type
      await this.deleteExistingLogos(proposalId, type);

      // Upload new logo
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileName = `${proposalId}-${type}-logo.${fileExtension}`;
      const storageRef = ref(storage, `proposals/${proposalId}/logos/${fileName}`);
      
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      return downloadURL;
    } catch (error) {
      console.error('Error uploading logo:', error);
      throw error;
    }
  },

  /**
   * Create a new proposal
   * @param {string} proposalId - The ID for the new proposal
   * @param {Object} proposalData - The proposal data
   * @returns {Promise<void>}
   */
  async create(proposalId, proposalData) {
    try {
      // Validate required fields
      if (!proposalId || !proposalData.companyId) {
        throw new Error('Proposal ID and Company ID are required');
      }
  
      const proposalRef = doc(db, 'proposals', proposalId);
      const timestamp = serverTimestamp();
      
      await setDoc(proposalRef, {
        ...proposalData,
        status: proposalData.status || 'draft',
        createdAt: timestamp,
        updatedAt: timestamp
      });
    } catch (error) {
      console.error('Error creating proposal:', error);
      throw error;
    }
  },

  /**
   * Get proposal by ID
   * @param {string} proposalId - The ID of the proposal to fetch
   * @returns {Promise<Object|null>} The proposal data or null if not found
   */
  async getById(proposalId) {
    try {
      if (!proposalId) {
        throw new Error('Proposal ID is required');
      }

      const proposalDoc = await getDoc(doc(db, 'proposals', proposalId));
      if (!proposalDoc.exists()) {
        return null;
      }
      return {
        proposalId: proposalDoc.id,
        ...proposalDoc.data()
      };
    } catch (error) {
      console.error('Error fetching proposal:', error);
      throw error;
    }
  },

  /**
   * Update an existing proposal
   * @param {string} proposalId - The ID of the proposal to update
   * @param {Object} updateData - The data to update
   * @returns {Promise<void>}
   */
  async update(proposalId, updateData) {
    try {
      if (!proposalId) {
        throw new Error('Proposal ID is required');
      }

      const proposalRef = doc(db, 'proposals', proposalId);
      await updateDoc(proposalRef, {
        ...updateData,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating proposal:', error);
      throw error;
    }
  },

  /**
   * Delete a proposal and its associated files
   * @param {string} proposalId - The ID of the proposal to delete
   * @returns {Promise<void>}
   */
  async delete(proposalId) {
    try {
      if (!proposalId) {
        throw new Error('Proposal ID is required');
      }

      // Delete the entire proposal storage folder
      const proposalStorageRef = ref(storage, `proposals/${proposalId}`);
      try {
        const filesList = await listAll(proposalStorageRef);
        
        // Delete all files in all subdirectories
        const deletePromises = [];
        
        // Delete files in root
        filesList.items.forEach(itemRef => {
          deletePromises.push(deleteObject(itemRef));
        });
        
        // Delete files in subdirectories
        for (const prefix of filesList.prefixes) {
          const subFiles = await listAll(prefix);
          subFiles.items.forEach(itemRef => {
            deletePromises.push(deleteObject(itemRef));
          });
        }
        
        if (deletePromises.length > 0) {
          await Promise.all(deletePromises);
        }
      } catch (error) {
        // Ignore errors if no files exist
        if (!error.message.includes('Object not found')) {
          throw error;
        }
      }

      // Delete the proposal document
      await deleteDoc(doc(db, 'proposals', proposalId));
    } catch (error) {
      console.error('Error deleting proposal:', error);
      throw error;
    }
  },

  /**
   * Get latest proposal for a company
   * @param {string} companyId - The ID of the company
   * @returns {Promise<Object|null>} The most recent proposal or null if none found
   */
  async getLatestProposalForCompany(companyId) {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }

      console.log('Querying proposals for company:', companyId);
      
      // Simple query just filtering by companyId
      const q = query(
        collection(db, 'proposals'),
        where('companyId', '==', companyId)
      );

      const querySnapshot = await getDocs(q);
      console.log('Query results:', querySnapshot.size, 'documents found');
      
      if (querySnapshot.empty) {
        return null;
      }

      // Convert the docs to an array and add the ID
      const proposals = querySnapshot.docs.map(doc => ({
        proposalId: doc.id,
        ...doc.data()
      }));

      // Sort by createdAt in memory, most recent first
      proposals.sort((a, b) => {
        const dateA = a.createdAt?.toDate?.() || new Date(a.createdAt);
        const dateB = b.createdAt?.toDate?.() || new Date(b.createdAt);
        return dateB - dateA;
      });

      // Return the most recent proposal
      return proposals[0];
    } catch (error) {
      console.error('Error fetching latest proposal:', error);
      throw error;
    }
  },

  /**
   * Get all proposals for a company
   * @param {string} companyId - The ID of the company
   * @returns {Promise<Array>} Array of proposals
   */
  async getAllForCompany(companyId) {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }

      const q = query(
        collection(db, 'proposals'),
        where('companyId', '==', companyId),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        proposalId: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching company proposals:', error);
      throw error;
    }
  }
};

export default proposalService;