import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { proposalService } from '../services/proposalService';
import { 
  FaChevronLeft,
  FaChevronRight, 
  FaDollarSign,
  FaUsers,
  FaRecycle,
  FaSpinner,
  FaExclamationTriangle
} from 'react-icons/fa';
import { iconMap } from '../utils/iconMap';

// Constants for screen breakpoints
const BREAKPOINTS = {
  MOBILE: 640,
  TABLET: 768,
  DESKTOP: 1024
};

// Reusable components
const Card = ({ children, className = "", highlighted = false }) => (
  <div className={`bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300 ${
    highlighted ? 'border-2 border-purple-200' : ''
  } ${className}`}>
    {children}
  </div>
);

const MetricCard = ({ icon: Icon, value, label, bgColor, textColor }) => (
  <Card className="p-4 sm:p-6">
    <div className={`w-12 h-12 ${bgColor} rounded-lg flex items-center justify-center mb-4`}>
      <Icon className={`w-6 h-6 ${textColor}`} />
    </div>
    <div className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-2">{value}</div>
    <div className="text-xs sm:text-sm text-gray-500 uppercase tracking-wider">{label}</div>
  </Card>
);

const NavigationButton = ({ direction, onClick, disabled }) => {
  const Icon = direction === 'next' ? FaChevronRight : FaChevronLeft;
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        p-2 sm:p-3 bg-gray-800/90 text-white rounded-full
        hover:bg-gray-700 transition-colors duration-200
        disabled:opacity-50 disabled:cursor-not-allowed
        focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
      `}
      aria-label={direction === 'next' ? 'Next slide' : 'Previous slide'}
    >
      <Icon className="w-4 h-4 sm:w-5 sm:h-5" />
    </button>
  );
};

const getTagColors = (color) => {
  const colors = {
    blue: 'bg-blue-100 text-blue-800',
    pink: 'bg-pink-100 text-pink-800',
    green: 'bg-green-100 text-green-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    orange: 'bg-orange-100 text-orange-800'
  };
  return colors[color] || 'bg-gray-100 text-gray-800';
};

const DynamicPresentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [proposalData, setProposalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState([]);
  const { companyId, proposalId } = useParams();
  const navigate = useNavigate();
  const isMobile = windowWidth <= 640; // Simplified breakpoint

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch proposal data
  useEffect(() => {
    const fetchProposal = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get latest proposal for company
        const data = await proposalService.getLatestProposalForCompany(companyId);
        if (!data) {
          setError('not_found');
          return;
        }
  
        setProposalData(data);
      } catch (err) {
        console.error('Error loading presentation:', err);
        setError('unknown');
      } finally {
        setLoading(false);
      }
    };
  
    fetchProposal();
  }, [companyId]);

  const partnerLogoUrl = "https://firebasestorage.googleapis.com/v0/b/donatingsimplified.appspot.com/o/Donating%20Simplified%20White%20Wordmark.png?alt=media&token=234482ff-4a28-42ed-adce-ca04a42fb120";

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyNavigation = (e) => {
      if (e.key === 'ArrowRight' && currentSlide < slides.length - 1) {
        setCurrentSlide(prev => prev + 1);
      } else if (e.key === 'ArrowLeft' && currentSlide > 0) {
        setCurrentSlide(prev => prev - 1);
      }
    };

    window.addEventListener('keydown', handleKeyNavigation);
    return () => window.removeEventListener('keydown', handleKeyNavigation);
  }, [currentSlide, slides.length]);

  // Update slides when proposal data changes
  useEffect(() => {
    if (proposalData) {
      setSlides([
        // Cover Slide
        <div key="cover" className="relative h-full flex flex-col justify-center items-center">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900">
            <div className="absolute inset-0 opacity-10">
              <div className="w-full h-full" style={{ 
                backgroundImage: 'radial-gradient(circle at 1px 1px, white 1px, transparent 0)',
                backgroundSize: '40px 40px' 
              }}></div>
            </div>
          </div>

          <div className="relative z-10 w-full h-full flex flex-col justify-center items-center text-white p-6">
            <div className="flex flex-col sm:flex-row items-center gap-6 mb-12">
              {proposalData.cover?.clientLogo && (
                <div className="w-24 sm:w-32 h-24 sm:h-32">
                  <img 
                    src={proposalData.cover.clientLogo}
                    alt="Client logo"
                    className="w-full h-full object-contain"
                  />
                </div>
              )}
                 <>
                <div className="hidden sm:block h-20 w-px bg-purple-400/30"></div>
                <div className="w-48 sm:w-80">
                  <img 
                    src={partnerLogoUrl}
                    alt="Partner logo"
                    className="w-full h-full object-contain"
                  />
                </div>
              </>
            </div>
            
            <div className="flex flex-col items-center">
              <h1 className="text-4xl sm:text-6xl lg:text-7xl font-bold tracking-tight text-center mb-6">
                {proposalData.cover?.title}
              </h1>
              <div className="w-24 h-1 bg-purple-400 rounded-full mb-6"></div>
              <h2 className="text-2xl sm:text-4xl lg:text-5xl font-light tracking-wide text-purple-200 text-center">
                {proposalData.cover?.subtitle}
              </h2>
            </div>

            <div className="absolute bottom-8 sm:bottom-12 left-0 right-0 flex justify-center">
              <div className="text-purple-300/50 text-base sm:text-xl tracking-[0.5em] uppercase">
                {proposalData.cover?.year}
              </div>
            </div>
          </div>
        </div>,

        // Strategy Slide
        <div key="strategy" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
              {proposalData.strategy?.title}
            </h2>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {proposalData.strategy?.topCards.map((card, index) => (
                <Card key={index} className="p-6">
                  {card.icon && iconMap[card.icon] && React.createElement(iconMap[card.icon], {
                    className: "w-8 h-8 text-purple-600 mb-6"
                  })}
                  <h3 className="text-xl font-bold mb-4 text-gray-800">{card.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{card.description}</p>
                </Card>
              ))}
            </div>

            {proposalData.strategy?.bottomCard && (
              <Card className="mt-8 sm:mt-12 p-6">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
                  {proposalData.strategy.bottomCard.icon && 
                   iconMap[proposalData.strategy.bottomCard.icon] &&
                   React.createElement(iconMap[proposalData.strategy.bottomCard.icon], {
                     className: "w-10 h-10 text-purple-600 flex-shrink-0"
                   })}
                  <div>
                    <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">
                      {proposalData.strategy.bottomCard.title}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                      {proposalData.strategy.bottomCard.description}
                    </p>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>,

        // Impact Dashboard Slide
        <div key="impact" className="h-full overflow-y-auto bg-white p-6 sm:p-12">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-8 sm:mb-12">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800">
                Impact Dashboard
              </h2>
              <div className="bg-red-500/80 text-white px-4 py-1.5 sm:py-2 text-lg font-bold 
                            tracking-wider rounded-md shadow-md whitespace-nowrap">
                PENDING
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {proposalData.impact?.metrics && (
                <>
                  <MetricCard
                    icon={FaDollarSign}
                    value={proposalData.impact.metrics.donations?.value}
                    label="Value of Donations"
                    bgColor="bg-blue-50"
                    textColor="text-blue-600"
                  />
                  <MetricCard
                    icon={FaUsers}
                    value={proposalData.impact.metrics.peopleHelped?.value}
                    label="People Helped"
                    bgColor="bg-purple-50"
                    textColor="text-purple-600"
                  />
                  <MetricCard
                    icon={FaRecycle}
                    value={proposalData.impact.metrics.wasteReduced?.value}
                    label="Saved from Landfill"
                    bgColor="bg-teal-50"
                    textColor="text-teal-600"
                  />
                  <MetricCard
                    icon={FaDollarSign}
                    value={proposalData.impact.metrics.taxSavings?.value}
                    label="Tax Savings"
                    bgColor="bg-green-50"
                    textColor="text-green-600"
                  />
                </>
              )}
            </div>

            {proposalData.impact?.infoCard && (
              <Card className="mt-8 sm:mt-12 p-6 bg-gradient-to-r from-orange-50 to-red-50 border border-orange-100">
                <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">
                  {proposalData.impact.infoCard.title}
                </h3>
                <p className="text-gray-700 leading-relaxed sm:text-lg">
                  {proposalData.impact.infoCard.description}
                </p>
              </Card>
            )}
          </div>
        </div>,

        // Options Slide
        <div key="options" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
              {proposalData.options?.title}
            </h2>
            
            <div className="space-y-6 sm:space-y-8">
              {proposalData.options?.options.map((option, index) => (
                <Card key={index} highlighted={option.isRecommended} className="p-6">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                  <div className="space-y-1">
                    <h3 className="text-2xl sm:text-3xl font-bold text-gray-800">
                      {option.title}
                    </h3>
                    {option.subtitle && (
                      <p className={`text-lg sm:text-xl text-${option.accentColor || 'purple'}-700 font-medium`}>
                        {option.subtitle}
                      </p>
                    )}
                  </div>
                    {option.isRecommended && (
                      <span className="bg-purple-100 text-purple-800 px-4 py-1.5 rounded-full text-sm sm:text-base font-medium whitespace-nowrap">
                        Recommended
                      </span>
                    )}
                  </div>

                  <div className={`pl-4 sm:pl-6 border-l-4 border-${option.accentColor || 'purple'}-500`}>
                    <ul className="space-y-3">
                      {option.bulletPoints.map((point, index) => (
                        <li key={index} className="flex items-center text-gray-600">
                          <div className={`w-2 h-2 bg-${option.accentColor || 'purple'}-500 rounded-full mr-3 flex-shrink-0`}></div>
                          <span>{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card>
              ))}
            </div>

            {proposalData.options?.specialNote?.visible && proposalData.options?.specialNote?.text && (
              <div className="mt-8 bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-xl shadow-md">
                <p className="text-yellow-800 text-sm sm:text-base">
                  <span className="font-bold">Special Note:</span> {proposalData.options.specialNote.text}
                </p>
              </div>
            )}
          </div>
        </div>,

        // Charity Profiles Slide
        <div key="charities" className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-6 sm:p-12">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8 sm:mb-12 text-gray-800">
              {proposalData.charities?.title}
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
              {proposalData.charities?.profiles.map((charity, index) => (
                <Card key={index} className="p-6 relative">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 mb-4">
                    <h3 className="text-xl font-bold text-gray-800">{charity.name}</h3>
                    <span className={`${getTagColors(charity.tagColor)} px-4 py-1.5 rounded-full text-sm font-medium whitespace-nowrap`}>
                      {charity.tag}
                    </span>
                  </div>

                  <p className="text-sm sm:text-base text-gray-600 mb-6 leading-relaxed">
                    {charity.description}
                  </p>

                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-3 pt-4 border-t border-gray-100">
                    <span className="text-sm text-gray-500">Service Area: {charity.area}</span>
                    <span className="bg-gray-100 px-3 py-1.5 rounded-full text-sm font-medium">
                      {charity.pallets}
                    </span>
                  </div>

                  {charity.priority && (
                    <div className="absolute -top-2 -right-2 bg-yellow-400 text-yellow-900 
                                   px-3 py-1 rounded-lg text-sm font-bold shadow-md">
                      Priority Partner
                    </div>
                  )}
                </Card>
              ))}
            </div>
          </div>
        </div>
      ]);
    }
  }, [proposalData]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="flex items-center gap-3 text-white">
          <FaSpinner className="w-6 h-6 animate-spin" />
          <span className="text-xl">Loading presentation...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="flex flex-col items-center gap-4 text-white text-center px-4">
          <FaExclamationTriangle className="w-12 h-12 text-yellow-500" />
          <div className="text-xl">
            {error === 'unauthorized' && 'You are not authorized to view this presentation.'}
            {error === 'not_found' && 'Presentation not found.'}
            {error === 'unknown' && 'An error occurred while loading the presentation.'}
          </div>
          <button 
            onClick={() => navigate(`/client-preview/${companyId}`)}
            className="mt-4 px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
          >
            Back to Overview
          </button>
        </div>
      </div>
    );
  }

  if (!proposalData || slides.length === 0) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-white text-xl">No presentation data available</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4 sm:py-12">
      {/* Presentation Container */}
      <div className={`relative w-full max-w-7xl mx-auto ${
        isMobile ? 'h-[85vh]' : 'aspect-video'
      } bg-white shadow-2xl rounded-2xl overflow-hidden`}>
        {/* Slides */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ 
              type: "spring", 
              stiffness: 300, 
              damping: 30,
              mass: 1 
            }}
            className="absolute inset-0"
          >
            {slides[currentSlide]}
          </motion.div>
        </AnimatePresence>

        {/* Navigation Controls */}
        <div className="absolute bottom-4 sm:bottom-8 right-4 sm:right-8 flex gap-2 sm:gap-4 z-20">
          <NavigationButton
            direction="prev"
            onClick={prevSlide}
            disabled={currentSlide === 0}
          />
          <NavigationButton
            direction="next"
            onClick={nextSlide}
            disabled={currentSlide === slides.length - 1}
          />
        </div>

        {/* Slide Counter */}
        <div className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 bg-gray-800/90 text-white 
                       px-4 sm:px-6 py-1.5 sm:py-2 rounded-full text-sm sm:text-base font-medium z-20">
          {currentSlide + 1} / {slides.length}
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full max-w-7xl mx-auto mt-4 sm:mt-8 px-4">
        <div className="bg-gray-700 rounded-full h-1">
          <div 
            className="bg-purple-500 h-1 rounded-full transition-all duration-500 ease-in-out"
            style={{ width: `${((currentSlide + 1) / slides.length) * 100}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default DynamicPresentation;