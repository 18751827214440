// src/services/approvalRequestService.js
import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';

const COLLECTION_NAME = 'approvalRequests';

export const approvalRequestService = {
  async createRequest(requestData) {
    try {
      const requestRef = doc(collection(db, COLLECTION_NAME));
      await setDoc(requestRef, {
        ...requestData,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: requestData.status || 'pending'
      });
      return requestRef.id;
    } catch (error) {
      console.error('Error creating approval request:', error);
      throw error;
    }
  },

  async getRequestsByDistributionCenter(distributionCenterId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where('distributionCenterId', '==', distributionCenterId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting approval requests:', error);
      throw error;
    }
  },

  async getRequestIdByTaskId(taskId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where('taskId', '==', taskId)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        throw new Error('No approval request found for task');
      }
      return querySnapshot.docs[0].id;
    } catch (error) {
      console.error('Error getting approval request by task ID:', error);
      throw error;
    }
  },

  async updateRequest(requestId, updateData) {
    try {
      await updateDoc(doc(db, COLLECTION_NAME, requestId), {
        ...updateData,
        updatedAt: new Date()
      });
    } catch (error) {
      console.error('Error updating approval request:', error);
      throw error;
    }
  }
};