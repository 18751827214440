import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import Plot from 'react-plotly.js';
import { 
  FaFireAlt, FaBalanceScale, FaCog, FaChartLine, FaCalculator, 
  FaDollarSign, FaPercentage, FaInfoCircle, FaChartArea, 
  FaHandHoldingUsd, FaMoneyBillWave, FaPercent
} from 'react-icons/fa';
import { BsArrowUpRight, BsArrowDownRight } from 'react-icons/bs';
import '../styles/DonationLiquidationPlot.css';

const TAX_RATE = 0.21;
const DEFAULT_FMV_MAX = 100;
const DEFAULT_CB_MAX = 75;
const DEFAULT_LIQ_PERCENT_MAX = 0.15;

const donationBenefit = (FMV, CB) => {
  const unrealizedGain = Math.max(FMV - CB, 0);
  const taxImpact = Math.min(CB + (0.5 * unrealizedGain), 2 * CB);
  return taxImpact * TAX_RATE;
};

const destructionBenefit = (CB) => CB * TAX_RATE;

const liquidationBenefit = (FMV, CB, liquidationPercentage) => {
  const liquidationValue = FMV * liquidationPercentage;
  const adjustedCB = Math.max(CB - liquidationValue, 0);
  const taxSavings = adjustedCB * TAX_RATE;
  return liquidationValue + taxSavings;
};

const DonationVsDestructionCalculator = () => {
  const [inputs, setInputs] = useState({ fmv: 100, cb: 50 });
  const [results, setResults] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: parseFloat(value) }));
  };

  const calculateBenefits = () => {
    const { fmv, cb } = inputs;
    const donationBenefitValue = donationBenefit(fmv, cb);
    const destructionBenefitValue = destructionBenefit(cb);
    const difference = donationBenefitValue - destructionBenefitValue;
    const percentageAdvantage = destructionBenefitValue > 0 ? (difference / destructionBenefitValue) * 100 : 0;

    setResults({
      donationBenefit: donationBenefitValue,
      destructionBenefit: destructionBenefitValue,
      difference,
      percentageAdvantage
    });
  };

  return (
    <div className="calculator">
      <div className="calculator-inputs">
        <label>
          <FaDollarSign /> Fair Market Value ($)
          <input type="number" name="fmv" value={inputs.fmv} onChange={handleInputChange} min="1" />
        </label>
        <label>
          <FaDollarSign /> Cost Basis ($)
          <input type="number" name="cb" value={inputs.cb} onChange={handleInputChange} min="0" max={inputs.fmv} />
        </label>
        <button onClick={calculateBenefits}>Calculate</button>
      </div>
      {results && (
        <div className="calculator-results">
          <div className="result-item">
            <span>Donation Benefit:</span>
            <span>${results.donationBenefit.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Destruction Benefit:</span>
            <span>${results.destructionBenefit.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Difference:</span>
            <span>${results.difference.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Advantage:</span>
            <span>
              {results.difference > 0 ? (
                <><BsArrowUpRight color="green" /> Donation by </>
              ) : (
                <><BsArrowDownRight color="red" /> Destruction by </>
              )}
              {Math.abs(results.percentageAdvantage).toFixed(2)}%
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const DonationVsDestructionHeatMap = () => {
  const [fmvRange, setFmvRange] = useState({ min: 0, max: DEFAULT_FMV_MAX });
  const [cbRange, setCbRange] = useState({ min: 0, max: DEFAULT_CB_MAX });
  const [containerSize, setContainerSize] = useState({ width: 800, height: 1000 });
  const containerRef = useRef(null);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('fmv')) {
      setFmvRange(prev => ({ ...prev, [name.replace('fmv', '').toLowerCase()]: parseInt(value) }));
    } else {
      setCbRange(prev => ({ ...prev, [name.replace('cb', '').toLowerCase()]: parseInt(value) }));
    }
  };

  const heatmapData = useMemo(() => {
    const data = [];
    const fmvValues = [];
    const cbValues = [];

    for (let FMV = fmvRange.min; FMV <= fmvRange.max; FMV++) {
      fmvValues.push(FMV);
      const row = [];
      for (let CB = cbRange.min; CB <= cbRange.max; CB++) {
        if (FMV === fmvRange.min) cbValues.push(CB);
        const donationBenefitValue = donationBenefit(FMV, CB);
        const destructionBenefitValue = destructionBenefit(CB);
        const percentageAdvantage = destructionBenefitValue > 0
          ? ((donationBenefitValue - destructionBenefitValue) / destructionBenefitValue) * 100
          : 0;
        row.push(percentageAdvantage);
      }
      data.push(row);
    }
    return { data, fmvValues, cbValues };
  }, [fmvRange, cbRange]);

  const plotData = [{
    z: heatmapData.data,
    x: heatmapData.fmvValues,
    y: heatmapData.cbValues,
    type: 'heatmap',
    colorscale: [
      [0, 'rgb(230, 240, 255)'],  // Light blue for minimum advantage
      [1, 'rgb(0, 0, 128)']       // Dark blue for maximum advantage
    ],
    zmin: 0,  // Ensure we start from 0 (equal benefit)
    colorbar: {
      title: 'Donation Advantage (%)',
      titleside: 'right',
      thickness: 20,
      len: 0.75,
      y: 0.5
    },
    hoverinfo: 'text',
    text: heatmapData.data.map((row, i) => 
      row.map((val, j) => 
        `FMV: $${heatmapData.fmvValues[i]}<br>` +
        `Cost Basis: $${heatmapData.cbValues[j]}<br>` +
        `Donation Advantage: ${val.toFixed(2)}%`
      )
    )
  }];

  const layout = {
    title: 'Donation vs Destruction Advantage',
    xaxis: { 
      title: 'Fair Market Value ($)', 
      autorange: true,
      gridcolor: 'rgb(255, 255, 255)',
    },
    yaxis: { 
      title: 'Cost Basis ($)', 
      autorange: true,
      gridcolor: 'rgb(255, 255, 255)',
    },
    autosize: true,
    margin: { l: 65, r: 50, b: 65, t: 90 },
  };

  return (
    <div className="heatmap-subsection" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <h3><FaChartArea /> Donation vs Destruction Heat Map</h3>
      <div className="customization-card" style={{ marginBottom: '20px' }}>
        <h4><FaInfoCircle /> Customize Heat Map</h4>
        <div className="heatmap-controls" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="heatmap-control">
            <h5><FaDollarSign /> FMV Range</h5>
            <div className="input-group">
              <label>
                Min:
                <input type="number" name="fmvMin" value={fmvRange.min} onChange={handleRangeChange} min="0" max={fmvRange.max} />
              </label>
              <label>
                Max:
                <input type="number" name="fmvMax" value={fmvRange.max} onChange={handleRangeChange} min={fmvRange.min} />
              </label>
            </div>
          </div>
          <div className="heatmap-control">
            <h5><FaDollarSign /> CB Range</h5>
            <div className="input-group">
              <label>
                Min:
                <input type="number" name="cbMin" value={cbRange.min} onChange={handleRangeChange} min="0" max={cbRange.max} />
              </label>
              <label>
                Max:
                <input type="number" name="cbMax" value={cbRange.max} onChange={handleRangeChange} min={cbRange.min} />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-container" ref={containerRef} style={{ flexGrow: 1, minHeight: '500px' }}>
        <Plot
          data={plotData}
          layout={layout}
          config={{ responsive: true }}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
        />
      </div>
    </div>
  );
};

const CustomizationControls = ({ customRanges, setCustomRanges, showCrossSection, setShowCrossSection, crossSectionLiqPercent, setCrossSectionLiqPercent }) => {
  const handleRangeChange = useCallback((e) => {
    const { name, value } = e.target;
    setCustomRanges(prev => ({
      ...prev,
      [name]: name.includes('liqPercent') ? parseFloat(value) / 100 : parseFloat(value)
    }));
  }, [setCustomRanges]);

  return (
    <div className="customization-controls">
      <div className="control-grid">
        <div className="control-group">
          <h4><FaDollarSign /> Fair Market Value Range ($)</h4>
          <div className="input-group">
            <label>
              Min:
              <input type="number" name="fmvMin" value={customRanges.fmvMin} onChange={handleRangeChange} min="1" max={customRanges.fmvMax} />
            </label>
            <label>
              Max:
              <input type="number" name="fmvMax" value={customRanges.fmvMax} onChange={handleRangeChange} min={customRanges.fmvMin} />
            </label>
          </div>
        </div>
        <div className="control-group">
          <h4><FaDollarSign /> Cost Basis Range ($)</h4>
          <div className="input-group">
            <label>
              Min:
              <input type="number" name="cbMin" value={customRanges.cbMin} onChange={handleRangeChange} min="0" max={customRanges.cbMax} />
            </label>
            <label>
              Max:
              <input type="number" name="cbMax" value={customRanges.cbMax} onChange={handleRangeChange} min={customRanges.cbMin} />
            </label>
          </div>
        </div>
        <div className="control-group">
          <h4><FaPercent /> Liquidation Percentage Range</h4>
          <div className="input-group">
            <label>
              Min (%):
              <input type="number" name="liqPercentMin" value={customRanges.liqPercentMin * 100} onChange={handleRangeChange} min="1" max={customRanges.liqPercentMax * 100} step="0.1" />
            </label>
            <label>
              Max (%):
              <input type="number" name="liqPercentMax" value={customRanges.liqPercentMax * 100} onChange={handleRangeChange} min={customRanges.liqPercentMin * 100} max="100" step="0.1" />
            </label>
          </div>
        </div>
      </div>
      <div className="cross-section-controls">
        <h4><FaChartArea /> Cross Section View</h4>
        <div className="toggle-group">
          <label className="toggle">
            <input
              type="checkbox"
              checked={showCrossSection}
              onChange={(e) => setShowCrossSection(e.target.checked)}
            />
            <span className="toggle-slider"></span>
            Show Cross Section
          </label>
          {showCrossSection && (
            <label>
              Liquidation %:
              <input
                type="number"
                value={crossSectionLiqPercent * 100}
                onChange={(e) => setCrossSectionLiqPercent(parseFloat(e.target.value) / 100)}
                min={customRanges.liqPercentMin * 100}
                max={customRanges.liqPercentMax * 100}
                step="0.1"
              />
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

const DonationLiquidationChart = ({ plotData, layout, config }) => (
  <div className="chart-container">
    <Plot
      data={plotData}
      layout={layout}
      config={config}
      style={{width: "100%", height: "100%"}}
    />
  </div>
);

const Statistics = ({ statistics }) => (
  <div className="statistics">
    <div className="stat-item">
      <span className="stat-label"><FaHandHoldingUsd /> Scenarios favoring donation:</span>
      <span className="stat-value">{statistics.donationBetterCount.toLocaleString()}</span>
    </div>
    <div className="stat-item">
      <span className="stat-label"><FaMoneyBillWave /> Scenarios favoring liquidation:</span>
      <span className="stat-value">{statistics.liquidationBetterCount.toLocaleString()}</span>
    </div>
    <div className="stat-item">
      <span className="stat-label"><FaPercentage /> Maximum donation advantage:</span>
      <span className="stat-value">{statistics.maxDonationAdvantage.toFixed(2)}%</span>
    </div>
    <div className="stat-item">
      <span className="stat-label"><FaChartLine /> Average donation advantage:</span>
      <span className="stat-value">{statistics.avgDonationAdvantage.toFixed(2)}%</span>
    </div>
  </div>
);

const Calculator = ({ calculatorInputs, setCalculatorInputs, calculatorResults, calculateBenefits }) => {
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setCalculatorInputs(prev => ({
      ...prev,
      [name]: name === 'liqPercent' ? parseFloat(value) / 100 : parseFloat(value)
    }));
  }, [setCalculatorInputs]);

  return (
    <div className="calculator">
      <div className="calculator-inputs">
        <label>
          <FaDollarSign /> Fair Market Value ($):
          <input type="number" name="fmv" value={calculatorInputs.fmv} onChange={handleInputChange} min="1" />
        </label>
        <label>
          <FaDollarSign /> Cost Basis ($):
          <input type="number" name="cb" value={calculatorInputs.cb} onChange={handleInputChange} min="0" max={calculatorInputs.fmv} />
        </label>
        <label>
          <FaPercentage /> Liquidation Percentage:
          <input type="number" name="liqPercent" value={calculatorInputs.liqPercent * 100} onChange={handleInputChange} min="1" max="100" step="0.1" />
        </label>
        <button onClick={calculateBenefits}>Calculate</button>
      </div>
      {calculatorResults && (
        <div className="calculator-results">
          <div className="result-item">
            <span>Donation Benefit:</span>
            <span>${calculatorResults.donationBenefit.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Liquidation Benefit:</span>
            <span>${calculatorResults.liquidationBenefit.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Difference:</span>
            <span>${calculatorResults.difference.toFixed(2)}</span>
          </div>
          <div className="result-item">
            <span>Advantage:</span>
            <span>
              {calculatorResults.difference > 0 ? (
                <><BsArrowUpRight color="green" /> Donation by </>
              ) : (
                <><BsArrowDownRight color="red" /> Liquidation by </>
              )}
              {Math.abs(calculatorResults.percentageAdvantage).toFixed(2)}%
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const DonationLiquidationPlot = () => {
  const [customRanges, setCustomRanges] = useState({
    fmvMin: 1,
    fmvMax: DEFAULT_FMV_MAX,
    cbMin: 0,
    cbMax: DEFAULT_CB_MAX,
    liqPercentMin: 0.01,
    liqPercentMax: DEFAULT_LIQ_PERCENT_MAX,
  });

  const [showCrossSection, setShowCrossSection] = useState(false);
  const [crossSectionLiqPercent, setCrossSectionLiqPercent] = useState(0.05);

  const [calculatorInputs, setCalculatorInputs] = useState({
    fmv: 100,
    cb: 50,
    liqPercent: 0.10
  });

  const [calculatorResults, setCalculatorResults] = useState(null);

  const calculateBenefits = useCallback(() => {
    const { fmv, cb, liqPercent } = calculatorInputs;
    const donationBenefitValue = donationBenefit(fmv, cb);
    const liquidationBenefitValue = liquidationBenefit(fmv, cb, liqPercent);
    const difference = donationBenefitValue - liquidationBenefitValue;
    const percentageAdvantage = liquidationBenefitValue > 0 ? (difference / liquidationBenefitValue) * 100 : 0;

    setCalculatorResults({
      donationBenefit: donationBenefitValue,
      liquidationBenefit: liquidationBenefitValue,
      difference,
      percentageAdvantage
    });
  }, [calculatorInputs]);

  const { plotData, layout, config, statistics } = useMemo(() => {
    const donation = [];
    const liquidation = [];
    let donationBetterCount = 0;
    let maxDonationAdvantage = 0;
    let sumDonationAdvantage = 0;

    const { fmvMin, fmvMax, cbMin, cbMax, liqPercentMin, liqPercentMax } = customRanges;

    for (let FMV = fmvMin; FMV <= fmvMax; FMV += 1) {
      for (let CB = Math.max(cbMin, 0); CB <= Math.min(FMV, cbMax); CB += 1) {
        for (let liqPercent = liqPercentMin; liqPercent <= liqPercentMax; liqPercent += 0.01) {
          if (showCrossSection && liqPercent !== crossSectionLiqPercent) continue;
    
          const donationVal = donationBenefit(FMV, CB);
          const liquidationVal = liquidationBenefit(FMV, CB, liqPercent);
          const diff = donationVal - liquidationVal;
          const percentageAdvantage = liquidationVal > 0 ? (diff / liquidationVal) * 100 : 0;
    
          const dataPoint = {
            x: FMV,
            y: CB,
            z: liqPercent * 100, // Convert to percentage
            value: diff,
            percentage: percentageAdvantage
          };
    
          if (diff > 0) {
            donation.push(dataPoint);
            donationBetterCount++;
            maxDonationAdvantage = Math.max(maxDonationAdvantage, percentageAdvantage);
            sumDonationAdvantage += percentageAdvantage;
          } else {
            liquidation.push(dataPoint);
          }
        }
      }
    }
    
    const plotData = [
      {
        type: 'scatter3d',
        mode: 'markers',
        x: liquidation.map(d => d.x),
        y: liquidation.map(d => d.y),
        z: liquidation.map(d => d.z),
        marker: {
          size: 2,
          color: 'rgba(255, 0, 0, 0.3)',
          opacity: 0.1,
        },
        hoverinfo: 'none',
        name: 'Liquidation Better',
        showlegend: true,
        legendgroup: 'group1',
        line: {
          color: 'rgb(255, 0, 0)',
          width: 2
        },
      },
      {
        type: 'scatter3d',
        mode: 'markers',
        x: donation.map(d => d.x),
        y: donation.map(d => d.y),
        z: donation.map(d => d.z),
        marker: {
          size: 4,
          color: donation.map(d => d.percentage),
          colorscale: [
            [0, 'rgb(144,238,144)'],
            [1, 'rgb(0,0,128)']
          ],
          colorbar: {
            title: 'Donation Advantage (%)',
            ticksuffix: '%',
            len: 0.8,
            thickness: 20,
            x: 1.1,
          },
          cmin: 0,
          cmax: 100,
          opacity: 0.8,
        },
        hoverinfo: 'text',
        text: donation.map(d => 
          `FMV: $${d.x}<br>Cost Basis: $${d.y}<br>Liquidation %: ${d.z.toFixed(2)}%<br>Donation Advantage: ${d.percentage.toFixed(2)}%`
        ),
        name: 'Donation Better',
        showlegend: true,
        legendgroup: 'group2',
      },
    ];
    
    const layout = {
      title: {
        text: showCrossSection 
          ? `Donation vs Liquidation (${(crossSectionLiqPercent * 100).toFixed(2)}% Liquidation)`
          : 'Scenarios Where Donation Outperforms Liquidation',
        font: { size: 24, color: '#333' }
      },
      scene: {
        xaxis: { 
          title: 'Fair Market Value ($)', 
          range: [customRanges.fmvMin, customRanges.fmvMax],
          backgroundcolor: 'rgb(230, 230,230)',
          gridcolor: 'rgb(255, 255, 255)',
          showbackground: true,
          zerolinecolor: 'rgb(255, 255, 255)',
        },
        yaxis: { 
          title: 'Cost Basis ($)', 
          range: [customRanges.cbMin, customRanges.cbMax],
          backgroundcolor: 'rgb(230, 230,230)',
          gridcolor: 'rgb(255, 255, 255)',
          showbackground: true,
          zerolinecolor: 'rgb(255, 255, 255)',
        },
        zaxis: { 
          title: 'Liquidation %', 
          range: [customRanges.liqPercentMin * 100, customRanges.liqPercentMax * 100],
          backgroundcolor: 'rgb(230, 230,230)',
          gridcolor: 'rgb(255, 255, 255)',
          showbackground: true,
          zerolinecolor: 'rgb(255, 255, 255)',
          ticksuffix: '%', // Add percentage symbol to tick labels
        },
        camera: showCrossSection 
          ? { eye: { x: 0, y: -2, z: 0.1 } }
          : { eye: { x: 1.5, y: 1.5, z: 1.2 } },
        aspectratio: { x: 1, y: 1, z: 0.7 },
      },
      margin: { l: 0, r: 0, b: 0, t: 60 },
      paper_bgcolor: 'rgb(250,250,250)',
      plot_bgcolor: 'rgb(250,250,250)',
      autosize: true, 
    };
    
    const config = {
      responsive: true,
      displayModeBar: false,
    };
    
    const statistics = {
      donationBetterCount,
      liquidationBetterCount: liquidation.length,
      maxDonationAdvantage,
      avgDonationAdvantage: sumDonationAdvantage / donationBetterCount || 0
    };
    
    return { plotData, layout, config, statistics };
  }, [customRanges, showCrossSection, crossSectionLiqPercent]);

  return (
    <div className="plot-wrapper">
      <section className="main-section donation-destruction-section">
        <h2><FaFireAlt /> Donation vs. Destruction</h2>
        <div className="section-content">
          <DonationVsDestructionCalculator />
          <DonationVsDestructionHeatMap />
        </div>
      </section>
      <section className="main-section donation-liquidation-section">
        <h2><FaBalanceScale /> Donation vs. Liquidation</h2>
        <div className="section-content">
          <div className="subsection customization-subsection">
            <h3><FaCog /> Customize Graph</h3>
            <CustomizationControls
              customRanges={customRanges}
              setCustomRanges={setCustomRanges}
              showCrossSection={showCrossSection}
              setShowCrossSection={setShowCrossSection}
              crossSectionLiqPercent={crossSectionLiqPercent}
              setCrossSectionLiqPercent={setCrossSectionLiqPercent}
            />
          </div>

          <div className="subsection chart-subsection">
            <DonationLiquidationChart
              plotData={plotData}
              layout={layout}
              config={config}
            />
          </div>

          <div className="subsection statistics-subsection">
            <h3><FaChartLine /> Statistics</h3>
            <Statistics statistics={statistics} />
          </div>

          <div className="subsection calculator-subsection">
            <h3><FaCalculator /> Benefit Calculator</h3>
            <Calculator
              calculatorInputs={calculatorInputs}
              setCalculatorInputs={setCalculatorInputs}
              calculatorResults={calculatorResults}
              calculateBenefits={calculateBenefits}
            />
          </div>
        </div>
      </section>
    </div>
  );
};


export default DonationLiquidationPlot;