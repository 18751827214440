// src/services/purchaseService.js

import { db, storage } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { companyService } from './companyService';

const COLLECTION_NAME = 'purchaseOrders';

export const purchaseService = {
  async createPurchaseOrder(poData) {
    try {
      console.log('Creating purchase order with data:', poData);
  
      const newPORef = doc(collection(db, COLLECTION_NAME));
      const purchaseOrderWithId = {
        ...poData,
        id: newPORef.id,
        status: 'pending',
        createdAt: new Date().toISOString()
      };
  
      console.log('Generated purchase order:', purchaseOrderWithId);
  
      let pdfUrl = null;
      try {
        const pdfBlob = await this.generatePDF(purchaseOrderWithId);
        console.log('PDF generated successfully');
        pdfUrl = await this.uploadPDF(pdfBlob, newPORef.id);
        console.log('PDF uploaded successfully, URL:', pdfUrl);
      } catch (pdfError) {
        console.error('Failed to generate or upload PDF:', pdfError);
        // Continue without PDF
      }
  
      if (pdfUrl) {
        purchaseOrderWithId.pdfUrl = pdfUrl;
      }
  
      await setDoc(newPORef, purchaseOrderWithId);
      console.log('Purchase order saved to Firestore');
  
      return purchaseOrderWithId;
    } catch (error) {
      console.error('Error creating purchase order:', error);
      throw new Error(`Failed to create purchase order: ${error.message}`);
    }
  },

  async getById(purchaseOrderId) {
    try {
      const poDoc = await getDoc(doc(db, COLLECTION_NAME, purchaseOrderId));
      return poDoc.exists() ? { id: poDoc.id, ...poDoc.data() } : null;
    } catch (error) {
      console.error('Error getting purchase order by ID:', error);
      throw error;
    }
  },

  async update(purchaseOrderId, updateData) {
    try {
      const purchaseRef = doc(db, COLLECTION_NAME, purchaseOrderId);
      await updateDoc(purchaseRef, {
        ...updateData,
        updatedAt: new Date().toISOString()
      });
      
      // Fetch and return the updated purchase order
      const updatedPO = await this.getById(purchaseOrderId);
      return updatedPO;
    } catch (error) {
      console.error('Error updating purchase order:', error);
      throw error;
    }
  },

  async fetchAllPurchaseOrders(distributionCenterId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", distributionCenterId)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error fetching all purchase orders:', error);
      throw error;
    }
  },

  async approvePurchaseOrder(purchaseOrderId, approvalData) {
    try {
      const po = await this.getById(purchaseOrderId);
      if (!po) {
        throw new Error('Purchase order not found');
      }

      const updatedPdfBlob = await this.generatePDF(po, approvalData.signature);
      const pdfUrl = await this.uploadPDF(updatedPdfBlob, purchaseOrderId);

      await this.update(purchaseOrderId, {
        status: 'approved',
        signature: approvalData.signature,
        approverId: approvalData.approverId,
        approvalTimestamp: approvalData.approvalTimestamp,
        pdfUrl: pdfUrl
      });

      return { ...po, status: 'approved', ...approvalData, pdfUrl };
    } catch (error) {
      console.error('Error approving purchase order:', error);
      throw error;
    }
  },

  async generatePDF(purchaseOrder, signature = null) {
    const pdf = new jsPDF();
    
    try {
      const company = await companyService.getById(purchaseOrder.companyId);
      const logoUrl = company.logo;
  
      pdf.setFont("helvetica");
  
      if (logoUrl) {
        const logoWidth = 40;
        const logoHeight = 20;
        const logoImage = await this.getImageFromUrl(logoUrl);
        if (logoImage) {
          pdf.addImage(logoImage, 'PNG', 10, 10, logoWidth, logoHeight);
        } else {
          console.warn('Failed to load company logo, skipping logo in PDF');
        }
      }

      pdf.setFontSize(22);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Purchase Order', pdf.internal.pageSize.width / 2, 20, { align: 'center' });

      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(`PO Number: ${purchaseOrder.id}`, 10, 40);
      pdf.text(`Date: ${new Date(purchaseOrder.date).toLocaleDateString()}`, 10, 45);

      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('From:', 10, 55);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(company.name, 10, 60);
      pdf.text(purchaseOrder.distributionCenterName, 10, 65);

      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Created By:', pdf.internal.pageSize.width - 60, 55);
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(purchaseOrder.adminUserName, pdf.internal.pageSize.width - 60, 60);

      pdf.autoTable({
        startY: 75,
        head: [['Description', 'Quantity', 'Fair Market Value', 'Shipping', 'Total']],
        body: [
          [
            purchaseOrder.description,
            purchaseOrder.quantity.toString(),
            `$${purchaseOrder.fairMarketValue.toFixed(2)}`,
            `$${purchaseOrder.shippingQuotePrice.toFixed(2)}`,
            `$${purchaseOrder.totalPrice}`
          ]
        ],
        theme: 'striped',
        headStyles: { fillColor: [41, 128, 185], textColor: 255 },
        alternateRowStyles: { fillColor: [236, 240, 241] },
      });

      let yPos = pdf.autoTable.previous.finalY + 10;
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(`Pallet Count: ${purchaseOrder.palletCount}`, 10, yPos);
      yPos += 5;
      pdf.text(`Original Donation Creator: ${purchaseOrder.originalDonationCreatorName}`, 10, yPos);
      yPos += 10;

      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Terms:', 10, yPos);
      yPos += 5;
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(purchaseOrder.terms, 10, yPos, { maxWidth: 180 });
      
      yPos += pdf.getTextDimensions(purchaseOrder.terms, { maxWidth: 180 }).h + 10;
      
      pdf.setFontSize(12);
      pdf.setTextColor(44, 62, 80);
      pdf.text('Notes:', 10, yPos);
      yPos += 5;
      pdf.setFontSize(10);
      pdf.setTextColor(52, 73, 94);
      pdf.text(purchaseOrder.notes, 10, yPos, { maxWidth: 180 });

      if (signature) {
        const signatureHeight = 30;
        const pageHeight = pdf.internal.pageSize.height;
        pdf.addImage(signature, 'PNG', 10, pageHeight - 40, 50, signatureHeight);
        pdf.setFontSize(10);
        pdf.setTextColor(52, 73, 94);
        pdf.text('Approved Signature', 10, pageHeight - 45);
        
        if (purchaseOrder.approverId && purchaseOrder.approvalTimestamp) {
          pdf.text(`Approved By: ${purchaseOrder.approverId}`, 10, pageHeight - 55);
          pdf.text(`Approval Date: ${new Date(purchaseOrder.approvalTimestamp).toLocaleString()}`, 10, pageHeight - 50);
        }
      }

      pdf.setFontSize(8);
      pdf.setTextColor(127, 140, 141);
      pdf.text('This is a computer-generated document. No signature is required.', 10, pdf.internal.pageSize.height - 10);

      return pdf.output('blob');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error(`Failed to generate PDF: ${error.message}`);
  }
},

  async uploadPDF(pdfBlob, purchaseOrderId) {
    try {
      const storageReference = ref(storage, `${COLLECTION_NAME}/${purchaseOrderId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading PDF:', error);
      throw error;
    }
  },

  async getImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image from URL: ${url}`);
        resolve(null); // Resolve with null instead of rejecting
      };
      img.src = url;
    });
  },

  async getApprovedPurchaseOrders() {
    const q = query(collection(db, COLLECTION_NAME), where("status", "==", "approved"));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async updatePurchaseOrderItemStatus(purchaseId, itemId, status, completionDate) {
    try {
      const purchaseRef = doc(db, COLLECTION_NAME, purchaseId);
      const purchaseDoc = await getDoc(purchaseRef);
      
      if (!purchaseDoc.exists()) {
        throw new Error('Purchase order not found');
      }
    
      const purchaseData = purchaseDoc.data();
      const updatedItems = purchaseData.items.map(item => 
        item.id === itemId ? { ...item, status, completionDate } : item
      );
    
      await updateDoc(purchaseRef, { 
        items: updatedItems,
        updatedAt: new Date().toISOString()
      });
    
      return { id: purchaseId, ...purchaseData, items: updatedItems };
    } catch (error) {
      console.error('Error updating purchase order item status:', error);
      throw error;
    }
  },
};