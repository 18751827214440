// src/components/ManageLogistics.js


import React, { useReducer, useCallback, useMemo, useEffect, useState } from 'react';
import {
  FaSearch,
  FaCalendarAlt,
  FaTruck,
  FaCheckCircle,
  FaExclamationTriangle,
  FaWarehouse,
  FaFilter,
  FaDownload,
  FaTimesCircle,
  FaCircle,
  FaBuilding
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { logisticsService } from '../services/logisticsService';
import { approvalRequestService } from '../services/approvalRequestService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { quoteService } from '../services/quoteService';
import { messageService } from '../services/messageService';
import { charityService } from '../services/charityService';
import { distributionCenterService } from '../services/distributionCenterService';
import '../styles/ManageLogistics.css';


const initialState = {
  quotes: [],
  logisticsTasks: [],
  user: null,
  userRole: null,
  loading: true,
  error: null,
  filters: {
    status: 'all',
    distributionCenter: 'all',
    company: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  }
};


function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_FILTER':
      return { ...state, filters: { ...state.filters, [action.payload.name]: action.payload.value } };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters };
    default:
      return state;
  }
}


const statusOptions = [
  { value: 'notScheduled', icon: FaCircle, color: '#6c757d', label: 'Not Scheduled' },
  { value: 'pendingApproval', icon: FaExclamationTriangle, color: '#FFA500', label: 'Pending Approval' },
  { value: 'scheduled', icon: FaCalendarAlt, color: '#3498DB', label: 'Scheduled' },
  { value: 'inProgress', icon: FaTruck, color: '#28A745', label: 'In Progress' },
  { value: 'completed', icon: FaCheckCircle, color: '#28A745', label: 'Completed' },
  { value: 'rejected', icon: FaTimesCircle, color: '#E74C3C', label: 'Rejected' },
];


const ManageLogistics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);


  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');


      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');


      const userRole = await userService.getUserRole(currentUser.id);
      if (userRole !== 'SystemAdmin') {
        throw new Error('Access denied. Only System Admins can manage logistics.');
      }


      // Fetch approved quotes and tasks
      const [approvedQuotes, tasks] = await Promise.all([
        quoteService.getApprovedQuotes(),
        logisticsService.getTasks()
      ]);


      // Validate quotes and ensure they have the required structure
      const validQuotes = approvedQuotes.filter(quote => {
        try {
          // Validate required quote fields
          if (!quote.id || !quote.donationId || !quote.distributionCenterId ||
              !quote.companyName || !quote.distributionCenterName) {
            console.warn(`Quote ${quote.id} is missing required fields`);
            return false;
          }


          // Validate assignedCharities
          if (!quote.assignedCharities?.length) {
            console.warn(`Quote ${quote.id} has no assigned charities`);
            return false;
          }


          // Validate charity assignments
          const validAssignments = quote.assignedCharities.every(charity =>
            charity.charityId &&
            charity.charityName &&
            charity.locationId &&
            charity.locationName &&
            typeof charity.palletCount === 'number'
          );


          if (!validAssignments) {
            console.warn(`Quote ${quote.id} has invalid charity assignments`);
            return false;
          }


          return true;
        } catch (error) {
          console.error(`Error processing quote ${quote.id}:`, error);
          return false;
        }
      });


      if (validQuotes.length < approvedQuotes.length) {
        toast.warn(`${approvedQuotes.length - validQuotes.length} quote(s) were skipped due to invalid data.`);
      }


      // Get unique distribution centers and companies from valid quotes
      const uniqueDistributionCenters = [...new Set(validQuotes.map(q => q.distributionCenterId))];
      const uniqueCompanies = [...new Set(validQuotes.map(q => q.companyId))];


      dispatch({
        type: 'SET_DATA',
        payload: {
          user: userData,
          userRole,
          quotes: validQuotes,
          logisticsTasks: tasks,
          distributionCenters: uniqueDistributionCenters,
          companies: uniqueCompanies
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to load logistics data. Please refresh the page.');
    }
  }, []);


  const handleSchedule = useCallback(async (quote, charity, pickupDateTime, deliveryDate, isSpecialAdmin = false) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
 
      const currentUser = authService.getUserSession();
      if (!currentUser?.id) {
        throw new Error('No authenticated user found');
      }
 
      // Check if there's an existing task for this charity/location
      const existingTask = state.logisticsTasks.find(t =>
        t.quoteId === quote.id &&
        t.charityId === charity.charityId &&
        t.charityLocationId === charity.locationId
      );
 
      // Fetch complete charity data including locations
      const [charityData, distributionCenter] = await Promise.all([
        charityService.getById(charity.charityId),
        distributionCenterService.getById(quote.distributionCenterId)
      ]);
     
      if (!charityData) {
        throw new Error(`Charity not found with ID: ${charity.charityId}`);
      }
 
      if (!distributionCenter) {
        throw new Error(`Distribution center not found with ID: ${quote.distributionCenterId}`);
      }
 
      // Find the specific location
      const charityLocation = charityData.locations.find(loc => loc.id === charity.locationId);
      if (!charityLocation) {
        throw new Error(`Location not found with ID: ${charity.locationId} for charity: ${charity.charityId}`);
      }
 
      // Format address for shipping
      const formatAddress = (address) => {
        const line2 = address.line2 ? ` ${address.line2}` : '';
        return `${address.line1}${line2}, ${address.city}, ${address.stateProvince} ${address.zipCode}, ${address.country}`;
      };
 
      // Get primary contact information for the location
      const getPrimaryContact = (charityData, locationId) => {
        const location = charityData.locations.find(loc => loc.id === locationId);
        if (!location?.primaryContact) {
          return charityData.primaryContact || {
            name: '',
            phone: '',
            email: '',
            title: ''
          };
        }
        return location.primaryContact;
      };
 
      // Calculate pallet group based on charity's index
      const charityIndex = quote.assignedCharities.findIndex(
        ac => ac.charityId === charity.charityId && ac.locationId === charity.locationId
      );
      const palletGroup = charityIndex + 1;
 
      // Find corresponding shipping quote
      const shippingQuote = quote.shippingQuotes?.find(sq => sq.palletGroup === palletGroup);
 
      // Current timestamp for consistency
      const currentTimestamp = new Date().toISOString();
 
      // Prepare task data with approval information if auto-approving
      const taskData = {
        quoteId: quote.id,
        donationId: quote.donationId,
        itemId: quote.itemId,
        distributionCenterId: quote.distributionCenterId,
        distributionCenterName: distributionCenter.name,
        distributionCenterAddress: {
          street: distributionCenter.address.street,
          street2: '',
          city: distributionCenter.address.city,
          state: distributionCenter.address.state,
          zip: distributionCenter.address.zip,
          country: 'USA'
        },
        charityId: charity.charityId,
        charityName: charityData.orgName,
        charityLocationId: charity.locationId,
        charityLocationName: charity.locationName,
        primaryContact: getPrimaryContact(charityData, charity.locationId),
        charityAddress: {
          street: charityLocation.address.line1,
          street2: charityLocation.address.line2,
          city: charityLocation.address.city,
          state: charityLocation.address.stateProvince,
          zip: charityLocation.address.zipCode,
          country: charityLocation.address.country
        },
        shippingAddress: formatAddress(charityLocation.address),
        shippingQuoteUrl: shippingQuote?.url,
        palletGroup,
        palletQuantity: charity.palletCount,
        pickupDateTime,
        deliveryDate,
        itemDescription: quote.description,
        requestedByUserId: currentUser.id,
        lastUpdatedBy: currentUser.id,
        lastUpdatedAt: currentTimestamp,
        status: isSpecialAdmin ? 'scheduled' : 'pendingApproval',
        ...(isSpecialAdmin && {
          approvedBy: currentUser.id,
          approvedAt: currentTimestamp,
          autoApproved: true,
          approvalNotes: 'Auto-approved by system administrator'
        })
      };
 
      // Create or update the task
      let newTask;
      if (existingTask) {
        newTask = await logisticsService.updateTask(existingTask.id, {
          ...taskData,
          createdBy: existingTask.createdBy || currentUser.id,
          createdAt: existingTask.createdAt
        });
      } else {
        newTask = await logisticsService.createTask({
          ...taskData,
          createdBy: currentUser.id,
          createdAt: currentTimestamp
        });
      }
 
      // Create the approval request
      const approvalRequestData = {
        type: 'pickupDateApproval',
        taskId: newTask.id,
        distributionCenterId: quote.distributionCenterId,
        pickupDateTime,
        deliveryDate,
        requestedByUserId: currentUser.id,
        createdAt: currentTimestamp
      };
     
      if (isSpecialAdmin) {
        // Create an auto-approved request
        await approvalRequestService.createRequest({
          ...approvalRequestData,
          status: 'approved',
          approvedBy: currentUser.id,
          approvedAt: currentTimestamp,
          autoApproved: true,
          approvalNotes: 'Auto-approved by system administrator',
          taskId: newTask.id,
          taskStatus: 'scheduled',
          taskUpdateTimestamp: currentTimestamp,
          approved: true,
          approvalDate: currentTimestamp,
          reviewedBy: currentUser.id,
          reviewedAt: currentTimestamp,
          decision: 'approved'
        });
     
        // Update task to match approval flow
        await logisticsService.updateTask(newTask.id, {
          status: 'scheduled',
          approvedBy: currentUser.id,
          approvedAt: currentTimestamp,
          lastUpdatedBy: currentUser.id,
          lastUpdatedAt: currentTimestamp,
          autoApproved: true,
          approvalNotes: 'Auto-approved by system administrator',
          approved: true
        });
     
        toast.success('Task scheduled and auto-approved successfully');
      } else {
        // Create pending request for normal flow
        await approvalRequestService.createRequest({
          ...approvalRequestData,
          status: 'pending'
        });
     
        toast.success('Task scheduled and sent for approval');
      }
 
      // Send notifications
      try {
        let creatorPromise = null;
        let dcManagerPromise = null;
 
        if (quote.requestorId) {
          creatorPromise = userService.getById(quote.requestorId);
        }
 
        if (distributionCenter.managerId) {
          dcManagerPromise = userService.getById(distributionCenter.managerId);
        }
 
        const [creator, dcManager] = await Promise.all([
          creatorPromise || Promise.resolve(null),
          dcManagerPromise || Promise.resolve(null)
        ]);
 
        // Notify the quote creator
        if (creator?.phone) {
          await messageService.sendPickupApprovalRequestNotification(
            creator.phone,
            {
              itemDescription: quote.description,
              distributionCenterName: quote.distributionCenterName,
              pickupDateTime,
              deliveryDate,
              charityName: charityData.orgName,
              palletQuantity: charity.palletCount,
              status: isSpecialAdmin ? 'approved' : 'pending',
              autoApproved: isSpecialAdmin
            }
          );
        }
 
        // If auto-approved, notify DC manager
        if (isSpecialAdmin && dcManager?.phone) {
          await messageService.sendAutoApprovedPickupNotification(
            dcManager.phone,
            {
              itemDescription: quote.description,
              pickupDateTime,
              deliveryDate,
              charityName: charityData.orgName,
              palletQuantity: charity.palletCount,
              approverName: currentUser.name || 'System Administrator'
            }
          );
        }
      } catch (messageError) {
        console.error('Error sending notifications:', messageError);
        console.error('Context:', {
          quoteId: quote.id,
          requestorId: quote.requestorId,
          distributionCenterId: quote.distributionCenterId,
          dcManagerId: distributionCenter?.managerId
        });
        toast.warn('Task scheduled but notifications could not be sent');
      }
 
      await fetchData();
    } catch (error) {
      console.error('Error scheduling logistics task:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to schedule task: ' + error.message);
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  }, [fetchData, state.logisticsTasks]);


  const handleCompleteTask = async (task, acceptedDate, taxReceiptFile) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
 
      // Step 1: Upload tax receipt
      const taxReceiptUrl = await logisticsService.uploadTaxReceipt(taxReceiptFile, task.id);
 
      // Step 2: Complete task
      const result = await logisticsService.completeTask(task.id, acceptedDate, taxReceiptUrl);
     
      // Step 3: Show appropriate success message
      if (result.donationCompleted) {
        toast.success('Task, quote, and donation marked as completed');
      } else if (result.quoteCompleted) {
        toast.success('Task completed and quote marked as completed');
      } else {
        toast.success('Task completed successfully');
      }
 
      // Step 4: Refresh data
      await fetchData();


    } catch (error) {
      console.error('Error completing task:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to complete task: ' + error.message);
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  };;


  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const filteredQuotes = useMemo(() => {
    return state.quotes.filter(quote => {
      const searchLower = state.filters.searchTerm.toLowerCase();
      const matchesSearch =
        quote.id.toLowerCase().includes(searchLower) ||
        quote.description?.toLowerCase().includes(searchLower) ||
        quote.distributionCenterName.toLowerCase().includes(searchLower) ||
        quote.companyName.toLowerCase().includes(searchLower) ||
        quote.assignedCharities.some(charity =>
          charity.charityName.toLowerCase().includes(searchLower) ||
          charity.locationName.toLowerCase().includes(searchLower) ||
          charity.palletCount.toString().includes(searchLower)
        );


      const matchesDistributionCenter = state.filters.distributionCenter === 'all' ||
        quote.distributionCenterId === state.filters.distributionCenter;


      const matchesCompany = state.filters.company === 'all' ||
        quote.companyId === state.filters.company;


      const matchesStatus = state.filters.status === 'all' ||
        quote.assignedCharities.some(charity => {
          const task = state.logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === charity.charityId &&
            t.charityLocationId === charity.locationId
          );
          if (state.filters.status === 'notScheduled') {
            return !task;
          }
          return task && task.status === state.filters.status;
        });


      const matchesDateRange =
        (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
        quote.assignedCharities.some(charity => {
          const task = state.logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === charity.charityId &&
            t.charityLocationId === charity.locationId
          );
          return task &&
            new Date(task.pickupDateTime) >= new Date(state.filters.dateRange.start) &&
            new Date(task.deliveryDate) <= new Date(state.filters.dateRange.end);
        });


      return matchesSearch && matchesDistributionCenter && matchesCompany &&
             matchesStatus && matchesDateRange;
    });
  }, [state.quotes, state.logisticsTasks, state.filters]);


  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;
  if (state.userRole !== 'SystemAdmin') {
    return <ErrorMessage message="Access denied. Only System Admins can manage logistics." />;
  }


  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="manage-logistics-container">
          <div className="logistics-content">
            <h1><FaTruck /> Manage Logistics</h1>
            <FilterBar
              filters={state.filters}
              distributionCenters={state.distributionCenters}
              companies={state.companies}
              dispatch={dispatch}
              statusOptions={statusOptions}
            />
            <LogisticsTable
              quotes={filteredQuotes}
              logisticsTasks={state.logisticsTasks}
              onSchedule={handleSchedule}
              onCompleteTask={handleCompleteTask}
              statusOptions={statusOptions}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};


const FilterBar = React.memo(({ filters, distributionCenters, companies, dispatch, statusOptions }) => (
  <div className="filters">
    <div className="filter-group">
      <FaSearch className="icon" />
      <input
        type="text"
        placeholder="Search by quote ID, item, charity, or location"
        value={filters.searchTerm}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'searchTerm', value: e.target.value } })}
      />
    </div>
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select
        value={filters.company}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'company', value: e.target.value } })}
      >
        <option value="all">All Companies</option>
        {companies.map(companyId => (
          <option key={companyId} value={companyId}>{companyId}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaWarehouse className="icon" />
      <select
        value={filters.distributionCenter}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'distributionCenter', value: e.target.value } })}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dcId => (
          <option key={dcId} value={dcId}>{dcId}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaFilter className="icon" />
      <select
        value={filters.status}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'status', value: e.target.value } })}
      >
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <input
        type="date"
        value={filters.dateRange.start || ''}
        onChange={(e) => dispatch({
          type: 'SET_FILTER',
          payload: {
            name: 'dateRange',
            value: { ...filters.dateRange, start: e.target.value }
          }
        })}
      />
      <span>to</span>
      <input
        type="date"
        value={filters.dateRange.end || ''}
        onChange={(e) => dispatch({
          type: 'SET_FILTER',
          payload: {
            name: 'dateRange',
            value: { ...filters.dateRange, end: e.target.value }
          }
        })}
      />
    </div>
    <button
      className="btn btn-reset"
      onClick={() => dispatch({ type: 'RESET_FILTERS' })}
    >
      Reset Filters
    </button>
  </div>
));


const StatusIndicator = ({ status, statusOptions }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
 
  return (
    <div
      className={`status-indicator ${status}`}
      data-tooltip-id="tooltip"
      data-tooltip-content={statusOption.label}
    >
      <Icon /> <span>{statusOption.label}</span>
    </div>
  );
};


const LogisticsTable = React.memo(({ quotes, logisticsTasks, onSchedule, onCompleteTask, statusOptions }) => {
  const [expandedQuote, setExpandedQuote] = useState(null);


  const handleExpand = (quoteId) => {
    setExpandedQuote(expandedQuote === quoteId ? null : quoteId);
  };


  return (
    <div className="logistics-table-container">
      <table className="logistics-table">
        <thead>
          <tr>
            <th>Quote ID</th>
            <th>Company</th>
            <th>Item Description</th>
            <th>Distribution Center</th>
            <th>Total Pallets</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map(quote => (
            <React.Fragment key={quote.id}>
              <tr className={expandedQuote === quote.id ? 'expanded' : ''}>
                <td>{quote.id}</td>
                <td>{quote.companyName}</td>
                <td>{quote.description}</td>
                <td>{quote.distributionCenterName}</td>
                <td>{quote.assignedCharities.reduce((sum, charity) => sum + charity.palletCount, 0)}</td>
                <td>
                  <button
                    className="btn btn-expand"
                    onClick={() => handleExpand(quote.id)}
                  >
                    {expandedQuote === quote.id ? 'Collapse' : 'Expand'}
                  </button>
                </td>
              </tr>
              {expandedQuote === quote.id && (
                <tr>
                  <td colSpan="6">
                    <PalletGroupTable
                      quote={quote}
                      logisticsTasks={logisticsTasks}
                      onSchedule={onSchedule}
                      onCompleteTask={onCompleteTask}
                      statusOptions={statusOptions}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
});


const PalletGroupTable = React.memo(({
  quote,
  logisticsTasks,
  onSchedule,
  onCompleteTask,
  statusOptions
}) => {
  const [schedulingCharity, setSchedulingCharity] = useState(null);
  const [completingCharity, setCompletingCharity] = useState(null);
  const [completingTaskId, setCompletingTaskId] = useState(null);
  const [schedulingStates, setSchedulingStates] = useState({});
  const [isSpecialAdmin, setIsSpecialAdmin] = useState(false);


  const handleScheduleClick = (charity) => {
    const charityKey = `${charity.charityId}-${charity.locationId}`;
    setSchedulingStates(prev => ({
      ...prev,
      [charityKey]: true
    }));
    setSchedulingCharity(charity);
    setCompletingCharity(null);
  };


  const handleCompleteClick = (charity) => {
    setCompletingCharity(charity);
    setSchedulingCharity(null);
  };


  const handleScheduleSubmit = async (e, charity) => {
    e.preventDefault();
    const pickupDate = e.target.pickupDate.value;
    const pickupTime = e.target.pickupTime.value;
    const deliveryDate = e.target.deliveryDate.value;
    const pickupDateTime = `${pickupDate}T${pickupTime}:00`;
   
    try {
      await onSchedule(quote, charity, pickupDateTime, deliveryDate, isSpecialAdmin);
      setSchedulingCharity(null);
      // Reset scheduling state for this charity
      const charityKey = `${charity.charityId}-${charity.locationId}`;
      setSchedulingStates(prev => ({
        ...prev,
        [charityKey]: false
      }));
      setIsSpecialAdmin(false); // Reset special admin toggle
    } catch (error) {
      toast.error('Failed to schedule pickup: ' + error.message);
    }
  };


  const handleCancelSchedule = (charity) => {
    setSchedulingCharity(null);
    // Reset scheduling state for this charity
    const charityKey = `${charity.charityId}-${charity.locationId}`;
    setSchedulingStates(prev => ({
      ...prev,
      [charityKey]: false
    }));
    setIsSpecialAdmin(false); // Reset special admin toggle
  };


  const handleCompleteSubmit = async (e, charity, task) => {
    e.preventDefault();
    setCompletingTaskId(task.id);
    try {
      const acceptedDate = e.target.acceptedDate.value;
      const taxReceiptFile = e.target.taxReceipt.files[0];


      if (!taxReceiptFile) {
        throw new Error('Please select a tax receipt file');
      }


      if (!acceptedDate) {
        throw new Error('Please select the accepted date');
      }


      await onCompleteTask(task, acceptedDate, taxReceiptFile);
      setCompletingCharity(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCompletingTaskId(null);
    }
  };


  return (
    <table className="pallet-group-table">
      <thead>
        <tr>
          <th>Charity</th>
          <th>Location</th>
          <th>Pallet Count</th>
          <th>Status</th>
          <th>Pickup Date/Time</th>
          <th>Delivery Date</th>
          <th>Shipping Quote</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {quote.assignedCharities.map(charity => {
          const task = logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === charity.charityId &&
            t.charityLocationId === charity.locationId
          );
          const isCompleting = task && completingTaskId === task.id;
          const charityKey = `${charity.charityId}-${charity.locationId}`;
          const isScheduling = schedulingStates[charityKey];


          return (
            <tr key={`${charity.charityId}-${charity.locationId}`}>
              <td>{charity.charityName}</td>
              <td>{charity.locationName}</td>
              <td>{charity.palletCount}</td>
              <td>
                <StatusIndicator
                  status={task ? task.status : 'notScheduled'}
                  statusOptions={statusOptions}
                />
              </td>
              <td>{task ? new Date(task.pickupDateTime).toLocaleString() : '-'}</td>
              <td>{task ? new Date(task.deliveryDate).toLocaleDateString() : '-'}</td>
              <td>
                {(() => {
                  const charityIndex = quote.assignedCharities.findIndex(
                    ac => ac.charityId === charity.charityId && ac.locationId === charity.locationId
                  );
                  const palletGroup = charityIndex + 1;
                  const shippingQuote = quote.shippingQuotes?.find(sq => sq.palletGroup === palletGroup);
                 
                  return shippingQuote?.url ? (
                    <a
                      href={shippingQuote.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-download"
                      data-tooltip-id="tooltip"
                      data-tooltip-content={`Download Shipping Quote - ${shippingQuote.price}`}
                    >
                      <FaDownload /> Quote (${shippingQuote.price})
                    </a>
                  ) : '-';
                })()}
              </td>
              <td>
                <div className="action-buttons">
                  {(!task || task.status === 'rejected') && !isScheduling && (
                    <button
                      className="btn btn-schedule"
                      onClick={() => handleScheduleClick(charity)}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={task ? "Reschedule rejected task" : "Schedule new task"}
                    >
                      {task ? 'Reschedule' : 'Schedule'}
                    </button>
                  )}
                  {task && (task.status === 'scheduled' || task.status === 'inProgress') && 
                   !completingCharity && (
                    <button
                      className="btn btn-complete"
                      onClick={() => handleCompleteClick(charity)}
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Complete task"
                      disabled={isCompleting}
                    >
                      {isCompleting ? (
                        <span className="loading-button">
                          <div className="spinner"></div>
                          Completing...
                        </span>
                      ) : (
                        'Complete'
                      )}
                    </button>
                  )}
                  {task && task.bolPdfUrl && (
                    <a
                      href={task.bolPdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-download"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Download Bill of Lading"
                    >
                      <FaDownload /> BOL
                    </a>
                  )}
                </div>


                {schedulingCharity && schedulingCharity.charityId === charity.charityId &&
 schedulingCharity.locationId === charity.locationId && (
  <form onSubmit={(e) => handleScheduleSubmit(e, charity)} className="scheduling-form">
    <div className="form-group">
      <label htmlFor="pickupDate">Pickup Date:</label>
      <input type="date" id="pickupDate" name="pickupDate" required />
    </div>
    <div className="form-group">
      <label htmlFor="pickupTime">Pickup Time:</label>
      <input type="time" id="pickupTime" name="pickupTime" required />
    </div>
    <div className="form-group">
      <label htmlFor="deliveryDate">Delivery Date:</label>
      <input type="date" id="deliveryDate" name="deliveryDate" required />
    </div>
    <div className="form-group">
      <label className="checkbox-label">
        <input
          type="checkbox"
          checked={isSpecialAdmin}
          onChange={(e) => setIsSpecialAdmin(e.target.checked)}
        />
        <span>Auto-approve pickup request</span>
      </label>
    </div>
    <div className="form-actions">
      <button type="submit" className="btn btn-confirm-schedule">
        Confirm
      </button>
      <button
        type="button"
        className="btn btn-cancel-schedule"
        onClick={() => handleCancelSchedule(charity)}
      >
        Cancel
      </button>
    </div>
  </form>
)}


                {completingCharity && completingCharity.charityId === charity.charityId &&
                 completingCharity.locationId === charity.locationId && task && (
                  <form onSubmit={(e) => handleCompleteSubmit(e, charity, task)} className="completing-form">
                    <div className="form-group">
                      <label htmlFor="acceptedDate">Accepted by Charity Date:</label>
                      <input
                        type="date"
                        id="acceptedDate"
                        name="acceptedDate"
                        required
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="taxReceipt">Tax Receipt PDF:</label>
                      <input
                        type="file"
                        id="taxReceipt"
                        name="taxReceipt"
                        accept=".pdf"
                        required
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn btn-confirm-complete"
                        disabled={isCompleting}
                      >
                        {isCompleting ? (
                          <span className="loading-button">
                            <div className="spinner"></div>
                            Processing...
                          </span>
                        ) : (
                          'Confirm'
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel-complete"
                        onClick={() => setCompletingCharity(null)}
                        disabled={isCompleting}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});


export default ManageLogistics;