// src/utils/iconMap.js
import { 
    FaChevronLeft,
    FaChevronRight,
    FaDollarSign,
    FaUsers,
    FaRecycle,
    FaHeart,
    FaArrowRight,
    FaUpload,
    FaCheck,
    FaTrash,
    FaSync,
    FaFile,
    FaChartBar,
    FaGift,
    FaBuilding
  } from 'react-icons/fa';
  
  export const iconMap = {
    // Navigation
    chevronLeft: FaChevronLeft,
    chevronRight: FaChevronRight,
    arrowRight: FaArrowRight,
    
    // Actions
    upload: FaUpload,
    check: FaCheck,
    delete: FaTrash,
    refresh: FaSync,
    
    // Content
    document: FaFile,
    chart: FaChartBar,
    gift: FaGift,
    users: FaUsers,
    building: FaBuilding,
    
    // Categories
    dollar: FaDollarSign,
    recycle: FaRecycle,
    heart: FaHeart,
    
    // Usage example:
    // <iconMap.users className="w-6 h-6" />
  };
  
  export default iconMap;