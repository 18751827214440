// src/components/ManageDonations.js

import React, { useState, useEffect, useCallback } from 'react';
import { 
  FaSearch, 
  FaFilter, 
  FaTimes, 
  FaCalendarAlt, 
  FaClock, 
  FaQuoteRight, 
  FaCheckCircle, 
  FaCalendarCheck,
  FaTruck,
  FaEdit,
  FaFileAlt,
  FaDollarSign,
  FaBuilding,
  FaWarehouse,
  FaUser,
  FaBox,
  FaMoneyBillWave,
  FaInfoCircle,
  FaExclamationTriangle,
  FaMapMarkerAlt,
  FaStar,
} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/ManageDonations.css';
import Sidebar from './Sidebar';
import Header from './Header';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import AssignDonation from './AssignDonation';
import { donationService } from '../services/donationService';
import { companyService } from '../services/companyService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';
import { authService } from '../services/authService';
import { quoteService } from '../services/quoteService';
import { messageService } from '../services/messageService';

const statusOptions = [
  { value: 'Pending', icon: FaClock, color: '#FFA500' },
  { value: 'Valued', icon: FaMoneyBillWave, color: '#20B2AA' },
  { value: 'Quoted', icon: FaQuoteRight, color: '#4299E1' },
  { value: 'Quote Sent', icon: FaCheckCircle, color: '#48BB78' },
  { value: 'Quote Rejected', icon: FaTimes, color: '#FF4444' },
  { value: 'Scheduled', icon: FaCalendarCheck, color: '#9F7AEA' },
  { value: 'Delivered', icon: FaTruck, color: '#38A169' },
];


const RejectionDetailsPopup = ({ quoteId, onClose }) => {
  const [rejectionDetails, setRejectionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRejectionDetails = async () => {
      try {
        const quote = await quoteService.getById(quoteId);
        if (quote) {
          const rejector = await userService.getById(quote.rejectedBy);
          setRejectionDetails({
            reason: quote.rejectionReason,
            rejectedBy: rejector.name,
            // Convert ISO string to Date object directly
            timestamp: new Date(quote.rejectionTimestamp)
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching rejection details:', error);
        setError('Failed to load rejection details');
        setIsLoading(false);
      }
    };

    fetchRejectionDetails();
  }, [quoteId]);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date);
  };

  return (
    <div className="popup-overlay">
      <div className="rejection-popup">
        <div className="rejection-popup-header">
          <h3>
            <FaExclamationTriangle className="rejection-icon" /> 
            Quote Rejection Details
          </h3>
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        {isLoading ? (
          <div className="rejection-popup-content">
            <div className="loading-spinner">Loading...</div>
          </div>
        ) : error ? (
          <div className="rejection-popup-content">
            <div className="error-message">{error}</div>
          </div>
        ) : rejectionDetails && (
          <div className="rejection-popup-content">
            <div className="rejection-detail">
              <label>Rejected By:</label>
              <p><FaUser className="detail-icon" /> {rejectionDetails.rejectedBy}</p>
            </div>
            <div className="rejection-detail">
              <label>Rejection Time:</label>
              <p>
                <FaCalendarAlt className="detail-icon" />
                {formatDate(rejectionDetails.timestamp)}
              </p>
            </div>
            <div className="rejection-detail">
              <label>Reason:</label>
              <p className="rejection-reason">
                <FaInfoCircle className="detail-icon" />
                {rejectionDetails.reason}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ManageItemsTable = ({ 
  donationId, 
  items, 
  handleItemAssignment,
  handleFMVSubmit,
  handleCreateQuote,
  handleCreateSpecialQuote,
}) => {
  const [fmvInputs, setFmvInputs] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [quoteUrls, setQuoteUrls] = useState({});
  const [selectedQuote, setSelectedQuote] = useState(null);

  useEffect(() => {
    const fetchQuoteUrls = async () => {
      const urls = {};
      for (const item of items) {
        if (item.quoteId && ['Quote Sent', 'Quote Rejected'].includes(item.status)) {
          try {
            const quote = await quoteService.getById(item.quoteId);
            if (quote?.pdfUrl) {
              urls[item.itemID] = quote.pdfUrl;
            }
          } catch (error) {
            console.error('Error fetching Quote:', error);
          }
        }
      }
      setQuoteUrls(urls);
    };

    fetchQuoteUrls();
  }, [items]);

  const handleFMVInputChange = (itemID, value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    setFmvInputs(prev => ({...prev, [itemID]: numericValue}));
  };

  const formatCurrency = (value) => {
    if (!value) return '$0.00';
    return `$${parseFloat(value).toFixed(2)}`;
  };

  const handleFMVInputFocus = (itemID) => {
    setFocusedInput(itemID);
  };

  const handleFMVInputBlur = (itemID) => {
    setFocusedInput(null);
    if (fmvInputs[itemID]) {
      setFmvInputs(prev => ({...prev, [itemID]: parseFloat(prev[itemID]).toFixed(2)}));
    }
  };

  const submitFMV = (itemID) => {
    handleFMVSubmit(donationId, itemID, fmvInputs[itemID]);
  };

  const handleKeyPress = (event, itemID) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitFMV(itemID);
    }
  };

  const getButtonStates = (status) => ({
    assignEnabled: ['Valued', 'Quoted', 'Quote Rejected'].includes(status),
    createQuoteEnabled: ['Quoted', 'Quote Rejected'].includes(status),
    createQuoteActive: status === 'Quoted'
  });

  const showQuoteButton = (status) => ['Quote Sent', 'Quote Rejected'].includes(status);

  return (
    <>
      <table className="manage-items-table">
        <thead>
          <tr>
            <th>Item Description</th>
            <th>Quantity</th>
            <th>Pallet Count</th>
            <th>Estimated Value</th>
            <th>Fair Market Value</th>
            <th>Shipping Price</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            const { assignEnabled, createQuoteEnabled } = getButtonStates(item.status);
            return (
              <tr key={item.itemID}>
                <td>{item.description}</td>
                <td>{item.quantity}</td>
                <td>{item.palletCount}</td>
                <td>{formatCurrency(item.estimatedValue)}</td>
                <td>
                  {!item.fairMarketValue ? (
                    <div className="fmv-input-group">
                      <input 
                        type="text" 
                        value={focusedInput === item.itemID ? 
                          (fmvInputs[item.itemID] || '') : 
                          formatCurrency(fmvInputs[item.itemID] || item.fairMarketValue)}
                        onChange={(e) => handleFMVInputChange(item.itemID, e.target.value)} 
                        onFocus={() => handleFMVInputFocus(item.itemID)}
                        onBlur={() => handleFMVInputBlur(item.itemID)}
                        onKeyPress={(e) => handleKeyPress(e, item.itemID)}
                        className="form-input"
                      />
                      <button 
                        onClick={() => submitFMV(item.itemID)}
                        className="btn btn-primary btn-sm"
                      >
                        Submit FMV
                      </button>
                    </div>
                  ) : (
                    <div>{formatCurrency(item.fairMarketValue)}</div>
                  )}
                  {item.fmvAssessmentUrl && (
                    <a href={item.fmvAssessmentUrl} target="_blank" rel="noopener noreferrer" className="btn btn-secondary btn-sm">
                      <FaFileAlt /> View FMV Assessment
                    </a>
                  )}
                </td>
                <td>{formatCurrency(item.shippingQuotePrice)}</td>
                <td>
                  <StatusIndicator status={item.status} />
                  {showQuoteButton(item.status) && quoteUrls[item.itemID] && (
                    <a 
                      href={quoteUrls[item.itemID]} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="btn btn-secondary btn-sm mt-2"
                    >
                      <FaFileAlt /> View Quote
                    </a>
                  )}
                  {item.status === 'Quote Rejected' && item.quoteId && (
                    <button 
                      onClick={() => setSelectedQuote(item.quoteId)}
                      className="btn btn-warning btn-sm mt-2"
                    >
                      <FaExclamationTriangle /> View Rejection Details
                    </button>
                  )}
                </td>
                <td className="actions-cell">
                  <div className="actions-container">
                    <button 
                      onClick={() => handleItemAssignment(item)} 
                      className={`btn btn-sm ${
                        assignEnabled ? 'btn-primary' : 'disabled-btn'
                      }`}
                      disabled={!assignEnabled}
                    >
                      Assign to Charity
                    </button>
                    <button 
                      onClick={() => createQuoteEnabled && handleCreateQuote(item)} 
                      className={`btn btn-sm ${
                        createQuoteEnabled ? 'btn-quoted' : 'disabled-btn'
                      }`}
                      disabled={!createQuoteEnabled}
                    >
                      <FaDollarSign /> Create Quote
                    </button>
                    <button 
                    onClick={() => createQuoteEnabled && handleCreateSpecialQuote(item)}
                    className={`btn btn-sm ${
                      createQuoteEnabled ? 'btn-special-quote' : 'disabled-btn'
                    }`}
                    disabled={!createQuoteEnabled}
                  >
                    <FaStar /> Create Special Quote
                  </button>
                  </div>
                  {!assignEnabled && (
                    <div className="tooltip">
                      <span className="tooltiptext">
                        {item.status === 'Quote Sent' ? 
                          'Quote has been sent' : 
                          'Item must be Valued, Quoted, or Quote Rejected to assign to charity'}
                      </span>
                    </div>
                  )}
                  {!createQuoteEnabled && (
                    <div className="tooltip">
                      <span className="tooltiptext">
                        Item must be Quoted or Quote Rejected to create a quote
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {selectedQuote && (
        <RejectionDetailsPopup 
          quoteId={selectedQuote}
          onClose={() => setSelectedQuote(null)}
        />
      )}
    </>
  );
};

const ManageDonations = () => {
  const [state, setState] = useState({
    allDonations: [],
    filteredDonations: [],
    distributionCenters: [],
    companies: [],
    user: null,
    userRole: null,
    isLoading: true,
    error: null,
    success: null
  });

  const [filters, setFilters] = useState({
    selectedDC: 'all',
    selectedCompany: 'all',
    statusFilter: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  });

  const [selectedDonation, setSelectedDonation] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [company, setCompany] = useState(null);
  const [distributionCenter, setDistributionCenter] = useState(null);
  const [donationCreator, setDonationCreator] = useState(null);
  const [draftQuote, setDraftQuote] = useState(null);

  const updateState = useCallback((newState, callback) => {
    setState(prevState => {
      const updatedState = { ...prevState, ...newState };
      if (callback) {
        setTimeout(() => callback(updatedState), 0);
      }
      return updatedState;
    });
  }, []);

  const fetchInitialData = useCallback(async (userId) => {
    try {
      const user = await userService.getById(userId);
      const userRole = await userService.getUserRole(userId);
      let userCompanies = [];
      let allDonations = [];
      let allDCs = [];

      if (userRole === 'SystemAdmin') {
        userCompanies = await companyService.getAll();
      } else {
        const companyData = await companyService.getById(user.company);
        userCompanies = [companyData];
      }

      for (const company of userCompanies) {
        const [donationsData, dcsData] = await Promise.all([
          donationService.getByCompany(company.id),
          distributionCenterService.getByCompany(company.id)
        ]);
        allDonations = [...allDonations, ...donationsData];
        allDCs = [...allDCs, ...dcsData];
      }

      updateState({
        allDonations: allDonations,
        filteredDonations: allDonations,
        distributionCenters: allDCs,
        companies: userCompanies,
        user: user,
        userRole: userRole,
        isLoading: false
      });
    } catch (error) {
      console.error('Error fetching initial data:', error);
      updateState({ error: 'Failed to load data. Please try again.', isLoading: false });
    }
  }, [updateState]);

  useEffect(() => {
    const fetchUserAndInitialData = async () => {
      const currentUser = authService.getUserSession();
      if (currentUser) {
        const role = await userService.getUserRole(currentUser.id);
        if (role === 'SystemAdmin') {
          await fetchInitialData(currentUser.id);
        } else {
          updateState({ error: 'Access denied. Only System Admins can manage donations.', isLoading: false });
        }
      } else {
        updateState({ error: 'No user found. Please log in.', isLoading: false });
      }
    };

    fetchUserAndInitialData();
  }, [fetchInitialData, updateState]);

  const applyFilters = useCallback(() => {
    let filtered = state.allDonations.filter(donation => 
      donation.status.toLowerCase() !== 'completed'
    );
    
    if (filters.selectedCompany !== 'all') {
      filtered = filtered.filter(donation => donation.companyId === filters.selectedCompany);
    }
  
    if (filters.selectedDC !== 'all') {
      filtered = filtered.filter(donation => donation.distributionCenterId === filters.selectedDC);
    }
  
    if (filters.statusFilter !== 'all') {
      filtered = filtered.filter(donation => 
        donation.status.toLowerCase() === filters.statusFilter.toLowerCase()
      );
    }
  
    if (filters.dateRange.start || filters.dateRange.end) {
      filtered = filtered.filter(donation => {
        const donationDate = new Date(donation.createdAt.seconds * 1000);
        return (!filters.dateRange.start || donationDate >= filters.dateRange.start) &&
               (!filters.dateRange.end || donationDate <= filters.dateRange.end);
      });
    }
  
    if (filters.searchTerm) {
      const searchTerm = filters.searchTerm.toLowerCase();
      filtered = filtered.filter(donation =>
        donation.id.toLowerCase().includes(searchTerm) ||
        state.companies.find(c => c.id === donation.companyId)?.name.toLowerCase().includes(searchTerm) ||
        state.distributionCenters.find(dc => dc.id === donation.distributionCenterId)?.name.toLowerCase().includes(searchTerm) ||
        donation.items.some(item => item.description.toLowerCase().includes(searchTerm))
      );
    }
  
    updateState({ filteredDonations: filtered });
  }, [state.allDonations, state.companies, state.distributionCenters, filters, updateState]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);


  const handleCompanyChange = (companyId) => {
    setFilters(prev => ({ ...prev, selectedCompany: companyId }));
  };
  
  const handleDCChange = (dcId) => {
    setFilters(prev => ({ ...prev, selectedDC: dcId }));
  };

  const handleStatusFilterChange = (status) => {
    setFilters(prev => ({ ...prev, statusFilter: status }));
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setFilters(prev => ({ ...prev, dateRange: { start, end } }));
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilters(prev => ({ ...prev, searchTerm }));
  };

  const handleManageClick = async (donationId) => {
    const donation = await donationService.getById(donationId);
    setSelectedDonation(donation);

    const company = state.companies.find(c => c.id === donation.companyId);
    setCompany(company);

    const dc = state.distributionCenters.find(dc => dc.id === donation.distributionCenterId);
    setDistributionCenter(dc);

    const user = await userService.getById(donation.createdBy);
    setDonationCreator(user);
  };

  const handleItemAssignment = (item) => {
    setSelectedItem(item);
  };

  const handleAssignmentSave = async (updatedItem) => {
    try {
      const updatedDonation = await donationService.updateItem(selectedDonation.id, updatedItem.itemID, updatedItem);
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      setSelectedItem(null);
      updateState({ success: 'Item updated successfully.' });
    } catch (error) {
      console.error('Error updating item:', error);
      updateState({ error: 'Failed to update item. Please try again.' });
    }
  };

  const handleFMVSubmit = async (donationId, itemId, fairMarketValue) => {
    if (state.userRole !== 'SystemAdmin') {
      updateState({ error: 'Only System Admins can submit FMV assessments.' });
      return;
    }
    try {
      const updatedDonation = await donationService.submitFMVAssessment(
        donationId, 
        itemId, 
        fairMarketValue, 
        company, 
        distributionCenter
      );
      
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      updateState({ success: 'FMV Assessment submitted successfully.' });
    } catch (error) {
      console.error('Error submitting FMV Assessment:', error);
      updateState({ error: 'Failed to submit FMV Assessment. Please try again.' });
    }
  };

  const handleCreateQuote = (item) => {
    if (state.userRole !== 'SystemAdmin') {
      updateState({ error: 'Only System Admins can create quotes.' });
      return;
    }
  
    if (!item.assignedCharities || item.assignedCharities.length === 0) {
      updateState({ error: 'Please assign charities before creating a quote.' });
      return;
    }
  
    // Calculate prices for each charity group
    const charityGroups = item.assignedCharities.map((charity, index) => {
      // Find the corresponding shipping quote using palletGroup
      const shippingQuote = item.shippingQuotes?.find(quote => 
        quote.palletGroup === index + 1
      );
  
      // Default quote price is $300 per pallet
      const defaultQuotePrice = charity.palletCount * 300;
  
      return {
        palletGroup: index + 1,
        charityId: charity.charityId,
        charityName: charity.charityName,
        locationId: charity.locationId,
        locationName: charity.locationName,
        palletQuantity: charity.palletCount,
        pricePerPallet: 300,
        subtotalPrice: defaultQuotePrice,
        shippingQuotePrice: shippingQuote?.price || 0,
        shippingQuoteUrl: shippingQuote?.url || '',
        totalPrice: defaultQuotePrice
      };
    });
  
    // Calculate total prices
    const totalBaseCost = charityGroups.reduce((sum, group) => sum + group.totalPrice, 0);
    const actualShippingCost = item.shippingQuotePrice || 0;
  
    setDraftQuote({
      itemId: item.itemID,
      description: item.description,
      quantity: item.quantity,
      palletCount: item.palletCount,
      fairMarketValue: item.fairMarketValue || 0,
      fmvAssessmentUrl: item.fmvAssessmentUrl,
      costBasis: item.costBasis,
      unitOfMeasure: item.unitOfMeasure,
      totalWeight: item.totalWeight,
      sku: item.sku,
      charityGroups,
      date: new Date().toISOString().split('T')[0],
      amount: totalBaseCost.toFixed(2),
      totalShippingCost: actualShippingCost.toFixed(2),
      totalPrice: totalBaseCost.toFixed(2),
      notes: '',
      terms: 'Net 30',
      scheduleForCompletion: '',
      adminUserId: state.user.id,
      adminUserName: state.user.name,
      companyId: company.id,
      companyName: company.name,
      distributionCenterId: distributionCenter.id,
      distributionCenterName: distributionCenter.name,
      requestorId: donationCreator.id,
      requestorName: donationCreator.name,
      status: 'pending',
      validityPeriod: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    });
  };

  const handleCreateSpecialQuote = (item) => {
    if (state.userRole !== 'SystemAdmin') {
      updateState({ error: 'Only System Admins can create special quotes.' });
      return;
    }
  
    if (!item.assignedCharities || item.assignedCharities.length === 0) {
      updateState({ error: 'Please assign charities before creating a quote.' });
      return;
    }
  
    // Calculate prices for each charity group (similar to regular quote)
    const charityGroups = item.assignedCharities.map((charity, index) => {
      const shippingQuote = item.shippingQuotes?.find(quote => 
        quote.palletGroup === index + 1
      );
  
      const defaultQuotePrice = charity.palletCount * 300;
  
      return {
        palletGroup: index + 1,
        charityId: charity.charityId,
        charityName: charity.charityName,
        locationId: charity.locationId,
        locationName: charity.locationName,
        palletQuantity: charity.palletCount,
        pricePerPallet: 300,
        subtotalPrice: defaultQuotePrice,
        shippingQuotePrice: shippingQuote?.price || 0,
        shippingQuoteUrl: shippingQuote?.url || '',
        totalPrice: defaultQuotePrice
      };
    });
  
    const totalBaseCost = charityGroups.reduce((sum, group) => sum + group.totalPrice, 0);
    const actualShippingCost = item.shippingQuotePrice || 0;
  
    // Set up draft quote with special quote flag
    setDraftQuote({
      itemId: item.itemID,
      description: item.description,
      quantity: item.quantity,
      palletCount: item.palletCount,
      fairMarketValue: item.fairMarketValue || 0,
      fmvAssessmentUrl: item.fmvAssessmentUrl,
      costBasis: item.costBasis,
      unitOfMeasure: item.unitOfMeasure,
      totalWeight: item.totalWeight,
      sku: item.sku,
      charityGroups,
      date: new Date().toISOString().split('T')[0],
      amount: totalBaseCost.toFixed(2),
      totalShippingCost: actualShippingCost.toFixed(2),
      totalPrice: totalBaseCost.toFixed(2),
      notes: 'Special Quote - Auto-approved',
      terms: 'Net 30',
      scheduleForCompletion: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 2 weeks default
      adminUserId: state.user.id,
      adminUserName: state.user.name,
      companyId: company.id,
      companyName: company.name,
      distributionCenterId: distributionCenter.id,
      distributionCenterName: distributionCenter.name,
      requestorId: donationCreator.id,
      requestorName: donationCreator.name,
      validityPeriod: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      donationId: selectedDonation.id,
      isSpecialQuote: true
    });
  };

  const handleQuoteFieldChange = (field, value) => {
    setDraftQuote(prevQuote => {
      const updatedQuote = { ...prevQuote, [field]: value };
      
      // Recalculate total price when amount or shipping price changes
      if (field === 'amount' || field === 'shippingQuotePrice') {
        updatedQuote.totalPrice = (
          parseFloat(updatedQuote.amount || 0) + 
          parseFloat(updatedQuote.shippingQuotePrice || 0)
        ).toFixed(2);
      }
      
      return updatedQuote;
    });
  };

  const [isSubmittingQuote, setIsSubmittingQuote] = useState(false);

  const handleSubmitQuote = async (quote) => {
    // Validation
    if (!quote.amount || !quote.terms || !quote.scheduleForCompletion) {
      updateState({ error: 'Please fill in all required fields.' });
      return;
    }
  
    try {
      // Ensure all numerical values are properly formatted
      const formattedQuote = {
        ...quote,
        donationId: selectedDonation.id,
        amount: parseFloat(quote.amount).toFixed(2),
        totalShippingCost: parseFloat(quote.totalShippingCost).toFixed(2),
        totalPrice: parseFloat(quote.totalPrice).toFixed(2),
        charityGroups: quote.charityGroups.map(group => ({
          ...group,
          pricePerPallet: parseFloat(group.pricePerPallet),
          shippingQuotePrice: parseFloat(group.shippingQuotePrice || 0),
          palletQuantity: parseInt(group.palletQuantity)
        }))
      };
  
      // Create quote based on type
      const newQuote = formattedQuote.isSpecialQuote 
        ? await quoteService.createSpecialQuote(formattedQuote)
        : await quoteService.createQuote(formattedQuote);
  
      // Update donation item status
      const updatedDonation = await donationService.updateItem(
        selectedDonation.id,
        quote.itemId,
        {
          quoteId: newQuote.id,
          status: formattedQuote.isSpecialQuote ? 'Quote Approved' : 'Quote Sent'
        }
      );
  
      // Update UI state
      setSelectedDonation(updatedDonation);
      updateDonationInState(updatedDonation);
      setDraftQuote(null);
      updateState({ 
        success: formattedQuote.isSpecialQuote 
          ? 'Special quote created and auto-approved successfully.' 
          : 'Quote submitted successfully.' 
      });
  
      // Send notification if user has phone number
      if (donationCreator?.phone) {
        try {
          await messageService.sendNewQuoteNotification(
            donationCreator.phone,
            {
              id: newQuote.id,
              itemDescription: quote.description,
              palletCount: quote.palletCount,
              amount: parseFloat(quote.totalPrice),
              validityPeriod: quote.validityPeriod,
              isSpecial: formattedQuote.isSpecialQuote
            }
          );
        } catch (notificationError) {
          console.error('Failed to send notification:', notificationError);
          // Don't fail the quote creation if notification fails
        }
      }
    } catch (error) {
      updateState({ 
        error: quote.isSpecialQuote
          ? `Failed to create special quote: ${error.message}`
          : `Failed to submit quote: ${error.message}` 
      });
    }
  };

  const updateDonationInState = (updatedDonation) => {
    updateState(prevState => ({
      allDonations: prevState.allDonations.map(donation =>
        donation.id === updatedDonation.id ? updatedDonation : donation
      ),
      filteredDonations: prevState.filteredDonations.map(donation =>
        donation.id === updatedDonation.id ? updatedDonation : donation
      )
    }));
  };

  if (state.isLoading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;
  if (state.userRole !== 'SystemAdmin') return <div className="access-denied">Access denied. Only System Admins can manage donations.</div>;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="manage-donations-container">
          <h1><FaBox /> Manage Donations</h1>
          <FilterSection 
            filters={filters}
            handleDCChange={handleDCChange}
            handleCompanyChange={handleCompanyChange}
            handleStatusFilterChange={handleStatusFilterChange}
            handleDateRangeChange={handleDateRangeChange}
            handleSearch={handleSearch}
            distributionCenters={state.distributionCenters}
            companies={state.companies}
          />
          <DonationsTable 
            donations={state.filteredDonations}
            companies={state.companies}
            distributionCenters={state.distributionCenters}
            handleManageClick={handleManageClick}
          />

          {selectedDonation && (
            <div className="manage-items-section">
              <h2><FaEdit /> Manage Donation: {selectedDonation.id}</h2>
              <StatCards 
                donation={selectedDonation}
                company={company}
                distributionCenter={distributionCenter}
                user={donationCreator}
              />
              <ManageItemsTable 
                donationId={selectedDonation.id}
                items={selectedDonation.items}
                handleItemAssignment={handleItemAssignment}
                handleFMVSubmit={handleFMVSubmit}
                handleCreateQuote={handleCreateQuote}
                handleCreateSpecialQuote={handleCreateSpecialQuote}
              />
            </div>
          )}

          {selectedItem && (
            <AssignDonation
              item={{...selectedItem, donationId: selectedDonation.id}}
              onAssign={handleAssignmentSave}
              onClose={() => setSelectedItem(null)}
            />
          )}
          {draftQuote && (
            <DraftQuoteSection
              draftQuote={draftQuote}
              handleQuoteFieldChange={handleQuoteFieldChange}
              handleSubmitQuote={handleSubmitQuote}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const FilterSection = ({ 
  filters, 
  handleDCChange, 
  handleCompanyChange,
  handleStatusFilterChange, 
  handleDateRangeChange, 
  handleSearch, 
  distributionCenters,
  companies
}) => (
  <div className="filters">
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select value={filters.selectedCompany} onChange={(e) => handleCompanyChange(e.target.value)}>
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaWarehouse className="icon" />
      <select value={filters.selectedDC} onChange={(e) => handleDCChange(e.target.value)}>
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaFilter className="icon" />
      <select value={filters.statusFilter} onChange={(e) => handleStatusFilterChange(e.target.value)}>
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.value}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <DatePicker
        selectsRange={true}
        startDate={filters.dateRange.start}
        endDate={filters.dateRange.end}
        onChange={handleDateRangeChange}
        isClearable={true}
        placeholderText="Select date range"
      />
    </div>
    <div className="filter-group">
      <FaSearch className="icon" />
      <input
        type="text"
        placeholder="Search donations..."
        value={filters.searchTerm}
        onChange={handleSearch}
      />
    </div>
  </div>
);

const DonationsTable = ({ donations, companies, distributionCenters, handleManageClick }) => (
  <table className="donations-table">
    <thead>
      <tr>
        <th>Donation ID</th>
        <th>Date</th>
        <th>Company</th>
        <th>Distribution Center</th>
        <th>Items</th>
        <th>Total Value</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {donations.map(donation => (
        <tr key={donation.id}>
          <td>{donation.id}</td>
          <td>{new Date(donation.createdAt.seconds * 1000).toLocaleDateString()}</td>
          <td>{companies.find(c => c.id === donation.companyId)?.name || 'N/A'}</td>
          <td>{distributionCenters.find(dc => dc.id === donation.distributionCenterId)?.name || 'N/A'}</td>
          <td>{donation.items.length}</td>
          <td>${donation.totalEstimatedValue.toFixed(2)}</td>
          <td><StatusIndicator status={donation.status} /></td>
          <td>
            <button onClick={() => handleManageClick(donation.id)} className="btn btn-primary">
              <FaEdit /> Manage
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const StatusIndicator = ({ status }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <div className="status-indicator" style={{ color: statusOption.color }}>
      <Icon /> {status}
    </div>
  );
};

const StatCards = ({ donation, company, distributionCenter, user }) => (
  <div className="stat-cards">
    <div className="stat-card">
      <FaBuilding className="icon" />
      <h3>Company</h3>
      <p>{company?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaWarehouse className="icon" />
      <h3>Distribution Center</h3>
      <p>{distributionCenter?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaUser className="icon" />
      <h3>Created By</h3>
      <p>{user?.name || 'N/A'}</p>
    </div>
    <div className="stat-card">
      <FaCalendarAlt className="icon" />
      <h3>Date Created</h3>
      <p>{new Date(donation.createdAt.seconds * 1000).toLocaleDateString()}</p>
    </div>
    <div className="stat-card">
      <FaBox className="icon" />
      <h3>Total Pallet Count</h3>
      <p>{donation.items.reduce((sum, item) => sum + item.palletCount, 0)}</p>
    </div>
    <div className="stat-card">
      <FaMoneyBillWave className="icon" />
      <h3>Total Estimated Value</h3>
      <p>${donation.totalEstimatedValue.toFixed(2)}</p>
    </div>
  </div>
);

const CustomPriceInput = ({ groupIndex, price, onPriceChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(price.toString());

  const handleInputChange = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    setInputValue(numericValue);
  };

  const handleSave = () => {
    const numericValue = parseFloat(inputValue);
    if (!isNaN(numericValue)) {
      onPriceChange(groupIndex, numericValue);
      setIsEditing(false);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setInputValue(price.toString());
    }
  };

  return (
    <div className="custom-price-input">
      {isEditing ? (
        <div className="price-edit-container">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onKeyDown={handleKeyPress}
            className="form-input"
            autoFocus
          />
          <div className="price-actions">
            <button
              type="button"
              onClick={handleSave}
              className="btn btn-primary btn-sm"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => {
                setIsEditing(false);
                setInputValue(price.toString());
              }}
              className="btn btn-secondary btn-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="price-display">
          <span>{formatCurrency(price)}</span>
          <button
            type="button"
            onClick={() => setIsEditing(true)}
            className="btn btn-secondary btn-sm"
          >
            Customize
          </button>
        </div>
      )}
    </div>
  );
};

const CharityGroupSection = ({ group, index, onPriceChange }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  return (
    <div className="charity-group">
      <div className="charity-group-header">
        <h5>{group.charityName}</h5>
        <span className="location">
          <FaMapMarkerAlt /> {group.locationName}
        </span>
      </div>

      <div className="group-details">
        <div className="detail-group">
          <div className="detail-item">
            <label>Pallets:</label>
            <span>{group.palletQuantity}</span>
          </div>
          
          <div className="detail-item price-settings">
            <label>Price Per Pallet:</label>
            <CustomPriceInput
              groupIndex={index}
              price={group.pricePerPallet}
              onPriceChange={onPriceChange}
            />
          </div>
        </div>

        <div className="detail-group">
          <div className="detail-item subtotal">
            <label>Subtotal:</label>
            <span>
              {formatCurrency(group.palletQuantity * group.pricePerPallet)}
            </span>
          </div>
          
          <div className="detail-item shipping-info">
            <label>
              <span>Internal Shipping Quote:</span>
              <InfoTooltip text="For internal reference only. Not included in client quote." />
            </label>
            <span>{formatCurrency(group.shippingQuotePrice || 0)}</span>
          </div>
        </div>

        {group.shippingQuoteUrl && (
          <div className="quote-link">
            <a 
              href={group.shippingQuoteUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="btn btn-secondary btn-sm"
            >
              <FaFileAlt /> View Shipping Quote
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const DraftQuoteSection = ({ draftQuote, handleQuoteFieldChange, handleSubmitQuote }) => {
  const formatCurrency = (value) => {
    if (!value && value !== 0) return '$0.00';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const handlePriceChange = (groupIndex, newPrice) => {
    const updatedGroups = draftQuote.charityGroups.map((group, index) => {
      if (index === groupIndex) {
        const subtotalPrice = group.palletQuantity * newPrice;
        return {
          ...group,
          pricePerPallet: newPrice,
          subtotalPrice,
          totalPrice: subtotalPrice + (group.shippingQuotePrice || 0)
        };
      }
      return group;
    });

    // Update total amounts
    const totalBaseCost = updatedGroups.reduce((sum, group) => sum + group.subtotalPrice, 0);
    const totalShippingCost = updatedGroups.reduce((sum, group) => sum + (group.shippingQuotePrice || 0), 0);
    
    handleQuoteFieldChange('charityGroups', updatedGroups);
    handleQuoteFieldChange('amount', totalBaseCost.toFixed(2));
    handleQuoteFieldChange('totalShippingCost', totalShippingCost.toFixed(2));
    handleQuoteFieldChange('totalPrice', (totalBaseCost + totalShippingCost).toFixed(2));
  };

  return (
    <div className="draft-quote-section">
      <h3><FaFileAlt /> Draft Quote</h3>
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmitQuote(draftQuote);
      }}>
        {/* Basic Item Information */}
        <div className="form-row">
          <div className="form-group">
            <label><FaBox /> Item Description:</label>
            <input type="text" value={draftQuote.description} readOnly className="form-input" />
          </div>
          <div className="form-group">
            <label><FaBox /> SKU:</label>
            <input type="text" value={draftQuote.sku} readOnly className="form-input" />
          </div>
          <div className="form-group">
            <label><FaBox /> Quantity ({draftQuote.unitOfMeasure}):</label>
            <input type="text" value={draftQuote.quantity} readOnly className="form-input" />
          </div>
        </div>

        {/* Charity Groups Section */}
        <div className="charity-groups-section">
          <h4><FaMapMarkerAlt /> Shipping Destinations and Pricing</h4>
          {draftQuote.charityGroups.map((group, index) => (
            <CharityGroupSection
              key={index}
              group={group}
              index={index}
              onPriceChange={handlePriceChange}
            />
          ))}
        </div>

        {/* Quote Summary */}
        <div className="quote-summary">
          <h4><FaDollarSign /> Quote Summary</h4>
          <div className="summary-details">
            <div className="summary-item">
              <label>Base Amount:</label>
              <span>{formatCurrency(parseFloat(draftQuote.amount))}</span>
            </div>
            <div className="summary-item info">
              <label>
                Internal Shipping Total:
                <InfoTooltip text="Total of actual shipping quotes (for internal reference only)" />
              </label>
              <span>{formatCurrency(parseFloat(draftQuote.totalShippingCost))}</span>
            </div>
            <div className="summary-item total">
              <label>Total Quote Amount:</label>
              <span>{formatCurrency(parseFloat(draftQuote.totalPrice))}</span>
            </div>
          </div>
        </div>

        {/* Quote Details */}
        <div className="form-row">
          <div className="form-group">
            <label><FaCalendarAlt /> Valid Until:</label>
            <input
              type="date"
              value={draftQuote.validityPeriod}
              onChange={(e) => handleQuoteFieldChange('validityPeriod', e.target.value)}
              required
              className="form-input"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="form-group">
            <label><FaFileAlt /> Terms:</label>
            <select
              value={draftQuote.terms}
              onChange={(e) => handleQuoteFieldChange('terms', e.target.value)}
              required
              className="form-input"
            >
              <option value="Net 30">Net 30</option>
              <option value="Net 45">Net 45</option>
              <option value="Net 60">Net 60</option>
              <option value="Due on Receipt">Due on Receipt</option>
            </select>
          </div>
          <div className="form-group">
            <label><FaCalendarCheck /> Schedule for Completion:</label>
            <input
              type="date"
              value={draftQuote.scheduleForCompletion}
              onChange={(e) => handleQuoteFieldChange('scheduleForCompletion', e.target.value)}
              required
              className="form-input"
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
        </div>

        {/* Notes Section */}
        <div className="form-group">
          <label><FaEdit /> Notes:</label>
          <textarea
            value={draftQuote.notes}
            onChange={(e) => handleQuoteFieldChange('notes', e.target.value)}
            className="form-textarea"
            placeholder="Enter any additional notes or special instructions..."
            rows={4}
          />
        </div>

        {/* Submit Button */}
        <div className="form-actions">
          <button type="submit" className="btn btn-primary submit-quote-btn">
            <FaFileAlt /> {draftQuote.isSpecialQuote ? 'Submit Special Quote' : 'Submit Quote'}
          </button>
        </div>

        {/* Quote Type Indicator */}
        {draftQuote.isSpecialQuote && (
          <div className="special-quote-indicator">
            <FaStar className="star-icon" />
            <span>This is a Special Quote with auto-approval</span>
          </div>
        )}
      </form>
    </div>
  );
};

// Helper component for tooltips
const InfoTooltip = ({ text }) => (
  <div className="tooltip-container">
    <FaInfoCircle className="info-icon" />
    <span className="tooltip-text">{text}</span>
  </div>
);

export default ManageDonations;