import React, { useState, useEffect, useCallback } from 'react';
import { 
  FaSearch, 
  FaMapMarkerAlt,
  FaTimes,
  FaSpinner,
  FaCheck
} from 'react-icons/fa';
import { charityService } from '../../services/charityService';
import '../../styles/Charities/CharitySelector.css';

const CharitySelector = ({ 
  selectedCharityId,
  selectedLocationId, 
  onSelect,
  onClose 
}) => {
  const [charities, setCharities] = useState([]);
  const [charitiesWithLocations, setCharitiesWithLocations] = useState([]);
  const [filteredCharities, setFilteredCharities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch charities and their locations
  useEffect(() => {
    const fetchCharitiesAndLocations = async () => {
      try {
        setLoading(true);
        setError(null);
        // Get all charities with their locations
        const charitiesData = await charityService.getAll();
        setCharities(charitiesData);

        // Format the data to include locations with full addresses
        const charitiesWithLocs = charitiesData.map(charity => ({
          ...charity,
          locations: charity.locations?.map(loc => ({
            ...loc,
            fullAddress: `${loc.address.city}, ${loc.address.stateProvince}`
          })) || []
        }));

        setCharitiesWithLocations(charitiesWithLocs);
        setFilteredCharities(charitiesWithLocs);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching charities:', err);
        setError('Failed to load charities. Please try again.');
        setLoading(false);
      }
    };

    fetchCharitiesAndLocations();
  }, []);

  // Filter charities based on search
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredCharities(charitiesWithLocations);
      return;
    }

    const searchLower = searchTerm.toLowerCase();
    const filtered = charitiesWithLocations.filter(charity => 
      charity.orgName.toLowerCase().includes(searchLower) ||
      charity.locations.some(loc => 
        loc.fullAddress.toLowerCase().includes(searchLower)
      )
    );
    
    setFilteredCharities(filtered);
  }, [searchTerm, charitiesWithLocations]);

  // Handle charity and location selection
  const handleSelect = (charity, location) => {
    onSelect({
      charityId: charity.id,
      charityName: charity.orgName,
      locationId: location.id,
      locationName: location.fullAddress
    });
  };

  if (loading) {
    return (
      <div className="charity-selector loading">
        <FaSpinner className="spinner" />
        <p>Loading charities...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="charity-selector error">
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  return (
    <div className="charity-selector">
      <div className="charity-selector-header">
        <div className="search-container">
          <div className="search-box">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search charities or locations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
        </div>
        
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>

      <div className="charities-list">
        {filteredCharities.map(charity => (
          <div key={charity.id} className="charity-item">
            <div className="charity-header">
              <h3>{charity.orgName}</h3>
            </div>
            
            <div className="locations-list">
              {charity.locations.map(location => (
                <div 
                  key={location.id}
                  className={`location-item ${
                    charity.id === selectedCharityId && 
                    location.id === selectedLocationId ? 'selected' : ''
                  }`}
                  onClick={() => handleSelect(charity, location)}
                >
                  <div className="location-info">
                    <FaMapMarkerAlt className="location-icon" />
                    <div className="location-details">
                      <p className="address">{location.fullAddress}</p>
                      <div className="capacity-info">
                        <span>Capacity: {location.palletCapacity.maximum} pallets</span>
                      </div>
                    </div>
                  </div>
                  
                  {charity.id === selectedCharityId && 
                   location.id === selectedLocationId && (
                    <FaCheck className="selected-icon" />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}

        {filteredCharities.length === 0 && (
          <div className="no-results">
            <p>No charities found matching your search</p>
            {searchTerm && (
              <button onClick={() => setSearchTerm('')}>
                Clear Search
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CharitySelector;