// src/components/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { 
  FaEnvelope, 
  FaLock, 
  FaSpinner,
  FaExclamationCircle 
} from 'react-icons/fa';

const InputField = ({ 
  icon: Icon, 
  type, 
  value, 
  onChange, 
  placeholder, 
  error,
  name,
  required = true,
  autoComplete = 'off'
}) => (
  <div className="space-y-1">
    <div className="relative">
      <Icon className="absolute left-3 top-3 text-gray-400" />
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        className={`
          w-full pl-10 pr-3 py-2 border rounded-lg
          focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent
          transition duration-200
          ${error ? 'border-red-300 bg-red-50' : 'border-gray-300'}
          text-gray-900 placeholder-gray-400
        `}
      />
    </div>
    {error && (
      <p className="text-sm text-red-600 ml-1">{error}</p>
    )}
  </div>
);

const Login = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    error: '',
    isLoading: false
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value,
      error: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setFormState(prev => ({
      ...prev,
      isLoading: true,
      error: ''
    }));

    try {
      // Attempt login
      const user = await authService.login(formState.email, formState.password);
      
      // Fetch additional user data
      const userData = await authService.getUserData(user.uid);
      
      if (userData) {
        // Set user session
        const sessionData = {
          id: user.uid,
          email: user.email,
          name: userData.name,
          role: userData.role,
          phone: userData.phone,
          companies: userData.companies,
          distributionCenters: userData.distributionCenters
        };

        authService.setUserSession(sessionData);

        // Check for redirect URL first
        const redirectUrl = sessionStorage.getItem('redirectUrl');
        if (redirectUrl) {
          sessionStorage.removeItem('redirectUrl');
          navigate(redirectUrl);
        } else {
          // Handle routing based on role
          if (userData.role === 'Preview') {
            navigate(`/client-preview/${userData.company}`);
          } else {
            navigate('/my-impact-dashboard');
          }
        }
      } else {
        throw new Error('User data not found. Please contact support.');
      }
    } catch (error) {
      console.error('Login error:', error);
      
      setFormState(prev => ({
        ...prev,
        error: error.message,
        isLoading: false
      }));
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        {/* Login Card */}
        <div className="bg-white rounded-xl shadow-xl p-8 space-y-8">
          {/* Header */}
          <div className="text-center">
            {/* Logo */}
            <img 
            src="https://firebasestorage.googleapis.com/v0/b/donatingsimplified.appspot.com/o/Donating%20Simplified%20Black%20Wordmark.png?alt=media&token=98c50c6b-b4de-4a00-9b9a-00c949d51727"
            alt="Donating Simplified" 
            className="mx-auto h-12 mb-6"
          />
            <h2 className="text-3xl font-extrabold text-gray-900 mb-2">
              Welcome back
            </h2>
            <p className="text-gray-500">
              Sign in to your account to continue
            </p>
          </div>

          {/* Error Message */}
          {formState.error && (
            <div className="flex items-center gap-2 bg-red-50 text-red-600 p-4 rounded-lg border border-red-100">
              <FaExclamationCircle className="flex-shrink-0" />
              <span className="text-sm">{formState.error}</span>
            </div>
          )}

          {/* Login Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <InputField
              icon={FaEnvelope}
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              placeholder="Email address"
              autoComplete="email"
            />

            <InputField
              icon={FaLock}
              type="password"
              name="password"
              value={formState.password}
              onChange={handleInputChange}
              placeholder="Password"
              autoComplete="current-password"
            />

            <button
              type="submit"
              disabled={formState.isLoading}
              className="
                w-full bg-gradient-to-r from-purple-600 to-indigo-600
                text-white py-2 px-4 rounded-lg
                hover:from-purple-700 hover:to-indigo-700
                focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
                transition-all duration-200
                disabled:opacity-50 disabled:cursor-not-allowed
                font-medium text-sm
                flex items-center justify-center h-11
              "
            >
              {formState.isLoading ? (
                <span className="flex items-center gap-2">
                  <FaSpinner className="animate-spin" />
                  Signing in...
                </span>
              ) : (
                'Sign in'
              )}
            </button>
          </form>

          {/* Help Links */}
          <div className="space-y-2">
            <p className="text-center text-sm text-gray-500">
              Need help?{' '}
              <a href="mailto:support@donatingsimplified.com" className="text-purple-600 hover:text-purple-500 font-medium">
                Contact support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;