import React, { useMemo, useCallback, useState, memo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { 
  FaDollarSign, 
  FaUsers, 
  FaRecycle,
  FaHeart,
  FaFile,
  FaChartBar,
  FaUpload,
  FaGift,
  FaBuilding,
  FaSpinner,
  FaSync,
} from 'react-icons/fa';
import PreviewPresentation from './PreviewPresentation';
import { proposalService } from '../services/proposalService';
import { companyService } from '../services/companyService';

// Icon mapping for strategy cards
const iconMap = {
  dollar: FaDollarSign,
  users: FaUsers,
  recycle: FaRecycle,
  heart: FaHeart,
  file: FaFile,
  chart: FaChartBar,
  upload: FaUpload,
  gift: FaGift,
  building: FaBuilding,
};

// Memoized form components
const StyledInput = memo(({ label, error, ...props }) => (
  <div className="flex flex-col gap-2">
    {label && <label className="font-medium text-gray-700">{label}</label>}
    <input
      {...props}
      className={`
        px-4 py-2 rounded-lg border bg-white
        focus:outline-none focus:ring-2 focus:ring-purple-500/50
        transition-all duration-200
        ${error ? 'border-red-400' : 'border-gray-200'}
      `}
    />
    {error && (
      <span className="text-sm text-red-500">{error}</span>
    )}
  </div>
));

const StyledTextarea = memo(({ label, error, ...props }) => (
  <div className="flex flex-col gap-2">
    {label && <label className="font-medium text-gray-700">{label}</label>}
    <textarea
      {...props}
      className={`
        px-4 py-2 rounded-lg border bg-white
        focus:outline-none focus:ring-2 focus:ring-purple-500/50
        transition-all duration-200 min-h-[100px] resize-y
        ${error ? 'border-red-400' : 'border-gray-200'}
      `}
    />
    {error && (
      <span className="text-sm text-red-500">{error}</span>
    )}
  </div>
));

const FileUpload = memo(({ label, preview, loading, ...props }) => (
  <div className="flex flex-col gap-2">
    {label && <label className="font-medium text-gray-700">{label}</label>}
    <div className="relative">
      <input
        type="file"
        {...props}
        className="hidden"
        id={`file-${label}`}
        disabled={loading}
      />
      <label
        htmlFor={`file-${label}`}
        className={`
          flex flex-col items-center justify-center
          w-full h-32 border-2 border-dashed rounded-lg
          cursor-pointer transition-all duration-200
          ${loading ? 'bg-gray-50' : 'hover:bg-gray-50'}
          ${preview ? 'border-purple-200' : 'border-gray-300'}
        `}
      >
        {preview ? (
          <img
            src={preview}
            alt={label}
            className="w-full h-full object-contain rounded-lg"
          />
        ) : (
          <div className="flex flex-col items-center">
            <FaUpload className="w-8 h-8 text-gray-400 mb-2" />
            <span className="text-sm text-gray-500">
              {loading ? 'Uploading...' : 'Click to upload'}
            </span>
          </div>
        )}
      </label>
    </div>
  </div>
));

const IconSelect = memo(({ value, onChange }) => (
  <div className="flex flex-col gap-2">
    <label className="font-medium text-gray-700">Icon</label>
    <div className="flex flex-wrap gap-2">
      {Object.entries(iconMap).map(([key, Icon]) => (
        <button
          key={key}
          type="button"
          onClick={() => onChange(key)}
          className={`
            p-3 rounded-lg transition-all duration-200
            ${value === key ? 'bg-purple-100 text-purple-600' : 'bg-gray-100 text-gray-600'}
            hover:bg-purple-50 hover:text-purple-500
          `}
        >
          <Icon className="w-5 h-5" />
        </button>
      ))}
    </div>
  </div>
));

const ColorPicker = memo(({ label, value, onChange }) => (
  <div className="flex flex-col gap-2">
    <label className="font-medium text-gray-700">{label}</label>
    <div className="flex flex-wrap gap-2">
      {['blue', 'purple', 'green', 'red', 'orange', 'indigo', 'pink'].map((color) => (
        <button
          key={color}
          type="button"
          onClick={() => onChange(color)}
          className={`
            w-8 h-8 rounded-full border-2 transition-all duration-200
            ${value === color ? 'ring-2 ring-offset-2 ring-purple-500' : ''}
            bg-${color}-500 hover:opacity-90
          `}
        />
      ))}
    </div>
  </div>
));

const StepIndicator = memo(({ steps, currentStep }) => (
  <div className="flex items-center justify-between w-full mb-8">
    {steps.map((step, index) => (
      <div
        key={index}
        className="flex items-center"
      >
        <div className={`
          flex items-center justify-center
          w-10 h-10 rounded-full border-2
          transition-all duration-200
          ${index === currentStep
            ? 'border-purple-600 bg-purple-600 text-white'
            : index < currentStep
              ? 'border-purple-600 bg-white text-purple-600'
              : 'border-gray-300 bg-white text-gray-400'
          }
        `}>
          <span className="text-sm font-medium">{index + 1}</span>
        </div>
        {index < steps.length - 1 && (
          <div className={`
            w-full h-0.5 mx-2
            transition-all duration-200
            ${index < currentStep ? 'bg-purple-600' : 'bg-gray-300'}
          `} />
        )}
      </div>
    ))}
  </div>
));

const StepHeader = memo(({ step }) => {
  const Icon = step.icon;
  return (
    <div className="flex items-center justify-between mb-8">
      <div>
        <h1 className="text-3xl font-bold text-gray-900">{step.title}</h1>
        <p className="text-gray-500 mt-1">{step.subtitle}</p>
      </div>
      <Icon className="w-8 h-8 text-purple-600" />
    </div>
  );
});

// Step Components
const CoverStep = ({ register, errors, watch, setValue, loading, proposalId }) => {
  // Add state for tracking logo updates
  const [logoKey, setLogoKey] = useState(0);
  const watchedValues = watch(['cover.title', 'cover.subtitle', 'cover.year', 'cover.clientLogo']);
  
  // Force refresh when logo URL changes
  useEffect(() => {
    setLogoKey(prev => prev + 1);
  }, [watch('cover.clientLogo')]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <StyledInput
            type="text"
            label="Title"
            {...register('cover.title', { 
              required: 'Title is required',
              onChange: (e) => {
                setValue('cover.title', e.target.value, { shouldDirty: true });
              }
            })}
            error={errors?.cover?.title?.message}
            placeholder="Donation Strategy"
            defaultValue={watch('cover.title')}
          />
          
          <StyledInput
            type="text"
            label="Subtitle"
            {...register('cover.subtitle', {
              onChange: (e) => {
                setValue('cover.subtitle', e.target.value, { shouldDirty: true });
              }
            })}
            error={errors?.cover?.subtitle?.message}
            placeholder="Mission LA | Powered by Donating Simplified"
            defaultValue={watch('cover.subtitle')}
          />
          
          <StyledInput
            type="number"
            label="Year"
            {...register('cover.year', {
              onChange: (e) => {
                setValue('cover.year', e.target.value, { shouldDirty: true });
              }
            })}
            error={errors?.cover?.year?.message}
            placeholder={new Date().getFullYear().toString()}
            defaultValue={watch('cover.year')}
          />
        </div>
        
        <div>
          <FileUpload
            key={logoKey} // Add key to force remount
            label="Client Logo"
            accept="image/*"
            onChange={async (e) => {
              const file = e.target.files?.[0];
              if (file && proposalId) {
                try {
                  const url = await proposalService.uploadLogo(file, proposalId, 'client');
                  setValue('cover.clientLogo', url, { shouldDirty: true });
                  // Force parent preview to update
                  setValue('cover.lastUpdated', Date.now(), { shouldDirty: true });
                } catch (error) {
                  console.error('Error uploading logo:', error);
                }
              }
            }}
            preview={watch('cover.clientLogo')}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

const StrategyStep = memo(({ register, errors, watch, setValue }) => {
  // Watch all strategy fields for debugging
  const watchedValues = watch(['strategy.title', 'strategy.topCards', 'strategy.bottomCard']);
  
  useEffect(() => {
    console.log('Current strategy values:', watchedValues);
  }, [watchedValues]);

  // Ensure topCards array always has 3 items
  useEffect(() => {
    const currentTopCards = watch('strategy.topCards') || [];
    if (currentTopCards.length < 3) {
      setValue('strategy.topCards', [
        ...currentTopCards,
        ...Array(3 - currentTopCards.length).fill({
          icon: '',
          title: '',
          description: ''
        })
      ], { shouldDirty: true });
    }
  }, [watch, setValue]);

  return (
    <div className="space-y-6">
      <StyledInput
        type="text"
        label="Strategy Section Title"
        {...register('strategy.title', {
          onChange: (e) => {
            setValue('strategy.title', e.target.value, { shouldDirty: true });
          }
        })}
        error={errors?.strategy?.title?.message}
        defaultValue={watch('strategy.title')}
        placeholder="Custom Donation Strategy"
      />
      
      <div className="space-y-4">
        <h3 className="font-semibold text-gray-700">Strategy Cards</h3>
        {watch('strategy.topCards')?.map((_, index) => (
          <div key={index} className="p-4 bg-gray-50 rounded-lg space-y-4">
            <IconSelect
              value={watch(`strategy.topCards.${index}.icon`)}
              onChange={(value) => {
                setValue(`strategy.topCards.${index}.icon`, value, { shouldDirty: true });
              }}
            />
            
            <StyledInput
              type="text"
              label="Card Title"
              {...register(`strategy.topCards.${index}.title`, {
                onChange: (e) => {
                  setValue(`strategy.topCards.${index}.title`, e.target.value, { shouldDirty: true });
                }
              })}
              error={errors?.strategy?.topCards?.[index]?.title?.message}
              defaultValue={watch(`strategy.topCards.${index}.title`)}
            />
            
            <StyledTextarea
              label="Description"
              {...register(`strategy.topCards.${index}.description`, {
                onChange: (e) => {
                  setValue(`strategy.topCards.${index}.description`, e.target.value, { shouldDirty: true });
                }
              })}
              error={errors?.strategy?.topCards?.[index]?.description?.message}
              defaultValue={watch(`strategy.topCards.${index}.description`)}
            />
          </div>
        ))}
      </div>

      <div className="p-4 bg-gray-50 rounded-lg space-y-4">
        <h3 className="font-semibold text-gray-700">Bottom Card</h3>
        <IconSelect
          value={watch('strategy.bottomCard.icon')}
          onChange={(value) => {
            setValue('strategy.bottomCard.icon', value, { shouldDirty: true });
          }}
        />
        
        <StyledInput
          type="text"
          label="Title"
          {...register('strategy.bottomCard.title', {
            onChange: (e) => {
              setValue('strategy.bottomCard.title', e.target.value, { shouldDirty: true });
            }
          })}
          error={errors?.strategy?.bottomCard?.title?.message}
          defaultValue={watch('strategy.bottomCard.title')}
        />
        
        <StyledTextarea
          label="Description"
          {...register('strategy.bottomCard.description', {
            onChange: (e) => {
              setValue('strategy.bottomCard.description', e.target.value, { shouldDirty: true });
            }
          })}
          error={errors?.strategy?.bottomCard?.description?.message}
          defaultValue={watch('strategy.bottomCard.description')}
        />
      </div>
    </div>
  );
});

const ImpactStep = memo(({ register, errors, watch, setValue }) => {
  // Watch all impact fields for debugging
  const watchedValues = watch([
    'impact.metrics.donations.value',
    'impact.metrics.peopleHelped.value',
    'impact.metrics.wasteReduced.value',
    'impact.metrics.taxSavings.value',
    'impact.infoCard.title',
    'impact.infoCard.description'
  ]);
  
  useEffect(() => {
    console.log('Current impact values:', watchedValues);
  }, [watchedValues]);

  // Initialize default metric values if empty
  useEffect(() => {
    const metrics = ['donations', 'peopleHelped', 'wasteReduced', 'taxSavings'];
    metrics.forEach(metric => {
      const currentValue = watch(`impact.metrics.${metric}.value`);
      if (!currentValue) {
        setValue(`impact.metrics.${metric}.value`, '', { shouldDirty: true });
      }
    });
  }, [watch, setValue]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <StyledInput
          type="text"
          label="Donations Value"
          {...register('impact.metrics.donations.value', {
            onChange: (e) => {
              setValue('impact.metrics.donations.value', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.metrics?.donations?.value?.message}
          defaultValue={watch('impact.metrics.donations.value')}
          placeholder="$2.1M"
        />
        
        <StyledInput
          type="text"
          label="People Helped"
          {...register('impact.metrics.peopleHelped.value', {
            onChange: (e) => {
              setValue('impact.metrics.peopleHelped.value', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.metrics?.peopleHelped?.value?.message}
          defaultValue={watch('impact.metrics.peopleHelped.value')}
          placeholder="3,875"
        />
        
        <StyledInput
          type="text"
          label="Waste Reduced"
          {...register('impact.metrics.wasteReduced.value', {
            onChange: (e) => {
              setValue('impact.metrics.wasteReduced.value', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.metrics?.wasteReduced?.value?.message}
          defaultValue={watch('impact.metrics.wasteReduced.value')}
          placeholder="28.8k lbs"
        />
        
        <StyledInput
          type="text"
          label="Tax Savings"
          {...register('impact.metrics.taxSavings.value', {
            onChange: (e) => {
              setValue('impact.metrics.taxSavings.value', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.metrics?.taxSavings?.value?.message}
          defaultValue={watch('impact.metrics.taxSavings.value')}
          placeholder="$267K"
        />
      </div>

      <div className="space-y-4">
        <StyledInput
          type="text"
          label="Impact Card Title"
          {...register('impact.infoCard.title', {
            onChange: (e) => {
              setValue('impact.infoCard.title', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.infoCard?.title?.message}
          defaultValue={watch('impact.infoCard.title')}
          placeholder="LA Wildfire Relief Impact"
        />
        
        <StyledTextarea
          label="Impact Description"
          {...register('impact.infoCard.description', {
            onChange: (e) => {
              setValue('impact.infoCard.description', e.target.value, {
                shouldDirty: true
              });
            }
          })}
          error={errors?.impact?.infoCard?.description?.message}
          defaultValue={watch('impact.infoCard.description')}
          placeholder="Describe the impact..."
        />
      </div>
    </div>
  );
});

const OptionsStep = memo(({ register, errors, watch, setValue }) => {
  // Track local state for options
  const [localOptions, setLocalOptions] = useState(() => {
    const formOptions = watch('options.options') || [];
    return formOptions.map(option => ({
      ...option,
      title: option.title || '',
      subtitle: option.subtitle || '',
      bulletPoints: option.bulletPoints || [''],
      accentColor: option.accentColor || 'blue',
      isRecommended: option.isRecommended || false
    }));
  });

  // Sync form data changes to local state
  useEffect(() => {
    const formOptions = watch('options.options') || [];
    if (JSON.stringify(formOptions) !== JSON.stringify(localOptions)) {
      setLocalOptions(formOptions);
    }
  }, [watch, localOptions]);

  // Handle field changes
  const handleFieldChange = useCallback((optionIndex, field, value) => {
    const updatedOptions = [...localOptions];
    const updatedOption = { ...updatedOptions[optionIndex] };
    
    if (field.includes('bulletPoints')) {
      const pointIndex = parseInt(field.split('.').pop());
      updatedOption.bulletPoints = [...(updatedOption.bulletPoints || [''])];
      updatedOption.bulletPoints[pointIndex] = value;
    } else {
      updatedOption[field] = value;
    }
    
    updatedOptions[optionIndex] = updatedOption;
    setLocalOptions(updatedOptions);
    setValue('options.options', updatedOptions, { 
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true 
    });
  }, [localOptions, setValue]);

  const handleOptionAdd = useCallback(() => {
    const newOption = {
      title: '',
      subtitle: '',
      bulletPoints: [''],
      accentColor: 'blue',
      isRecommended: false
    };

    const updatedOptions = [...localOptions, newOption];
    setLocalOptions(updatedOptions);
    setValue('options.options', updatedOptions, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  }, [localOptions, setValue]);

  const handleOptionRemove = useCallback((optionIndex) => {
    const updatedOptions = localOptions.filter((_, index) => index !== optionIndex);
    setLocalOptions(updatedOptions);
    setValue('options.options', updatedOptions, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  }, [localOptions, setValue]);

  const handleBulletPointAdd = useCallback((optionIndex) => {
    const updatedOptions = [...localOptions];
    updatedOptions[optionIndex] = {
      ...updatedOptions[optionIndex],
      bulletPoints: [...(updatedOptions[optionIndex].bulletPoints || ['']), '']
    };
    
    setLocalOptions(updatedOptions);
    setValue('options.options', updatedOptions, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  }, [localOptions, setValue]);

  const handleBulletPointRemove = useCallback((optionIndex, pointIndex) => {
    const updatedOptions = [...localOptions];
    const currentPoints = updatedOptions[optionIndex].bulletPoints;
    
    if (currentPoints?.length > 1) {
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        bulletPoints: currentPoints.filter((_, index) => index !== pointIndex)
      };
      
      setLocalOptions(updatedOptions);
      setValue('options.options', updatedOptions, { 
        shouldDirty: true,
        shouldTouch: true 
      });
    }
  }, [localOptions, setValue]);

  return (
    <div className="space-y-6">
      <StyledInput
        type="text"
        label="Options Section Title"
        {...register('options.title', {
          onChange: (e) => {
            setValue('options.title', e.target.value, { 
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true
            });
          }
        })}
        error={errors?.options?.title?.message}
        defaultValue={watch('options.title')}
      />

      {localOptions.map((option, optionIndex) => (
        <div key={optionIndex} className="p-4 bg-gray-50 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-gray-700">Option {optionIndex + 1}</h3>
            <button
              type="button"
              onClick={() => handleOptionRemove(optionIndex)}
              className="text-red-500 hover:text-red-700"
            >
              Remove Option
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <StyledInput
              type="text"
              label="Title"
              value={option.title || ''}
              onChange={(e) => handleFieldChange(optionIndex, 'title', e.target.value)}
              error={errors?.options?.options?.[optionIndex]?.title?.message}
            />
            
            <StyledInput
              type="text"
              label="Subtitle"
              value={option.subtitle || ''}
              onChange={(e) => handleFieldChange(optionIndex, 'subtitle', e.target.value)}
              error={errors?.options?.options?.[optionIndex]?.subtitle?.message}
            />
          </div>

          <div className="mt-4">
            <label className="font-medium text-gray-700">Bullet Points</label>
            {(option.bulletPoints || ['']).map((point, pointIndex) => (
              <div key={`${optionIndex}-${pointIndex}`} className="flex items-center gap-2 mt-2">
                <StyledInput
                  type="text"
                  value={point || ''}
                  onChange={(e) => handleFieldChange(
                    optionIndex,
                    `bulletPoints.${pointIndex}`,
                    e.target.value
                  )}
                  error={errors?.options?.options?.[optionIndex]?.bulletPoints?.[pointIndex]?.message}
                />
                <button
                  type="button"
                  onClick={() => handleBulletPointRemove(optionIndex, pointIndex)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleBulletPointAdd(optionIndex)}
              className="text-purple-600 hover:text-purple-700 text-sm font-medium mt-2"
            >
              + Add Bullet Point
            </button>
          </div>

          <ColorPicker
            label="Accent Color"
            value={option.accentColor || 'blue'}
            onChange={(color) => handleFieldChange(optionIndex, 'accentColor', color)}
          />

          <div className="flex items-center gap-2 mt-4">
            <input
              type="checkbox"
              checked={option.isRecommended || false}
              onChange={(e) => handleFieldChange(optionIndex, 'isRecommended', e.target.checked)}
              className="w-4 h-4 text-purple-600 rounded"
            />
            <label className="text-sm text-gray-700">Mark as Recommended Option</label>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={handleOptionAdd}
        className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg
                 text-gray-500 hover:text-gray-700 hover:border-gray-400
                 transition-colors duration-200"
      >
        Add Option
      </button>

      <div className="p-4 bg-gray-50 rounded-lg">
        <div className="flex items-center gap-2 mb-4">
          <input
            type="checkbox"
            {...register('options.specialNote.visible', {
              onChange: (e) => {
                // Update form state
                setValue('options.specialNote.visible', e.target.checked, {
                  shouldDirty: true,
                  shouldTouch: true
                });
                // Force re-render
                setValue('options.specialNote.lastUpdated', Date.now(), {
                  shouldDirty: true
                });
              }
            })}
            className="w-4 h-4 text-purple-600 rounded"
          />
          <label className="font-medium text-gray-700">Include Special Note</label>
        </div>
        
        {watch('options.specialNote.visible') && (
          <StyledTextarea
            label="Special Note Text"
            {...register('options.specialNote.text', {
              onChange: (e) => {
                setValue('options.specialNote.text', e.target.value, {
                  shouldDirty: true,
                  shouldTouch: true
                });
              }
            })}
            error={errors?.options?.specialNote?.text?.message}
            defaultValue={watch('options.specialNote.text')}
          />
        )}
      </div>
    </div>
  );
});

const CharityStep = memo(({ register, errors, watch, setValue }) => {
  // Track local state for charity profiles
  const [localProfiles, setLocalProfiles] = useState(() => {
    const formProfiles = watch('charities.profiles') || [];
    return formProfiles.map(profile => ({
      ...profile,
      name: profile.name || '',
      tag: profile.tag || '',
      tagColor: profile.tagColor || 'blue',
      description: profile.description || '',
      area: profile.area || '',
      pallets: profile.pallets || '',
      priority: profile.priority || false
    }));
  });

  // Sync form data changes to local state
  useEffect(() => {
    const formProfiles = watch('charities.profiles') || [];
    if (JSON.stringify(formProfiles) !== JSON.stringify(localProfiles)) {
      setLocalProfiles(formProfiles);
    }
  }, [watch, localProfiles]);

  // Handle field changes
  const handleFieldChange = useCallback((profileIndex, field, value) => {
    const updatedProfiles = [...localProfiles];
    updatedProfiles[profileIndex] = {
      ...updatedProfiles[profileIndex],
      [field]: value
    };
    
    setLocalProfiles(updatedProfiles);
    setValue('charities.profiles', updatedProfiles, { 
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true 
    });
  }, [localProfiles, setValue]);

  const handleCharityAdd = useCallback(() => {
    const newProfile = {
      name: '',
      tag: '',
      tagColor: 'blue',
      description: '',
      area: '',
      pallets: '',
      priority: false
    };

    const updatedProfiles = [...localProfiles, newProfile];
    setLocalProfiles(updatedProfiles);
    setValue('charities.profiles', updatedProfiles, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  }, [localProfiles, setValue]);

  const handleCharityRemove = useCallback((profileIndex) => {
    const updatedProfiles = localProfiles.filter((_, index) => index !== profileIndex);
    setLocalProfiles(updatedProfiles);
    setValue('charities.profiles', updatedProfiles, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  }, [localProfiles, setValue]);

  return (
    <div className="space-y-6">
      <StyledInput
        type="text"
        label="Charities Section Title"
        {...register('charities.title', {
          onChange: (e) => {
            setValue('charities.title', e.target.value, { 
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true
            });
          }
        })}
        error={errors?.charities?.title?.message}
        defaultValue={watch('charities.title')}
      />

      {localProfiles.map((profile, profileIndex) => (
        <div key={profileIndex} className="p-4 bg-gray-50 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-gray-700">Charity {profileIndex + 1}</h3>
            <button
              type="button"
              onClick={() => handleCharityRemove(profileIndex)}
              className="text-red-500 hover:text-red-700"
            >
              Remove Charity
            </button>
          </div>

          <StyledInput
            type="text"
            label="Charity Name"
            value={profile.name || ''}
            onChange={(e) => handleFieldChange(profileIndex, 'name', e.target.value)}
            error={errors?.charities?.profiles?.[profileIndex]?.name?.message}
          />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <StyledInput
              type="text"
              label="Tag"
              value={profile.tag || ''}
              onChange={(e) => handleFieldChange(profileIndex, 'tag', e.target.value)}
              error={errors?.charities?.profiles?.[profileIndex]?.tag?.message}
            />
            
            <ColorPicker
              label="Tag Color"
              value={profile.tagColor || 'blue'}
              onChange={(color) => handleFieldChange(profileIndex, 'tagColor', color)}
            />
          </div>

          <StyledTextarea
            label="Description"
            value={profile.description || ''}
            onChange={(e) => handleFieldChange(profileIndex, 'description', e.target.value)}
            error={errors?.charities?.profiles?.[profileIndex]?.description?.message}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <StyledInput
              type="text"
              label="Service Area"
              value={profile.area || ''}
              onChange={(e) => handleFieldChange(profileIndex, 'area', e.target.value)}
              error={errors?.charities?.profiles?.[profileIndex]?.area?.message}
            />
            
            <StyledInput
              type="text"
              label="Pallet Capacity"
              value={profile.pallets || ''}
              onChange={(e) => handleFieldChange(profileIndex, 'pallets', e.target.value)}
              error={errors?.charities?.profiles?.[profileIndex]?.pallets?.message}
            />
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={profile.priority || false}
              onChange={(e) => handleFieldChange(profileIndex, 'priority', e.target.checked)}
              className="w-4 h-4 text-purple-600 rounded"
            />
            <label className="text-sm text-gray-700">Mark as Priority Partner</label>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={handleCharityAdd}
        className="w-full p-4 border-2 border-dashed border-gray-300 rounded-lg
                 text-gray-500 hover:text-gray-700 hover:border-gray-400
                 transition-colors duration-200"
      >
        Add Charity Profile
      </button>
    </div>
  );
});

// Memoized preview section
const PreviewSection = ({ formData }) => {
  const [previewKey, setPreviewKey] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleRefresh = () => {
    setIsRefreshing(true);
    // Create new key but maintain current slide
    setPreviewKey(prevKey => prevKey + 1);
    setTimeout(() => setIsRefreshing(false), 500);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className="sticky top-6 hidden lg:block h-fit"
    >
      <div className="bg-white rounded-2xl shadow-xl p-8">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-gray-900">
            Live Preview
          </h2>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2 px-3 py-1 bg-purple-100 text-purple-600 rounded-full text-sm">
              <FaSpinner className="w-4 h-4 animate-spin" />
              Auto-updating
            </div>
            <button
              onClick={handleRefresh}
              disabled={isRefreshing}
              className="flex items-center gap-2 px-3 py-1 bg-gray-100 text-gray-600 hover:bg-gray-200 rounded-full text-sm transition-colors duration-200"
              type="button"
            >
              <FaSync className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              Refresh
            </button>
          </div>
        </div>
        
        <div className="aspect-video bg-gray-50 rounded-xl overflow-hidden border border-gray-100">
          <PreviewPresentation 
            key={`preview-${previewKey}`} 
            data={formData}
            initialSlide={currentSlide}
            onSlideChange={setCurrentSlide}
          />
        </div>
      </div>
    </motion.div>
  );
};

const CompanySelector = memo(({ onCompanySelect, selectedCompanyId, disabled }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        setLoading(true);
        const companiesList = await companyService.getAll();
        setCompanies(companiesList);
      } catch (err) {
        console.error('Error loading companies:', err);
        setError('Failed to load companies');
      } finally {
        setLoading(false);
      }
    };

    loadCompanies();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center gap-2 text-gray-500">
        <FaSpinner className="w-4 h-4 animate-spin" />
        Loading companies...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 mb-8">
      <label className="font-medium text-gray-700">Select Company</label>
      <div className="relative">
        <select
          value={selectedCompanyId || ''}
          onChange={(e) => onCompanySelect(e.target.value)}
          disabled={disabled}
          className={`
            w-full px-4 py-2 rounded-lg border bg-white
            focus:outline-none focus:ring-2 focus:ring-purple-500/50
            transition-all duration-200
            ${disabled ? 'bg-gray-50' : ''}
            ${error ? 'border-red-400' : 'border-gray-200'}
          `}
        >
          <option value="">Select a company...</option>
          {companies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </select>
        <FaBuilding className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
    </div>
  );
});

const CreateCustomProposal = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [saveStatus, setSaveStatus] = useState({ type: null, message: null });
  const [storedData, setStoredData] = useState(null);
  const [proposalId, setProposalId] = useState(null);

   // Generate proposal ID on component mount
   useEffect(() => {
    const id = proposalService.generateProposalId();
    setProposalId(id);
  }, []);

  // Define steps array with useMemo
  const steps = useMemo(() => [
    {
      title: 'Cover Information',
      subtitle: 'Set the first impression',
      component: CoverStep,
      icon: FaFile,
      fields: ['cover']
    },
    {
      title: 'Strategy Cards',
      subtitle: 'Define your approach',
      component: StrategyStep,
      icon: FaRecycle,
      fields: ['strategy']
    },
    {
      title: 'Impact Dashboard',
      subtitle: 'Showcase your results',
      component: ImpactStep,
      icon: FaChartBar,
      fields: ['impact']
    },
    {
      title: 'Donation Options',
      subtitle: 'Present the possibilities',
      component: OptionsStep,
      icon: FaGift,
      fields: ['options']
    },
    {
      title: 'Charity Profiles',
      subtitle: 'Highlight your partners',
      component: CharityStep,
      icon: FaBuilding,
      fields: ['charities']
    }
  ], []);

  // Initialize form with default values
  const defaultValues = useMemo(() => ({
    cover: {
      title: 'Donation Strategy',
      subtitle: '',
      year: new Date().getFullYear(),
      clientLogo: '',
      partnerLogo: ''
    },
    strategy: {
      title: 'Custom Donation Strategy',
      topCards: [{}, {}, {}],
      bottomCard: {}
    },
    impact: {
      metrics: {
        donations: { value: '' },
        peopleHelped: { value: '' },
        wasteReduced: { value: '' },
        taxSavings: { value: '' }
      },
      infoCard: {
        title: '',
        description: ''
      }
    },
    options: {
      title: 'Donation Options',
      options: [],
      specialNote: {
        text: '',
        visible: false
      }
    },
    charities: {
      title: 'Charity Partner Profiles',
      profiles: []
    }
  }), []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    getValues,
    reset,
    trigger
  } = useForm({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  // Save form data with persistence
  const saveFormData = useCallback(async (showStatus = true) => {
    if (saving) return false;

    try {
      setSaving(true);
      const currentData = getValues();
      
      // Merge with stored data to prevent data loss
      const mergedData = {
        ...storedData,
        ...currentData,
        lastSaved: new Date().toISOString()
      };

      // Update stored data state
      setStoredData(mergedData);
      
      // Persist to localStorage
      localStorage.setItem('proposalFormData', JSON.stringify(mergedData));

      if (showStatus) {
        setSaveStatus({
          type: 'success',
          message: 'Changes saved'
        });
        setTimeout(() => setSaveStatus({ type: null, message: null }), 3000);
      }

      return true;
    } catch (error) {
      console.error('Error saving form data:', error);
      setSaveStatus({
        type: 'error',
        message: 'Failed to save changes'
      });
      return false;
    } finally {
      setSaving(false);
    }
  }, [getValues, saving, storedData]);

  // Load saved data initially
  useEffect(() => {
    const loadSavedData = async () => {
      try {
        setLoading(true);
        const savedData = localStorage.getItem('proposalFormData');

        if (savedData) {
          const parsedData = JSON.parse(savedData);
          
          // Validate data structure
          const isValidData = Object.keys(defaultValues).every(
            key => typeof parsedData[key] === 'object'
          );

          if (isValidData) {
            // Merge with defaults
            const mergedData = {
              ...defaultValues,
              ...parsedData
            };

            setStoredData(mergedData);
            await reset(mergedData);
          }
        }
      } catch (error) {
        console.error('Error loading saved data:', error);
        setSaveStatus({
          type: 'error',
          message: 'Failed to load saved data'
        });
      } finally {
        setLoading(false);
        setIsInitialized(true);
      }
    };

    loadSavedData();
  }, [defaultValues, reset]);

  // Autosave on form changes
  const formValues = watch();
  useEffect(() => {
    if (!isInitialized || !isDirty) return;

    const saveTimer = setTimeout(() => {
      saveFormData(false);
    }, 1000);

    return () => clearTimeout(saveTimer);
  }, [formValues, isInitialized, isDirty, saveFormData]);

  // Enhanced validation
  const validateStep = useCallback(async () => {
    try {
      const currentStepFields = steps[currentStep].fields;
      const isValid = await trigger(currentStepFields);

      if (!isValid) {
        setSaveStatus({
          type: 'error',
          message: 'Please fix all errors before proceeding'
        });
      }

      return isValid;
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    }
  }, [currentStep, steps, trigger]);

  // Step navigation with data persistence
  const onStepChange = useCallback(async (direction) => {
    if (loading || saving) return;

    const targetStep = direction === 'next' 
      ? currentStep + 1 
      : currentStep - 1;

    if (targetStep < 0 || targetStep >= steps.length) return;

    try {
      setSaving(true);

      // For next navigation, validate first
      if (direction === 'next') {
        const isValid = await validateStep();
        if (!isValid) {
          setSaving(false);
          return;
        }
      }

      // Save current state
      const savedSuccessfully = await saveFormData(false);
      if (!savedSuccessfully) {
        throw new Error('Failed to save form data');
      }

      // Update step and ensure data persistence
      setCurrentStep(targetStep);
      
      // Reapply stored data
      if (storedData) {
        reset(storedData);
      }

    } catch (error) {
      console.error('Navigation error:', error);
      setSaveStatus({
        type: 'error',
        message: 'Failed to navigate'
      });
    } finally {
      setSaving(false);
    }
  }, [currentStep, loading, saving, steps.length, validateStep, saveFormData, storedData, reset]);

  // Form submission
  const onSubmit = async (data) => {
    if (loading || saving) return;
    if (!selectedCompanyId) {
      setSaveStatus({
        type: 'error',
        message: 'Please select a company before creating the proposal'
      });
      return;
    }

    try {
      setLoading(true);
      
      // Final validation
      const isValid = await trigger();
      if (!isValid) {
        setSaveStatus({
          type: 'error',
          message: 'Please fix all errors before submitting'
        });
        return;
      }

      // Final save
      const savedSuccessfully = await saveFormData(false);
      if (!savedSuccessfully) {
        throw new Error('Failed to save final form data');
      }

      await proposalService.create(proposalId, {
        ...data,
        companyId: selectedCompanyId,
        status: 'draft',
        createdAt: new Date(),
        updatedAt: new Date()
      });
      
      // Clear saved data
      localStorage.removeItem('proposalFormData');
      setStoredData(null);
      
      // Reset form
      await reset(defaultValues);
      setCurrentStep(0);
      setSelectedCompanyId(null);
      
      setSaveStatus({
        type: 'success',
        message: 'Proposal created successfully!'
      });
    } catch (error) {
      console.error('Error creating proposal:', error);
      setSaveStatus({
        type: 'error',
        message: 'Failed to create proposal'
      });
    } finally {
      setLoading(false);
    }
  };

  // Loading state
  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <FaSpinner className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-purple-50 p-6">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-xl p-8"
        >
          {/* Add CompanySelector at the top */}
          <CompanySelector
            onCompanySelect={setSelectedCompanyId}
            selectedCompanyId={selectedCompanyId}
            disabled={loading || saving}
          />

          <StepHeader step={steps[currentStep]} />
          <StepIndicator steps={steps} currentStep={currentStep} />

          {/* Save Status */}
          {saveStatus.message && (
            <div
              className={`
                mb-4 p-3 rounded-lg text-sm
                ${saveStatus.type === 'success'
                  ? 'bg-green-50 text-green-700'
                  : 'bg-red-50 text-red-700'
                }
              `}
            >
              {saveStatus.message}
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <CurrentStepComponent
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              loading={loading || saving}
              proposalId={proposalId}
            />

            <div className="flex items-center justify-between pt-8 border-t border-gray-100">
              <div className="flex items-center gap-4">
                <button
                  type="button"
                  onClick={() => onStepChange('prev')}
                  disabled={currentStep === 0 || loading || saving}
                  className={`
                    px-6 py-2 rounded-lg font-medium
                    transition-all duration-200
                    ${(currentStep === 0 || loading || saving)
                      ? 'opacity-50 cursor-not-allowed bg-gray-100 text-gray-400'
                      : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                    }
                  `}
                >
                  Previous
                </button>
                {(saving || loading) && (
                  <span className="text-sm text-gray-500 flex items-center gap-2">
                    <FaSpinner className="w-4 h-4 animate-spin" />
                    {saving ? 'Saving...' : 'Processing...'}
                  </span>
                )}
              </div>

              <button
                type="button"
                onClick={async (e) => {
                  e.preventDefault();
                  if (currentStep === steps.length - 1) {
                    await handleSubmit(onSubmit)();
                  } else {
                    await onStepChange('next');
                  }
                }}
                disabled={loading || saving}
                className={`
                  px-6 py-2 rounded-lg font-medium
                  transition-all duration-200
                  ${(loading || saving)
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-purple-700'
                  }
                  bg-purple-600 text-white
                `}
              >
                {loading
                  ? 'Processing...'
                  : saving
                    ? 'Saving...'
                    : currentStep === steps.length - 1
                      ? 'Create Proposal'
                      : 'Next'
                }
              </button>
            </div>
          </form>
        </motion.div>

        {/* Preview Section */}
        <PreviewSection formData={formValues} />
      </div>
    </div>
  );
};

// Add display names for debugging
StyledInput.displayName = 'StyledInput';
StyledTextarea.displayName = 'StyledTextarea';
FileUpload.displayName = 'FileUpload';
IconSelect.displayName = 'IconSelect';
ColorPicker.displayName = 'ColorPicker';
StepIndicator.displayName = 'StepIndicator';
StepHeader.displayName = 'StepHeader';
CoverStep.displayName = 'CoverStep';
StrategyStep.displayName = 'StrategyStep';
ImpactStep.displayName = 'ImpactStep';
OptionsStep.displayName = 'OptionsStep';
CharityStep.displayName = 'CharityStep';
PreviewSection.displayName = 'PreviewSection';
CreateCustomProposal.displayName = 'CreateCustomProposal';

export default CreateCustomProposal;