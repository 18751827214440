import React, { useEffect, useState, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header';
import Sidebar from './Sidebar';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import CreateCustomProposal from './CreateCustomProposal';

import { authService } from '../services/authService';
import { userService } from '../services/userService';

const CustomProposal = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
    user: null,
    userRole: null,
    userCompany: null,
    pageData: null
  });

  // Data fetching
  const fetchData = useCallback(async () => {
    try {
      // Authentication check
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      // Fetch user data and role
      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');
      const userRole = await userService.getUserRole(currentUser.id);

      // Fetch page-specific data based on user role
      let pageData;
      if (userRole === 'SystemAdmin') {
        // Fetch system-wide data
        pageData = await fetchSystemWideData();
      } else {
        // Fetch data specific to user's distribution centers
        const userDCIds = userData.distributionCenters || [userData.distributionCenter];
        pageData = await fetchUserSpecificData(userDCIds);
      }

      setState(prev => ({
        ...prev,
        user: userData,
        userRole,
        userCompany: userData.company,
        pageData,
        loading: false,
        error: null
      }));

    } catch (error) {
      console.error('Error fetching data:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to load page data. Please try again.',
        loading: false
      }));
      toast.error('Failed to load page data. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Example functions for fetching different types of data
  const fetchSystemWideData = async () => {
    // Implement system-wide data fetching
    return {};
  };

  const fetchUserSpecificData = async (distributionCenterIds) => {
    // Implement user-specific data fetching
    return {};
  };

  // Loading state
  if (state.loading) return <Loader />;
  
  // Error state
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="app-container">
      <Header 
        title="Custom Proposal" 
        user={state.user} 
      />
      <div className="main-content">
        <Sidebar />
        <div className="page-container">
          <div className="page-content">
            {/* Page Header */}
            <div className="page-header">
              <h1>Custom Proposal</h1>
            </div>

            {/* Main Content */}
            <div className="page-sections">
              <CreateCustomProposal 
                user={state.user}
                userRole={state.userRole}
                userCompany={state.userCompany}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default CustomProposal;