// CharityExplorer.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  FaSearch, 
  FaFilter, 
  FaTh, 
  FaMapMarkedAlt, 
  FaTimes, 
  FaSpinner,
  FaChevronDown,
  FaCheck,
} from 'react-icons/fa';
import { charityService } from '../../services/charityService';
import CharityCard from './CharityCard';
import CharityMap from './CharityMap';
import CharityProfile from './CharityProfile';
import debounce from 'lodash/debounce';
import '../../styles/Charities/CharityExplorer.css';

const CharityExplorer = () => {
  // Core state
  const [charities, setCharities] = useState([]);
  const [filteredCharities, setFilteredCharities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [selectedCharity, setSelectedCharity] = useState(null);
  
  // UI state
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState('');
  const [sortOption, setSortOption] = useState('name');
  
  // Search and filter state
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    goods: [],
    causes: [],
    states: [],
    facilities: {
      hasLoadingDock: false,
      needsLiftGate: false,
      needsPalletJack: false
    },
    palletCapacity: 0,
    appointmentRequired: null,
    openNow: false
  });

  // Fetch initial data
  useEffect(() => {
    const fetchCharities = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await charityService.getAll();
        setCharities(data);
        setFilteredCharities(data); // Initially show all charities
      } catch (err) {
        setError('Failed to load charities. Please try again later.');
        console.error('Error fetching charities:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchCharities();
  }, []);

  // Memoized filter options
  const filterOptions = useMemo(() => ({
    goods: [...new Set(charities.flatMap(c => c.acceptableGoods))].sort(),
    causes: [...new Set(charities.flatMap(c => c.organizationCauses))].sort(),
    states: [...new Set(charities.flatMap(c => 
      c.locations.map(l => l.address.stateProvince)
    ))].sort(),
    maxPalletCapacity: Math.max(...charities.flatMap(c =>
      c.locations.map(l => l.palletCapacity.maximum)
    ), 0)
  }), [charities]);

  // Handler functions
  const handleClearFilters = useCallback(() => {
    setFilters({
      goods: [],
      causes: [],
      states: [],
      facilities: {
        hasLoadingDock: false,
        needsLiftGate: false,
        needsPalletJack: false
      },
      palletCapacity: 0,
      appointmentRequired: null,
      openNow: false
    });
    setSearchTerm('');
    setFilteredCharities(charities); // Reset to show all charities
  }, [charities]);

  const handleFilterChange = useCallback((key, value) => {
    setFilters(prev => {
      if (key.includes('.')) {
        const [parent, child] = key.split('.');
        return {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        };
      }
      return { ...prev, [key]: value };
    });
  }, []);

  const handleViewDetails = useCallback((charityId) => {
    const charity = charities.find(c => c.id === charityId);
    setSelectedCharity(charity);
  }, [charities]);

  // Debounced search function
  const debouncedSearch = useMemo(
    () => debounce((value) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  // Clean up debounced function
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Filter logic
  const applyFilters = useCallback(() => {
    let result = charities;

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(charity => 
        charity.orgName.toLowerCase().includes(searchLower) ||
        charity.description.toLowerCase().includes(searchLower) ||
        charity.organizationCauses.some(cause => 
          cause.toLowerCase().includes(searchLower)
        )
      );
    }

    // Apply filters only if they're set
    if (filters.goods.length > 0) {
      result = result.filter(charity =>
        filters.goods.some(good => charity.acceptableGoods.includes(good))
      );
    }

    if (filters.causes.length > 0) {
      result = result.filter(charity =>
        filters.causes.some(cause => charity.organizationCauses.includes(cause))
      );
    }

    if (filters.states.length > 0) {
      result = result.filter(charity =>
        charity.locations.some(loc => 
          filters.states.includes(loc.address.stateProvince)
        )
      );
    }

    const { facilities } = filters;
    if (Object.values(facilities).some(v => v)) {
      result = result.filter(charity =>
        charity.locations.some(loc => {
          if (facilities.hasLoadingDock && !loc.facilities.hasLoadingDock) return false;
          if (facilities.needsLiftGate && !loc.facilities.needsLiftGate) return false;
          if (facilities.needsPalletJack && !loc.facilities.needsPalletJack) return false;
          return true;
        })
      );
    }

    if (filters.palletCapacity > 0) {
      result = result.filter(charity =>
        charity.locations.some(loc => 
          loc.palletCapacity.maximum >= filters.palletCapacity
        )
      );
    }

    if (filters.appointmentRequired !== null) {
      result = result.filter(charity =>
        charity.locations.some(loc => 
          loc.appointmentRequired === filters.appointmentRequired
        )
      );
    }

    if (filters.openNow) {
      const now = new Date();
      const day = now.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
      const time = now.toLocaleTimeString('en-US', { 
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit' 
      });

      result = result.filter(charity =>
        charity.locations.some(loc => {
          const hours = loc.hours[day];
          if (!hours || hours.toLowerCase() === 'closed') return false;
          const [open, close] = hours.split('-');
          return time >= open && time <= close;
        })
      );
    }

    // Apply sorting
    result = sortCharities(result);
    
    setFilteredCharities(result);
  }, [charities, searchTerm, filters]);

  // Sort function
  const sortCharities = useCallback((charitiesToSort) => {
    switch (sortOption) {
      case 'name':
        return [...charitiesToSort].sort((a, b) => a.orgName.localeCompare(b.orgName));
      case 'locations':
        return [...charitiesToSort].sort((a, b) => b.locations.length - a.locations.length);
      case 'capacity':
        return [...charitiesToSort].sort((a, b) => {
          const aCapacity = Math.max(...a.locations.map(l => l.palletCapacity.maximum));
          const bCapacity = Math.max(...b.locations.map(l => l.palletCapacity.maximum));
          return bCapacity - aCapacity;
        });
      case 'updated':
        return [...charitiesToSort].sort((a, b) => 
          new Date(b.updatedAt) - new Date(a.updatedAt)
        );
      default:
        return charitiesToSort;
    }
  }, [sortOption]);

  // Effect to apply filters when dependencies change
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  // Custom Dropdown Component
  const Dropdown = ({ label, value, options, onChange, multiple = false }) => {
    const isOpen = dropdownOpen === label;
    
    return (
      <div className="custom-dropdown">
        <button
          className="dropdown-trigger"
          onClick={() => setDropdownOpen(isOpen ? '' : label)}
        >
          <span>{value?.length ? `${value.length} selected` : label}</span>
          <FaChevronDown className={`icon ${isOpen ? 'open' : ''}`} />
        </button>
        
        {isOpen && (
          <div className="dropdown-content">
            {options.map(option => (
              <div
                key={option}
                className="dropdown-item"
                onClick={() => {
                  if (multiple) {
                    const newValue = value.includes(option)
                      ? value.filter(v => v !== option)
                      : [...value, option];
                    onChange(newValue);
                  } else {
                    onChange(option);
                    setDropdownOpen('');
                  }
                }}
              >
                {multiple && (
                  <span className="checkbox">
                    {value.includes(option) && <FaCheck />}
                  </span>
                )}
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Custom Toggle Component
  const Toggle = ({ label, checked, onChange }) => (
    <div className="custom-toggle">
      <span>{label}</span>
      <button
        className={`toggle-button ${checked ? 'active' : ''}`}
        onClick={() => onChange(!checked)}
        type="button"
        role="switch"
        aria-checked={checked}
      />
    </div>
  );

  // Filter Drawer Component
  const FilterDrawer = () => (
    <div className={`filter-drawer ${isFilterDrawerOpen ? 'open' : ''}`}>
      <div className="filter-header">
        <h3>Filters</h3>
        <button 
          className="close-button"
          onClick={() => setIsFilterDrawerOpen(false)}
        >
          <FaTimes />
        </button>
      </div>

      <div className="filter-content">
        {/* Active filters */}
        {(searchTerm || Object.values(filters).some(val => 
          Array.isArray(val) ? val.length > 0 : val
        )) && (
          <div className="active-filters">
            {searchTerm && (
              <span className="filter-tag">
                Search: {searchTerm}
                <FaTimes onClick={() => setSearchTerm('')} />
              </span>
            )}
            <button 
              className="clear-filters"
              onClick={handleClearFilters}
            >
              Clear all filters
            </button>
          </div>
        )}

        {/* Filter sections */}
        <div className="filter-section">
          <h4>Acceptable Goods</h4>
          <Dropdown
            label="Select goods"
            value={filters.goods}
            options={filterOptions.goods}
            onChange={(value) => handleFilterChange('goods', value)}
            multiple
          />
        </div>

        <div className="filter-section">
          <h4>Causes</h4>
          <Dropdown
            label="Select causes"
            value={filters.causes}
            options={filterOptions.causes}
            onChange={(value) => handleFilterChange('causes', value)}
            multiple
          />
        </div>

        <div className="filter-section">
          <h4>States</h4>
          <Dropdown
            label="Select states"
            value={filters.states}
            options={filterOptions.states}
            onChange={(value) => handleFilterChange('states', value)}
            multiple
          />
        </div>

        <div className="filter-section">
          <h4>Facilities</h4>
          <Toggle
            label="Has Loading Dock"
            checked={filters.facilities.hasLoadingDock}
            onChange={(checked) => handleFilterChange('facilities.hasLoadingDock', checked)}
          />
          <Toggle
            label="Needs Lift Gate"
            checked={filters.facilities.needsLiftGate}
            onChange={(checked) => handleFilterChange('facilities.needsLiftGate', checked)}
          />
          <Toggle
            label="Needs Pallet Jack"
            checked={filters.facilities.needsPalletJack}
            onChange={(checked) => handleFilterChange('facilities.needsPalletJack', checked)}
          />
        </div>

        <div className="filter-section">
          <h4>Minimum Pallet Capacity: {filters.palletCapacity}</h4>
          <input
            type="range"
            min="0"
            max={filterOptions.maxPalletCapacity}
            value={filters.palletCapacity}
            onChange={(e) => handleFilterChange('palletCapacity', Number(e.target.value))}
            className="custom-slider"
          />
        </div>

        <div className="filter-section">
          <Toggle
            label="Open Now"
            checked={filters.openNow}
            onChange={(checked) => handleFilterChange('openNow', checked)}
          />
          <Toggle
            label="Appointment Required"
            checked={filters.appointmentRequired}
            onChange={(checked) => handleFilterChange('appointmentRequired', checked)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="charity-explorer">
      {/* Header section */}
      <div className="explorer-header">
        <div className="search-bar">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search charities..."
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </div>

        <button 
          className="filter-button"
          onClick={() => setIsFilterDrawerOpen(true)}
        >
          <FaFilter />
          <span>Filters</span>
          {Object.values(filters).some(val => 
            Array.isArray(val) ? val.length > 0 : val
          ) && (
            <span className="filter-count">
              {Object.values(filters).reduce((count, val) => 
                count + (Array.isArray(val) ? (val.length > 0 ? 1 : 0) : (val ? 1 : 0)), 0
              )}
            </span>
          )}
        </button>

        <div className="view-toggle">
          <button
            className={viewMode === 'list' ? 'active' : ''}
            onClick={() => setViewMode('list')}
            title="List view"
          >
            <FaTh />
          </button>
          <button
            className={viewMode === 'map' ? 'active' : ''}
            onClick={() => setViewMode('map')}
            title="Map view"
          >
            <FaMapMarkedAlt />
          </button>
        </div>
      </div>

      {/* Results count and sort */}
      <div className="results-header">
        <span className="results-count">
          {filteredCharities.length} 
          {filteredCharities.length === 1 ? ' charity' : ' charities'} found
        </span>

        <Dropdown
            label="Sort by"
            value={[sortOption]}
            options={['name', 'locations', 'capacity', 'updated']} // Changed to simple strings
            onChange={(value) => setSortOption(value[0])}
            />
      </div>

      {/* Main content */}
      {error ? (
        <div className="error-state">
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>
            Retry
          </button>
        </div>
      ) : loading ? (
        <div className="loading-state">
          <FaSpinner className="spinner" />
          <p>Loading charities...</p>
        </div>
      ) : filteredCharities.length === 0 ? (
        <div className="empty-state">
          <FaSearch className="empty-icon" />
          <h3>No charities found</h3>
          <p>Try adjusting your filters or search terms</p>
          <button onClick={handleClearFilters}>
            Clear all filters
          </button>
        </div>
      ) : (
        <div className={`results-grid ${viewMode}`}>
          {viewMode === 'list' ? (
            filteredCharities.map(charity => (
              <CharityCard
                key={charity.id}
                charity={charity}
                onViewDetails={handleViewDetails}
              />
            ))
          ) : (
            <CharityMap
              charities={filteredCharities}
              onSelectCharity={handleViewDetails}
            />
          )}
        </div>
      )}

      {/* Filter drawer */}
      {isFilterDrawerOpen && <FilterDrawer />}

      {/* Charity profile modal */}
      {selectedCharity && (
        <CharityProfile
          charity={selectedCharity}
          onClose={() => setSelectedCharity(null)}
        />
      )}

      {/* Backdrop for drawer */}
      {isFilterDrawerOpen && (
        <div 
          className="backdrop"
          onClick={() => setIsFilterDrawerOpen(false)}
        />
      )}
    </div>
  );
};

export default CharityExplorer;