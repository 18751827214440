import React, { useState, useEffect, useCallback } from 'react';
import { 
  FaUpload, FaFileAlt, FaTrash, FaSpinner, FaBuilding, 
  FaClipboard, FaCalendarAlt, FaDollarSign, FaPallet, 
  FaBalanceScale, FaClock, FaBoxOpen,
  FaListAlt, FaPlus, FaMinus, FaWeight, FaUserShield 
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { companyService } from '../services/companyService';
import { userService } from '../services/userService';
import { messageService } from '../services/messageService';

const UNITS_OF_MEASURE = [
  'Cases', 'Pounds', 'Each', 'Boxes', 'Bags', 'Bundles', 'Drums', 'Gallons', 'Other'
];

// Utility functions for formatting and validation
const formatCurrency = (amount) => {
  if (!amount) return '';
  const numericValue = typeof amount === 'string' ? parseFloat(amount.replace(/[$,]/g, '')) : amount;
  if (isNaN(numericValue)) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(numericValue);
};

const formatNumber = (value) => {
  if (!value) return '';
  const numericValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
  if (isNaN(numericValue)) return '';
  return new Intl.NumberFormat('en-US').format(numericValue);
};

const stripFormatting = (value) => {
  if (!value) return '';
  return value.toString().replace(/[$,]/g, '');
};

// Form Components
function SelectField({ label, icon: Icon, value, onChange, options, disabled, required, placeholder }) {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-semibold mb-2">
        <Icon className="inline-block mr-2" /> {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        className="w-full px-3 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 
                 disabled:bg-gray-100 disabled:cursor-not-allowed transition duration-150 ease-in-out"
      >
        <option value="">{placeholder || `Select ${label}`}</option>
        {options.map(option => (
          <option key={option.id || option} value={option.id || option}>
            {option.name || option}
          </option>
        ))}
      </select>
    </div>
  );
}

function FileUpload({ onFileUpload, fileName, onRemoveFile }) {
  const handleTemplateDownload = () => {
    // Create sample data
    const sampleData = [
      {
        sku: 'SAMPLE-001',
        description: 'Sample Product Description',
        quantity: '100',
        unitofmeasure: 'Cases',
        estimatedvalue: '1000.00',
        palletcount: '1',
        costbasis: '800.00',
        expirationdate: '2025-12-31',
        totalweight: '500'
      }
    ];

    // Convert to CSV
    const csvContent = Papa.unparse({
      fields: [
        'SKU',
        'Description',
        'Quantity',
        'UnitOfMeasure',
        'EstimatedValue',
        'PalletCount',
        'CostBasis',
        'ExpirationDate',
        'TotalWeight'
      ],
      data: sampleData
    });

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'donation_template.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="mb-6 p-6 border-2 border-dashed rounded-lg border-gray-300 bg-gray-50">
      <div className="flex flex-col items-center">
        <FaUpload className="text-3xl text-gray-400 mb-2" />
        
        <div className="flex flex-col sm:flex-row items-center gap-4 mb-4">
          <button
            type="button"
            onClick={handleTemplateDownload}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm 
                     text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 
                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <FaFileAlt className="mr-2" /> Download Template
          </button>
          
          <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 
                         transition duration-150 ease-in-out inline-flex items-center">
            <FaUpload className="mr-2" />
            Choose CSV File
            <input
              type="file"
              accept=".csv"
              onChange={onFileUpload}
              className="hidden"
            />
          </label>
        </div>

        <div className="text-sm text-gray-600 mb-4">
          <h4 className="font-medium mb-2">Required Fields:</h4>
          <ul className="list-disc list-inside grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-1">
            <li>SKU</li>
            <li>Description</li>
            <li>Quantity</li>
            <li>Unit of Measure</li>
            <li>Estimated Value</li>
            <li>Pallet Count</li>
            <li>Cost Basis</li>
          </ul>
          <h4 className="font-medium mt-3 mb-2">Optional Fields:</h4>
          <ul className="list-disc list-inside grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-1">
            <li>Expiration Date (YYYY-MM-DD)</li>
            <li>Total Weight (in lbs)</li>
          </ul>
        </div>

        {fileName && (
          <div className="flex items-center gap-2 text-sm text-gray-600 bg-white px-3 py-2 rounded-md border border-gray-200">
            <FaFileAlt />
            <span>{fileName}</span>
            <button
              onClick={onRemoveFile}
              className="text-red-500 hover:text-red-600 p-1 ml-2"
              aria-label="Remove file"
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function FormField({ label, icon: Icon, value, onChange, className, type = "text", required = true, options = null }) {
  const baseClassName = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-gray-100";
  
  return (
    <div className={`form-field ${className}`}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {Icon && <Icon className="inline-block mr-2" />}
        {label}
        {!required && <span className="text-gray-500 text-xs ml-2">(Optional)</span>}
      </label>
      {options ? (
        <select
          value={value}
          onChange={onChange}
          className={baseClassName}
          required={required}
        >
          <option value="">Select {label}</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          value={value}
          onChange={onChange}
          className={baseClassName}
          required={required}
        />
      )}
    </div>
  );
}

function ManualEntryForm({ items, onItemChange, onAddItem, onRemoveItem }) {
  return (
    <div className="space-y-6">
      {items.map((item, index) => (
        <div key={index} className="p-6 bg-white rounded-lg shadow-sm border border-gray-200">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Item {index + 1}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* SKU Field */}
            <FormField
              label="SKU"
              icon={FaBoxOpen}
              value={item.sku}
              onChange={(e) => onItemChange(index, 'sku', e.target.value)}
              required={true}
            />

            {/* Description Field */}
            <FormField
              label="Description"
              icon={FaFileAlt}
              value={item.description}
              onChange={(e) => onItemChange(index, 'description', e.target.value)}
              required={true}
            />

            {/* Quantity Field */}
            <FormField
              label="Quantity"
              icon={FaListAlt}
              value={item.quantity}
              onChange={(e) => onItemChange(index, 'quantity', e.target.value)}
              required={true}
            />

            {/* Unit of Measure Field */}
            <FormField
              label="Unit of Measure"
              icon={FaBalanceScale}
              value={item.unitOfMeasure}
              onChange={(e) => onItemChange(index, 'unitOfMeasure', e.target.value)}
              required={true}
              options={UNITS_OF_MEASURE}
            />

            {/* Estimated Value Field */}
            <FormField
              label="Estimated Value"
              icon={FaDollarSign}
              value={item.estimatedValue}
              onChange={(e) => onItemChange(index, 'estimatedValue', e.target.value)}
              required={true}
            />

            {/* Pallet Count Field */}
            <FormField
              label="Pallet Count"
              icon={FaPallet}
              value={item.palletCount}
              onChange={(e) => onItemChange(index, 'palletCount', e.target.value)}
              required={true}
            />

            {/* Expiration Date Field */}
            <FormField
              label="Expiration Date"
              icon={FaCalendarAlt}
              value={item.expirationDate}
              onChange={(e) => onItemChange(index, 'expirationDate', e.target.value)}
              type="date"
              required={false}
            />

            {/* Total Weight Field */}
            <FormField
              label="Total Weight (lbs)"
              icon={FaWeight}
              value={item.totalWeight}
              onChange={(e) => onItemChange(index, 'totalWeight', e.target.value)}
              required={false}
            />

            {/* Cost Basis Field */}
            <FormField
              label="Cost Basis"
              icon={FaDollarSign}
              value={item.costBasis}
              onChange={(e) => onItemChange(index, 'costBasis', e.target.value)}
              required={true}
            />

            {/* Additional fields following the same pattern... */}
            
            {items.length > 1 && (
              <button
                type="button"
                onClick={() => onRemoveItem(index)}
                className="col-span-full mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                         rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 
                         focus:ring-offset-2 focus:ring-red-500"
              >
                <FaMinus className="mr-2" /> Remove Item
              </button>
            )}
          </div>
        </div>
      ))}
      
      <button
        type="button"
        onClick={onAddItem}
        className="w-full mt-4 inline-flex justify-center items-center px-4 py-2 border border-transparent 
                 text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 
                 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <FaPlus className="mr-2" /> Add Another Item
      </button>
    </div>
  );
}

function CsvPreview({ data }) {
  if (!data.length) return null;

  const formatValue = (key, value) => {
    if (!value) return 'N/A';
    
    switch(key.toLowerCase()) {
      case 'estimatedvalue':
      case 'costbasis':
        return formatCurrency(value);
      case 'quantity':
      case 'palletcount':
        return formatNumber(value);
      case 'totalweight':
        return value ? `${formatNumber(value)} lbs` : 'N/A';
      case 'expirationdate':
        return value ? new Date(value).toLocaleDateString() : 'N/A';
      default:
        return value;
    }
  };

  return (
    <div className="mt-8 bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-medium text-gray-900">
          <FaListAlt className="inline-block mr-2" /> CSV Preview
        </h3>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Est. Value</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pallets</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Weight</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Exp. Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cost Basis</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.sku}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.description}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('quantity', row.quantity)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.unitofmeasure}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('estimatedvalue', row.estimatedvalue)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('palletcount', row.palletcount)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('totalweight', row.totalweight)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('expirationdate', row.expirationdate)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{formatValue('costbasis', row.costbasis)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="px-6 py-4 border-t border-gray-200">
        <p className="text-sm text-gray-500">Showing all {data.length} items</p>
      </div>
    </div>
  );
}

function PendingDonations({ donations }) {
  if (!donations.length) {
    return (
      <div className="mt-8 bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          <FaClock className="inline-block mr-2" /> Pending Donations
        </h3>
        <p className="text-gray-500">No pending donations</p>
      </div>
    );
  }

  return (
    <div className="mt-8 bg-white rounded-lg shadow overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <h3 className="text-lg font-medium text-gray-900">
          <FaClock className="inline-block mr-2" /> Pending Donations
        </h3>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Value</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Pallets</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Weight</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {donations.map((donation, index) => (
              <tr key={donation.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {new Date(donation.createdAt.seconds * 1000).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatCurrency(donation.totalEstimatedValue)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {formatNumber(donation.totalPalletCount)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {donation.totalWeight ? `${formatNumber(donation.totalWeight)} lbs` : 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    {donation.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function StartNewDonation() {
  // State management
  const [user, setUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [pendingDonations, setPendingDonations] = useState([]);
  const [selectedDC, setSelectedDC] = useState('');
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [donationMode, setDonationMode] = useState('manual');
  const [items, setItems] = useState([{
    sku: '',
    description: '',
    quantity: '',
    unitOfMeasure: '',
    estimatedValue: '',
    palletCount: '',
    expirationDate: '',
    totalWeight: '',
    costBasis: ''
  }]);

  // Fetch data functions
  const fetchPendingDonations = useCallback(async (distributionCenterId) => {
    try {
      const donations = await donationService.getByDistributionCenter(distributionCenterId);
      setPendingDonations(donations.filter(d => d.status?.toLowerCase() === 'pending'));
    } catch (error) {
      console.error('Error fetching pending donations:', error);
      toast.error('Failed to fetch pending donations');
    }
  }, []);

  const fetchDistributionCenters = async (companyId) => {
    try {
      const dcs = await distributionCenterService.getByCompany(companyId);
      setDistributionCenters(dcs);
      setSelectedDC('');
    } catch (error) {
      console.error('Error fetching distribution centers:', error);
      toast.error('Failed to fetch distribution centers');
    }
  };

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const currentUser = authService.getUserSession();
        if (!currentUser) {
          toast.error('Please log in to view this page');
          return;
        }

        const fullUserData = await userService.getById(currentUser.id);
        setUser(fullUserData);

        if (fullUserData.role === 'SystemAdmin') {
          const allCompanies = await companyService.getAll();
          setCompanies(allCompanies);
        } else {
          const userDCs = fullUserData.distributionCenters || [fullUserData.distributionCenter];
          if (userDCs.length > 0) {
            const dcs = await Promise.all(userDCs.map(dcId => 
              distributionCenterService.getById(dcId)
            ));
            setDistributionCenters(dcs);
            setSelectedDC(userDCs[0]);
            await fetchPendingDonations(userDCs[0]);
          } else {
            toast.error('No distribution centers assigned');
          }
        }
      } catch (error) {
        console.error('Error loading initial data:', error);
        toast.error('Failed to load initial data');
      }
    };

    loadInitialData();
  }, [fetchPendingDonations]);

  // Event handlers
  const handleCompanyChange = async (e) => {
    const newCompanyId = e.target.value;
    setSelectedCompany(newCompanyId);
    if (newCompanyId) {
      await fetchDistributionCenters(newCompanyId);
    } else {
      setDistributionCenters([]);
      setSelectedDC('');
    }
  };

  const handleDCChange = async (e) => {
    const newDC = e.target.value;
    setSelectedDC(newDC);
    if (newDC) {
      await fetchPendingDonations(newDC);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    setFileName(file.name);
    setCsvData([]);

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => {
        // Normalize headers by removing spaces and making lowercase
        return header.toLowerCase().replace(/\s+/g, '');
      },
      complete: (result) => {
        if (result.data && result.data.length > 0) {
          const validationErrors = validateCsvData(result.data);
          if (validationErrors.length > 0) {
            toast.error('CSV Validation Errors:');
            validationErrors.forEach(error => toast.error(error));
            setCsvData([]);
            setFileName('');
          } else {
            setCsvData(result.data);
          }
        } else {
          toast.error('The CSV file is empty or invalid');
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        toast.error(`Error parsing CSV: ${error.message}`);
        setCsvData([]);
        setFileName('');
      }
    });
  };

  const validateCsvData = (data) => {
    const requiredFields = ['sku', 'description', 'quantity', 'unitofmeasure', 'estimatedvalue', 'palletcount', 'costbasis'];
    const errors = [];

    // Check if all required fields are present in headers
    const headers = Object.keys(data[0]).map(h => h.toLowerCase());
    requiredFields.forEach(field => {
      if (!headers.includes(field)) {
        errors.push(`Missing required column: ${field}`);
      }
    });

    if (errors.length > 0) return errors;

    data.forEach((row, index) => {
      const rowNum = index + 1; // Human-readable row number

      // Required field validation
      requiredFields.forEach(field => {
        const error = validateRequired(row[field], field, rowNum);
        if (error) errors.push(error);
      });

      // Number validations with proper error handling
      const numberValidations = [
        { field: 'quantity', label: 'Quantity', allowZero: false },
        { field: 'estimatedvalue', label: 'Estimated Value', allowZero: false },
        { field: 'palletcount', label: 'Pallet Count', allowZero: true },
        { field: 'costbasis', label: 'Cost Basis', allowZero: true }
      ];

      numberValidations.forEach(({ field, label, allowZero }) => {
        const value = row[field];
        if (value !== undefined && value !== '') {
          const error = validateNumber(value, label, rowNum, allowZero);
          if (error) errors.push(error);
        }
      });

      // Unit of Measure validation
      if (row.unitofmeasure) {
        if (!UNITS_OF_MEASURE.map(u => u.toLowerCase()).includes(row.unitofmeasure.toLowerCase())) {
          errors.push(`Row ${rowNum}: Invalid unit of measure. Must be one of: ${UNITS_OF_MEASURE.join(', ')}`);
        }
      }

      // Optional field validations
      if (row.expirationdate) {
        const error = validateDate(row.expirationdate, rowNum);
        if (error) errors.push(error);
      }

      if (row.totalweight !== undefined && row.totalweight !== '') {
        const error = validateNumber(row.totalweight, 'Total Weight', rowNum, true);
        if (error) errors.push(error);
      }
    });

    return errors;
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName('');
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    
    if (['estimatedValue', 'costBasis'].includes(field)) {
      const strippedValue = value.replace(/[^\d.]/g, '');
      const decimalCount = (strippedValue.match(/\./g) || []).length;
      if (decimalCount <= 1) {
        const parts = strippedValue.split('.');
        if (parts[1] && parts[1].length > 2) {
          parts[1] = parts[1].slice(0, 2);
          newItems[index][field] = parts.join('.');
        } else {
          newItems[index][field] = strippedValue;
        }
      }
    } else if (['quantity', 'palletCount', 'totalWeight'].includes(field)) {
      const strippedValue = stripFormatting(value);
      if (strippedValue === '' || (!isNaN(strippedValue) && parseFloat(strippedValue) >= 0)) {
        newItems[index][field] = strippedValue;
      }
    } else {
      newItems[index][field] = value;
    }
    
    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([...items, {
      sku: '',
      description: '',
      quantity: '',
      unitOfMeasure: '',
      estimatedValue: '',
      palletCount: '',
      expirationDate: '',
      totalWeight: '',
      costBasis: ''
    }]);
  };

  const handleRemoveItem = (index) => {
    if (items.length > 1) {
      setItems(items.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDC || (user.role === 'SystemAdmin' && !selectedCompany)) {
      toast.error('Please select all required fields');
      return;
    }

    if (donationMode === 'automatic' && csvData.length === 0) {
      toast.error('Please upload a valid CSV file');
      return;
    }

    setIsLoading(true);

    try {
      const donationItems = donationMode === 'automatic'
        ? csvData.map(item => ({
            itemID: uuidv4(),
            sku: item.sku,
            description: item.description,
            quantity: parseFloat(stripFormatting(item.quantity)),
            unitOfMeasure: item.unitofmeasure,
            estimatedValue: parseFloat(stripFormatting(item.estimatedvalue)),
            palletCount: parseInt(stripFormatting(item.palletcount)),
            expirationDate: item.expirationdate ? new Date(item.expirationdate) : null,
            totalWeight: item.totalweight ? parseFloat(stripFormatting(item.totalweight)) : null,
            costBasis: parseFloat(stripFormatting(item.costbasis)),
            status: 'Pending'
          }))
        : items.map(item => ({
            itemID: uuidv4(),
            ...item,
            quantity: parseFloat(stripFormatting(item.quantity)),
            estimatedValue: parseFloat(stripFormatting(item.estimatedValue)),
            palletCount: parseInt(stripFormatting(item.palletCount)),
            totalWeight: item.totalWeight ? parseFloat(stripFormatting(item.totalWeight)) : null,
            costBasis: parseFloat(stripFormatting(item.costBasis)),
            status: 'Pending'
          }));

      const donationData = {
        companyId: user.role === 'SystemAdmin' ? selectedCompany : user.company,
        distributionCenterId: selectedDC,
        createdBy: user.id,
        status: 'Pending',
        items: donationItems,
        totalEstimatedValue: donationItems.reduce((sum, item) => sum + item.estimatedValue, 0),
        totalPalletCount: donationItems.reduce((sum, item) => sum + item.palletCount, 0),
        totalCostBasis: donationItems.reduce((sum, item) => sum + item.costBasis, 0),
        totalWeight: donationItems.reduce((sum, item) => sum + (item.totalWeight || 0), 0),
        notes: notes || null
      };

      const newDonationId = await donationService.create(donationData);
      
      if (user.phone) {
        const dc = distributionCenters.find(dc => dc.id === selectedDC);
        try {
          await messageService.sendDonationCreatedNotification(user.phone, {
            ...donationData,
            id: newDonationId,
            distributionCenterName: dc.name
          });
        } catch (error) {
          console.error('Error sending notification:', error);
        }
      }

      toast.success('Donation submitted successfully!');
      
      // Reset form
      if (donationMode === 'automatic') {
        setCsvData([]);
        setFileName('');
      } else {
        setItems([{
          sku: '',
          description: '',
          quantity: '',
          unitOfMeasure: '',
          estimatedValue: '',
          palletCount: '',
          expirationDate: '',
          totalWeight: '',
          costBasis: ''
        }]);
      }
      
      setNotes('');
      await fetchPendingDonations(selectedDC);
    } catch (error) {
      console.error('Error creating donation:', error);
      toast.error('Failed to create donation');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) return <Loader />;

  return (
    <div className="min-h-screen bg-gray-100">
      <Header user={user} />
      <div className="flex">
        <Sidebar />
        <main className="flex-1 p-8 pl-64">
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h1 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
                <FaBoxOpen className="mr-3" /> Start New Donation
              </h1>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                {user.role === 'SystemAdmin' && (
                  <SelectField
                    label="Company"
                    icon={FaUserShield}
                    value={selectedCompany}
                    onChange={handleCompanyChange}
                    options={companies}
                    required
                    placeholder="Select Company"
                  />
                )}

                <SelectField
                  label="Distribution Center"
                  icon={FaBuilding}
                  value={selectedDC}
                  onChange={handleDCChange}
                  options={distributionCenters}
                  disabled={user.role === 'SystemAdmin' && !selectedCompany}
                  required
                  placeholder="Select Distribution Center"
                />

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      <FaListAlt className="inline-block mr-2" /> Donation Mode
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      <button
                        type="button"
                        onClick={() => setDonationMode('automatic')}
                        className={`p-4 text-center rounded-lg border ${
                          donationMode === 'automatic'
                            ? 'bg-blue-50 border-blue-500 text-blue-700'
                            : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <FaUpload className="mx-auto mb-2" />
                        Automatic (CSV)
                      </button>
                      <button
                        type="button"
                        onClick={() => setDonationMode('manual')}
                        className={`p-4 text-center rounded-lg border ${
                          donationMode === 'manual'
                            ? 'bg-blue-50 border-blue-500 text-blue-700'
                            : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'
                        }`}
                      >
                        <FaFileAlt className="mx-auto mb-2" />
                        Manual Entry
                      </button>
                    </div>
                  </div>

                  {donationMode === 'automatic' ? (
                    <FileUpload
                      onFileUpload={handleFileUpload}
                      fileName={fileName}
                      onRemoveFile={handleRemoveFile}
                    />
                  ) : (
                    <ManualEntryForm
                      items={items}
                      onItemChange={handleItemChange}
                      onAddItem={handleAddItem}
                      onRemoveItem={handleRemoveItem}
                    />
                  )}

                  <div className="mt-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      <FaClipboard className="inline-block mr-2" /> Additional Notes
                      <span className="text-gray-500 text-xs ml-2">(Optional)</span>
                    </label>
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      rows="4"
                      className="w-full px-3 py-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter any additional notes here..."
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading || 
                      (donationMode === 'automatic' && csvData.length === 0) || 
                      !selectedDC || 
                      (user.role === 'SystemAdmin' && !selectedCompany)}
                    className="w-full flex justify-center items-center px-4 py-2 border border-transparent 
                             text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                             disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-150"
                  >
                    {isLoading ? (
                      <>
                        <FaSpinner className="animate-spin mr-2" /> Submitting...
                      </>
                    ) : (
                      <>
                        <FaBoxOpen className="mr-2" /> Submit Donation
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>

            {csvData.length > 0 && donationMode === 'automatic' && (
              <CsvPreview data={csvData} />
            )}

            <PendingDonations donations={pendingDonations} />
          </div>
        </main>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
}

// Validation Functions
const validateRequired = (value, fieldName, rowIndex) => {
  if (!value || value.toString().trim() === '') {
    return `Row ${rowIndex}: Missing ${fieldName}`;
  }
  return null;
};

const validateNumber = (value, fieldName, rowIndex, allowZero = false) => {
  const num = parseFloat(stripFormatting(value));
  if (isNaN(num) || (!allowZero && num <= 0) || (allowZero && num < 0)) {
    return `Row ${rowIndex}: Invalid ${fieldName}`;
  }
  return null;
};

const validateDate = (value, rowIndex) => {
  if (!value) return null;
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return `Row ${rowIndex}: Invalid date format`;
  }
  return null;
};

const validateUnitOfMeasure = (value, rowIndex) => {
  if (!UNITS_OF_MEASURE.includes(value)) {
    return `Row ${rowIndex}: Invalid unit of measure. Must be one of: ${UNITS_OF_MEASURE.join(', ')}`;
  }
  return null;
};

const validateCsvData = (data) => {
  const errors = [];
  const requiredFields = [
    { field: 'sku', label: 'SKU' },
    { field: 'description', label: 'Description' },
    { field: 'quantity', label: 'Quantity' },
    { field: 'unitofmeasure', label: 'Unit of Measure' },
    { field: 'estimatedvalue', label: 'Estimated Value' },
    { field: 'palletcount', label: 'Pallet Count' },
    { field: 'costbasis', label: 'Cost Basis' }
  ];

  data.forEach((row, index) => {
    const rowNum = index + 2; // Add 2 to account for header row and 0-based index

    // Required field validation
    requiredFields.forEach(({ field, label }) => {
      const error = validateRequired(row[field], label, rowNum);
      if (error) errors.push(error);
    });

    // Number validations
    const numberValidations = [
      { field: 'quantity', label: 'Quantity', allowZero: false },
      { field: 'estimatedvalue', label: 'Estimated Value', allowZero: false },
      { field: 'palletcount', label: 'Pallet Count', allowZero: true },
      { field: 'costbasis', label: 'Cost Basis', allowZero: true }
    ];

    numberValidations.forEach(({ field, label, allowZero }) => {
      if (row[field]) {
        const error = validateNumber(row[field], label, rowNum, allowZero);
        if (error) errors.push(error);
      }
    });

    // Unit of Measure validation
    if (row.unitofmeasure) {
      const error = validateUnitOfMeasure(row.unitofmeasure, rowNum);
      if (error) errors.push(error);
    }

    // Optional field validations
    if (row.expirationdate) {
      const error = validateDate(row.expirationdate, rowNum);
      if (error) errors.push(error);
    }

    if (row.totalweight) {
      const error = validateNumber(row.totalweight, 'Total Weight', rowNum, true);
      if (error) errors.push(error);
    }
  });

  return errors;
};

const validateManualEntry = (items) => {
  const errors = [];
  
  items.forEach((item, index) => {
    const rowNum = index + 1;

    // Required field validation
    const requiredFields = [
      { field: 'sku', label: 'SKU' },
      { field: 'description', label: 'Description' },
      { field: 'quantity', label: 'Quantity' },
      { field: 'unitOfMeasure', label: 'Unit of Measure' },
      { field: 'estimatedValue', label: 'Estimated Value' },
      { field: 'palletCount', label: 'Pallet Count' },
      { field: 'costBasis', label: 'Cost Basis' }
    ];

    requiredFields.forEach(({ field, label }) => {
      const error = validateRequired(item[field], label, rowNum);
      if (error) errors.push(error);
    });

    // Number validations
    if (item.quantity) {
      const error = validateNumber(item.quantity, 'Quantity', rowNum, false);
      if (error) errors.push(error);
    }

    if (item.estimatedValue) {
      const error = validateNumber(item.estimatedValue, 'Estimated Value', rowNum, false);
      if (error) errors.push(error);
    }

    if (item.palletCount) {
      const error = validateNumber(item.palletCount, 'Pallet Count', rowNum, true);
      if (error) errors.push(error);
    }

    if (item.costBasis) {
      const error = validateNumber(item.costBasis, 'Cost Basis', rowNum, true);
      if (error) errors.push(error);
    }

    // Unit of Measure validation
    if (item.unitOfMeasure) {
      const error = validateUnitOfMeasure(item.unitOfMeasure, rowNum);
      if (error) errors.push(error);
    }

    // Optional field validations
    if (item.expirationDate) {
      const error = validateDate(item.expirationDate, rowNum);
      if (error) errors.push(error);
    }

    if (item.totalWeight) {
      const error = validateNumber(item.totalWeight, 'Total Weight', rowNum, true);
      if (error) errors.push(error);
    }
  });

  return errors;
};

export default StartNewDonation;