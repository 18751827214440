// src/services/quoteService.js

import { db, storage } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { companyService } from './companyService';
import { donationService } from './donationService';
import { format } from 'date-fns';
import { userService } from './userService';
import { distributionCenterService } from './distributionCenterService';
import {completedDeliveriesService} from './completedDeliveriesService';

const COLLECTION_NAME = 'quotes';

class QuoteError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'QuoteError';
    this.code = code;
  }
}

export const quoteService = {
  async createQuote(quoteData) {
    try {
      console.log('Creating quote with data:', quoteData);
  
      const newQuoteRef = doc(collection(db, COLLECTION_NAME));
  
      // Transform charity groups to match expected structure
      const assignedCharities = quoteData.charityGroups?.map(group => ({
        charityId: group.charityId,
        charityName: group.charityName,
        locationId: group.locationId,
        locationName: group.locationName,
        palletCount: group.palletQuantity,
        pricePerPallet: group.pricePerPallet
      })) || [];
  
      // Transform shipping quotes
      const shippingQuotes = quoteData.charityGroups?.map(group => ({
        palletGroup: group.palletGroup,
        price: group.shippingQuotePrice,
        url: group.shippingQuoteUrl
      })) || [];
  
      // Structure quote document
      const formattedQuote = {
        id: newQuoteRef.id,
        itemId: quoteData.itemId,
        description: quoteData.description,
        quantity: quoteData.quantity,
        palletCount: quoteData.palletCount,
        fairMarketValue: quoteData.fairMarketValue,
        fmvAssessmentUrl: quoteData.fmvAssessmentUrl,
        costBasis: quoteData.costBasis,
        unitOfMeasure: quoteData.unitOfMeasure,
        totalWeight: quoteData.totalWeight,
        sku: quoteData.sku,
        
        // Administrative info
        adminUserId: quoteData.adminUserId,
        adminUserName: quoteData.adminUserName,
        requestorId: quoteData.requestorId,
        requestorName: quoteData.requestorName,
        companyId: quoteData.companyId,
        companyName: quoteData.companyName,
        distributionCenterId: quoteData.distributionCenterId,
        distributionCenterName: quoteData.distributionCenterName,
        
        // Quote specific details
        amount: parseFloat(quoteData.amount),
        shippingQuotePrice: parseFloat(quoteData.totalShippingCost),
        totalPrice: parseFloat(quoteData.totalPrice),
        notes: quoteData.notes || '',
        terms: quoteData.terms,
        scheduleForCompletion: quoteData.scheduleForCompletion,
        validityPeriod: quoteData.validityPeriod,
        
        // Status and metadata
        status: 'pending',
        createdAt: new Date().toISOString(),
        
        // Transformed arrays
        assignedCharities,
        shippingQuotes,
        
        // If there's a donation reference
        donationId: quoteData.donationId || null
      };
  
      // Generate and upload PDF
      let pdfUrl = null;
      try {
        const pdfBlob = await this.generatePDF(formattedQuote);
        console.log('PDF generated successfully');
        pdfUrl = await this.uploadPDF(pdfBlob, newQuoteRef.id);
        console.log('PDF uploaded successfully, URL:', pdfUrl);
        formattedQuote.pdfUrl = pdfUrl;
      } catch (pdfError) {
        console.error('Failed to generate or upload PDF:', pdfError);
        // Continue with quote creation even if PDF fails
      }
  
      // Save to Firestore
      await setDoc(newQuoteRef, formattedQuote);
      console.log('Quote saved to Firestore');
  
      return formattedQuote;
    } catch (error) {
      console.error('Error creating quote:', error);
      throw new QuoteError(`Failed to create quote: ${error.message}`, 'CREATE_ERROR');
    }
  },

  async getById(quoteId) {
    try {
      const quoteDoc = await getDoc(doc(db, COLLECTION_NAME, quoteId));
      return quoteDoc.exists() ? { id: quoteDoc.id, ...quoteDoc.data() } : null;
    } catch (error) {
      console.error('Error getting quote by ID:', error);
      throw new QuoteError(`Failed to get quote: ${error.message}`, 'GET_ERROR');
    }
  },

  async update(quoteId, updateData) {
    try {
      if (!quoteId) {
        throw new QuoteError('Quote ID is required', 'INVALID_ID');
      }
  
      const quoteRef = doc(db, COLLECTION_NAME, quoteId);
      
      // Get the current quote to verify it exists
      const currentQuote = await this.getById(quoteId);
      if (!currentQuote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }
  
      // Create a clean update object
      const cleanUpdate = {
        ...updateData,
        updatedAt: new Date().toISOString()
      };
  
      // Handle special fields that need data cleaning
      if (cleanUpdate.assignedCharities) {
        // Ensure each charity object has the required fields and correct types
        cleanUpdate.assignedCharities = cleanUpdate.assignedCharities.map(charity => ({
          charityId: charity.charityId,
          charityName: charity.charityName,
          locationId: charity.locationId,
          locationName: charity.locationName,
          palletCount: Number(charity.palletCount) || 0,
          pricePerPallet: Number(charity.pricePerPallet) || 0,
          taxReceiptUrl: charity.taxReceiptUrl || null,
          acceptedByCharityDate: charity.acceptedByCharityDate || null,
          completedDeliveryId: charity.completedDeliveryId || null,
          palletGroup: Number(charity.palletGroup) || null
        }));
      }
  
      // Handle numeric fields
      if (cleanUpdate.amount) {
        cleanUpdate.amount = Number(cleanUpdate.amount);
      }
      if (cleanUpdate.shippingQuotePrice) {
        cleanUpdate.shippingQuotePrice = Number(cleanUpdate.shippingQuotePrice);
      }
      if (cleanUpdate.totalPrice) {
        cleanUpdate.totalPrice = Number(cleanUpdate.totalPrice);
      }
  
      // Handle dates
      if (cleanUpdate.scheduleForCompletion) {
        cleanUpdate.scheduleForCompletion = new Date(cleanUpdate.scheduleForCompletion).toISOString();
      }
      if (cleanUpdate.validityPeriod) {
        cleanUpdate.validityPeriod = new Date(cleanUpdate.validityPeriod).toISOString();
      }
  
      // Perform the update
      await updateDoc(quoteRef, cleanUpdate);
      
      // Verify the update
      const updatedQuote = await this.getById(quoteId);
      
      // Verify critical fields were updated correctly
      if (cleanUpdate.assignedCharities) {
        const verifyCharities = updatedQuote.assignedCharities.every(charity => {
          return typeof charity.charityId === 'string' &&
                 typeof charity.locationId === 'string' &&
                 typeof charity.palletCount === 'number';
        });
        
        if (!verifyCharities) {
          throw new QuoteError('Failed to verify charity data after update', 'VERIFICATION_ERROR');
        }
      }
  
      return updatedQuote;
    } catch (error) {
      console.error('Error updating quote:', error);
      throw new QuoteError(
        `Failed to update quote: ${error.message}`,
        error.code || 'UPDATE_ERROR'
      );
    }
  },

  async fetchAllQuotes(distributionCenterId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", distributionCenterId)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error fetching quotes:', error);
      throw new QuoteError(`Failed to fetch quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async approveQuote(quoteId, approvalData) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      const updatedPdfBlob = await this.generatePDF(quote, approvalData.signature);
      const pdfUrl = await this.uploadPDF(updatedPdfBlob, quoteId);

      // Update donation item status
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Approved'
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }

      const updatedQuote = await this.update(quoteId, {
        status: 'approved',
        signature: approvalData.signature,
        approverId: approvalData.approverId,
        approvalTimestamp: approvalData.approvalTimestamp,
        pdfUrl: pdfUrl
      });

      return updatedQuote;
    } catch (error) {
      console.error('Error approving quote:', error);
      throw new QuoteError(`Failed to approve quote: ${error.message}`, 'APPROVAL_ERROR');
    }
  },

  async rejectQuote(quoteId, rejectionData) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      // Update donation item status
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Rejected'
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }

      const updatedQuote = await this.update(quoteId, {
        status: 'rejected',
        rejectionReason: rejectionData.reason,
        rejectedBy: rejectionData.userId,
        rejectionTimestamp: new Date().toISOString()
      });

      return updatedQuote;
    } catch (error) {
      console.error('Error rejecting quote:', error);
      throw new QuoteError(`Failed to reject quote: ${error.message}`, 'REJECTION_ERROR');
    }
  },

  async generatePDF(quote, signature = null) {
    const pdf = new jsPDF();
    
    try {
      // Fetch all required data
      const [company, dc, requester, donation] = await Promise.all([
        companyService.getById(quote.companyId),
        distributionCenterService.getById(quote.distributionCenterId),
        userService.getById(quote.requestorId),
        quote.donationId ? donationService.getById(quote.donationId) : null
      ]);
  
      const donationItem = donation?.items?.find(item => item.itemID === quote.itemId);
  
      // PDF Styling Constants
      const colors = {
        primary: [41, 128, 185],    // Blue
        secondary: [44, 62, 80],    // Dark Gray
        accent: [52, 152, 219],     // Light Blue
        text: [51, 51, 51],         // Dark Gray
        lightGray: [245, 247, 250], // Very Light Gray
        background: [255, 255, 255], // White
      };
  
      const margin = 20;
      const pageWidth = pdf.internal.pageSize.width;
      const contentWidth = pageWidth - (2 * margin);
  
      // Helper Functions
      const setTextStyle = (size, style = 'normal', color = colors.text) => {
        pdf.setFontSize(size);
        pdf.setFont('helvetica', style);
        pdf.setTextColor(...color);
      };
  
      const formatAddress = (address) => {
        if (!address) return ['No address available'];
        return [
          address.street,
          `${address.city}, ${address.state} ${address.zip}`,
          address.country || 'United States'
        ].filter(Boolean);
      };
  
      const formatDate = (date) => format(new Date(date), 'MMMM dd, yyyy');
  
      // Header
      pdf.setFillColor(...colors.background);
      pdf.rect(0, 0, pageWidth, 70, 'F');
  
      // Left accent bar
      pdf.setFillColor(...colors.primary);
      pdf.rect(0, 0, 8, 70, 'F');
  
      // Company Logo
      if (company?.logo) {
        try {
          const logoImage = await this.getImageFromUrl(company.logo);
          if (logoImage) {
            pdf.addImage(logoImage, 'PNG', margin, 20, 40, 20);
          }
        } catch (logoError) {
          console.warn('Failed to load company logo:', logoError);
        }
      }
  
      // Quote Header
      setTextStyle(28, 'bold', colors.primary);
      pdf.text('SHIPPING QUOTE', pageWidth - margin, 35, { align: 'right' });
      
      setTextStyle(12, 'normal', colors.secondary);
      pdf.text(`Reference: ${quote.id}`, pageWidth - margin, 45, { align: 'right' });
      pdf.text(`Date: ${formatDate(quote.createdAt)}`, pageWidth - margin, 55, { align: 'right' });
  
      let yPos = 90;
  
      // Shipping Information Grid
      const drawInfoBox = (title, content, x, width) => {
        const boxHeight = 70;
        pdf.setFillColor(...colors.lightGray);
        pdf.roundedRect(x, yPos, width, boxHeight, 3, 3, 'F');
  
        setTextStyle(12, 'bold', colors.primary);
        pdf.text(title, x + 5, yPos + 15);
  
        setTextStyle(10, 'normal');
        content.forEach((line, i) => {
          pdf.text(line, x + 5, yPos + 30 + (i * 12));
        });
      };
  
      // From (Distribution Center) Info
      drawInfoBox(
        'FROM:',
        [
          dc.name,
          ...formatAddress(dc.address),
          `Phone: ${dc.phone || 'N/A'}`
        ],
        margin,
        (contentWidth - 10) / 2
      );
  
      // Item Details
      drawInfoBox(
        'ITEM DETAILS:',
        [
          `Description: ${quote.description}`,
          `SKU: ${donationItem?.sku || 'N/A'}`,
          `Quantity: ${donationItem?.quantity || 0} ${donationItem?.unitOfMeasure || ''}`,
          `Total Weight: ${donationItem?.totalWeight ? donationItem.totalWeight + ' lbs' : 'N/A'}`
        ],
        margin + (contentWidth + 10) / 2,
        (contentWidth - 10) / 2
      );
  
      yPos += 90;
  
      // Destinations Section
      setTextStyle(14, 'bold', colors.primary);
      pdf.text('SHIPPING DESTINATIONS', margin, yPos);
      yPos += 15;
  
      // Create destinations table
      const destinationsTableData = quote.assignedCharities.map((charity, index) => {
        const shippingQuote = quote.shippingQuotes.find(sq => 
          sq.palletGroup === index + 1
        );
        
        return [
          charity.charityName,
          charity.locationName,
          `${charity.palletCount} Pallets`,
          formatCurrency(300), // Standard rate per pallet
          formatCurrency(charity.palletCount * 300)
        ];
      });
  
      pdf.autoTable({
        startY: yPos,
        head: [['Charity', 'Location', 'Quantity', 'Price/Pallet', 'Subtotal']],
        body: destinationsTableData,
        theme: 'grid',
        headStyles: {
          fillColor: [...colors.primary],
          textColor: [255, 255, 255],
          fontStyle: 'bold',
          fontSize: 10,
          padding: 8,
          lineWidth: 0,
        },
        styles: {
          fontSize: 10,
          cellPadding: 6,
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { cellWidth: 'auto' },
          1: { cellWidth: 'auto' },
          2: { cellWidth: 40, halign: 'center' },
          3: { cellWidth: 45, halign: 'right' },
          4: { cellWidth: 45, halign: 'right' }
        },
        alternateRowStyles: {
          fillColor: [...colors.lightGray]
        }
      });
  
      yPos = pdf.lastAutoTable.finalY + 20;
  
      // Total Amount Box
      pdf.setFillColor(...colors.lightGray);
      pdf.roundedRect(pageWidth - 170, yPos, 150, 40, 3, 3, 'F');
  
      setTextStyle(12, 'bold', colors.primary);
      pdf.text('TOTAL AMOUNT:', pageWidth - 160, yPos + 25);
      setTextStyle(14, 'bold', colors.secondary);
      pdf.text(formatCurrency(quote.amount), pageWidth - margin - 10, yPos + 25, { align: 'right' });
  
      yPos += 60;
  
      // Terms and Schedule Section
      const drawSection = (title, content) => {
        pdf.setFillColor(...colors.lightGray);
        pdf.roundedRect(margin, yPos, contentWidth, 45, 3, 3, 'F');
        
        setTextStyle(12, 'bold', colors.primary);
        pdf.text(title, margin + 10, yPos + 20);
        
        setTextStyle(10, 'normal');
        const wrappedContent = pdf.splitTextToSize(content, contentWidth - 20);
        pdf.text(wrappedContent, margin + 10, yPos + 35);
        
        yPos += 55;
      };
  
      drawSection('TERMS AND CONDITIONS', quote.terms);
      drawSection('COMPLETION SCHEDULE', `Scheduled completion by: ${formatDate(quote.scheduleForCompletion)}`);
  
      if (quote.notes) {
        drawSection('ADDITIONAL NOTES', quote.notes);
      }
  
      // Signature Section
      if (signature || quote.status === 'approved') {
        const signatureY = pdf.internal.pageSize.height - 60;
        
        setTextStyle(12, 'bold', colors.primary);
        pdf.text('AUTHORIZATION', margin, signatureY - 10);
        
        if (signature) {
          pdf.addImage(signature, 'PNG', margin, signatureY, 50, 30);
        }
        
        setTextStyle(9, 'normal', colors.secondary);
        pdf.text('Authorized Signature', margin, signatureY + 35);
        
        if (quote.approvalTimestamp) {
          pdf.text(
            `Approved on: ${formatDate(quote.approvalTimestamp)}`,
            margin + 80,
            signatureY + 35
          );
        }
      }
  
      // Footer
      const footerY = pdf.internal.pageSize.height - 20;
      pdf.setFillColor(...colors.lightGray);
      pdf.rect(0, footerY - 10, pageWidth, 30, 'F');
  
      setTextStyle(9, 'normal', colors.secondary);
      pdf.text(`Valid until: ${formatDate(quote.validityPeriod)}`, margin, footerY);
      pdf.text(
        `Generated on ${format(new Date(), 'MMMM dd, yyyy HH:mm')}`,
        margin,
        footerY + 10
      );
      
      setTextStyle(9, 'bold', colors.primary);
      pdf.text('Thank you for your business!', pageWidth - margin, footerY, { align: 'right' });
  
      return pdf.output('blob');
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw new QuoteError(`Failed to generate PDF: ${error.message}`, 'PDF_ERROR');
    }
  },

  async uploadPDF(pdfBlob, quoteId) {
    try {
      const storageReference = ref(storage, `${COLLECTION_NAME}/${quoteId}.pdf`);
      await uploadBytes(storageReference, pdfBlob);
      return await getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading PDF:', error);
      throw new QuoteError(`Failed to upload PDF: ${error.message}`, 'UPLOAD_ERROR');
    }
  },

  async getImageFromUrl(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = () => {
        console.warn(`Failed to load image from URL: ${url}`);
        resolve(null);
      };
      img.src = url;
    });
  },

  async getApprovedQuotes() {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("status", "==", "approved"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting approved quotes:', error);
      throw new QuoteError(`Failed to get approved quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getRejectedQuotes() {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("status", "==", "rejected"));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting rejected quotes:', error);
      throw new QuoteError(`Failed to get rejected quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getQuotesByDonationId(donationId) {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("donationId", "==", donationId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting quotes by donation ID:', error);
      throw new QuoteError(`Failed to get quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getCompletedQuotesByDC(dcId) {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", dcId),
        where("status", "==", "completed")
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting completed quotes by DC:', error);
      throw new QuoteError(`Failed to get completed quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async getQuotesByItemId(itemId) {
    try {
      const q = query(collection(db, COLLECTION_NAME), where("itemId", "==", itemId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting quotes by item ID:', error);
      throw new QuoteError(`Failed to get quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  

  async addTaxReceiptsToQuote(quote) {
    try {
      if (!quote?.id) {
        throw new QuoteError('Invalid quote provided', 'INVALID_QUOTE');
      }

      console.log(`Starting addTaxReceiptsToQuote for quote: ${quote.id}`);
      
      // Input validation
      if (!Array.isArray(quote.assignedCharities) || quote.assignedCharities.length === 0) {
        throw new QuoteError('Quote has no assigned charities', 'NO_ASSIGNED_CHARITIES');
      }

      // Get all completed deliveries for this quote
      const completedDeliveries = await completedDeliveriesService.getByQuoteId(quote.id);
      console.log(`Found ${completedDeliveries.length} completed deliveries for quote ${quote.id}`);
      
      // Debug log: Print all completed deliveries details
      completedDeliveries.forEach(delivery => {
        console.log('Completed Delivery Details:', {
          deliveryId: delivery.id,
          charityId: delivery.charityId,
          locationId: delivery.charityLocationId,
          palletGroup: delivery.palletGroup,
          taxReceiptUrl: delivery.taxReceiptUrl,
          acceptedDate: delivery.acceptedByCharityDate
        });
      });

      if (!completedDeliveries.length) {
        throw new QuoteError('No completed deliveries found for quote', 'NO_COMPLETED_DELIVERIES');
      }

      // Create a map for quick delivery lookup
      const deliveryMap = new Map(
        completedDeliveries.map(delivery => [
          `${delivery.charityId}-${delivery.charityLocationId}-${delivery.palletGroup}`,
          delivery
        ])
      );

      // Debug log: Print delivery map keys
      console.log('Delivery Map Keys:', Array.from(deliveryMap.keys()));

      // Track missing information for detailed error reporting
      const missingDeliveries = [];
      const missingReceipts = [];
      
      // Update each charity with its corresponding delivery information
      const updatedAssignedCharities = quote.assignedCharities.map((charity, index) => {
        // Validate charity data
        if (!charity.charityId || !charity.locationId) {
          throw new QuoteError(
            `Invalid charity data at index ${index}`,
            'INVALID_CHARITY_DATA'
          );
        }

        const palletGroup = index + 1;
        const deliveryKey = `${charity.charityId}-${charity.locationId}-${palletGroup}`;
        const matchingDelivery = deliveryMap.get(deliveryKey);

        // Debug log: Print lookup attempt details
        console.log('Charity-Delivery Matching:', {
          charityName: charity.charityName,
          charityId: charity.charityId,
          locationId: charity.locationId,
          palletGroup,
          deliveryKey,
          hasMatchingDelivery: !!matchingDelivery,
          matchingDeliveryId: matchingDelivery?.id,
          taxReceiptUrl: matchingDelivery?.taxReceiptUrl
        });

        if (!matchingDelivery) {
          missingDeliveries.push({
            charityName: charity.charityName,
            palletGroup,
            locationName: charity.locationName
          });
          return charity; // Preserve original data
        }

        if (!matchingDelivery.taxReceiptUrl) {
          missingReceipts.push({
            charityName: charity.charityName,
            palletGroup,
            deliveryId: matchingDelivery.id,
            locationName: charity.locationName
          });
          return charity; // Preserve original data
        }

        // Debug log: Print charity update details
        console.log('Updating Charity with Tax Receipt:', {
          charityName: charity.charityName,
          originalTaxReceiptUrl: charity.taxReceiptUrl,
          newTaxReceiptUrl: matchingDelivery.taxReceiptUrl,
          originalDeliveryId: charity.completedDeliveryId,
          newDeliveryId: matchingDelivery.id
        });

        // Return updated charity data with delivery information
        return {
          ...charity,
          taxReceiptUrl: matchingDelivery.taxReceiptUrl,
          acceptedByCharityDate: matchingDelivery.acceptedByCharityDate,
          palletGroup: matchingDelivery.palletGroup,
          completedDeliveryId: matchingDelivery.id
        };
      });

      // Debug log: Print final charity updates
      console.log('Final Updated Charities:', updatedAssignedCharities.map(charity => ({
        charityName: charity.charityName,
        taxReceiptUrl: charity.taxReceiptUrl,
        completedDeliveryId: charity.completedDeliveryId,
        palletGroup: charity.palletGroup
      })));

      // Report any missing information
      if (missingDeliveries.length > 0) {
        const missingDetails = missingDeliveries
          .map(m => `${m.charityName} (${m.locationName}) - Pallet Group ${m.palletGroup}`)
          .join('\n');
        throw new QuoteError(
          `Missing deliveries for the following:\n${missingDetails}`,
          'MISSING_DELIVERIES'
        );
      }

      if (missingReceipts.length > 0) {
        const missingDetails = missingReceipts
          .map(m => `${m.charityName} (${m.locationName}) - Delivery ${m.deliveryId}`)
          .join('\n');
        throw new QuoteError(
          `Missing tax receipts for the following:\n${missingDetails}`,
          'MISSING_TAX_RECEIPTS'
        );
      }

      // Debug log: Print update data before saving
      console.log('Preparing to update quote with data:', {
        charityCount: updatedAssignedCharities.length,
        taxReceiptCount: updatedAssignedCharities.filter(c => c.taxReceiptUrl).length,
        updateTimestamp: new Date().toISOString()
      });

      // Prepare clean update data
      const updateData = {
        assignedCharities: JSON.parse(JSON.stringify(updatedAssignedCharities)),
        lastUpdatedAt: new Date().toISOString(),
        taxReceiptsAddedAt: new Date().toISOString()
      };

      // Update the quote in the database
      console.log(`Updating quote ${quote.id} with tax receipts`);
      const updatedQuote = await this.update(quote.id, updateData);

      // Verify the update was successful
      const verifyQuote = await this.getById(quote.id);
      const taxReceiptCount = verifyQuote.assignedCharities.filter(c => c.taxReceiptUrl).length;
      console.log(`Successfully updated quote ${quote.id}. Verified ${taxReceiptCount} tax receipts.`);

      return updatedQuote;
    } catch (error) {
      console.error('Error adding tax receipts to quote:', error);
      throw new QuoteError(
        `Failed to add tax receipts to quote: ${error.message}`,
        error.code || 'TAX_RECEIPT_UPDATE_ERROR'
      );
    }
  },

  async createSpecialQuote(quoteData) {
    try {
      console.log('Creating special quote with data:', quoteData);

      const newQuoteRef = doc(collection(db, COLLECTION_NAME));

      // Transform charity groups to match expected structure
      const assignedCharities = quoteData.charityGroups?.map(group => ({
        charityId: group.charityId,
        charityName: group.charityName,
        locationId: group.locationId,
        locationName: group.locationName,
        palletCount: group.palletQuantity,
        pricePerPallet: group.pricePerPallet
      })) || [];

      // Transform shipping quotes
      const shippingQuotes = quoteData.charityGroups?.map(group => ({
        palletGroup: group.palletGroup,
        price: group.shippingQuotePrice,
        url: group.shippingQuoteUrl
      })) || [];

      // Structure quote document with auto-approval data
      const formattedQuote = {
        id: newQuoteRef.id,
        itemId: quoteData.itemId,
        description: quoteData.description,
        quantity: quoteData.quantity,
        palletCount: quoteData.palletCount,
        fairMarketValue: quoteData.fairMarketValue,
        fmvAssessmentUrl: quoteData.fmvAssessmentUrl,
        costBasis: quoteData.costBasis,
        unitOfMeasure: quoteData.unitOfMeasure,
        totalWeight: quoteData.totalWeight,
        sku: quoteData.sku,
        
        // Administrative info
        adminUserId: quoteData.adminUserId,
        adminUserName: quoteData.adminUserName,
        requestorId: quoteData.requestorId,
        requestorName: quoteData.requestorName,
        companyId: quoteData.companyId,
        companyName: quoteData.companyName,
        distributionCenterId: quoteData.distributionCenterId,
        distributionCenterName: quoteData.distributionCenterName,
        
        // Quote specific details
        amount: parseFloat(quoteData.amount),
        shippingQuotePrice: parseFloat(quoteData.totalShippingCost),
        totalPrice: parseFloat(quoteData.totalPrice),
        notes: quoteData.notes,
        terms: quoteData.terms,
        scheduleForCompletion: quoteData.scheduleForCompletion,
        validityPeriod: quoteData.validityPeriod,
        
        // Special quote fields
        isSpecialQuote: true,
        status: 'approved',  // Auto-approve
        approverId: quoteData.adminUserId,
        approvalTimestamp: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        
        // Transformed arrays
        assignedCharities,
        shippingQuotes,
        
        donationId: quoteData.donationId || null
      };

      // Generate and upload PDF
      let pdfUrl = null;
      try {
        // Pass a system-generated signature for auto-approval
        const systemSignature = await this.generateSystemSignature(quoteData.adminUserName);
        const pdfBlob = await this.generatePDF(formattedQuote, systemSignature);
        console.log('PDF generated successfully');
        pdfUrl = await this.uploadPDF(pdfBlob, newQuoteRef.id);
        console.log('PDF uploaded successfully, URL:', pdfUrl);
        formattedQuote.pdfUrl = pdfUrl;
      } catch (pdfError) {
        console.error('Failed to generate or upload PDF:', pdfError);
      }

      // Save to Firestore
      await setDoc(newQuoteRef, formattedQuote);
      console.log('Special quote saved to Firestore');

      return formattedQuote;
    } catch (error) {
      console.error('Error creating special quote:', error);
      throw new QuoteError(`Failed to create special quote: ${error.message}`, 'CREATE_SPECIAL_ERROR');
    }
  },

  async generateSystemSignature(adminName) {
    // Create a simple canvas with text for the system signature
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 100;
    const ctx = canvas.getContext('2d');
    
    // Style the signature
    ctx.fillStyle = '#1a365d';
    ctx.font = '16px Arial';
    ctx.fillText(`Auto-approved by: ${adminName}`, 10, 30);
    ctx.font = '12px Arial';
    ctx.fillText(new Date().toLocaleString(), 10, 50);
    
    // Convert to base64
    return canvas.toDataURL('image/png');
  },

  async getCompletedQuotes() {
    try {
      const q = query(
        collection(db, COLLECTION_NAME), 
        where("status", "==", "completed")
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting completed quotes:', error);
      throw new QuoteError(`Failed to get completed quotes: ${error.message}`, 'FETCH_ERROR');
    }
  },

  async completeQuote(quoteId) {
    try {
      if (!quoteId) {
        throw new QuoteError('Quote ID is required', 'INVALID_QUOTE_ID');
      }
  
      console.log('=== STARTING QUOTE COMPLETION PROCESS ===');
      console.log(`QuoteID: ${quoteId}`);
  
      // Get the quote and verify its existence
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }
  
      // Verify quote status
      if (quote.status !== 'approved') {
        throw new QuoteError(
          `Cannot complete quote with status "${quote.status}". Quote must be approved first.`,
          'INVALID_STATUS'
        );
      }
  
      // Add tax receipts to the quote
      const updatedQuote = await this.addTaxReceiptsToQuote(quote);
  
      // Update quote status
      const finalUpdateData = {
        status: 'completed',
        completedAt: new Date().toISOString(),
        assignedCharities: JSON.parse(JSON.stringify(updatedQuote.assignedCharities))
      };
  
      // Perform final status update
      const finalQuote = await this.update(quoteId, finalUpdateData);
  
      // Update donation item status if applicable
      if (quote.donationId && quote.itemId) {
        try {
          await donationService.updateItem(quote.donationId, quote.itemId, {
            status: 'Quote Completed',
            completedAt: finalUpdateData.completedAt
          });
        } catch (error) {
          console.error('Error updating donation item status:', error);
        }
      }
  
      return finalQuote;
    } catch (error) {
      console.error('Error completing quote:', {
        quoteId,
        errorMessage: error.message,
        errorCode: error.code
      });
      throw new QuoteError(
        `Failed to complete quote: ${error.message}`,
        error.code || 'COMPLETION_ERROR'
      );
    }
  },

  async archiveQuote(quoteId) {
    try {
      const quote = await this.getById(quoteId);
      if (!quote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      await this.update(quoteId, {
        archived: true,
        archivedAt: new Date().toISOString()
      });

      return true;
    } catch (error) {
      console.error('Error archiving quote:', error);
      throw new QuoteError(`Failed to archive quote: ${error.message}`, 'ARCHIVE_ERROR');
    }
  },

  async bulkUpdateQuotes(quoteIds, updateData) {
    try {
      const updates = quoteIds.map(quoteId => 
        this.update(quoteId, updateData)
      );
      
      await Promise.all(updates);
      return true;
    } catch (error) {
      console.error('Error performing bulk update:', error);
      throw new QuoteError(`Failed to perform bulk update: ${error.message}`, 'BULK_UPDATE_ERROR');
    }
  },

  async duplicateQuote(quoteId) {
    try {
      const originalQuote = await this.getById(quoteId);
      if (!originalQuote) {
        throw new QuoteError('Quote not found', 'NOT_FOUND');
      }

      // Remove specific fields that shouldn't be duplicated
      const {
        id,
        status,
        signature,
        approvalTimestamp,
        approverId,
        rejectionReason,
        rejectedBy,
        rejectionTimestamp,
        createdAt,
        updatedAt,
        pdfUrl,
        ...quoteToDuplicate
      } = originalQuote;

      // Create new quote with duplicated data
      const duplicatedQuote = await this.createQuote({
        ...quoteToDuplicate,
        notes: `Duplicated from Quote ${originalQuote.id}\n${quoteToDuplicate.notes || ''}`.trim()
      });

      return duplicatedQuote;
    } catch (error) {
      console.error('Error duplicating quote:', error);
      throw new QuoteError(`Failed to duplicate quote: ${error.message}`, 'DUPLICATION_ERROR');
    }
  }
};

function formatCurrency(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
}

export default quoteService;