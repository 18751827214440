import React, { useState, useEffect, useCallback } from 'react';
import { 
  FaUpload, 
  FaDollarSign, 
  FaSave, 
  FaPlus, 
  FaMinus, 
  FaTrash, 
  FaBoxOpen,
  FaTimes,
  FaCheckCircle,
  FaExclamationCircle,
  FaMapMarkerAlt
} from 'react-icons/fa';
import { charityService } from '../services/charityService';
import { storage } from '../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import CharitySelector from './Charities/CharitySelector';
import '../styles/AssignDonation.css';

const AssignDonation = ({ item, onAssign, onClose }) => {
  // State management
  const [originalPalletCount] = useState(item.palletCount);
  const [palletGroups, setPalletGroups] = useState([]);
  const [charities, setCharities] = useState([]);
  const [targetGroupIndex, setTargetGroupIndex] = useState(null);
  const [showCharityCards, setShowCharityCards] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [focusedInputs, setFocusedInputs] = useState({});

  // Initialize pallet groups
  const initializePalletGroups = useCallback((fetchedCharities) => {
    if (item.assignedCharities?.length > 0) {
      const initialGroups = item.assignedCharities.map((charity, index) => {
        const matchedCharity = fetchedCharities.find(c => c.id === charity.charityId);
        return {
          palletGroup: index + 1,
          palletQuantity: charity.palletCount,
          charityId: charity.charityId,
          charityName: matchedCharity?.name || charity.charityName,
          locationId: charity.locationId,
          locationName: charity.locationName,
          quoteFile: null,
          quoteUrl: item.shippingQuotes?.[index]?.url || '',
          quotePrice: item.shippingQuotes?.[index]?.price?.toString() || ''
        };
      });

      const initialTotal = initialGroups.reduce((sum, group) => sum + group.palletQuantity, 0);
      if (initialTotal !== item.palletCount) {
        console.error('Initial pallet total mismatch:', initialTotal, 'should be', item.palletCount);
      }

      return initialGroups;
    }

    return [{
      palletGroup: 1,
      palletQuantity: item.palletCount,
      charityId: '',
      charityName: '',
      locationId: '',
      locationName: '',
      quoteFile: null,
      quoteUrl: '',
      quotePrice: ''
    }];
  }, [item]);

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const fetchedCharities = await charityService.getAll();
        setCharities(fetchedCharities);
        setPalletGroups(initializePalletGroups(fetchedCharities));
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data');
      }
    };

    fetchInitialData();
  }, [initializePalletGroups]);

  // Validation helper
  const validateTotalPallets = useCallback((groups) => {
    const total = groups.reduce((sum, group) => sum + group.palletQuantity, 0);
    return total === originalPalletCount;
  }, [originalPalletCount]);

  // Pallet quantity handlers
  const handlePalletQuantityChange = useCallback((groupIndex, change) => {
    setPalletGroups(prevGroups => {
      const newGroups = prevGroups.map((group, idx) => {
        if (idx === groupIndex) {
          const newQuantity = change === 1 
            ? group.palletQuantity + 1 
            : (change === -1 && group.palletQuantity > 1 ? group.palletQuantity - 1 : group.palletQuantity);
          
          return {
            ...group,
            palletQuantity: newQuantity
          };
        }
        return group;
      });
      
      return newGroups;
    });
  }, []);

  // Add new group
  const handleAddGroup = useCallback(() => {
    setPalletGroups(prevGroups => {
      const currentTotal = prevGroups.reduce((sum, group) => sum + group.palletQuantity, 0);
      
      if (currentTotal >= originalPalletCount) return prevGroups;
  
      const newGroup = {
        palletGroup: prevGroups.length + 1,
        palletQuantity: 1,
        charityId: '',
        charityName: '',
        locationId: '',
        locationName: '',
        quoteFile: null,
        quoteUrl: '',
        quotePrice: ''
      };
  
      return [...prevGroups, newGroup];
    });
  }, [originalPalletCount]);
  
  // Remove group
  const handleRemoveGroup = useCallback((groupIndex) => {
    if (targetGroupIndex === groupIndex) {
      setTargetGroupIndex(null);
      setShowCharityCards(false);
    }

    setPalletGroups(prevGroups => {
      if (prevGroups.length <= 1) return prevGroups;

      const removedQuantity = prevGroups[groupIndex].palletQuantity;
      const newGroups = prevGroups.filter((_, index) => index !== groupIndex);
      
      newGroups[newGroups.length - 1].palletQuantity += removedQuantity;

      return newGroups.map((group, index) => ({
        ...group,
        palletGroup: index + 1
      }));
    });
  }, [targetGroupIndex]);

  // Charity selection handlers
  const handleInitiateCharitySelection = useCallback((groupIndex) => {
    setTargetGroupIndex(groupIndex);
    setShowCharityCards(true);
    setErrorMessage('');
  }, []);

  const handleCharitySelect = useCallback((selection) => {
    if (targetGroupIndex === null) {
      setErrorMessage('Please select a pallet group first');
      return;
    }

    setPalletGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[targetGroupIndex] = {
        ...newGroups[targetGroupIndex],
        charityId: selection.charityId,
        charityName: selection.charityName,
        locationId: selection.locationId,
        locationName: selection.locationName
      };
      return newGroups;
    });
    
    setTargetGroupIndex(null);
    setShowCharityCards(false);
  }, [targetGroupIndex]);

  const getSelectedCharityId = useCallback((groupIndex) => {
    return palletGroups[groupIndex]?.charityId || null;
  }, [palletGroups]);

  const getSelectedLocationId = useCallback((groupIndex) => {
    return palletGroups[groupIndex]?.locationId || null;
  }, [palletGroups]);

  // Quote handling
  const handleQuoteUpload = useCallback((groupIndex, file) => {
    setPalletGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex] = { ...newGroups[groupIndex], quoteFile: file };
      return newGroups;
    });
  }, []);

  const handleQuotePriceChange = useCallback((groupIndex, value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    setPalletGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex] = { ...newGroups[groupIndex], quotePrice: numericValue };
      return newGroups;
    });
  }, []);

  const handleQuotePriceFocus = useCallback((groupIndex) => {
    setFocusedInputs(prev => ({ ...prev, [groupIndex]: true }));
  }, []);

  const handleQuotePriceBlur = useCallback((groupIndex) => {
    setFocusedInputs(prev => ({ ...prev, [groupIndex]: false }));
    setPalletGroups(prevGroups => {
      const newGroups = [...prevGroups];
      if (newGroups[groupIndex].quotePrice) {
        newGroups[groupIndex].quotePrice = parseFloat(newGroups[groupIndex].quotePrice).toFixed(2);
      }
      return newGroups;
    });
  }, []);

  // Format currency
  const formatCurrency = useCallback((value) => {
    if (!value) return '$0.00';
    return `$${parseFloat(value).toFixed(2)}`;
  }, []);

  // Calculate total shipping cost
  const calculateTotalShippingCost = useCallback((groups) => {
    return groups.reduce((sum, group) => 
      sum + (parseFloat(group.quotePrice || 0)), 0
    );
  }, []);

  // Save functionality
  const uploadQuotesToFirebase = async () => {
    return Promise.all(palletGroups.map(async (group) => {
      if (group.quoteFile) {
        const fileName = `${Date.now()}_${group.quoteFile.name}`;
        const storageRef = ref(
          storage, 
          `shippingQuotes/${item.donationId}/${item.itemID}/${fileName}`
        );
        await uploadBytes(storageRef, group.quoteFile);
        const downloadUrl = await getDownloadURL(storageRef);
        return { ...group, quoteUrl: downloadUrl };
      }
      return group;
    }));
  };

  const handleSave = async () => {
    setErrorMessage('');

    if (!validateTotalPallets(palletGroups)) {
      setErrorMessage(`Total pallets must equal ${originalPalletCount}. Current total: ${palletGroups.reduce((sum, group) => sum + group.palletQuantity, 0)}`);
      return;
    }

    const isIncomplete = palletGroups.some(group => 
      !group.charityId || !group.locationId || (!group.quoteFile && !group.quoteUrl) || !group.quotePrice
    );

    if (isIncomplete) {
      setErrorMessage('Please complete all fields for each pallet group before saving.');
      return;
    }

    setIsUploading(true);
    try {
      const updatedGroups = await uploadQuotesToFirebase();
      const totalQuotePrice = calculateTotalShippingCost(updatedGroups);

      const shippingQuotes = updatedGroups.map(group => ({
        palletGroup: group.palletGroup,
        palletQuantity: group.palletQuantity,
        url: group.quoteUrl,
        price: parseFloat(group.quotePrice || 0)
      }));

      const assignedCharities = updatedGroups.reduce((acc, group) => {
        if (group.charityId) {
          const existingCharity = acc.find(c => 
            c.charityId === group.charityId && c.locationId === group.locationId
          );
          if (existingCharity) {
            existingCharity.palletCount += group.palletQuantity;
          } else {
            acc.push({ 
              charityId: group.charityId, 
              charityName: group.charityName,
              locationId: group.locationId,
              locationName: group.locationName,
              palletCount: group.palletQuantity 
            });
          }
        }
        return acc;
      }, []);

      const updatedItem = {
        ...item,
        assignedCharities,
        shippingQuotes,
        shippingQuotePrice: totalQuotePrice,
        status: 'Quoted'
      };

      await onAssign(updatedItem);
      setIsUploading(false);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error saving assignments:", error);
      setIsUploading(false);
      setErrorMessage(error.message || 'Failed to save assignments');
    }
  };

  return (
    <div className="assign-donation-container">
      <h3>
        <FaBoxOpen className="header-icon" />
        Assign Donation: {item.description}
      </h3>
      
      <div className="pallet-group-assignments">
        <h4>Pallet Group Assignments</h4>
        {palletGroups.map((group, index) => (
          <div key={index} className="pallet-group-assignment">
            <div className="group-header">
              <h5>Group {group.palletGroup}</h5>
              {index > 0 && (
                <button 
                  onClick={() => handleRemoveGroup(index)}
                  className="remove-group-btn"
                  title="Remove group"
                >
                  <FaTrash />
                </button>
              )}
            </div>
            
            <div className="pallet-quantity">
              <button 
                onClick={() => handlePalletQuantityChange(index, -1)} 
                disabled={group.palletQuantity <= 1}
                className="quantity-btn"
                title="Decrease quantity"
              >
                <FaMinus />
              </button>
              <span>{group.palletQuantity} {group.palletQuantity === 1 ? 'Pallet' : 'Pallets'}</span>
              <button 
                onClick={() => handlePalletQuantityChange(index, 1)} 
                className="quantity-btn"
                title="Increase quantity"
              >
                <FaPlus />
              </button>
            </div>
            
            <button
              onClick={() => handleInitiateCharitySelection(index)}
              className={`assign-button 
                ${group.charityId ? 'assigned' : ''} 
                ${targetGroupIndex === index ? 'selecting' : ''}`}
            >
              {group.charityId ? (
                <div className="assignment-info">
                  <div className="charity-name">{group.charityName}</div>
                  <div className="location-name">
                    <FaMapMarkerAlt /> {group.locationName}
                  </div>
                </div>
              ) : (
                'Assign Charity'
              )}
            </button>

            <div className="quote-upload">
              <label className={group.quoteFile || group.quoteUrl ? 'has-file' : ''}>
                <FaUpload /> {group.quoteUrl ? 'Replace Quote' : 'Upload Quote'}
                <input
                  type="file"
                  onChange={(e) => handleQuoteUpload(index, e.target.files[0])}
                  accept=".pdf,.doc,.docx,.xls,.xlsx"
                />
              </label>
              {(group.quoteFile || group.quoteUrl) && (
                <span className="quote-uploaded">
                  Quote {group.quoteFile ? 'selected' : 'uploaded'}
                </span>
              )}
            </div>

            <div className="quote-price">
              <FaDollarSign />
              <input
                type="text"
                value={focusedInputs[index] ? group.quotePrice : formatCurrency(group.quotePrice)}
                onChange={(e) => handleQuotePriceChange(index, e.target.value)}
                onFocus={() => handleQuotePriceFocus(index)}
                onBlur={() => handleQuotePriceBlur(index)}
                placeholder="Enter quote price"
                className="quote-price-input"
              />
            </div>
          </div>
        ))}
        
        <button 
          onClick={handleAddGroup} 
          className="add-group-btn"
          disabled={palletGroups.reduce((sum, group) => sum + group.palletQuantity, 0) === originalPalletCount}
        >
          <FaPlus /> Add Group
        </button>
      </div>

      {showCharityCards && (
        <CharitySelector 
          selectedCharityId={getSelectedCharityId(targetGroupIndex)}
          selectedLocationId={getSelectedLocationId(targetGroupIndex)}
          onSelect={handleCharitySelect}
          onClose={() => {
            setShowCharityCards(false);
            setTargetGroupIndex(null);
          }}
        />
      )}

      {errorMessage && (
        <div className="error-message" role="alert">
          <FaExclamationCircle />
          <p>{errorMessage}</p>
        </div>
      )}
      
      <div className="summary-section">
        <div className="totals">
          <div className="total-item">
            <span>Total Pallets:</span>
            <span className={validateTotalPallets(palletGroups) ? 'valid-total' : 'invalid-total'}>
              {palletGroups.reduce((sum, group) => sum + group.palletQuantity, 0)} 
              {' '}/ {originalPalletCount}
              {!validateTotalPallets(palletGroups) && (
                <span className="validation-message">
                  (Adjust pallets to match original quantity)
                </span>
              )}
            </span>
          </div>
          <div className="total-item">
            <span>Total Shipping Cost:</span>
            <span>
              {formatCurrency(calculateTotalShippingCost(palletGroups))}
            </span>
          </div>
        </div>
      </div>

      <div className="button-group">
        <button 
          className="cancel-button" 
          onClick={onClose}
          disabled={isUploading}
        >
          Cancel
        </button>
        <button 
          className="save-button" 
          onClick={handleSave} 
          disabled={isUploading}
        >
          <FaSave /> {isUploading ? 'Saving...' : 'Save Assignments'}
        </button>
      </div>

      {showPopup && (
        <div className="success-popup" role="alert">
          <FaCheckCircle />
          <p>Assignments saved successfully!</p>
        </div>
      )}
    </div>
  );
};

export default AssignDonation;