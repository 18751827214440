// TestFunctions.js
import React, { useState, useEffect } from 'react';
import { FaCode, FaExclamationTriangle, FaEnvelope, FaUser } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/TestFunctions.css';

const TestFunctions = () => {
  // State management
  const [state, setState] = useState({
    user: null,
    userRole: null,
    loading: true,
    error: null,
    testResults: {}
  });

  // Loading states for individual functions
  const [functionLoading, setFunctionLoading] = useState({
    createUser: false,
    sendEmail: false
  });

  // Form data
  const [formData, setFormData] = useState({
    createUser: {
      email: '',
      password: '',
      displayName: ''
    },
    sendEmail: {
      to: '',
      templateData: {
        username: ''
      }
    }
  });

  // Load user data on mount
  useEffect(() => {
    const loadUserData = async () => {
      try {
        const currentUser = authService.getUserSession();
        if (!currentUser) throw new Error('No authenticated user found');

        const userData = await userService.getById(currentUser.id);
        if (!userData) throw new Error('User data not found');

        const userRole = await userService.getUserRole(currentUser.id);
        
        setState(prev => ({
          ...prev,
          user: userData,
          userRole,
          loading: false
        }));
      } catch (error) {
        console.error('Error:', error);
        setState(prev => ({
          ...prev,
          error: error.message,
          loading: false
        }));
      }
    };

    loadUserData();
  }, []);

  // Handle form input changes
  const handleInputChange = (formType, field, value) => {
    setFormData(prev => ({
      ...prev,
      [formType]: {
        ...prev[formType],
        [field]: value
      }
    }));
  };

  // Handle template data changes
  const handleTemplateDataChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      sendEmail: {
        ...prev.sendEmail,
        templateData: {
          ...prev.sendEmail.templateData,
          [field]: value
        }
      }
    }));
  };

  // Create user handler
  const handleCreateUser = async (e) => {
    e.preventDefault();
    setFunctionLoading(prev => ({ ...prev, createUser: true }));
    
    try {
      const functions = getFunctions();
      const createUserFunction = httpsCallable(functions, 'createUser');
      
      const result = await createUserFunction(formData.createUser);
      
      if (!result.data || !result.data.success) {
        throw new Error('User creation failed');
      }

      toast.success('User created successfully');
      setFormData(prev => ({
        ...prev,
        createUser: { email: '', password: '', displayName: '' }
      }));

      setState(prev => ({
        ...prev,
        testResults: {
          ...prev.testResults,
          createUser: {
            success: true,
            timestamp: new Date().toISOString(),
            data: result.data
          }
        }
      }));
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message || 'Failed to create user');
      setState(prev => ({
        ...prev,
        testResults: {
          ...prev.testResults,
          createUser: {
            success: false,
            timestamp: new Date().toISOString(),
            error: error.message
          }
        }
      }));
    } finally {
      setFunctionLoading(prev => ({ ...prev, createUser: false }));
    }
  };

  // Send email handler
  const handleSendEmail = async (e) => {
    e.preventDefault();
    setFunctionLoading(prev => ({ ...prev, sendEmail: true }));
    
    try {
      const functions = getFunctions();
      const sendEmailFunction = httpsCallable(functions, 'sendTransactionalEmail');
      
      const emailData = {
        to: formData.sendEmail.to,
        template: 'welcome',
        templateData: {
          username: formData.sendEmail.templateData.username,
          email: formData.sendEmail.to
        }
      };
      
      const result = await sendEmailFunction(emailData);
      
      if (!result.data || !result.data.success) {
        throw new Error('Email sending failed');
      }
  
      toast.success('Email sent successfully');
      setFormData(prev => ({
        ...prev,
        sendEmail: {
          to: '',
          templateData: { username: '' }
        }
      }));
  
      setState(prev => ({
        ...prev,
        testResults: {
          ...prev.testResults,
          sendEmail: {
            success: true,
            timestamp: new Date().toISOString(),
            data: {
              success: result.data.success,
              messageId: result.data.messageId,
              requestId: result.data.requestId
            }
          }
        }
      }));
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message || 'Failed to send email');
      setState(prev => ({
        ...prev,
        testResults: {
          ...prev.testResults,
          sendEmail: {
            success: false,
            timestamp: new Date().toISOString(),
            error: error.message
          }
        }
      }));
    } finally {
      setFunctionLoading(prev => ({ ...prev, sendEmail: false }));
    }
  };

  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  // Check for SystemAdmin role
  if (!state.user || state.userRole !== 'SystemAdmin') {
    return (
      <div className="unauthorized-message">
        <FaExclamationTriangle />
        <h2>Unauthorized Access</h2>
        <p>You must be a System Admin to view this page.</p>
        <p>Current role: {state.userRole || 'No role found'}</p>
      </div>
    );
  }

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="test-functions-container">
          <div className="test-functions-content">
            <h1><FaCode /> Test Backend Functions</h1>
            
            {/* Create User Section */}
            <div className="test-section">
              <h2><FaUser /> Create User</h2>
              <form onSubmit={handleCreateUser}>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    value={formData.createUser.email}
                    onChange={(e) => handleInputChange('createUser', 'email', e.target.value)}
                    disabled={functionLoading.createUser}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    value={formData.createUser.password}
                    onChange={(e) => handleInputChange('createUser', 'password', e.target.value)}
                    disabled={functionLoading.createUser}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Display Name:</label>
                  <input
                    type="text"
                    value={formData.createUser.displayName}
                    onChange={(e) => handleInputChange('createUser', 'displayName', e.target.value)}
                    disabled={functionLoading.createUser}
                    required
                  />
                </div>
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={functionLoading.createUser}
                >
                  {functionLoading.createUser ? 'Creating...' : 'Create User'}
                </button>
                {state.testResults.createUser && (
                  <div className={`test-result ${state.testResults.createUser.success ? 'success' : 'error'}`}>
                    <h4>Last Test: {new Date(state.testResults.createUser.timestamp).toLocaleString()}</h4>
                    <pre>
                      {JSON.stringify(
                        state.testResults.createUser.success 
                          ? state.testResults.createUser.data 
                          : state.testResults.createUser.error,
                        null,
                        2
                      )}
                    </pre>
                  </div>
                )}
              </form>
            </div>

            {/* Send Email Section */}
            <div className="test-section">
              <h2><FaEnvelope /> Test Welcome Email</h2>
              <form onSubmit={handleSendEmail}>
                <div className="form-group">
                  <label>Recipient Email:</label>
                  <input
                    type="email"
                    value={formData.sendEmail.to}
                    onChange={(e) => handleInputChange('sendEmail', 'to', e.target.value)}
                    disabled={functionLoading.sendEmail}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Recipient Name:</label>
                  <input
                    type="text"
                    value={formData.sendEmail.templateData.username}
                    onChange={(e) => handleTemplateDataChange('username', e.target.value)}
                    disabled={functionLoading.sendEmail}
                    required
                  />
                </div>
                <button 
                  type="submit" 
                  className="btn btn-primary"
                  disabled={functionLoading.sendEmail}
                >
                  {functionLoading.sendEmail ? 'Sending...' : 'Send Welcome Email'}
                </button>
                {state.testResults.sendEmail && (
                  <div className={`test-result ${state.testResults.sendEmail.success ? 'success' : 'error'}`}>
                    <h4>Last Test: {new Date(state.testResults.sendEmail.timestamp).toLocaleString()}</h4>
                    <pre>
                      {JSON.stringify(
                        state.testResults.sendEmail.success 
                          ? state.testResults.sendEmail.data 
                          : state.testResults.sendEmail.error,
                        null,
                        2
                      )}
                    </pre>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default TestFunctions;