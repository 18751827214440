// src/services/clientOrganizationService.js

/**
 * @typedef {Object} Organization
 * @property {string} id - Unique identifier for the organization
 * @property {string} companyId - Company identifier matching Firestore records
 * @property {string} name - Display name of the organization
 * @property {string[]} whitelistedEmails - List of authorized email addresses
 * @property {string} previewPath - Base preview path for the organization
 * @property {function(string): string} presentationPath - Function to generate presentation path
 * @property {Object} settings - Organization-specific settings
 * @property {string} settings.timezone - Organization's timezone
 * @property {string} settings.locale - Organization's locale preference
 * @property {Object} branding - Organization branding configuration
 * @property {string[]} branding.colors - Brand colors
 * @property {string} branding.logoUrl - Organization logo URL
 */

/**
 * Configuration object for client organizations
 * @type {Object.<string, Organization>}
 */
const CLIENT_ORGANIZATIONS = {
  'goop': {
    id: 'goop',
    companyId: '8ITtDUq6z87vrk7hZG7k',
    name: 'Goop',
    whitelistedEmails: [
      'client@goop.com',
      'executive@goop.com',
      'goop@donatingsimplified.com',
      'goop@simpli.supply',
      'tommy@wave.vip',
      'tpraskin@gmail.com'
    ],
    previewPath: '/client-preview/goop',
    presentationPath: (proposalId) => `/client-preview/goop/${proposalId}`,
    settings: {
      timezone: 'America/Los_Angeles',
      locale: 'en-US'
    },
    branding: {
      colors: ['#4A90E2', '#50E3C2', '#F5A623'],
      logoUrl: '/assets/logos/goop.svg'
    }
  },
  'lapresentation': {
    id: 'lapresentation',
    companyId: 'la_present_2024',
    name: 'LAPresentation',
    whitelistedEmails: [
      'preview@donatingsimplified.com'
    ],
    previewPath: '/client-preview/la_present_2024',
    presentationPath: (proposalId) => `/client-preview/la_present_2024/${proposalId}`,
    settings: {
      timezone: 'America/Los_Angeles',
      locale: 'en-US'
    },
    branding: {
      colors: ['#2D9CDB', '#27AE60', '#EB5757'],
      logoUrl: '/assets/logos/lapresentation.svg'
    }
  }
};

/**
 * Error class for organization-related errors
 */
class OrganizationError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'OrganizationError';
    this.code = code;
  }
}

/**
 * Service for managing client organization data and access
 */
export const clientOrganizationService = {
  /**
   * Get organization information for a given email
   * @param {string} email - User's email address
   * @returns {Organization|null} Organization object if found, null otherwise
   * @throws {OrganizationError} If email is invalid
   */
  getOrganizationForEmail(email) {
    if (!email || typeof email !== 'string') {
      throw new OrganizationError('Invalid email provided', 'INVALID_EMAIL');
    }

    const lowerEmail = email.toLowerCase().trim();
    return Object.values(CLIENT_ORGANIZATIONS).find(org => 
      org.whitelistedEmails.includes(lowerEmail)
    ) || null;
  },

  /**
   * Get organization by company ID
   * @param {string} companyId - Company identifier
   * @returns {Organization|null} Organization object if found, null otherwise
   * @throws {OrganizationError} If companyId is invalid
   */
  getOrganizationByCompanyId(companyId) {
    if (!companyId || typeof companyId !== 'string') {
      throw new OrganizationError('Invalid company ID provided', 'INVALID_COMPANY_ID');
    }

    return Object.values(CLIENT_ORGANIZATIONS).find(org => 
      org.companyId === companyId
    ) || null;
  },

  /**
   * Get organization by organization ID
   * @param {string} orgId - Organization identifier
   * @returns {Organization|null} Organization object if found, null otherwise
   * @throws {OrganizationError} If orgId is invalid
   */
  getOrganizationById(orgId) {
    if (!orgId || typeof orgId !== 'string') {
      throw new OrganizationError('Invalid organization ID provided', 'INVALID_ORG_ID');
    }

    return CLIENT_ORGANIZATIONS[orgId] || null;
  },

  /**
   * Check if an email is authorized for any organization
   * @param {string} email - Email to check
   * @returns {boolean} True if email is authorized, false otherwise
   */
  isEmailAuthorized(email) {
    try {
      return this.getOrganizationForEmail(email) !== null;
    } catch (error) {
      return false;
    }
  },

  /**
   * Get all organization IDs
   * @returns {string[]} Array of organization IDs
   */
  getAllOrganizationIds() {
    return Object.keys(CLIENT_ORGANIZATIONS);
  },

  /**
   * Get organization settings
   * @param {string} orgId - Organization identifier
   * @returns {Object|null} Organization settings if found, null otherwise
   */
  getOrganizationSettings(orgId) {
    const org = this.getOrganizationById(orgId);
    return org ? org.settings : null;
  },

  /**
   * Get organization branding configuration
   * @param {string} orgId - Organization identifier
   * @returns {Object|null} Organization branding if found, null otherwise
   */
  getOrganizationBranding(orgId) {
    const org = this.getOrganizationById(orgId);
    return org ? org.branding : null;
  },

  /**
   * Generate presentation URL for an organization and proposal
   * @param {string} orgId - Organization identifier
   * @param {string} proposalId - Proposal identifier
   * @returns {string|null} Presentation URL if org found, null otherwise
   */
  getPresentationUrl(orgId, proposalId) {
    const org = this.getOrganizationById(orgId);
    return org ? org.presentationPath(proposalId) : null;
  },

  /**
   * Validate if a company ID exists
   * @param {string} companyId - Company identifier to validate
   * @returns {boolean} True if company ID exists, false otherwise
   */
  isValidCompanyId(companyId) {
    return Object.values(CLIENT_ORGANIZATIONS)
      .some(org => org.companyId === companyId);
  },

  /**
   * Get preview path for an organization
   * @param {string} orgId - Organization identifier
   * @returns {string|null} Preview path if org found, null otherwise
   */
  getPreviewPath(orgId) {
    const org = this.getOrganizationById(orgId);
    return org ? org.previewPath : null;
  }
};

// Freeze the service to prevent modifications
Object.freeze(clientOrganizationService);
Object.freeze(CLIENT_ORGANIZATIONS);

export default clientOrganizationService;