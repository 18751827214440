import React, { useState, useEffect, memo, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaDollarSign,
  FaUsers,
  FaRecycle,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa';
import { iconMap } from '../utils/iconMap';

// Memoized reusable components
const Card = memo(({ children, className = "", highlighted = false }) => (
  <div 
    className={`
      bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-300 
      ${highlighted ? 'border-2 border-purple-200' : ''} 
      ${className}
    `}
  >
    {children}
  </div>
));

const MetricCard = memo(({ icon: Icon, value, label, bgColor, textColor }) => (
  <Card className="p-3">
    <div className={`w-8 h-8 ${bgColor} rounded-lg flex items-center justify-center mb-2`}>
      <Icon className={`w-4 h-4 ${textColor}`} />
    </div>
    <div className="text-lg font-bold text-gray-800 mb-1">{value}</div>
    <div className="text-xs text-gray-500 uppercase tracking-wider">{label}</div>
  </Card>
));

// Memoized slide components
const CoverSlide = memo(({ data }) => {
  // Memoize the cover data to prevent unnecessary re-renders
  const coverData = useMemo(() => ({
    title: data?.cover?.title || 'Donation Strategy',
    subtitle: data?.cover?.subtitle || 'Mission LA | Powered by Donating Simplified',
    year: data?.cover?.year || new Date().getFullYear(),
    clientLogo: data?.cover?.clientLogo
  }), [data?.cover]);

  // Hardcoded partner logo URL
  const partnerLogoUrl = "https://firebasestorage.googleapis.com/v0/b/donatingsimplified.appspot.com/o/Donating%20Simplified%20White%20Wordmark.png?alt=media&token=234482ff-4a28-42ed-adce-ca04a42fb120";

  return (
    <div className="relative h-full flex flex-col justify-center items-center">
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-purple-900 to-blue-900">
        <div className="absolute inset-0 opacity-10">
          <div 
            className="w-full h-full" 
            style={{ 
              backgroundImage: 'radial-gradient(circle at 1px 1px, white 1px, transparent 0)',
              backgroundSize: '20px 20px' 
            }}
          />
        </div>
      </div>

      <div className="relative z-10 w-full h-full flex flex-col justify-center items-center text-white p-4">
        <div className="flex items-center gap-4 mb-6">
          {coverData.clientLogo && (
            <img 
              src={coverData.clientLogo}
              alt="Client logo"
              className="w-16 h-16 object-contain"
            />
          )}
          <>
            <div className="h-12 w-px bg-purple-400/30" />
            <img 
              src={partnerLogoUrl}
              alt="Partner logo"
              className="w-32 object-contain"
            />
          </>
        </div>
        
        <div className="flex flex-col items-center text-center">
          <h1 className="text-2xl font-bold tracking-tight mb-2">
            {coverData.title}
          </h1>
          <div className="w-12 h-0.5 bg-purple-400 rounded-full mb-2" />
          <h2 className="text-lg font-light tracking-wide text-purple-200">
            {coverData.subtitle}
          </h2>
        </div>

        <div className="absolute bottom-4 left-0 right-0 flex justify-center">
          <div className="text-purple-300/50 text-sm tracking-[0.5em] uppercase">
            {coverData.year}
          </div>
        </div>
      </div>
    </div>
  );
});

const StrategySlide = memo(({ data }) => {
  // Memoize strategy data
  const strategyData = useMemo(() => ({
    title: data?.strategy?.title || 'Custom Donation Strategy',
    topCards: data?.strategy?.topCards || [],
    bottomCard: data?.strategy?.bottomCard || {}
  }), [data?.strategy]);

  return (
    <div className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-4">
      <div className="max-w-full mx-auto">
        <h2 className="text-xl font-bold mb-4 text-gray-800">
          {strategyData.title}
        </h2>
        
        <div className="grid grid-cols-3 gap-3 mb-4">
          {strategyData.topCards.map((card, index) => (
            <Card key={index} className="p-3">
              {card.icon && iconMap[card.icon] && (
                <div className="w-6 h-6 text-purple-600 mb-2">
                  {React.createElement(iconMap[card.icon])}
                </div>
              )}
              <h3 className="text-sm font-bold mb-1 text-gray-800">{card.title}</h3>
              <p className="text-xs text-gray-600 leading-relaxed">{card.description}</p>
            </Card>
          ))}
        </div>

        {strategyData.bottomCard && (
          <Card className="p-3">
            <div className="flex items-start gap-3">
              {strategyData.bottomCard.icon && iconMap[strategyData.bottomCard.icon] && (
                <div className="w-6 h-6 text-purple-600 flex-shrink-0">
                  {React.createElement(iconMap[strategyData.bottomCard.icon])}
                </div>
              )}
              <div>
                <h3 className="text-sm font-bold text-gray-800 mb-1">
                  {strategyData.bottomCard.title}
                </h3>
                <p className="text-xs text-gray-600 leading-relaxed">
                  {strategyData.bottomCard.description}
                </p>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
});

const ImpactSlide = memo(({ data }) => {
  // Memoize impact data
  const impactData = useMemo(() => ({
    metrics: {
      donations: data?.impact?.metrics?.donations?.value || '$0',
      peopleHelped: data?.impact?.metrics?.peopleHelped?.value || '0',
      wasteReduced: data?.impact?.metrics?.wasteReduced?.value || '0',
      taxSavings: data?.impact?.metrics?.taxSavings?.value || '$0'
    },
    infoCard: {
      title: data?.impact?.infoCard?.title || 'Impact Overview',
      description: data?.impact?.infoCard?.description || 'Impact description will appear here...'
    }
  }), [data?.impact]);

  return (
    <div className="h-full overflow-y-auto bg-white p-4">
      <div className="max-w-full mx-auto">
        <div className="flex items-start gap-2 mb-4">
          <h2 className="text-xl font-bold text-gray-800">Impact Dashboard</h2>
          <div className="bg-red-500/80 text-white px-2 py-0.5 text-xs font-bold 
                       tracking-wider rounded shadow-sm whitespace-nowrap">
            PENDING
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mb-4">
          <MetricCard
            icon={FaDollarSign}
            value={impactData.metrics.donations}
            label="Value of Donations"
            bgColor="bg-blue-50"
            textColor="text-blue-600"
          />
          <MetricCard
            icon={FaUsers}
            value={impactData.metrics.peopleHelped}
            label="People Helped"
            bgColor="bg-purple-50"
            textColor="text-purple-600"
          />
          <MetricCard
            icon={FaRecycle}
            value={impactData.metrics.wasteReduced}
            label="Saved from Landfill"
            bgColor="bg-teal-50"
            textColor="text-teal-600"
          />
          <MetricCard
            icon={FaDollarSign}
            value={impactData.metrics.taxSavings}
            label="Tax Savings"
            bgColor="bg-green-50"
            textColor="text-green-600"
          />
        </div>

        <Card className="p-3 bg-gradient-to-r from-orange-50 to-red-50 border border-orange-100">
          <h3 className="text-sm font-bold text-gray-800 mb-2">
            {impactData.infoCard.title}
          </h3>
          <p className="text-xs text-gray-700 leading-relaxed">
            {impactData.infoCard.description}
          </p>
        </Card>
      </div>
    </div>
  );
});

const OptionsSlide = memo(({ data }) => {
  // Memoize options data
  const optionsData = useMemo(() => ({
    title: data?.options?.title || 'Donation Options',
    options: data?.options?.options || [],
    specialNote: data?.options?.specialNote || { visible: false, text: '' }
  }), [data?.options]);

  return (
    <div className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-4">
      <div className="max-w-full mx-auto">
        <h2 className="text-xl font-bold mb-4 text-gray-800">
          {optionsData.title}
        </h2>
        
        <div className="space-y-3">
          {optionsData.options.map((option, index) => (
            <Card 
              key={index} 
              highlighted={option.isRecommended}
              className="p-3"
            >
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-2">
                <h3 className="text-sm font-bold text-gray-800">
                  {option.title} {option.subtitle && `- ${option.subtitle}`}
                </h3>
                {option.isRecommended && (
                  <span className="bg-purple-100 text-purple-800 px-2 py-0.5 rounded-full text-xs font-medium">
                    Recommended
                  </span>
                )}
              </div>
              
              {option.bulletPoints?.length > 0 && (
                <ul className="space-y-1 mb-2">
                  {option.bulletPoints.map((point, i) => (
                    <li key={i} className="flex items-start gap-2 text-xs text-gray-600">
                      <div className="w-1 h-1 bg-purple-500 rounded-full mt-1.5 flex-shrink-0" />
                      <span>{point}</span>
                    </li>
                  ))}
                </ul>
              )}
            </Card>
          ))}
        </div>

        {optionsData.specialNote.visible && optionsData.specialNote.text && (
          <div className="mt-4 bg-yellow-50 border-l-2 border-yellow-400 p-3 rounded-lg shadow-sm">
            <p className="text-xs text-yellow-800">
              <span className="font-bold">Special Note:</span> {optionsData.specialNote.text}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

const CharitySlide = memo(({ data }) => {
  // Memoize charity data
  const charityData = useMemo(() => ({
    title: data?.charities?.title || 'Charity Partner Profiles',
    profiles: data?.charities?.profiles || []
  }), [data?.charities]);

  return (
    <div className="h-full overflow-y-auto bg-gradient-to-br from-white to-gray-50 p-4">
      <div className="max-w-full mx-auto">
        <h2 className="text-xl font-bold mb-4 text-gray-800">
          {charityData.title}
        </h2>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          {charityData.profiles.map((charity, index) => (
            <Card key={index} className="p-3 relative">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2 mb-2">
                <h3 className="text-sm font-bold text-gray-800">{charity.name}</h3>
                <span className={`px-2 py-0.5 rounded-full text-xs font-medium whitespace-nowrap
                  ${getTagColors(charity.tagColor)}`}>
                  {charity.tag}
                </span>
              </div>
              
              <p className="text-xs text-gray-600 mb-3 leading-relaxed">
                {charity.description}
              </p>
              
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2 pt-2 border-t border-gray-100">
                <span className="text-xs text-gray-500">Area: {charity.area}</span>
                <span className="bg-gray-100 px-2 py-0.5 rounded-full text-xs font-medium">
                  {charity.pallets}
                </span>
              </div>
              
              {charity.priority && (
                <div className="absolute -top-1 -right-1 bg-yellow-400 text-yellow-900 
                             px-2 py-0.5 rounded text-xs font-bold shadow-sm">
                  Priority
                </div>
              )}
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
});

// Helper function for tag colors
const getTagColors = (color) => {
  const colorMap = {
    blue: 'bg-blue-100 text-blue-800',
    pink: 'bg-pink-100 text-pink-800',
    green: 'bg-green-100 text-green-800',
    indigo: 'bg-indigo-100 text-indigo-800',
    orange: 'bg-orange-100 text-orange-800',
    purple: 'bg-purple-100 text-purple-800',
    red: 'bg-red-100 text-red-800'
  };
  return colorMap[color] || 'bg-gray-100 text-gray-800';
};

// Main component
const PreviewPresentation = memo(({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  // Memoize slides array to prevent unnecessary re-renders
  const slides = useMemo(() => [
    <CoverSlide key="cover" data={data} />,
    <StrategySlide key="strategy" data={data} />,
    <ImpactSlide key="impact" data={data} />,
    <OptionsSlide key="options" data={data} />,
    <CharitySlide key="charities" data={data} />
  ], [data]);

  // Reset slide when cover title changes (indicates new presentation)
  useEffect(() => {
    setCurrentSlide(0);
  }, [data?.cover?.title]);

  // Keyboard navigation with proper dependency
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'ArrowRight') {
        setCurrentSlide(prev => (prev + 1) % slides.length);
      }
      if (e.key === 'ArrowLeft') {
        setCurrentSlide(prev => (prev - 1 + slides.length) % slides.length);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [slides.length]);

  // Memoize the slide navigation controls
  const navigationControls = useMemo(() => {
    const handlePrevSlide = () => {
      setCurrentSlide(prev => (prev - 1 + slides.length) % slides.length);
    };

    const handleNextSlide = () => {
      setCurrentSlide(prev => (prev + 1) % slides.length);
    };

    return (
      <div className="absolute bottom-2 right-2 flex items-center gap-1 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <button
          onClick={handlePrevSlide}
          disabled={currentSlide === 0}
          className="p-1 bg-black/50 text-white rounded-full hover:bg-black/70 disabled:opacity-50 disabled:hover:bg-black/50"
          aria-label="Previous slide"
        >
          <FaChevronLeft className="w-3 h-3" />
        </button>
        <div className="px-2 py-1 bg-black/50 text-white rounded-full text-xs">
          {currentSlide + 1} / {slides.length}
        </div>
        <button
          onClick={handleNextSlide}
          disabled={currentSlide === slides.length - 1}
          className="p-1 bg-black/50 text-white rounded-full hover:bg-black/70 disabled:opacity-50 disabled:hover:bg-black/50"
          aria-label="Next slide"
        >
          <FaChevronRight className="w-3 h-3" />
        </button>
      </div>
    );
  }, [currentSlide, slides.length]);

  // Memoize the progress bar
  const progressBar = useMemo(() => (
    <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-200">
      <div
        className="h-full bg-purple-500 transition-all duration-300"
        style={{ width: `${((currentSlide + 1) / slides.length) * 100}%` }}
      />
    </div>
  ), [currentSlide, slides.length]);

  return (
    <div className="w-full h-full bg-gray-100 rounded-lg overflow-hidden relative group">
      {/* Current Slide with AnimatePresence for smooth transitions */}
      <AnimatePresence mode="wait">
        <motion.div
          key={currentSlide}
          className="absolute inset-0"
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -10 }}
          transition={{ duration: 0.2 }}
        >
          {slides[currentSlide]}
        </motion.div>
      </AnimatePresence>

      {/* Navigation Controls */}
      {navigationControls}

      {/* Progress Bar */}
      {progressBar}
    </div>
  );
});

// Add display names for debugging
PreviewPresentation.displayName = 'PreviewPresentation';
Card.displayName = 'Card';
MetricCard.displayName = 'MetricCard';
CoverSlide.displayName = 'CoverSlide';
StrategySlide.displayName = 'StrategySlide';
ImpactSlide.displayName = 'ImpactSlide';
OptionsSlide.displayName = 'OptionsSlide';
CharitySlide.displayName = 'CharitySlide';

export default PreviewPresentation;