// src/components/ClientLogin.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { clientOrganizationService } from '../services/clientOrganizationService';
import { 
  FaEnvelope, 
  FaLock, 
  FaSpinner,
  FaExclamationCircle
} from 'react-icons/fa';

/**
 * @typedef {Object} LoginFormState
 * @property {string} email - User's email
 * @property {string} password - User's password
 * @property {string} error - Error message
 * @property {boolean} isLoading - Loading state
 */

const ERROR_MESSAGES = {
  INVALID_CREDENTIALS: 'Invalid email or password.',
  UNAUTHORIZED: 'Access denied. This preview is only available to authorized clients.',
  NETWORK_ERROR: 'Unable to connect. Please check your internet connection.',
  GENERAL_ERROR: 'An error occurred. Please try again later.'
};

/**
 * Input field component with icon
 */
const InputField = ({ 
  icon: Icon, 
  type, 
  name,
  value, 
  onChange, 
  placeholder, 
  error,
  required = true,
  autoComplete = 'off'
}) => (
  <div className="space-y-1">
    <div className="relative">
      <Icon className="absolute left-3 top-3 text-gray-400" />
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        className={`
          w-full pl-10 pr-3 py-2 border rounded-md
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
          transition-colors
          ${error ? 'border-red-300' : 'border-gray-300'}
        `}
      />
    </div>
    {error && (
      <p className="text-sm text-red-600">{error}</p>
    )}
  </div>
);

const ClientLogin = () => {
  // Form state
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    error: '',
    isLoading: false
  });

  const navigate = useNavigate();

  /**
   * Redirects to preview page with correct organization data
   */
  const redirectToPreview = useCallback((organization) => {
    // Store organization data in session
    const orgData = {
      orgId: organization.id,
      companyId: organization.companyId,
      name: organization.name,
      settings: organization.settings,
      branding: organization.branding
    };
    sessionStorage.setItem('orgData', JSON.stringify(orgData));

    // Get redirect URL from session or use default
    const redirectUrl = sessionStorage.getItem('redirectUrl') || 
                       organization.previewPath;
    sessionStorage.removeItem('redirectUrl');

    navigate(redirectUrl);
  }, [navigate]);

  // Check for existing session on mount
  useEffect(() => {
    const checkExistingSession = async () => {
      try {
        const currentUser = await authService.getCurrentUser();
        if (currentUser) {
          const organization = clientOrganizationService.getOrganizationForEmail(currentUser.email);
          if (organization) {
            redirectToPreview(organization);
          }
        }
      } catch (error) {
        console.error('Session check failed:', error);
      }
    };

    checkExistingSession();
  }, [redirectToPreview]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value,
      error: '' // Clear error on input change
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setFormState(prev => ({
      ...prev,
      isLoading: true,
      error: ''
    }));

    try {
      // Attempt login
      const user = await authService.login(formState.email, formState.password);
      
      // Validate organization access
      const organization = clientOrganizationService.getOrganizationForEmail(user.email);
      
      if (!organization) {
        throw new Error('UNAUTHORIZED');
      }

      redirectToPreview(organization);

    } catch (error) {
      console.error('Login error:', error);
      
      setFormState(prev => ({
        ...prev,
        error: ERROR_MESSAGES[error.message] || ERROR_MESSAGES.GENERAL_ERROR,
        isLoading: false
      }));

      // Clean up on auth error
      if (error.message === 'UNAUTHORIZED') {
        await authService.logout();
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        {/* Login Card */}
        <div className="bg-white rounded-lg shadow-md p-8">
          {/* Header */}
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Client Preview Login
            </h2>
            <p className="text-gray-500">
              Enter your credentials to access the preview
            </p>
          </div>

          {/* Error Message */}
          {formState.error && (
            <div className="mb-6 flex items-center gap-2 bg-red-50 text-red-600 p-4 rounded-md">
              <FaExclamationCircle className="flex-shrink-0" />
              <span>{formState.error}</span>
            </div>
          )}

          {/* Login Form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Email Input */}
            <InputField
              icon={FaEnvelope}
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              placeholder="Email"
              autoComplete="email"
            />

            {/* Password Input */}
            <InputField
              icon={FaLock}
              type="password"
              name="password"
              value={formState.password}
              onChange={handleInputChange}
              placeholder="Password"
              autoComplete="current-password"
            />

            {/* Submit Button */}
            <button
              type="submit"
              disabled={formState.isLoading}
              className="
                w-full bg-blue-600 text-white py-2 px-4 rounded-md
                hover:bg-blue-700 focus:outline-none focus:ring-2
                focus:ring-blue-500 focus:ring-offset-2 transition-colors
                disabled:opacity-50 disabled:cursor-not-allowed
                font-medium
              "
            >
              {formState.isLoading ? (
                <span className="flex items-center justify-center gap-2">
                  <FaSpinner className="animate-spin" />
                  Signing in...
                </span>
              ) : (
                'Sign in'
              )}
            </button>
          </form>

          {/* Help Text */}
          <p className="mt-6 text-center text-sm text-gray-500">
            Contact your account manager if you need assistance
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClientLogin;