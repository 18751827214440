import React, { useState, useMemo } from 'react';
import { FaSearch, FaEdit, FaMapMarkerAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../../styles/Charities/CharityTable.css';

const RESULTS_LIMIT = 5;

const CharityTable = ({ charities, onSelectCharity, selectedCharityId }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Memoized filtered charities, limited to 5 results
  const filteredCharities = useMemo(() => {
    const searchLower = searchTerm.toLowerCase().trim();
    return charities
      .filter(charity => 
        charity.orgName.toLowerCase().includes(searchLower) ||
        charity.ein.toLowerCase().includes(searchLower)
      )
      .slice(0, RESULTS_LIMIT); // Limit to top 5 results
  }, [charities, searchTerm]);

  // Calculate total matches (before limiting)
  const totalMatches = useMemo(() => {
    const searchLower = searchTerm.toLowerCase().trim();
    return charities.filter(charity => 
      charity.orgName.toLowerCase().includes(searchLower) ||
      charity.ein.toLowerCase().includes(searchLower)
    ).length;
  }, [charities, searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderEmptyState = () => (
    <tr>
      <td colSpan="4">
        <div className="empty-state">
          {searchTerm 
            ? 'No charities found matching your search.'
            : 'No charities available.'}
        </div>
      </td>
    </tr>
  );

  const renderTableRow = (charity) => (
    <tr 
      key={charity.id}
      className={selectedCharityId === charity.id ? 'selected' : ''}
    >
      <td>
        <div className="org-name">{charity.orgName}</div>
      </td>
      <td>
        <div className="ein">{charity.ein}</div>
      </td>
      <td>
        <div className="locations-count">
          <FaMapMarkerAlt />
          {charity.locations?.length || 0} location{charity.locations?.length !== 1 ? 's' : ''}
        </div>
      </td>
      <td>
        <button
          type="button"
          onClick={() => onSelectCharity(charity)}
          className="edit-button"
          aria-label={`Edit ${charity.orgName}`}
        >
          <FaEdit />
          Edit
        </button>
      </td>
    </tr>
  );

  return (
    <div className="charity-table-container">
      <div className="search-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search by organization name or EIN..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
          aria-label="Search charities"
        />
        {searchTerm && totalMatches > RESULTS_LIMIT && (
          <div className="search-results-count">
            Showing top {RESULTS_LIMIT} of {totalMatches} matches
          </div>
        )}
      </div>

      <div className="table-wrapper">
        <table className="charity-table" role="grid">
          <thead>
            <tr>
              <th scope="col">Organization</th>
              <th scope="col">EIN</th>
              <th scope="col">Locations</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCharities.length > 0 
              ? filteredCharities.map(renderTableRow)
              : renderEmptyState()
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

CharityTable.propTypes = {
  charities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      orgName: PropTypes.string.isRequired,
      ein: PropTypes.string.isRequired,
      locations: PropTypes.array
    })
  ).isRequired,
  onSelectCharity: PropTypes.func.isRequired,
  selectedCharityId: PropTypes.string
};

export default CharityTable;