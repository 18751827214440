import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

// Register required ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

// Modern Tailwind-inspired color palette
const COLORS = {
  primary: {
    base: 'rgb(59, 130, 246)',      // blue-500
    light: 'rgba(59, 130, 246, 0.1)',
    gradient: ['rgba(59, 130, 246, 0.2)', 'rgba(59, 130, 246, 0)']
  },
  secondary: [
    'rgb(99, 102, 241)',    // indigo-500
    'rgb(168, 85, 247)',    // purple-500
    'rgb(236, 72, 153)',    // pink-500
    'rgb(239, 68, 68)',     // red-500
    'rgb(245, 158, 11)',    // amber-500
    'rgb(16, 185, 129)',    // emerald-500
    'rgb(6, 182, 212)',     // cyan-500
    'rgb(14, 165, 233)',    // sky-500
  ]
};

// Formatting utilities
const formatters = {
  currency: (value) => {
    if (!value || isNaN(value)) return '$0.00';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(value);
  },

  shortCurrency: (value) => {
    if (!value || isNaN(value)) return '$0';
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: 1,
      currency: 'USD',
      style: 'currency'
    }).format(value);
  },

  percent: (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }).format(value / 100);
  }
};

// Chart data preparation functions
const prepareChartData = {
  cumulative: (quotes) => {
    if (!Array.isArray(quotes) || quotes.length === 0) {
      return { datasets: [] };
    }

    // Filter valid quotes and ensure proper date parsing
    const validQuotes = quotes
      .filter(quote => {
        const date = new Date(quote?.completedAt);
        const value = Number(quote?.fairMarketValue);
        return date instanceof Date && !isNaN(date) && 
               !isNaN(value) && value > 0;
      })
      .map(quote => ({
        ...quote,
        parsedDate: new Date(quote.completedAt),
        value: Number(quote.fairMarketValue)
      }))
      // Ensure strict chronological ordering
      .sort((a, b) => a.parsedDate.getTime() - b.parsedDate.getTime());

    // Ensure monotonically increasing values by accumulating per day
    const dailyTotals = validQuotes.reduce((acc, quote) => {
      const dateKey = quote.parsedDate.toISOString().split('T')[0];
      if (!acc[dateKey]) {
        acc[dateKey] = 0;
      }
      acc[dateKey] += quote.value;
      return acc;
    }, {});

    // Create cumulative data points with proper date sorting
    let runningTotal = 0;
    const data = Object.entries(dailyTotals)
      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
      .map(([date, value]) => {
        runningTotal += value;
        return {
          x: new Date(date),
          y: runningTotal,
          meta: {
            date,
            dailyValue: value,
            runningTotal
          }
        };
      });

    return {
      datasets: [{
        data,
        borderColor: COLORS.primary.base,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          
          if (!chartArea) return COLORS.primary.light;
          
          const gradient = ctx.createLinearGradient(
            0, 
            chartArea.bottom, 
            0, 
            chartArea.top
          );
          gradient.addColorStop(0, COLORS.primary.gradient[1]);
          gradient.addColorStop(1, COLORS.primary.gradient[0]);
          
          return gradient;
        },
        borderWidth: 2,
        fill: true,
        tension: 0.2,
        cubicInterpolationMode: 'monotone',
        capBezierPoints: false,
        stepped: false,
        pointRadius: 0,
        pointHitRadius: 8,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: COLORS.primary.base,
        pointHoverBorderColor: 'white',
        pointHoverBorderWidth: 2
      }]
    };
  },

  charityDistribution: (quotes, charities) => {
    if (!quotes?.length || !charities) return { labels: [], datasets: [{ data: [] }] };

    const distribution = {};
    quotes.forEach(quote => {
      quote.assignedCharities?.forEach(assignment => {
        const name = charities[assignment.charityId]?.orgName || 'Unknown';
        distribution[name] = (distribution[name] || 0) + (assignment.palletCount || 0);
      });
    });

    const sortedData = Object.entries(distribution)
      .sort(([, a], [, b]) => b - a);

    // Group smaller segments into "Others"
    const data = {
      labels: [],
      values: []
    };

    if (sortedData.length > 7) {
      const mainCharities = sortedData.slice(0, 6);
      const othersSum = sortedData.slice(6)
        .reduce((sum, [, count]) => sum + count, 0);

      data.labels = [...mainCharities.map(([name]) => name), 'Others'];
      data.values = [...mainCharities.map(([, count]) => count), othersSum];
    } else {
      data.labels = sortedData.map(([name]) => name);
      data.values = sortedData.map(([, count]) => count);
    }

    return {
      labels: data.labels,
      datasets: [{
        data: data.values,
        backgroundColor: COLORS.secondary,
        hoverOffset: 24
      }]
    };
  }
};

// Chart configuration options
const chartOptions = {
  line: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'nearest'
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 6,
        hitRadius: 8,
        display: false,
        drawActiveElementsOnTop: false
      },
      line: {
        tension: 0.4
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d'
          }
        },
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 6,
          display: true
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false
        },
        ticks: {
          callback: value => formatters.shortCurrency(value)
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        backgroundColor: 'white',
        titleColor: 'rgb(15, 23, 42)',
        bodyColor: 'rgb(71, 85, 105)',
        borderColor: 'rgb(226, 232, 240)',
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        callbacks: {
          title: (items) => {
            if (!items[0]?.raw?.x) return '';
            return format(new Date(items[0].raw.x), 'PPPP');
          },
          label: (item) => {
            const meta = item.raw.meta;
            return [
              `Daily Value: ${formatters.currency(meta.dailyValue)}`,
              `Total: ${formatters.currency(meta.runningTotal)}`
            ];
          }
        }
      }
    }
  },

  pie: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'rgb(15, 23, 42)',
        bodyColor: 'rgb(71, 85, 105)',
        borderColor: 'rgb(226, 232, 240)',
        borderWidth: 1,
        padding: 12,
        cornerRadius: 8,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = value / total;
            return `${value} pallets (${formatters.percent(percentage * 100)})`;
          }
        }
      }
    }
  }
};

const DonationCharts = ({ quotes = [], charities = {} }) => {
  const hasData = useMemo(() => 
    quotes.length > 0 && Object.keys(charities).length > 0,
    [quotes, charities]
  );

  if (!hasData) {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-sm p-6 min-h-[400px] flex items-center justify-center">
          <p className="text-slate-500">No donation data available</p>
        </div>
        <div className="bg-white rounded-xl shadow-sm p-6 min-h-[400px] flex items-center justify-center">
          <p className="text-slate-500">No charity distribution data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-lg font-semibold text-slate-900 mb-6">
          Cumulative Donation Value
        </h3>
        <div className="h-[400px]">
          <Line 
            data={prepareChartData.cumulative(quotes)} 
            options={chartOptions.line}
          />
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <h3 className="text-lg font-semibold text-slate-900 mb-6">
          Distribution by Charity
        </h3>
        <div className="h-[400px]">
          <Pie 
            data={prepareChartData.charityDistribution(quotes, charities)} 
            options={chartOptions.pie}
          />
        </div>
      </div>
    </div>
  );
};

// Export the data preparation functions under the original API name
export const getChartData = {
  cumulative: prepareChartData.cumulative,
  charity: prepareChartData.charityDistribution
};

export default DonationCharts;