// src/services/completedDonationsService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, query, where, runTransaction } from 'firebase/firestore';
import { quoteService } from './quoteService';
import { logisticsService } from './logisticsService';
import { donationService } from './donationService';
import { companyService } from './companyService';

const COLLECTION_NAME = 'completedDonations';

class CompletedDonationError extends Error {
  constructor(message, code, details = null) {
    super(message);
    this.name = 'CompletedDonationError';
    this.code = code;
    this.details = details;
  }
}

// Data retrieval and validation functions
const fetchAndValidateData = async (quoteId) => {
  const results = {
    quote: null,
    donation: null,
    company: null,
    tasks: [],
    errors: []
  };

  try {
    // Get initial quote
    results.quote = await quoteService.getById(quoteId);
    if (!results.quote) {
      throw new CompletedDonationError('Quote not found', 'QUOTE_NOT_FOUND');
    }

    // Get parent donation
    results.donation = await donationService.getById(results.quote.donationId);
    if (!results.donation) {
      throw new CompletedDonationError('Parent donation not found', 'DONATION_NOT_FOUND');
    }

    // Get company information
    results.company = await companyService.getById(results.donation.companyId);
    if (!results.company) {
      throw new CompletedDonationError('Company not found', 'COMPANY_NOT_FOUND');
    }

    // Get all related tasks
    results.tasks = await logisticsService.getTasks({ quoteId });
    
    // Validate all required data is present
    validateRequiredData(results);
    
    return results;
  } catch (error) {
    console.error('Error fetching required data:', error);
    throw error;
  }
};

const validateRequiredData = (data) => {
  const { quote, donation, company, tasks } = data;
  const errors = [];

  // Validate quote assignments
  if (!quote.assignedCharities?.length) {
    errors.push(new CompletedDonationError(
      'Quote has no assigned charities',
      'INVALID_QUOTE_DATA'
    ));
  }

  // Validate tasks completion
  const incompleteTasks = tasks.filter(task => task.status !== 'completed');
  if (incompleteTasks.length > 0) {
    errors.push(new CompletedDonationError(
      'Found incomplete tasks',
      'INCOMPLETE_TASKS',
      { taskIds: incompleteTasks.map(t => t.id) }
    ));
  }

  // Validate tasks match assignments
  if (tasks.length !== quote.assignedCharities.length) {
    errors.push(new CompletedDonationError(
      'Mismatch between tasks and assignments',
      'TASK_ASSIGNMENT_MISMATCH',
      {
        tasksCount: tasks.length,
        assignmentsCount: quote.assignedCharities.length
      }
    ));
  }

  if (errors.length > 0) {
    throw new CompletedDonationError(
      'Data validation failed',
      'VALIDATION_ERROR',
      { errors }
    );
  }
};

const buildCompletedDonationDocument = (data) => {
  const { quote, donation, company, tasks } = data;
  
  // Find the corresponding donation item
  const donationItem = donation.items.find(item => item.quoteId === quote.id);
  if (!donationItem) {
    throw new CompletedDonationError(
      'Donation item not found for quote',
      'ITEM_NOT_FOUND'
    );
  }

  // Build the completed donation structure
  return {
    // Metadata
    originalDonationId: donation.id,
    status: 'completed',
    createdAt: new Date().toISOString(),
    completedAt: new Date().toISOString(),
    createdBy: donation.createdBy,
    notes: donation.notes || '',

    // Company Information
    company: {
      id: company.id,
      name: company.name,
      address: company.address,
      primaryContact: company.primaryContact
    },

    // Item Details
    item: {
      id: donationItem.itemId,
      metadata: {
        description: donationItem.description,
        sku: donationItem.sku,
        unitOfMeasure: donationItem.unitOfMeasure,
        expirationDate: donationItem.expirationDate
      },
      documents: {
        quotePdf: quote.pdfUrl,
        fmvAssessment: donationItem.fmvAssessmentUrl
      },
      totals: {
        palletCount: donationItem.palletCount,
        costBasis: donationItem.costBasis,
        fairMarketValue: donationItem.fairMarketValue,
        weight: donationItem.totalWeight,
        quantity: donationItem.quantity,
        serviceFee: quote.amount,
        shippingTotal: quote.shippingQuotePrice
      },
      distributionCenter: {
        id: quote.distributionCenterId,
        name: quote.distributionCenterName,
        address: tasks[0].distributionCenterAddress
      },
      palletGroups: quote.assignedCharities.map(assignment => {
        const task = tasks.find(t => 
          t.charityId === assignment.charityId && 
          t.palletGroup === assignment.palletGroup
        );

        const shippingQuote = quote.shippingQuotes.find(sq =>
          sq.palletGroup === assignment.palletGroup
        );

        if (!task || !shippingQuote) {
          throw new CompletedDonationError(
            'Missing task or shipping quote for pallet group',
            'MISSING_PALLET_GROUP_DATA',
            { palletGroup: assignment.palletGroup }
          );
        }

        return {
          groupId: assignment.palletGroup,
          palletCount: assignment.palletCount,
          charity: {
            id: assignment.charityId,
            name: assignment.charityName,
            locationId: assignment.locationId,
            locationName: assignment.locationName,
            address: task.charityAddress,
            primaryContact: task.primaryContact
          },
          delivery: {
            taskId: task.id,
            status: task.status,
            pickupDateTime: task.pickupDateTime,
            deliveryDate: task.deliveryDate,
            acceptedByCharityDate: task.acceptedByCharityDate,
            createdBy: task.createdBy,
            createdAt: task.createdAt,
            lastUpdatedBy: task.lastUpdatedBy,
            lastUpdatedAt: task.lastUpdatedAt
          },
          documents: {
            shippingQuote: shippingQuote.url,
            billOfLading: task.bolPdfUrl,
            taxReceipt: task.taxReceiptUrl
          },
          shipping: {
            quote: shippingQuote,
            trackingNumber: task.trackingNumber,
            carrier: task.carrier
          }
        };
      })
    }
  };
};

export const completedDonationsService = {
  async createCompletedDonation(quoteId) {
    try {
      // Use a transaction to ensure data consistency
      return await runTransaction(db, async (transaction) => {
        // 1. Fetch and validate all required data
        const data = await fetchAndValidateData(quoteId);
        
        // 2. Build the completed donation document
        const completedDonationData = buildCompletedDonationDocument(data);
        
        // 3. Create new document reference
        const docRef = doc(collection(db, COLLECTION_NAME));
        completedDonationData.id = docRef.id;
        
        // 4. Write the document
        transaction.set(docRef, completedDonationData);
        
        return completedDonationData;
      });
    } catch (error) {
      console.error('Error creating completed donation:', error);
      throw error instanceof CompletedDonationError ? error :
        new CompletedDonationError(
          'Failed to create completed donation',
          'CREATE_ERROR',
          { originalError: error.message }
        );
    }
  },

  // Query methods remain similar but with updated error handling
  async getById(id) {
    try {
      if (!id) throw new CompletedDonationError('ID is required', 'INVALID_PARAMETER');
      
      const docRef = doc(collection(db, COLLECTION_NAME), id);
      const docSnap = await getDoc(docRef);
      
      return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
    } catch (error) {
      throw new CompletedDonationError(
        'Failed to retrieve completed donation',
        'GET_ERROR',
        { originalError: error.message }
      );
    }
  },

  async getByDateRange(startDate, endDate) {
    try {
      if (!startDate || !endDate) {
        throw new CompletedDonationError(
          'Start and end dates are required',
          'INVALID_PARAMETER'
        );
      }

      const q = query(
        collection(db, COLLECTION_NAME),
        where("completedAt", ">=", startDate),
        where("completedAt", "<=", endDate)
      );
      
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDonationError(
        'Failed to retrieve donations by date range',
        'QUERY_ERROR',
        { originalError: error.message }
      );
    }
  },

  async getAll() {
    try {
      const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
      
      if (querySnapshot.empty) {
        return [];
      }
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      throw new CompletedDonationError(
        'Failed to retrieve all completed donations',
        'QUERY_ERROR',
        { originalError: error.message }
      );
    }
  },
};

export default completedDonationsService;