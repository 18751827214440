import React, { useState, useEffect } from 'react';
import {
  FaUpload, FaFileAlt, FaTrash, FaSpinner, FaHandHoldingHeart,
  FaBuilding, FaListAlt, FaPlus, FaMapMarkerAlt,
  FaPhone, FaEnvelope, FaGlobe, FaBoxes, FaFileInvoice,
  FaClock, FaInfoCircle, FaExclamationTriangle, FaWarehouse,
  FaUser, FaCalendarCheck
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { charityService } from '../services/charityService';
import { userService } from '../services/userService';
import '../styles/CreateNewCharity.css';
import CsvPreview from './CsvPreview';
import CharityTable from './Charities/CharityTable';

// Constants
const PATTERNS = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  URL: /^https?:\/\/.+\..+/,
  ZIP: /^\d{5}(-\d{4})?$/,
  EIN: /^\d{2}-\d{7}$/,
  TIME: /^(?:(0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:AM|PM)\s*-\s*(0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:AM|PM)|Closed)$/i
};

const TIME_ZONES = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Hawaii',
  'America/Phoenix'
];

const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

// Initial state for a new location
const initialLocation = {
  isMainLocation: false,
  address: {
    line1: '',
    line2: '',
    city: '',
    stateProvince: '',
    zipCode: '',
    country: 'United States'
  },
  palletCapacity: {
    minimum: null,
    maximum: null,
  },
  contacts: [{
    isMainContact: true,
    firstName: null,
    lastName: null,
    role: '',
    phoneNumber: '',
    email: ''
  }],
  facilities: {
    hasLoadingDock: false,
    needsLiftGate: false,
    needsPalletJack: false
  },
  timeZone: 'America/New_York',
  hours: {
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: ''
  },
  appointmentRequired: false,
  deliveryNotes: ''
};

// Form Section Components
const MainCharityFields = ({ charityData, handleMainCharityChange, errors }) => (
  <div className="form-section">
    <h3>
      <FaBuilding className="inline mr-2" />
      Organization Information
    </h3>
    <div className="form-grid">
      <div className="form-group">
        <label htmlFor="orgName">
          <FaBuilding className="inline mr-2" />
          Organization Name
        </label>
        <input
          id="orgName"
          type="text"
          value={charityData.orgName}
          onChange={(e) => handleMainCharityChange('orgName', e.target.value)}
          className={`form-control ${errors.orgName ? 'error' : ''}`}
          required
        />
        {errors.orgName && <div className="error-text">{errors.orgName}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="ein">
          <FaFileInvoice className="inline mr-2" />
          EIN
        </label>
        <input
          id="ein"
          type="text"
          value={charityData.ein}
          onChange={(e) => handleMainCharityChange('ein', e.target.value)}
          placeholder="XX-XXXXXXX"
          className={`form-control ${errors.ein ? 'error' : ''}`}
          required
        />
        {errors.ein && <div className="error-text">{errors.ein}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="orgWebsite">
          <FaGlobe className="inline mr-2" />
          Website
        </label>
        <input
          id="orgWebsite"
          type="url"
          value={charityData.orgWebsite}
          onChange={(e) => handleMainCharityChange('orgWebsite', e.target.value)}
          placeholder="https://example.org"
          className={`form-control ${errors.orgWebsite ? 'error' : ''}`}
        />
        {errors.orgWebsite && <div className="error-text">{errors.orgWebsite}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="logoUrl">
          <FaFileAlt className="inline mr-2" />
          Logo URL
        </label>
        <input
          id="logoUrl"
          type="url"
          value={charityData.logoUrl}
          onChange={(e) => handleMainCharityChange('logoUrl', e.target.value)}
          placeholder="https://example.org/logo.png"
          className={`form-control ${errors.logoUrl ? 'error' : ''}`}
        />
        {errors.logoUrl && <div className="error-text">{errors.logoUrl}</div>}
      </div>

      <div className="form-group full-width">
        <label htmlFor="acceptableGoods">
          <FaBoxes className="inline mr-2" />
          Acceptable Goods
        </label>
        <input
          id="acceptableGoods"
          type="text"
          value={charityData.acceptableGoods.join(', ')}
          onChange={(e) => handleMainCharityChange('acceptableGoods', 
            e.target.value.split(',').map(item => item.trim()))}
          placeholder="Enter items separated by commas"
          className={`form-control ${errors.acceptableGoods ? 'error' : ''}`}
          required
        />
        {errors.acceptableGoods && 
          <div className="error-text">{errors.acceptableGoods}</div>}
      </div>

      <div className="form-group full-width">
        <label htmlFor="description">
          <FaInfoCircle className="inline mr-2" />
          Description
        </label>
        <textarea
          id="description"
          value={charityData.description}
          onChange={(e) => handleMainCharityChange('description', e.target.value)}
          className={`form-control ${errors.description ? 'error' : ''}`}
          rows="4"
          required
        />
        {errors.description && 
          <div className="error-text">{errors.description}</div>}
      </div>
    </div>
  </div>
);

const AddressFields = ({ location, index, handleLocationChange, errors }) => (
  <div className="form-section">
    <h4 className="text-lg font-semibold mb-4">
      <FaMapMarkerAlt className="inline mr-2" />
      Address Information
    </h4>
    <div className="form-grid">
      <div className="form-group full-width">
        <label htmlFor={`address-line1-${index}`}>
          Street Address
        </label>
        <input
          id={`address-line1-${index}`}
          type="text"
          value={location.address.line1}
          onChange={(e) => handleLocationChange(index, 'address.line1', e.target.value)}
          className={`form-control ${errors?.[`address.line1`] ? 'error' : ''}`}
          required
        />
        {errors?.[`address.line1`] && 
          <div className="error-text">{errors[`address.line1`]}</div>}
      </div>

      <div className="form-group full-width">
        <label htmlFor={`address-line2-${index}`}>
          Address Line 2
        </label>
        <input
          id={`address-line2-${index}`}
          type="text"
          value={location.address.line2}
          onChange={(e) => handleLocationChange(index, 'address.line2', e.target.value)}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor={`city-${index}`}>City</label>
        <input
          id={`city-${index}`}
          type="text"
          value={location.address.city}
          onChange={(e) => handleLocationChange(index, 'address.city', e.target.value)}
          className={`form-control ${errors?.[`address.city`] ? 'error' : ''}`}
          required
        />
        {errors?.[`address.city`] && 
          <div className="error-text">{errors[`address.city`]}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`state-${index}`}>State/Province</label>
        <input
          id={`state-${index}`}
          type="text"
          value={location.address.stateProvince}
          onChange={(e) => handleLocationChange(index, 'address.stateProvince', e.target.value)}
          className={`form-control ${errors?.[`address.stateProvince`] ? 'error' : ''}`}
          required
        />
        {errors?.[`address.stateProvince`] && 
          <div className="error-text">{errors[`address.stateProvince`]}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`zipcode-${index}`}>ZIP Code</label>
        <input
          id={`zipcode-${index}`}
          type="text"
          value={location.address.zipCode}
          onChange={(e) => handleLocationChange(index, 'address.zipCode', e.target.value)}
          className={`form-control ${errors?.[`address.zipCode`] ? 'error' : ''}`}
          required
        />
        {errors?.[`address.zipCode`] && 
          <div className="error-text">{errors[`address.zipCode`]}</div>}
      </div>
    </div>
  </div>
);

const ContactFields = ({ location, locationIndex, handleLocationChange, addContact, removeContact, errors }) => (
  <div className="form-section">
    <div className="flex justify-between items-center mb-4">
      <h4 className="text-lg font-semibold">
        <FaUser className="inline mr-2" />
        Contacts
      </h4>
      <button
        type="button"
        onClick={() => addContact(locationIndex)}
        className="action-button"
      >
        <FaPlus className="inline mr-2" />
        Add Contact
      </button>
    </div>

    {location.contacts.map((contact, contactIndex) => (
      <div key={contactIndex} className="contact-form bg-gray-50 p-4 rounded-lg mb-4">
        <div className="flex justify-between items-center mb-4">
          <h5 className="font-semibold">
            Contact {contactIndex + 1}
            {contact.isMainContact && ' (Main)'}
          </h5>
          {!contact.isMainContact && (
            <button
              type="button"
              onClick={() => removeContact(locationIndex, contactIndex)}
              className="action-button danger"
            >
              <FaTrash className="inline mr-2" />
              Remove
            </button>
          )}
        </div>

        <div className="form-grid">
          <div className="form-group">
            <label htmlFor={`contact-firstname-${locationIndex}-${contactIndex}`}>
              First Name
            </label>
            <input
              id={`contact-firstname-${locationIndex}-${contactIndex}`}
              type="text"
              value={contact.firstName}
              onChange={(e) => handleLocationChange(
                locationIndex,
                `contacts[${contactIndex}].firstName`,
                e.target.value
              )}
              className={`form-control ${errors?.[`contacts.${contactIndex}.firstName`] ? 'error' : ''}`}
            />
            {errors?.[`contacts.${contactIndex}.firstName`] && 
              <div className="error-text">
                {errors[`contacts.${contactIndex}.firstName`]}
              </div>}
          </div>

          <div className="form-group">
            <label htmlFor={`contact-lastname-${locationIndex}-${contactIndex}`}>
              Last Name
            </label>
            <input
              id={`contact-lastname-${locationIndex}-${contactIndex}`}
              type="text"
              value={contact.lastName}
              onChange={(e) => handleLocationChange(
                locationIndex,
                `contacts[${contactIndex}].lastName`,
                e.target.value
              )}
              className={`form-control ${errors?.[`contacts.${contactIndex}.lastName`] ? 'error' : ''}`}
            />
            {errors?.[`contacts.${contactIndex}.lastName`] && 
              <div className="error-text">
                {errors[`contacts.${contactIndex}.lastName`]}
              </div>}
          </div>

          <div className="form-group">
            <label htmlFor={`contact-role-${locationIndex}-${contactIndex}`}>
              Role
            </label>
            <input
              id={`contact-role-${locationIndex}-${contactIndex}`}
              type="text"
              value={contact.role}
              onChange={(e) => handleLocationChange(
                locationIndex,
                `contacts[${contactIndex}].role`,
                e.target.value
              )}
              className={`form-control ${errors?.[`contacts.${contactIndex}.role`] ? 'error' : ''}`}
              required
            />
            {errors?.[`contacts.${contactIndex}.role`] && 
              <div className="error-text">
                {errors[`contacts.${contactIndex}.role`]}
              </div>}
          </div>

          <div className="form-group">
            <label htmlFor={`contact-phone-${locationIndex}-${contactIndex}`}>
              <FaPhone className="inline mr-2" />
              Phone Number
            </label>
            <input
              id={`contact-phone-${locationIndex}-${contactIndex}`}
              type="tel"
              value={contact.phoneNumber}
              onChange={(e) => handleLocationChange(
                locationIndex,
                `contacts[${contactIndex}].phoneNumber`,
                e.target.value
              )}
              className={`form-control ${errors?.[`contacts.${contactIndex}.phoneNumber`] ? 'error' : ''}`}
              required
            />
            {errors?.[`contacts.${contactIndex}.phoneNumber`] && 
              <div className="error-text">
                {errors[`contacts.${contactIndex}.phoneNumber`]}
              </div>}
          </div>

          <div className="form-group">
            <label htmlFor={`contact-email-${locationIndex}-${contactIndex}`}>
              <FaEnvelope className="inline mr-2" />
              Email
            </label>
            <input
              id={`contact-email-${locationIndex}-${contactIndex}`}
              type="email"
              value={contact.email}
              onChange={(e) => handleLocationChange(
                locationIndex,
                `contacts[${contactIndex}].email`,
                e.target.value
              )}
              className={`form-control ${errors?.[`contacts.${contactIndex}.email`] ? 'error' : ''}`}
              required
            />
            {errors?.[`contacts.${contactIndex}.email`] && 
              <div className="error-text">
                {errors[`contacts.${contactIndex}.email`]}
              </div>}
          </div>
        </div>
      </div>
    ))}
  </div>
);

const FacilitiesFields = ({ location, index, handleLocationChange, errors }) => (
  <div className="form-section">
    <h4 className="text-lg font-semibold mb-4">
      <FaWarehouse className="inline mr-2" />
      Facilities & Capacity
    </h4>

    <div className="form-grid">
      <div className="form-group full-width">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="checkbox-wrapper">
            <input
              id={`loading-dock-${index}`}
              type="checkbox"
              checked={location.facilities.hasLoadingDock}
              onChange={(e) => handleLocationChange(index, 'facilities.hasLoadingDock', e.target.checked)}
              className="checkbox"
              aria-checked={location.facilities.hasLoadingDock}
            />
            <label className="checkbox-label" htmlFor={`loading-dock-${index}`}>
              <span>Has Loading Dock</span>
            </label>
          </div>

          <div className="checkbox-wrapper">
            <input
              id={`lift-gate-${index}`}
              type="checkbox"
              checked={location.facilities.needsLiftGate}
              onChange={(e) => handleLocationChange(index, 'facilities.needsLiftGate', e.target.checked)}
              className="checkbox"
              aria-checked={location.facilities.needsLiftGate}
            />
            <label className="checkbox-label" htmlFor={`lift-gate-${index}`}>
              <span>Needs Lift Gate</span>
            </label>
          </div>

          <div className="checkbox-wrapper">
            <input
              id={`pallet-jack-${index}`}
              type="checkbox"
              checked={location.facilities.needsPalletJack}
              onChange={(e) => handleLocationChange(index, 'facilities.needsPalletJack', e.target.checked)}
              className="checkbox"
              aria-checked={location.facilities.needsPalletJack}
            />
            <label className="checkbox-label" htmlFor={`pallet-jack-${index}`}>
              <span>Needs Pallet Jack</span>
            </label>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor={`min-pallets-${index}`}>
          Minimum Pallet Capacity
        </label>
        <input
          id={`min-pallets-${index}`}
          type="number"
          min="0"
          value={location.palletCapacity.minimum}
          onChange={(e) => handleLocationChange(index, 'palletCapacity.minimum', parseInt(e.target.value))}
          className={`form-control ${errors?.['palletCapacity.minimum'] ? 'error' : ''}`}
        />
        {errors?.['palletCapacity.minimum'] && 
          <div className="error-text">{errors['palletCapacity.minimum']}</div>}
      </div>

      <div className="form-group">
        <label htmlFor={`max-pallets-${index}`}>
          Maximum Pallet Capacity
        </label>
        <input
          id={`max-pallets-${index}`}
          type="number"
          min="0"
          value={location.palletCapacity.maximum}
          onChange={(e) => handleLocationChange(index, 'palletCapacity.maximum', parseInt(e.target.value))}
          className={`form-control ${errors?.['palletCapacity.maximum'] ? 'error' : ''}`}
        />
        {errors?.['palletCapacity.maximum'] && 
          <div className="error-text">{errors['palletCapacity.maximum']}</div>}
      </div>
    </div>
  </div>
);

const OperatingHours = ({ location, index, handleLocationChange, errors }) => (
  <div className="form-section">
    <h4 className="text-lg font-semibold mb-4">
      <FaClock className="inline mr-2" />
      Operating Hours
    </h4>

    <div className="form-group mb-4">
      <label htmlFor={`timezone-${index}`}>Time Zone</label>
      <select
        id={`timezone-${index}`}
        value={location.timeZone}
        onChange={(e) => handleLocationChange(index, 'timeZone', e.target.value)}
        className="form-control"
        required
      >
        {TIME_ZONES.map(zone => (
          <option key={zone} value={zone}>{zone}</option>
        ))}
      </select>
    </div>

    <div className="operating-hours-grid">
      {DAYS_OF_WEEK.map(day => (
        <div key={day} className="form-group">
          <label htmlFor={`hours-${day}-${index}`}>
            {day.charAt(0).toUpperCase() + day.slice(1)}
          </label>
          <input
            id={`hours-${day}-${index}`}
            type="text"
            value={location.hours[day]}
            onChange={(e) => handleLocationChange(index, `hours.${day}`, e.target.value)}
            placeholder="9:00 AM - 5:00 PM or Closed"
            className={`form-control ${errors?.[`hours.${day}`] ? 'error' : ''}`}
          />
          {errors?.[`hours.${day}`] && 
            <div className="error-text">{errors[`hours.${day}`]}</div>}
        </div>
      ))}
    </div>
  </div>
);

const DeliveryInfo = ({ location, index, handleLocationChange }) => (
  <div className="form-section">
    <h4 className="text-lg font-semibold mb-4">
      <FaCalendarCheck className="inline mr-2" />
      Delivery Information
    </h4>

    <div className="form-group mb-4">
      <div className="checkbox-wrapper">
        <input
          id={`appointment-required-${index}`}
          type="checkbox"
          checked={location.appointmentRequired}
          onChange={(e) => handleLocationChange(index, 'appointmentRequired', e.target.checked)}
          className="checkbox"
          aria-checked={location.appointmentRequired}
        />
        <label className="checkbox-label" htmlFor={`appointment-required-${index}`}>
          Appointment Required for Deliveries
        </label>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor={`delivery-notes-${index}`}>Delivery Notes</label>
      <textarea
        id={`delivery-notes-${index}`}
        value={location.deliveryNotes}
        onChange={(e) => handleLocationChange(index, 'deliveryNotes', e.target.value)}
        className="form-control"
        rows="4"
        placeholder="Enter any special instructions or notes for deliveries"
      />
    </div>
  </div>
);

function CreateNewCharity() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [entryMode, setEntryMode] = useState('manual');
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [charities, setCharities] = useState([]);

  // Initialize charity data state
  const [charityData, setCharityData] = useState({
    orgName: '',
    description: '',
    logoUrl: '',
    orgWebsite: '',
    organizationCauses: [],
    acceptableGoods: [],
    ein: '',
    organizationNotes: '',
    inventorySoftware: '',
    locations: [{
      ...initialLocation,
      isMainLocation: true
    }]
  });

  const [errors, setErrors] = useState({});

  // Add the useEffect for loading charities
  useEffect(() => {
    const loadCharities = async () => {
      if (entryMode === 'edit') {
        try {
          const loadedCharities = await charityService.getAll();
          setCharities(loadedCharities);
        } catch (error) {
          console.error('Error loading charities:', error);
          toast.error('Failed to load charities');
        }
      }
    };

    loadCharities();
  }, [entryMode]);

  // Authentication and Initial Data Loading
  useEffect(() => {
    const initializeComponent = async () => {
      setIsLoading(true);
      try {
        const currentUser = await authService.getCurrentUser();
        const userData = authService.getUserSession();

        if (currentUser) {
          if (userData?.role === 'SystemAdmin') {
            const fullUserData = await userService.getById(userData.id);
            setUser(fullUserData);
          } else {
            toast.error('You do not have permission to access this page.');
          }
        } else {
          toast.error('Please log in to access this page.');
        }
      } catch (error) {
        console.error('Error initializing component:', error);
        toast.error('An error occurred while loading the page.');
      } finally {
        setIsLoading(false);
      }
    };

    initializeComponent();
  }, []);

  // Form Field Change Handlers
  const handleMainCharityChange = (field, value) => {
    setCharityData(prev => ({
      ...prev,
      [field]: value
    }));
    
    // Clear error when field is modified
    if (errors[field]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handleLocationChange = (locationIndex, field, value) => {
    setCharityData(prev => {
      const newLocations = [...prev.locations];
      const fieldParts = field.split('.');
      
      if (field.includes('[')) {
        // Handle array fields (e.g., contacts[0].firstName)
        const [arrayField, rest] = field.split('[');
        const index = parseInt(rest);
        const remaining = rest.split('.').slice(1).join('.');
        const array = newLocations[locationIndex][arrayField];
        array[index] = {
          ...array[index],
          [remaining]: value
        };
      } else {
        // Handle nested object fields
        let target = newLocations[locationIndex];
        for (let i = 0; i < fieldParts.length - 1; i++) {
          target = target[fieldParts[i]];
        }
        target[fieldParts[fieldParts.length - 1]] = value;
      }

      return {
        ...prev,
        locations: newLocations
      };
    });

    // Clear location-specific error when field is modified
    if (errors[`location${locationIndex}`]?.[field]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        if (newErrors[`location${locationIndex}`]) {
          delete newErrors[`location${locationIndex}`][field];
          if (Object.keys(newErrors[`location${locationIndex}`]).length === 0) {
            delete newErrors[`location${locationIndex}`];
          }
        }
        return newErrors;
      });
    }
  };

  // Location Management
  const addLocation = () => {
    setCharityData(prev => ({
      ...prev,
      locations: [...prev.locations, {
        ...initialLocation,
        isMainLocation: false
      }]
    }));
  };

  const removeLocation = (index) => {
    if (charityData.locations.length > 1) {
      setCharityData(prev => ({
        ...prev,
        locations: prev.locations.filter((_, i) => i !== index)
      }));
      
      // Clear any errors for the removed location
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[`location${index}`];
        return newErrors;
      });
    }
  };

  // Contact Management
  const addContact = (locationIndex) => {
    setCharityData(prev => {
      const newLocations = [...prev.locations];
      newLocations[locationIndex].contacts.push({
        isMainContact: false,
        firstName: '',
        lastName: '',
        role: '',
        phoneNumber: '',
        email: ''
      });
      return {
        ...prev,
        locations: newLocations
      };
    });
  };

  const removeContact = (locationIndex, contactIndex) => {
    if (charityData.locations[locationIndex].contacts.length > 1) {
      setCharityData(prev => {
        const newLocations = [...prev.locations];
        newLocations[locationIndex].contacts = 
          newLocations[locationIndex].contacts.filter((_, i) => i !== contactIndex);
        return {
          ...prev,
          locations: newLocations
        };
      });

      // Clear any errors for the removed contact
      setErrors(prev => {
        const newErrors = { ...prev };
        if (newErrors[`location${locationIndex}`]) {
          Object.keys(newErrors[`location${locationIndex}`]).forEach(key => {
            if (key.startsWith(`contacts.${contactIndex}`)) {
              delete newErrors[`location${locationIndex}`][key];
            }
          });
        }
        return newErrors;
      });
    }
  };

  // Validation
  const validateCharity = () => {
    const newErrors = {};

    // Main charity validation
    if (!charityData.orgName?.trim()) {
      newErrors.orgName = 'Organization name is required';
    }
    if (!charityData.ein || !PATTERNS.EIN.test(charityData.ein)) {
      newErrors.ein = 'Valid EIN is required (XX-XXXXXXX)';
    }
    if (charityData.orgWebsite && !PATTERNS.URL.test(charityData.orgWebsite)) {
      newErrors.orgWebsite = 'Invalid website URL';
    }
    if (charityData.logoUrl && !PATTERNS.URL.test(charityData.logoUrl)) {
      newErrors.logoUrl = 'Invalid logo URL';
    }
    if (!charityData.acceptableGoods?.length) {
      newErrors.acceptableGoods = 'At least one acceptable good is required';
    }
    if (!charityData.description?.trim()) {
      newErrors.description = 'Description is required';
    }

    // Location validation
    charityData.locations.forEach((location, index) => {
      const locationErrors = {};

      // Validate address
      if (!location.address.line1?.trim()) {
        locationErrors['address.line1'] = 'Street address is required';
      }
      if (!location.address.city?.trim()) {
        locationErrors['address.city'] = 'City is required';
      }
      if (!location.address.stateProvince?.trim()) {
        locationErrors['address.stateProvince'] = 'State/Province is required';
      }
      if (!location.address.zipCode || !PATTERNS.ZIP.test(location.address.zipCode)) {
        locationErrors['address.zipCode'] = 'Valid ZIP code is required';
      }

      // Validate pallet capacity
      if (location.palletCapacity.minimum !== null && location.palletCapacity.minimum !== undefined && 
        location.palletCapacity.minimum < 0) {
      locationErrors['palletCapacity.minimum'] = 'Minimum capacity cannot be negative';
      }
      if (location.palletCapacity.minimum !== null && location.palletCapacity.minimum !== undefined &&
          location.palletCapacity.maximum !== null && location.palletCapacity.maximum !== undefined &&
          location.palletCapacity.maximum <= location.palletCapacity.minimum) {
        locationErrors['palletCapacity.maximum'] = 'Maximum capacity must be greater than minimum';
      }

      // Validate contacts
      location.contacts.forEach((contact, contactIndex) => {
        if (!contact.email || !PATTERNS.EMAIL.test(contact.email)) {
          locationErrors[`contacts.${contactIndex}.email`] = 'Valid email is required';
        }
        if (!contact.phoneNumber || !PATTERNS.PHONE.test(contact.phoneNumber)) {
          locationErrors[`contacts.${contactIndex}.phoneNumber`] = 'Valid phone number is required';
        }
        if (!contact.role?.trim()) {
          locationErrors[`contacts.${contactIndex}.role`] = 'Role is required';
        }
      });

      // Validate operating hours format
      Object.entries(location.hours).forEach(([day, hours]) => {
        if (hours && !PATTERNS.TIME.test(hours)) {
          locationErrors[`hours.${day}`] = 'Invalid time format (use: HH:MM AM/PM - HH:MM PM or Closed)';
        }
      });

      if (Object.keys(locationErrors).length > 0) {
        newErrors[`location${index}`] = locationErrors;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // CSV Processing
  const validateCsvData = (data) => {
    const errors = [];
    const requiredFields = [
      'orgname', 'ein', 'addressline1', 'city', 'state', 'zipcode','contactemail', 'contactphone', 'contactrole'
    ];

    data.forEach((row, index) => {
      requiredFields.forEach(field => {
        if (!row[field]) {
          errors.push(`Row ${index + 2}: Missing required field "${field}"`);
        }
      });

      // Validate field formats
      if (row.ein && !PATTERNS.EIN.test(row.ein)) {
        errors.push(`Row ${index + 2}: Invalid EIN format (XX-XXXXXXX)`);
      }
      if (row.contactemail && !PATTERNS.EMAIL.test(row.contactemail)) {
        errors.push(`Row ${index + 2}: Invalid email format`);
      }
      if (row.contactphone && !PATTERNS.PHONE.test(row.contactphone)) {
        errors.push(`Row ${index + 2}: Invalid phone format`);
      }
      if (row.website && !PATTERNS.URL.test(row.website)) {
        errors.push(`Row ${index + 2}: Invalid website URL`);
      }
      if (row.logourl && !PATTERNS.URL.test(row.logourl)) {
        errors.push(`Row ${index + 2}: Invalid logo URL`);
      }
      if (row.zipcode && !PATTERNS.ZIP.test(row.zipcode)) {
        errors.push(`Row ${index + 2}: Invalid ZIP code`);
      }

      // Validate numeric fields if provided
      ['minpallets', 'maxpallets'].forEach(field => {
        if (row[field] !== undefined && row[field] !== '' && isNaN(parseInt(row[field]))) {
          errors.push(`Row ${index + 2}: ${field} if provided must be a number`);
        }
      });

      // Only validate min/max relationship if both are provided
      if (row.minpallets !== undefined && row.minpallets !== '' && 
          row.maxpallets !== undefined && row.maxpallets !== '' &&
          parseInt(row.maxpallets) <= parseInt(row.minpallets)) {
        errors.push(`Row ${index + 2}: If both provided, maximum pallet capacity must be greater than minimum`);
      }

      // Validate hours format
      DAYS_OF_WEEK.forEach(day => {
        const hourField = `${day}hours`;
        if (row[hourField] && !PATTERNS.TIME.test(row[hourField])) {
          errors.push(`Row ${index + 2}: Invalid time format for ${hourField}. Use "HH:MM AM/PM - HH:MM PM" or "Closed"`);
        }
      });

      // Validate boolean fields
      ['hasloadingdock', 'needsliftgate', 'needspalletjack', 'appointmentrequired'].forEach(field => {
        if (row[field] && !['true', 'false'].includes(row[field].toLowerCase())) {
          errors.push(`Row ${index + 2}: ${field} must be either "true" or "false"`);
        }
      });
    });

    return errors;
  };

  const processCsvData = (data) => {
    return data.map(row => ({
      orgName: row.orgname,
      description: row.description || '',
      logoUrl: row.logourl || '',
      orgWebsite: row.website || '',
      organizationCauses: (row.causes || '').split(',').map(c => c.trim()).filter(Boolean),
      acceptableGoods: (row.acceptablegoods || '').split(',').map(g => g.trim()).filter(Boolean),
      ein: row.ein,
      organizationNotes: row.notes || '',
      inventorySoftware: row.inventorysoftware || '',
      locations: [{
        isMainLocation: true,
        address: {
          line1: row.addressline1,
          line2: row.addressline2 || '',
          city: row.city,
          stateProvince: row.state,
          zipCode: row.zipcode,
          country: row.country || 'United States'
        },
        palletCapacity: {
          minimum: row.minpallets ? parseInt(row.minpallets) : null,
          maximum: row.maxpallets ? parseInt(row.maxpallets) : null
        },
        contacts: [{
          isMainContact: true,
          firstName: row.contactfirstname,
          lastName: row.contactlastname,
          role: row.contactrole,
          phoneNumber: row.contactphone,
          email: row.contactemail
        }],
        facilities: {
          hasLoadingDock: row.hasloadingdock?.toLowerCase() === 'true',
          needsLiftGate: row.needsliftgate?.toLowerCase() === 'true',
          needsPalletJack: row.needspalletjack?.toLowerCase() === 'true'
        },
        timeZone: row.timezone || 'America/New_York',
        hours: DAYS_OF_WEEK.reduce((acc, day) => ({
          ...acc,
          [day]: row[`${day}hours`] || ''
        }), {}),
        appointmentRequired: row.appointmentrequired?.toLowerCase() === 'true',
        deliveryNotes: row.deliverynotes || ''
      }]
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name);
    setErrors({});
    setCsvData([]);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data && result.data.length > 0) {
          const validationErrors = validateCsvData(result.data);
          if (validationErrors.length > 0) {
            setErrors({ csv: validationErrors });
            toast.error('Validation errors found in CSV file.');
          } else {
            const processedData = processCsvData(result.data);
            setCsvData(processedData);
            toast.success('CSV file processed successfully');
          }
        } else {
          setErrors({ csv: ['The CSV file is empty or invalid'] });
          toast.error('Invalid CSV file');
        }
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
        setErrors({ csv: [`Error parsing CSV: ${error.message}`] });
        toast.error('Error parsing CSV file');
      }
    });
  };

  const handleSelectCharity = async (charity) => {
    try {
      const fullCharity = await charityService.getById(charity.id);
      setSelectedCharity(charity);
      setCharityData({
        ...fullCharity,
        locations: fullCharity.locations || []
      });
    } catch (error) {
      console.error('Error loading charity details:', error);
      toast.error('Failed to load charity details');
    }
  };

  // Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateCharity()) {
      toast.error('Please fix validation errors before submitting');
      return;
    }
  
    setIsLoading(true);
    try {
      const timestamp = new Date();
      
      if (selectedCharity) {
        // Update existing charity
        const mainCharityData = {
          orgName: charityData.orgName,
          description: charityData.description,
          logoUrl: charityData.logoUrl,
          orgWebsite: charityData.orgWebsite,
          organizationCauses: charityData.organizationCauses,
          acceptableGoods: charityData.acceptableGoods,
          ein: charityData.ein,
          organizationNotes: charityData.organizationNotes,
          inventorySoftware: charityData.inventorySoftware,
          updatedAt: timestamp
        };
  
        // Update existing charity
        await charityService.update(selectedCharity.id, mainCharityData);
      
        // Get existing locations to compare
        const existingCharity = await charityService.getById(selectedCharity.id);
        const existingLocations = existingCharity.locations || [];
        const existingLocationIds = new Set(existingLocations.map(loc => loc.id));
        
        // Track processed locations to identify deleted ones
        const processedLocationIds = new Set();
  
        // Update or create locations
        for (const location of charityData.locations) {
          if (location.id) {
            // Update existing location
            await charityService.updateLocation(selectedCharity.id, location.id, {
              ...location,
              updatedAt: timestamp
            });
            processedLocationIds.add(location.id);
          } else {
            // Create new location
            await charityService.createLocation(selectedCharity.id, {
              ...location,
              createdAt: timestamp,
              updatedAt: timestamp
            });
          }
        }
  
        // Delete removed locations
        const deletedLocationIds = Array.from(existingLocationIds)
          .filter(id => !processedLocationIds.has(id));
        
        for (const locationId of deletedLocationIds) {
          await charityService.deleteLocation(selectedCharity.id, locationId);
        }
  
        toast.success('Charity updated successfully!');
        
        // Refresh charities list
        const updatedCharities = await charityService.getAll();
        setCharities(updatedCharities);
  
      } else {
        // Create new charity with all data including locations
        const fullCharityData = {
          ...charityData,
          createdAt: timestamp,
          updatedAt: timestamp
        };
        
        const response = await charityService.create(fullCharityData);
        console.log('Created new charity with ID:', response);
        toast.success('Charity created successfully!');
      }
  
      // Reset form
      setCharityData({
        orgName: '',
        description: '',
        logoUrl: '',
        orgWebsite: '',
        organizationCauses: [],
        acceptableGoods: [],
        ein: '',
        organizationNotes: '',
        inventorySoftware: '',
        locations: [{
          ...initialLocation,
          isMainLocation: true
        }]
      });
      setSelectedCharity(null);
      setErrors({});
  
    } catch (error) {
      console.error('Error saving charity:', error);
      toast.error(`Failed to ${selectedCharity ? 'update' : 'create'} charity. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCharityForm = () => (
    <form onSubmit={handleSubmit}>
      <MainCharityFields
        charityData={charityData}
        handleMainCharityChange={handleMainCharityChange}
        errors={errors}
      />
  
      {charityData.locations.map((location, index) => (
        <div key={index} className="location-section">
          <div className="form-section-header">
            <h3>
              <FaWarehouse className="inline mr-2" />
              Location {index + 1}
              {location.isMainLocation && ' (Main)'}
            </h3>
            {!location.isMainLocation && (
              <button
                type="button"
                onClick={() => removeLocation(index)}
                className="remove-button"
              >
                <FaTrash className="inline mr-2" />
                Remove Location
              </button>
            )}
          </div>
  
          <AddressFields
            location={location}
            index={index}
            handleLocationChange={handleLocationChange}
            errors={errors[`location${index}`]}
          />
  
          <ContactFields
            location={location}
            locationIndex={index}
            handleLocationChange={handleLocationChange}
            addContact={addContact}
            removeContact={removeContact}
            errors={errors[`location${index}`]}
          />
  
          <FacilitiesFields
            location={location}
            index={index}
            handleLocationChange={handleLocationChange}
            errors={errors[`location${index}`]}
          />
  
          <OperatingHours
            location={location}
            index={index}
            handleLocationChange={handleLocationChange}
            errors={errors[`location${index}`]}
          />
  
          <DeliveryInfo
            location={location}
            index={index}
            handleLocationChange={handleLocationChange}
          />
        </div>
      ))}
  
      <div className="form-actions">
        <button
          type="button"
          onClick={addLocation}
          className="action-button"
        >
          <FaPlus className="inline mr-2" />
          Add Another Location
        </button>
  
        <button
          type="submit"
          disabled={isLoading}
          className="submit-button"
        >
          {isLoading ? (
            <>
              <FaSpinner className="inline mr-2 spinner" />
              {selectedCharity ? 'Updating...' : 'Creating...'}
            </>
          ) : (
            <>
              <FaHandHoldingHeart className="inline mr-2" />
              {selectedCharity ? 'Update Charity' : 'Create Charity'}
            </>
          )}
        </button>
      </div>
    </form>
  );

  const handleCsvSubmit = async () => {
    setIsLoading(true);
    try {
      const timestamp = new Date();
      
      // Process each charity in the CSV data
      for (const charityData of csvData) {
        // Create main charity document with locations included
        const charityId = await charityService.create({
          ...charityData,
          createdAt: timestamp,
          updatedAt: timestamp
        });
        
        // No need for separate location creation since charityService.create handles it
      }
  
      toast.success(`Successfully created ${csvData.length} ${csvData.length === 1 ? 'charity' : 'charities'}!`);
      setFileName('');
      setCsvData([]);
      
    } catch (error) {
      console.error('Error creating charities:', error);
      toast.error('Failed to create charities. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !user) return <Loader />;
  if (!user) return <div className="access-denied">Please log in to access this page.</div>;

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="create-new-charity-container">
          <h1>
            <FaHandHoldingHeart className="inline mr-2" />
            {entryMode === 'edit' ? 'Edit Charity' : 'Create New Charity'}
          </h1>
  
          <div className="create-new-charity-form">
            <div className="form-section">
              <div className="form-group">
                <label htmlFor="entry-mode">
                  <FaListAlt className="inline mr-2" />
                  Entry Mode
                </label>
                <select
                  id="entry-mode"
                  value={entryMode}
                  onChange={(e) => {
                    setEntryMode(e.target.value);
                    setCharityData({
                      orgName: '',
                      description: '',
                      logoUrl: '',
                      orgWebsite: '',
                      organizationCauses: [],
                      acceptableGoods: [],
                      ein: '',
                      organizationNotes: '',
                      inventorySoftware: '',
                      locations: [{
                        ...initialLocation,
                        isMainLocation: true
                      }]
                    });
                    setSelectedCharity(null);
                  }}
                  className="form-control"
                >
                  <option value="manual">Manual Entry</option>
                  <option value="csv">CSV Upload</option>
                  <option value="edit">Edit Charity</option>
                </select>
              </div>
  
              {entryMode === 'csv' && (
                <div className="csv-container">
                  <div className="file-upload">
                    <label htmlFor="csv-file">
                      <FaUpload className="inline mr-2" />
                      Upload CSV File
                    </label>
                    <input
                      id="csv-file"
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                    {fileName && (
                      <div className="file-info">
                        <FaFileAlt className="inline mr-2" />
                        {fileName}
                        <button
                          type="button"
                          onClick={() => {
                            setFileName('');
                            setCsvData([]);
                          }}
                          className="remove-file"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    )}
                    {errors.csv && (
                      <div className="message message-error">
                        <FaExclamationTriangle className="inline mr-2" />
                        {Array.isArray(errors.csv) ? (
                          <ul className="list-disc pl-5">
                            {errors.csv.map((error, index) => (
                              <li key={index}>{error}</li>
                            ))}
                          </ul>
                        ) : (
                          errors.csv
                        )}
                      </div>
                    )}
                  </div>
                  
                  {csvData.length > 0 && (
                    <div className="csv-preview-container">
                      <CsvPreview 
                        csvData={csvData}
                        isLoading={isLoading}
                        onSubmit={handleCsvSubmit}
                      />
                    </div>
                  )}
                </div>
              )}
  
              {entryMode === 'edit' && (
                <div className="edit-charity-container">
                  <CharityTable
                    charities={charities}
                    onSelectCharity={handleSelectCharity}
                    selectedCharityId={selectedCharity?.id}
                  />
                  {selectedCharity && renderCharityForm()}
                </div>
              )}
  
              {entryMode === 'manual' && renderCharityForm()}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateNewCharity;