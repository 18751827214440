// src/services/userService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { distributionCenterService } from '../services/distributionCenterService';
import { companyService } from '../services/companyService';
import { authService } from '../services/authService';

const COLLECTION_NAME = 'users';

export const userService = {
  async create(userData) {
    try {
      const { email, password, ...userDataWithoutPassword } = userData;
      
      // Create user in Firebase Authentication
      const firebaseUser = await authService.register(email, password);
      
      // Prepare user data for Firestore
      const firestoreUserData = {
        ...userDataWithoutPassword,
        id: firebaseUser.uid,
        email: email
      };
  
      // Add user to Firestore
      await setDoc(doc(db, COLLECTION_NAME, firebaseUser.uid), firestoreUserData);
  
      return firebaseUser.uid;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },

  async getById(userId) {
    const userDoc = await getDoc(doc(db, COLLECTION_NAME, userId));
    return userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null;
  },

  async getUserNameById(userId) {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData.name || 'Unknown User';
      }
      return 'Unknown User';
    } catch (error) {
      console.error('Error fetching user name:', error);
      return 'Unknown User';
    }
  },
  
  async getByCompany(companyId) {
    const q = query(collection(db, COLLECTION_NAME), where("company", "==", companyId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async getByDistributionCenter(dcId) {
    const q = query(collection(db, COLLECTION_NAME), where("distributionCenter", "==", dcId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async update(userId, updateData) {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, updateData);

      // If updating a System Admin's companies, ensure it includes all companies
      if (updateData.role === 'SystemAdmin') {
        const allCompanies = await companyService.getAll();
        const allCompanyIds = allCompanies.map(company => company.id);
        await updateDoc(userRef, { companies: allCompanyIds });
      }
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  },

  async delete(userId) {
    await deleteDoc(doc(db, COLLECTION_NAME, userId));
  },

  async getAdminsByCompany(companyId) {
    const q = query(
      collection(db, COLLECTION_NAME),
      where("company", "==", companyId),
      where("role", "==", "Admin")
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async createSystemAdmin(userData) {
    try {
      const { password, ...userDataWithoutPassword } = userData;
      const firebaseUser = await authService.register(userData.email, password);
      
      const systemAdminData = {
        ...userDataWithoutPassword,
        role: 'SystemAdmin',
        companies: userData.companies || [],
        distributionCenters: []
        // Note: We're not setting the 'company' field for System Admin
      };
  
      await setDoc(doc(db, 'users', firebaseUser.uid), systemAdminData);
  
      return firebaseUser.uid;
    } catch (error) {
      console.error('Error creating System Admin:', error);
      throw error;
    }
  },

  async getUserRole(userId) {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData.role || 'User';
      }
      return 'User';
    } catch (error) {
      console.error('Error fetching user role:', error);
      return 'User';
    }
  },

  async getAllUsers() {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

async getUsersByRole(role) {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where("role", "==", role));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting users by role:', error);
    throw error;
  }
},
};